/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Drawer, TextField, Button, Icon } from '@upperhand/playmaker';

import WysiwygEditor from 'shared/components/WysiwygEditor.jsx';

import { t } from 'shared/utils/LocaleUtils';

import EmailTemplate from 'shared/records/EmailTemplate';

import './styles.scss';

function DefaultActions({
  disableConfirm,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
}) {
  return (
    <>
      <Button type="secondary" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button disabled={disableConfirm} onClick={onConfirm}>
        {confirmLabel}
      </Button>
    </>
  );
}

function EmailTemplatePreview({ emailTemplate }) {
  return (
    <div
      className={clsx('email-preview', 'rich-text-container')}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: emailTemplate }}
    />
  );
}

function EmailTemplateDrawer(props) {
  const intl = useIntl();
  const {
    hideSubject = false,
    templatePreview = null,
    isOpen = false,
    isTemplatePreview = false,
    title = t('.title', intl, __filenamespace),
    confirmLabel = t('.create', intl, __filenamespace),
    cancelLabel = t('.cancel', intl, __filenamespace),
    previewLabel = t('.preview', intl, __filenamespace),
    backToEditLabel = t('.back_to', intl, __filenamespace),
    emailTemplate = new EmailTemplate(),
    disableConfirm = false,
    onClose = () => null,
    onChange = () => null,
    onConfirm = () => null,
    onCancel = () => null,
    onPreview = undefined,
  } = props;
  const showContent = isOpen && !isTemplatePreview;
  const showPreview = isOpen && isTemplatePreview;

  React.useEffect(() => {
    const preview = document?.querySelector('.email-preview');

    if (showPreview && emailTemplate && templatePreview && preview) {
      const messageBody = preview.querySelector('.message_body');
      const messageSubject = preview.querySelector('.message_subject');

      messageBody.innerHTML = emailTemplate.body;
      messageSubject.innerHTML = emailTemplate.subject;
    }
  }, [showPreview, emailTemplate, templatePreview]);

  return (
    <Drawer
      title={
        <>
          <div className="email-template-drawer__title">
            <Icon name="messaging" />
            {title}
          </div>
          {typeof onPreview === 'function' && (
            <button type="button" className="preview" onClick={onPreview}>
              {isTemplatePreview ? backToEditLabel : previewLabel}
            </button>
          )}
        </>
      }
      open={isOpen}
      headerType="dark"
      classes={{
        root: 'email-template-drawer',
        content: 'email-template-drawer__content',
        footer: 'email-template-drawer__footer',
        actions: 'email-template-drawer__actions',
        header: 'email-template-drawer__header',
      }}
      content={
        <>
          {showContent && (
            <>
              {!hideSubject && (
                <TextField
                  fullWidth
                  label="Subject"
                  value={emailTemplate.get('subject')}
                  onChange={e => onChange('subject', e.target.value)}
                />
              )}
              <WysiwygEditor
                useBase64={false}
                value={emailTemplate.get('body')}
                height={window.innerHeight - 300}
                onChange={value => onChange('body', value)}
              />
            </>
          )}
          {showPreview && (
            <EmailTemplatePreview emailTemplate={templatePreview} />
          )}
        </>
      }
      footer={
        <DefaultActions
          confirmLabel={confirmLabel}
          cancelLabel={cancelLabel}
          disableConfirm={disableConfirm}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      }
      onClose={onClose}
    />
  );
}

EmailTemplateDrawer.propTypes = {
  hideSubject: PropTypes.bool,
  templatePreview: PropTypes.string,
  isOpen: PropTypes.bool,
  emailTemplate: PropTypes.instanceOf(EmailTemplate),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  previewLabel: PropTypes.string,
  backToEditLabel: PropTypes.string,
  title: PropTypes.string,
  isTemplatePreview: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onPreview: PropTypes.func,
};

export default memo(EmailTemplateDrawer);
