import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Grid, Spinner, Switch, Icon } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import clsx from 'clsx';

import { t } from 'shared/utils/LocaleUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { smallScreen } from 'shared/utils/DOMUtils';

import PaymentMethodModal from 'containers/client/settings/paymentMethodModal/PaymentMethodModal.jsx';

import EmptyState from './components/EmptyState.jsx';
import PaymentMethodInfo from './components/PaymentMethodInfo.jsx';
import CardAdd from './components/CardAdd.jsx';

import ClientBillingSettingsActions from './Actions';
import ClientBillingSettingsStore from './Store';

import './styles.scss';

function Billing({
  clientBillingSettingsStore: { isLoading, paymentMethods, accessToVault },
  intl,
  drawerMode,
  staffMode,
  userId,
  access,
  customerUserId,
}) {
  const isMobile = smallScreen();
  const updateCustomerUser = () =>
    ClientBillingSettingsActions.updateCustomerUserAccess();

  useEffect(() => {
    if (!staffMode) {
      ClientBillingSettingsActions.mounted(
        userId,
        customerUserId,
        access,
        staffMode
      );
    }
  }, [access, customerUserId, staffMode, userId]);

  const hasPaymentMethods = !paymentMethods.isEmpty();

  return (
    <div className="client-settings-billing">
      {isLoading && (
        <FlexBoxJustifyCenter className="client-settings-billing__loading">
          <Spinner />
        </FlexBoxJustifyCenter>
      )}
      {!isLoading && (!hasPaymentMethods || !(accessToVault || !staffMode)) && (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <EmptyState intl={intl} access={accessToVault || !staffMode} />
          </Grid>
        </Grid>
      )}
      {!isLoading && hasPaymentMethods && (accessToVault || !staffMode) && (
        <div
          className={clsx(
            'client-settings-billing__content',
            (isMobile || drawerMode) &&
              'client-settings-billing__content-mobile'
          )}
        >
          {(isMobile || drawerMode) && <CardAdd />}
          {paymentMethods.map(paymentMethod => (
            <PaymentMethodInfo
              paymentMethod={paymentMethod}
              intl={intl}
              key={paymentMethod.id}
            />
          ))}
          {!isMobile && !drawerMode && <CardAdd />}
        </div>
      )}
      <div
        className={clsx(
          'client-settings-billing__access',
          !hasPaymentMethods && 'client-settings-billing__access_centered'
        )}
      >
        {!isLoading && !staffMode && (
          <div>
            <span
              data-tip
              data-for="pos_access"
              className="client-settings-billing__tooltip"
            >
              <Icon
                name="info"
                className="client-settings-billing__tooltip_icon"
              />
            </span>
            <ReactTooltip
              id="pos_access"
              place={!hasPaymentMethods ? 'top' : 'right'}
              className="client-settings-billing__uh-tooltip"
              effect="solid"
            >
              {t('.pos_info', intl, __filenamespace)}
            </ReactTooltip>
            <Switch
              checked={accessToVault}
              labelValue={t('.pos_access', intl, __filenamespace)}
              labelPlacement="start"
              onChange={updateCustomerUser}
            />
          </div>
        )}
      </div>
      <PaymentMethodModal
        userId={userId}
        onSuccess={ClientBillingSettingsActions.listPaymentMethods.defer}
      />
    </div>
  );
}

Billing.propTypes = {
  clientBillingSettingsStore: PropTypes.shape({
    paymentMethods: PropTypes.instanceOf(List),
    isLoading: PropTypes.bool,
  }),
  intl: PropTypes.object.isRequired,
  drawerMode: PropTypes.bool,
  staffMode: PropTypes.bool,
  access: PropTypes.bool,
};

Billing.defaultProps = {
  clientBillingSettingsStore: {},
  drawerMode: false,
  staffMode: false,
  access: false,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      clientBillingSettingsStore: ClientBillingSettingsStore,
    },
  })
)(Billing);
