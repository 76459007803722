import * as React from 'react';
import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { Dropdown } from '@upperhand/playmaker';

export const states = OrderedMap({
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FM: 'Fed. States of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'N. Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AE: 'A.F. Europe (ME & Canada)',
  AP: 'A.F. Pacific',
  AA: 'A.F. Americas (ex. Canada)',
});

function StateSelector({
  classes,
  disabled,
  errorText,
  label,
  name,
  onChange,
  placeholder,
  useAbbreviations,
  value,
  outline,
  disableUnderline,
}) {
  const items = states
    .map((localName, abbrev) => ({
      value: abbrev,
      label: useAbbreviations ? abbrev : localName,
    }))
    .toArray();

  return (
    <Dropdown
      disableUnderline={disableUnderline}
      outline={outline}
      classes={classes}
      disabled={disabled}
      fullWidth
      label={label}
      placeholder={placeholder}
      name={name}
      items={items}
      onChange={e => onChange(e, e.target.value, name)}
      value={value}
      errorText={errorText}
    />
  );
}

StateSelector.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  useAbbreviations: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

StateSelector.defaultProps = {
  classes: {},
  disabled: false,
  outline: true,
  disableUnderline: true,
  errorText: '',
  name: 'state',
};

export default StateSelector;
