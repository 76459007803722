/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useIntl } from 'react-intl';
import {
  Drawer,
  TextField,
  Dropdown,
  Grid,
  DateTimeField,
  Button,
  Checkbox,
  Icon,
} from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import EmailCampaign, {
  CAMPAIGN_MODES,
  CAMPAIGN_STATUSES,
} from 'shared/records/EmailCampaign';

import './styles.scss';

function Content({
  sendLater = false,
  isLoading = false,
  mode = CAMPAIGN_MODES.CREATE,
  campaign = new EmailCampaign(),
  contactGroups = [],
  emailTemplatesOptions = [],
  staffOptions = [],
  onChange = () => null,
  onSendLaterChange = () => null,
}) {
  const intl = useIntl();
  const disableInputs = mode === CAMPAIGN_MODES.VIEW || isLoading;
  const errors = campaign.get('errors');
  const showDates =
    sendLater || campaign.status !== CAMPAIGN_STATUSES.SCHEDULED;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={disableInputs}
          label={t('.name', intl, __filenamespace)}
          value={campaign.get('title')}
          errorText={errors.getErrors(['title'], intl)}
          onChange={e => onChange('title', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          fullWidth
          disabled={disableInputs}
          label={t('.email_template', intl, __filenamespace)}
          value={campaign.getIn(['email_template', 'id'])}
          errorText={errors.getErrors(['email_template'], intl)}
          onChange={e => onChange(['email_template', 'id'], e.target.value)}
          items={emailTemplatesOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          fullWidth
          disabled={disableInputs}
          label={t('.reply_to', intl, __filenamespace)}
          value={campaign.getIn(['reply_to', 'id'])}
          errorText={errors.getErrors(['reply_to'], intl)}
          onChange={e => onChange(['reply_to', 'id'], e.target.value)}
          items={staffOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          fullWidth
          disabled={disableInputs}
          label={
            <div className="create-campaign-drawer__content-field-label">
              {t('.contact_group', intl, __filenamespace)}{' '}
              <div data-tip data-for="segment-info">
                <Icon name="info" size="large" />
              </div>
              <ReactTooltip
                id="segment-info"
                effect="solid"
                offset={{ top: 10, left: 5 }}
                className="create-campaign-drawer__info--tooltip"
              >
                <p>
                  Segments are contact groups created through contact filters
                </p>
              </ReactTooltip>
            </div>
          }
          value={campaign.getIn(['contact_group', 'id'])}
          errorText={errors.getErrors(['contact_group'], intl)}
          onChange={e => onChange(['contact_group', 'id'], e.target.value)}
          items={contactGroups}
        />
      </Grid>
      {campaign.status === CAMPAIGN_STATUSES.SCHEDULED && (
        <Grid item xs={12}>
          <Checkbox
            disabled={disableInputs}
            checked={sendLater}
            label={t('.send_later', intl, __filenamespace)}
            onChange={onSendLaterChange}
          />
        </Grid>
      )}
      {showDates && (
        <Grid item container spacing={1} alignItems="flex-start">
          <Grid item xs={6}>
            <DateTimeField
              fullWidth
              disabled={disableInputs}
              type="date"
              minDate={moment()}
              label={t('.schedule_email', intl, __filenamespace)}
              value={campaign.get('send_date')}
              errorText={errors.getErrors(['send_date'], intl)}
              onChange={date => onChange('send_date', date)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimeField
              fullWidth
              disabled={disableInputs}
              type="time"
              label="&nbsp;"
              value={campaign.get('send_time')}
              errorText={errors.getErrors(['send_time'], intl)}
              onChange={date => onChange('send_time', date)}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function CreateCampaignDrawer(props) {
  const intl = useIntl();
  const {
    isLoading,
    mode = CAMPAIGN_MODES.CREATE,
    sendLater = false,
    isOpen = false,
    title = t(`.title_${mode}`, intl, __filenamespace),
    confirmButtonLabel = t('.create', intl, __filenamespace),
    cancelButtonLabel = t('.cancel', intl, __filenamespace),
    campaign = new EmailCampaign(),
    contactGroups = [],
    emailTemplatesOptions = [],
    staffOptions = [],
    onClose = () => null,
    onChange = () => null,
    onConfirm = () => null,
    onSendLaterChange = () => null,
  } = props;

  return (
    <Drawer
      classes={{
        root: 'create-campaign-drawer',
        content: 'create-campaign-drawer__content',
        actions: 'create-campaign-drawer__actions',
        footer: 'create-campaign-drawer__footer',
        header: 'create-campaign-drawer__header',
      }}
      open={isOpen}
      headerType="dark"
      title={title}
      onClose={onClose}
      content={
        <Content
          isLoading={isLoading}
          mode={mode}
          sendLater={sendLater}
          campaign={campaign}
          contactGroups={contactGroups}
          emailTemplatesOptions={emailTemplatesOptions}
          staffOptions={staffOptions}
          onChange={onChange}
          onSendLaterChange={onSendLaterChange}
        />
      }
      footer={
        <>
          <Button type="secondary" onClick={onClose}>
            {cancelButtonLabel}
          </Button>
          <Button
            type="primary"
            disabled={isLoading || !campaign.isValid}
            onClick={onConfirm}
          >
            {confirmButtonLabel}
          </Button>
        </>
      }
    />
  );
}

CreateCampaignDrawer.propTypes = {
  mode: PropTypes.oneOf([
    CAMPAIGN_MODES.CREATE,
    CAMPAIGN_MODES.EDIT,
    CAMPAIGN_MODES.VIEW,
  ]),
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  sendLater: PropTypes.bool,
  campaign: PropTypes.object,
  contactGroups: PropTypes.array,
  emailTemplatesOptions: PropTypes.array,
  staffOptions: PropTypes.array,
  title: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onSendLaterChange: PropTypes.func,
};

export default memo(CreateCampaignDrawer);
