import { Map } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { SessionSource } from 'sources';

import SessionSummaryDrawerActions from './Actions';

const DEFAULT_TAB = 0;

class SessionSummaryDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.sessionId = null;
    this.selectedTab = DEFAULT_TAB;
    this.editMenu = Map({
      anchorEl: null,
      open: false,
    });
    this.isEditMode = false;
    this.isLoading = false;

    this.bindListeners({
      fetchSuccess: SessionSummaryDrawerActions.fetchSuccess,
      fetchError: SessionSummaryDrawerActions.fetchError,

      mounted: SessionSummaryDrawerActions.mounted,
      selectTab: SessionSummaryDrawerActions.selectTab,
      toggleEditMenu: SessionSummaryDrawerActions.toggleEditMenu,
      setEditMode: SessionSummaryDrawerActions.setEditMode,
    });
  }

  mounted({ sessionId, selectedTab }) {
    this.sessionId = sessionId;
    this.selectedTab = selectedTab || DEFAULT_TAB;

    // refresh session when fetch exists
    if (this.sessionId !== null) this.getSession(this.sessionId);
  }

  getSession(sessionId) {
    this.isLoading = true;
    SessionSource.fetch({
      id: sessionId,
      params: { fields: ['note', 'client_note'] },
      success: SessionSummaryDrawerActions.fetchSuccess,
      error: SessionSummaryDrawerActions.fetchError,
    });
  }

  showMessageScreen() {
    this.showChatScreen = true;
  }

  closeMessageScreen() {
    this.showChatScreen = false;
  }

  updateAndValidateMessage({ key, value }) {
    this.message = this.message.set(key, value);
    this.valid = this.message.isValid();
  }

  fetchSuccess(session) {
    this.isLoading = false;
    this.sessionId = session.id;
  }

  fetchError(...args) {
    this.isLoading = false;
    this.notifyError('error fetching session', args);
  }

  selectTab(value) {
    this.selectedTab = value;
  }

  toggleEditMenu(event) {
    const target = event && event.currentTarget;
    this.editMenu = this.editMenu.set('anchorEl', target).set('open', !!target);
  }

  setEditMode(value) {
    this.isEditMode = value;
  }
}

export default alt.createStore(
  SessionSummaryDrawerStore,
  'SessionSummaryDrawerStore'
);
