import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, TextField, Grid, Typography } from '@upperhand/playmaker';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import CreateCampaignDrawer from 'containers/marketing/campaigns/components/CreateCampaignDrawer/index.jsx';
import EmailTemplateDrawer from 'containers/marketing/emails/components/EmailTemplateDrawer.jsx';
import CampaignsList from 'containers/marketing/campaigns/components/CampaignsList.jsx';
import EmptyState from 'containers/marketing/components/EmptyState.jsx';
import FiltersDrawer from 'containers/marketing/components/FiltersDrawer.jsx';
import ActiveFilters from 'containers/marketing/components/ActiveFilters.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import { CAMPAIGN_MODES } from 'shared/records/EmailCampaign';

import {
  EmailTemplatesDataStore,
  StaffDataStore,
  EmailCampaignsDataStore,
} from 'dataStores';

import MarketingEmailCampaignsActions from 'containers/marketing/campaigns/Actions';
import MarketingEmailCampaignsStore from 'containers/marketing/campaigns/Store';

import './styles.scss';

const inlineStyles = {
  spinnerContainerStyle: {
    position: 'relative',
  },
  spinnerWrapperStyle: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
};

function MarketingCampaigns({
  marketingEmailCampaignsStore,
  emailTemplatesDataStore,
  emailCampaignsDataStore,
  staffDataStore,
}) {
  useEffect(() => {
    MarketingEmailCampaignsActions.listMarketingCampaigns();
  }, []);

  const intl = useIntl();
  const { campaigns } = emailCampaignsDataStore;
  const { emailTemplates } = emailTemplatesDataStore;
  const { staff } = staffDataStore;
  const {
    drawerOpen,
    campaign,
    contactGroups,
    emailTemplatesIds,
    emailCampaignsIds,
    staffIds,
    emailTemplateDrawerOpen,
    emailTemplatePreview,
    search,
    pagination,
    loading,
    mode,
    filtersDrawerOpen,
    filters,
    appliedFilters,
    isExportingCampaigns,
    templatePreview,
    templatePreviewLoading,
    sendLater,
  } = marketingEmailCampaignsStore;

  const campaignsPagination = pagination.get('campaigns');
  const campaignsLoading = loading.get('campaigns', false);
  const staffLoading = loading.get('staff', false);
  const contactGroupsLoading = loading.get('contactGroups', false);
  const templatesLoading = loading.get('templates', false);
  const emailTemplatesOptions = emailTemplatesIds
    .map(id => ({
      label: emailTemplates.getIn([id, 'subject']),
      value: id,
    }))
    .toJS();
  const staffOptions = staffIds
    .map(id => ({
      label: staff.get(id).name(),
      value: id,
    }))
    .toJS();

  const handleFilterChange = (field, value) => {
    MarketingEmailCampaignsActions.handleFilterChange({
      field,
      value,
    });
  };

  return (
    <div className="marketing-campaigns">
      <div className="marketing-campaigns__header">
        <Button
          rounded
          classes={{
            root: 'marketing-campaigns__create-btn',
            label: 'marketing-campaigns__btn-label',
          }}
          onClick={() =>
            MarketingEmailCampaignsActions.openMarketingCampaignDrawer({})
          }
        >
          {t('.create_campaign', intl, __filenamespace)}
        </Button>
        <TextField
          rounded
          fullWidth
          value={search}
          placeholder={t('.search_placeholder', intl, __filenamespace)}
          classes={{ root: 'marketing-campaigns__search' }}
          onChange={e =>
            MarketingEmailCampaignsActions.searchMarketingCampaigns(
              e.target.value
            )
          }
        />
        <Button
          rounded
          icon="filter"
          iconPosition="right"
          type="secondary"
          classes={{
            root: 'marketing-campaigns__filter-btn',
            label: 'marketing-campaigns__btn-label',
          }}
          onClick={MarketingEmailCampaignsActions.toggleFiltersDrawer}
        >
          {t('.filters', intl, __filenamespace)}
        </Button>
        <Button
          rounded
          disabled={isExportingCampaigns}
          classes={{
            root: 'marketing-campaigns__export-btn',
            label: 'marketing-campaigns__btn-label',
          }}
          onClick={MarketingEmailCampaignsActions.exportMarketingCampaigns}
        >
          {t('.export', intl, __filenamespace)}
        </Button>
      </div>
      <ActiveFilters
        appliedFilters={appliedFilters}
        onRemoveFilter={MarketingEmailCampaignsActions.removeFilter}
        onClearFilters={MarketingEmailCampaignsActions.resetFilters}
      />
      <div className="marketing-campaigns__content">
        <SpinWhileLoading
          isLoading={campaignsLoading}
          spinnerContainerStyle={inlineStyles.spinnerContainerStyle}
          spinnerWrapperStyle={inlineStyles.spinnerWrapperStyle}
        >
          {emailCampaignsIds.size === 0 && (
            <EmptyState
              title={t('.title', intl, __filenamespace)}
              description={t('.description', intl, __filenamespace)}
            />
          )}
          {emailCampaignsIds.size > 0 && (
            <>
              <div className="marketing-campaigns__table-header">
                <div className="marketing-campaigns__table-header-content">
                  <Grid container md={12} alignItems="center" spacing={1}>
                    <Grid item md={2}>
                      <Typography variant="fieldLabel">
                        {t('.table_name', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography variant="fieldLabel">
                        {t('.table_template', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="fieldLabel">
                        {t('.table_date_created', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <Typography variant="fieldLabel">
                        {t('.table_status', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="fieldLabel">
                        {t('.table_date_scheduled', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <CampaignsList
                campaigns={campaigns}
                emailCampaignsIds={emailCampaignsIds}
              />
              <Paginator
                showInfo
                className="marketing-campaigns__paginator"
                pageLimit={0}
                currentPage={campaignsPagination.get('page')}
                perPage={campaignsPagination.get('perPage')}
                totalCount={campaignsPagination.get('totalCount')}
                onPageSelect={MarketingEmailCampaignsActions.handlePageSelect}
              />
            </>
          )}
        </SpinWhileLoading>
      </div>
      <CreateCampaignDrawer
        mode={mode}
        sendLater={sendLater}
        confirmButtonLabel={t('.create_confirm', intl, __filenamespace)}
        isLoading={
          staffLoading ||
          contactGroupsLoading ||
          templatesLoading ||
          templatePreviewLoading
        }
        isOpen={drawerOpen}
        campaign={campaign}
        contactGroups={contactGroups}
        emailTemplatesOptions={emailTemplatesOptions}
        staffOptions={staffOptions}
        onSendLaterChange={MarketingEmailCampaignsActions.toggleSendLater}
        onChange={MarketingEmailCampaignsActions.handleFieldChange}
        onClose={MarketingEmailCampaignsActions.closeMarketingCampaignDrawer}
        onConfirm={() =>
          MarketingEmailCampaignsActions.toggleMarketingEmailEdit({
            templateId: campaign.getIn(['email_template', 'id']),
          })
        }
      />
      <EmailTemplateDrawer
        title={t(`.template_drawer_title_${mode}`, intl, __filenamespace)}
        disableConfirm={mode === CAMPAIGN_MODES.VIEW}
        isOpen={emailTemplateDrawerOpen}
        emailTemplate={campaign}
        isTemplatePreview={emailTemplatePreview}
        templatePreview={templatePreview}
        confirmLabel={
          sendLater
            ? t('.template_drawer_confirm', intl, __filenamespace)
            : t('.template_drawer_confirm_now', intl, __filenamespace)
        }
        cancelLabel={t('.template_drawer_cancel', intl, __filenamespace)}
        onClose={() =>
          MarketingEmailCampaignsActions.toggleMarketingEmailEdit({})
        }
        onCancel={() =>
          MarketingEmailCampaignsActions.toggleMarketingEmailEdit({})
        }
        onConfirm={() =>
          MarketingEmailCampaignsActions.createMarketingCampaign(campaign)
        }
        onChange={MarketingEmailCampaignsActions.handleFieldChange}
        onPreview={MarketingEmailCampaignsActions.toggleMarketingEmailPreview}
      />
      <FiltersDrawer
        open={filtersDrawerOpen}
        filters={filters}
        appliedFilters={appliedFilters}
        onChange={handleFilterChange}
        onApply={MarketingEmailCampaignsActions.applyFilters}
        onClose={MarketingEmailCampaignsActions.toggleFiltersDrawer}
        onClearFilters={MarketingEmailCampaignsActions.resetFilters}
      />
    </div>
  );
}

MarketingCampaigns.propTypes = {
  marketingEmailCampaignsStore: PropTypes.object.isRequired,
  emailTemplatesDataStore: PropTypes.object.isRequired,
  emailCampaignsDataStore: PropTypes.object.isRequired,
  staffDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      marketingEmailCampaignsStore: MarketingEmailCampaignsStore,
      emailTemplatesDataStore: EmailTemplatesDataStore,
      emailCampaignsDataStore: EmailCampaignsDataStore,
      staffDataStore: StaffDataStore,
    },
  })
)(MarketingCampaigns);
