import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Grid, Spinner, Typography } from '@upperhand/playmaker';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NoteIcon from '@mui/icons-material/InsertDriveFile';
import PersonIcon from '@mui/icons-material/Person';

import Paginator from 'shared/components/Paginator.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';
import SessionTableHeader from 'containers/events/admin/schedule/shared/SessionTableHeader.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { smallScreen } from 'shared/utils/DOMUtils.js';

import {
  SessionDataStore,
  StaffDataStore,
  ScheduleDataStore,
} from 'dataStores';

import { currentUser } from 'shared/utils/UserUtils.jsx';

import EmptyState from './EmptyState.jsx';
import StaffSection from './StaffSection.jsx';
import WarningIcon from './icons/WarningIcon.jsx';

const styles = {
  cancelledIcon: {
    display: 'inline-block',
    marginRight: 10,
    marginTop: 5,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  noteIconContainer: {
    width: '24px',
    height: '24px',
    padding: '4px',
    position: 'absolute',
    right: '-15px',
    top: 'calc(50% - 12px)',
  },
  noteIcon: (iconSize = 12) => ({
    height: iconSize,
    width: iconSize,
    color: 'var(--color-warning-yellow)',
  }),
  icon: {
    height: 12,
    width: 12,
    marginRight: 8,
    color: uhColors.lightGrey,
  },
};

function CancelledIcon() {
  return (
    <>
      <span data-tip style={styles.cancelledIcon}>
        <WarningIcon color={uhColors.icons.warn} height={17} width={20} />
      </span>
      <ReactTooltip className="uh-tooltip" effect="solid">
        <FormattedMessage id={messageId('.cancelled', __filenamespace)} />
      </ReactTooltip>
    </>
  );
}

const SessionCard = injectIntl(
  ({
    sessionId,
    openSessionSummaryDrawer,
    sessions,
    staff,
    schedules,
    intl,
    isTeamEvent,
  }) => {
    const isClient = currentUser().isClient();
    const isCoach = currentUser().isCoach();
    const session = sessions.get(sessionId);

    if (!session) {
      return null;
    }

    const hasAdminNote = Boolean(session.note);
    const hasClientNote = session.client_note !== null;
    const hasNotes = hasAdminNote || hasClientNote;

    const clientCount = session.registration_ids.count();

    const schedule = schedules.get(session.schedule_id, {
      location: { name: 'TBD' },
      max_size: 1,
    });

    const location = schedule.location || { name: 'TBD' };
    const eventId = session.get('event_id', null);
    const defaultSelectedTab = isClient || isCoach ? 0 : 2;

    const menuOptions = [
      {
        label: t('.view_session', intl, __filenamespace),
        onClick: () => openSessionSummaryDrawer({ sessionId, eventId }),
        visible: true,
      },
    ]
      .filter(item => item.visible)
      .map(({ visible, ...rest }) => rest);
    if (smallScreen() && isTeamEvent) {
      if (isClient) {
        return (
          <Card contentDirection="row" menuOptions={null}>
            <Box sx={styles.flexCenter}>
              <Grid
                container
                alignItems="center"
                xs={12}
                spacing={1}
                justify="space-between"
              >
                <Grid item xs={12}>
                  {session.isCancelled() && <CancelledIcon />}
                  <Typography variant="subtitle2" display="inline">
                    {schedule.get('label')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item>
                      <div style={styles.flexCenter}>
                        <Typography display="inline">
                          {session.starts_at.format('ddd')}
                        </Typography>
                        &nbsp;
                        <Typography variant="subtitle2" display="inline">
                          {session.starts_at.format('MMM DD YY')}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography>{location.name}</Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid item>
                      <Typography>
                        <FormattedMessage
                          id={messageId('.duration', __filenamespace)}
                          values={{
                            startsAt: session.starts_at.format('h:mma'),
                            duration: session.ends_at.format('h:mma'),
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              {hasNotes && (
                <IconButton
                  sx={{ ...styles.noteIconContainer, right: 0 }}
                  onClick={() =>
                    openSessionSummaryDrawer({
                      sessionId,
                      eventId,
                      defaultSelectedTab,
                    })
                  }
                >
                  <NoteIcon sx={styles.noteIcon()} />
                </IconButton>
              )}
            </Box>
          </Card>
        );
      }
      return (
        <Card contentDirection="row" menuOptions={menuOptions}>
          <Box sx={styles.flexCenter}>
            <Grid
              container
              alignItems="center"
              xs={12}
              spacing={1}
              justify="space-between"
            >
              <Grid item xs={12}>
                {session.isCancelled() && <CancelledIcon />}
                <Typography variant="subtitle2" display="inline">
                  {schedule.get('label')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid item>
                    <div style={styles.flexCenter}>
                      <Typography display="inline">
                        {session.starts_at.format('ddd')}
                      </Typography>
                      &nbsp;
                      <Typography variant="subtitle2" display="inline">
                        {session.starts_at.format('MMM DD YY')}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <Typography>{location.name}</Typography>
                  </Grid>
                  <Grid item>
                    <StaffSection
                      intl={intl}
                      staffIds={session.staff_ids}
                      staff={staff}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid item>
                    <Typography>
                      <FormattedMessage
                        id={messageId('.duration', __filenamespace)}
                        values={{
                          startsAt: session.starts_at.format('h:mma'),
                          duration: session.ends_at.format('h:mma'),
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <div style={styles.flexCenter}>
                      <PersonIcon style={styles.icon} />
                      <Typography>
                        <FormattedMessage
                          id={messageId('.attendance_details', __filenamespace)}
                          values={{
                            clientCount,
                            maxClients: session.max_size,
                          }}
                        />
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item alignItems="center" spacing={1}>
                    <Box sx={styles.flexCenter}>
                      <ResourceIcon style={styles.icon} />
                      <Typography>{session.resource_ids.count()}</Typography>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {hasNotes && (
              <IconButton
                sx={styles.noteIconContainer}
                onClick={() =>
                  openSessionSummaryDrawer({
                    sessionId,
                    eventId,
                    defaultSelectedTab,
                  })
                }
              >
                <NoteIcon sx={styles.noteIcon(15)} />
              </IconButton>
            )}
          </Box>
        </Card>
      );
    }
    if (isClient && isTeamEvent) {
      return (
        <Card contentDirection="row" menuOptions={null}>
          <Box sx={styles.flexCenter}>
            <Grid container alignItems="center" xs={12} spacing={1}>
              <Grid item alignItems="center" xs={6} md={2} spacing={1}>
                {session.isCancelled() && <CancelledIcon />}
                <Typography variant="subtitle2" display="inline">
                  {schedule.get('label')}
                </Typography>
              </Grid>
              <Grid item alignItems="center" xs={6} md={2} spacing={1}>
                <div style={styles.flexCenter}>
                  <Typography display="inline">
                    {session.starts_at.format('ddd')}
                  </Typography>
                  &nbsp;
                  <Typography variant="subtitle2" display="inline">
                    {session.starts_at.format('MMM DD YY')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>
                  <FormattedMessage
                    id={messageId('.duration', __filenamespace)}
                    values={{
                      startsAt: session.starts_at.format('h:mma'),
                      duration: session.ends_at.format('h:mma'),
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>{location.name}</Typography>
              </Grid>
            </Grid>
            {hasNotes && (
              <IconButton
                sx={{ ...styles.noteIconContainer, right: 0 }}
                onClick={() =>
                  openSessionSummaryDrawer({
                    sessionId,
                    eventId,
                    defaultSelectedTab,
                  })
                }
              >
                <NoteIcon sx={styles.noteIcon()} />
              </IconButton>
            )}
          </Box>
        </Card>
      );
    }
    if (isTeamEvent) {
      return (
        <Card contentDirection="row" menuOptions={isClient ? [] : menuOptions}>
          <Box sx={styles.flexCenter}>
            <Grid container alignItems="center" xs={12} spacing={1}>
              <Grid item alignItems="center" xs={6} md={2} spacing={1}>
                {session.isCancelled() && <CancelledIcon />}
                <Typography variant="subtitle2" display="inline">
                  {schedule.get('label')}
                </Typography>
              </Grid>
              <Grid item alignItems="center" xs={6} md={2} spacing={1}>
                <div style={styles.flexCenter}>
                  <Typography display="inline">
                    {session.starts_at.format('ddd')}
                  </Typography>
                  &nbsp;
                  <Typography variant="subtitle2" display="inline">
                    {session.starts_at.format('MMM DD YY')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography>
                  <FormattedMessage
                    id={messageId('.duration', __filenamespace)}
                    values={{
                      startsAt: session.starts_at.format('h:mma'),
                      duration: session.ends_at.format('h:mma'),
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>{location.name}</Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <StaffSection
                  intl={intl}
                  staffIds={session.staff_ids}
                  staff={staff}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                {/* Can't get Grid to actually center with the icon so using flexbox to save time. */}
                <div style={styles.flexCenter}>
                  <PersonIcon style={styles.icon} />
                  <Typography>
                    <FormattedMessage
                      id={messageId('.attendance_details', __filenamespace)}
                      values={{
                        clientCount,
                        maxClients: session.max_size,
                      }}
                    />
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={1} alignItems="center" spacing={1}>
                {/* Can't get Grid to actually center with the icon so using flexbox to save time. */}
                <div style={styles.flexCenter}>
                  <ResourceIcon style={styles.icon} />
                  <Typography>{session.resource_ids.count()}</Typography>
                </div>
              </Grid>
            </Grid>
            {hasNotes && (
              <IconButton
                sx={styles.noteIconContainer}
                onClick={() =>
                  openSessionSummaryDrawer({
                    sessionId,
                    eventId,
                    defaultSelectedTab,
                  })
                }
              >
                <NoteIcon sx={styles.noteIcon(15)} />
              </IconButton>
            )}
          </Box>
        </Card>
      );
    }
    return (
      <Card contentDirection="row" menuOptions={menuOptions}>
        <Box sx={styles.flexCenter}>
          <Grid container alignItems="center" xs={12} spacing={1}>
            <Grid item alignItems="center" xs={6} md={2} spacing={1}>
              <div style={styles.flexCenter}>
                {session.isCancelled() && <CancelledIcon />}
                <Typography variant="subtitle2" display="inline">
                  {session.starts_at.format('MMM DD YY')}
                </Typography>
                &nbsp;
                <Typography display="inline">
                  {session.starts_at.format('ddd')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography>
                <FormattedMessage
                  id={messageId('.duration', __filenamespace)}
                  values={{
                    startsAt: session.starts_at.format('h:mma'),
                    duration: session.duration().as('minutes'),
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>{location.name}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <StaffSection
                intl={intl}
                staffIds={session.staff_ids}
                staff={staff}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>
                <FormattedMessage
                  id={messageId('.client_count', __filenamespace)}
                  values={{ clientCount }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              {/* Can't get Grid to actually center with the icon so using flexbox to save time. */}
              <div style={styles.flexCenter}>
                <PersonIcon style={styles.icon} />
                <Typography>
                  <FormattedMessage
                    id={messageId('.attendance_details', __filenamespace)}
                    values={{
                      clientCount,
                      maxClients: session.max_size,
                    }}
                  />
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={1} alignItems="center" spacing={1}>
              {/* Can't get Grid to actually center with the icon so using flexbox to save time. */}
              <div style={styles.flexCenter}>
                <ResourceIcon style={styles.icon} />
                <Typography>{session.resource_ids.count()}</Typography>
              </div>
            </Grid>
          </Grid>
          {hasNotes && (
            <IconButton
              sx={styles.noteIconContainer}
              onClick={() =>
                openSessionSummaryDrawer({
                  sessionId,
                  eventId,
                  defaultSelectedTab,
                })
              }
            >
              <NoteIcon sx={styles.noteIcon(15)} />
            </IconButton>
          )}
        </Box>
      </Card>
    );
  }
);

function SessionsList({
  eventId,
  filtersPresent,
  loading,
  onViewAttendeesClicked,
  openSessionSummaryDrawer,
  scheduleDataStore: { schedules },
  sessionDataStore: { sessions },
  sessionIds,
  staffDataStore: { staff },
  page,
  onPageSelect,
  perPage,
  totalCount,
  isTeamEvent,
}) {
  return loading ? (
    <Grid item xs={12}>
      <div style={{ textAlign: 'center' }}>
        <Spinner type="indeterminate" />
      </div>
    </Grid>
  ) : (
    <>
      <Grid item container spacing={1}>
        {!smallScreen(959) && (
          <Grid item md={12}>
            <SessionTableHeader isTeamEvent={isTeamEvent} />
          </Grid>
        )}
        {sessionIds.map(id => (
          <Grid item key={id} xs={12}>
            <SessionCard
              eventId={eventId}
              onViewAttendeesClicked={onViewAttendeesClicked}
              openSessionSummaryDrawer={openSessionSummaryDrawer}
              schedules={schedules}
              sessionId={id}
              sessions={sessions}
              staff={staff}
              isTeamEvent={isTeamEvent}
            />
          </Grid>
        ))}
      </Grid>
      {sessionIds.isEmpty() && (
        <Grid item xs={12}>
          <EmptyState
            filtersPresent={filtersPresent}
            scheduleViewMode={false}
            isTeamEvent={isTeamEvent}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Paginator
          currentPage={page}
          onPageSelect={onPageSelect}
          perPage={perPage}
          totalCount={totalCount}
        />
      </Grid>
    </>
  );
}

export default altContainer({
  stores: {
    sessionDataStore: SessionDataStore,
    staffDataStore: StaffDataStore,
    scheduleDataStore: ScheduleDataStore,
  },
})(SessionsList);
