import { Iterable, Record } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';

export const BILLING_ADDRESS_FIELDS = [
  'city',
  'street',
  'street2',
  'zip',
  'state',
];

export default class CardBilling extends Record({
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    let newObj;

    if (Iterable.isIterable(obj)) {
      newObj = obj.toJS();
    } else {
      newObj = obj;
    }

    super({
      ...newObj,
      street: newObj.street || '',
      street2: newObj.street2 || '',
      city: newObj.city || '',
      state: newObj.state || '',
      zip: newObj.zip || '',
    });
  }

  get noAddressOnFile() {
    return (
      !this.street.length ||
      !this.city.length ||
      !this.state.length ||
      !this.zip.length
    );
  }

  set(key, value) {
    return Record.prototype.set.call(this, key, value);
  }

  setError(key, value) {
    const errors = this.errors.add(key, value);

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }

  validate() {
    let errors = new FieldErrors();

    if (!this.street.length) {
      errors = errors.add('street', 'Street Address is required');
    }

    if (!this.city.length) {
      errors = errors.add('city', 'City is required');
    }

    if (!this.state.length) {
      errors = errors.add('state', 'State is required');
    }

    if (!this.zip.length) {
      errors = errors.add('zip', 'Zipcode is required');
    }

    if (this.zip.length && this.zip.length < 5) {
      errors = errors.add('zip', 'Zipcode must be 5 digits');
    }

    this.set('errors', errors);

    return errors;
  }
}
