/* eslint-disable prettier/prettier */
import nouns from 'locales/client_theme/nouns.js'

export default {
  'actions.yes': 'Yes',
  'actions.accept': 'Accept',
  'actions.add': 'Add',
  'actions.archive': 'Archive',
  'actions.apply': 'Apply',
  'actions.no': 'No',
  'actions.back': 'Back',
  'actions.cancel': 'Cancel',
  'actions.close': 'Close',
  'actions.choose_attendees': 'Choose Attendee(s)',
  'actions.clear': 'Clear',
  'actions.complete': 'Complete',
  'actions.continue': 'Continue',
  'actions.create': 'Create',
  'actions.delete': 'Delete',
  'actions.edit': 'Edit',
  'actions.enroll': 'Enroll',
  'actions.next': 'Next',
  'actions.previous': 'Previous',
  'actions.purchase': 'Purchase',
  'actions.publish': 'Publish',
  'actions.publishing': 'Publishing',
  'actions.remove': 'Remove',
  'actions.save': 'Save',
  'actions.saved': 'Saved',
  'actions.saving': 'Saving',
  'actions.select': 'Select',
  'actions.sign_in': 'Log in',
  'actions.skip': 'Skip',
  'actions.submit': 'Submit',
  'actions.view': 'View',
  'actions.unarchive': 'Unarchive',
  'actions.mark_all_paid': 'Mark All Paid',


  'not_found.NotFound.heading': 'Oops! Check your url.',
  'not_found.NotFound.explanation': "We can't find the page you are looking for.",
  'not_found.NotFound.not_found_btn': 'Go Home',
  'not_found.NotFound.error': '404 Error',

  'durations.days.short': '{n}d',
  'durations.months.short': '{n}m',
  'durations.years.short': '{n}y',
  'durations.days.medium': '{n} {n, plural, one {day} other {days}}',
  'durations.months.medium': '{n} mo.',
  'durations.years.medium': '{n} yr.',
  'durations.days.long': '{n} {n, plural, one {day} other {days}}',
  'durations.months.long': '{n} {n, plural, one {month} other {months}}',
  'durations.years.long': '{n} {n, plural, one {year} other {years}}',
  'durations.zero': 'today',

  'feature_wall.resources.resource_information':
    'Contact support@getupperhand.com to learn more about resources!',

  'automations.display.PerSessionPricingDetailsCard.processing_fee_note':
    '*Processing Fee ({percentage} + {dollarAmount} per transaction)',
  'automations.display.PerSessionPricingDetailsCard.processing_fee':
    'Processing Fee*',
  'automations.display.PerSessionPricingDetailsCard.total_earnings':
    'Total Earnings',
  'automations.display.PerSessionPricingDetailsCard.interchange_total': 'Total',

  'automations.display.RecurringPaymentPlanDetailsCard.processing_fee':
    'Processing Fee ({amount})',
  'automations.display.RecurringPaymentPlanDetailsCard.recurring_payments':
    'Recurring Payments',
  'automations.display.RecurringPaymentPlanDetailsCard.total_earnings':
    'Total Earnings',
  'automations.display.RecurringPaymentPlanDetailsCard.interchange_total':
    'Total',

  'automations.display.RecurringPaymentPlanSummary.total': 'Total',

  'automations.editing.AutomationEditDrawer.automations': 'Automations',

  'automations.editing.PerSessionPricingEditor.add_option': 'Add Option',
  'automations.editing.PerSessionPricingEditor.cart_description':
    'Cart Description',
  'automations.editing.PerSessionPricingEditor.for': 'For',
  'automations.editing.PerSessionPricingEditor.option_name': 'Option Name',
  'automations.editing.PerSessionPricingEditor.price': 'Price',
  'automations.editing.PerSessionPricingEditor.quantity': 'Quantity',
  'automations.editing.PerSessionPricingEditor.save': 'Save',
  'automations.editing.PerSessionPricingEditor.fee_error': 'Price must be at least {price}',

  'automations.editing.RecurringPaymentPlanEditor.payment_n': 'Payment {n}',
  'automations.editing.RecurringPaymentPlanEditor.date': 'Date',
  'automations.editing.RecurringPaymentPlanEditor.payment_date': 'Payment Date',
  'automations.editing.RecurringPaymentPlanEditor.amount': 'Amount',
  'automations.editing.RecurringPaymentPlanEditor.plan_name': 'Plan Name',
  'automations.editing.RecurringPaymentPlanEditor.name': 'Name',
  'automations.editing.RecurringPaymentPlanEditor.add_payment': 'Add Payment',
  'automations.editing.RecurringPaymentPlanEditor.save': 'Save',
  'automations.editing.RecurringPaymentPlanEditor.cancel': 'Cancel',
  'automations.editing.RecurringPaymentPlanEditor.n_payments':
    '{n} {n, plural, one {Payment} other {Payments}}',
  'automations.editing.RecurringPaymentPlanEditor.total': 'Total',
  'automations.editing.RecurringPaymentPlanEditor.create_plan': 'Create Plan',

  'calendar._Toolbar.availability': 'Availability',
  'calendar._Toolbar.filter_events': `Filter ${nouns.event}s`,

  'calendar._ViewSelect.day': 'Day',
  'calendar._ViewSelect.week': 'Week',

  'calendar._DateNavigator.today': 'Today',
  'calendar._DateNavigator.thisWeek': 'This Week',

  'calendar.date_picker._PickerCalendar.close': 'Close',

  'calendar.date_picker._DatePreview.today': 'Today',
  'calendar.date_picker._DatePreview.yesterday': 'Yesterday',
  'calendar.date_picker._DatePreview.nextWeek': 'Next Week',
  'calendar.date_picker._DatePreview.lastWeek': 'Last Week',

  'calendar._FilterDrawer.title':'Filters',
  'calendar._FilterDrawer.btn_apply':'Apply Filters',
  'calendar._FilterDrawer.clear':'Clear Filters',
  'calendar._FilterDrawer.paid':'Paid',
  'calendar._FilterDrawer.un_paid':'Unpaid',
  'calendar._FilterDrawer.all_staff':'All Staff',
  'calendar._FilterDrawer.select_staff':'Select Staff',
  'calendar._FilterButton.filters':'Filters',

  'calendar._Search.search': 'Search',
  'calendar._Search.ok': 'OK',
  'calendar._Search.search_staff': 'Search Staff',
  'calendar._Search.search_staff_resources': 'Search Staff or Resource',
  'calendar._Search.search_events': `Search ${nouns.event}s`,

  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.customize_columns':
    'Customize Columns',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.customize_columns_instructions':
    'Choose and organize the calendar columns below. Add, remove, and order the columns to customize your view.',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.save': 'Save',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.save_new':
    'Save New',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.cancel': 'Cancel',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.remove': 'Remove',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.delete_confirmation_title':
    'Delete {title}?',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.delete_message':
    'Deleting {title} cannot be reversed.',

  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.save_success':
    'Calendar view successfully saved',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.delete_success':
    'Calendar view deleted',
  'calendar.customize_calendar_drawer.CustomizeCalendarDrawer.minimum_error':
    'You must display at least one Staff member.',

  'calendar.customize_calendar_drawer._AddCalendarsDropdown.add_staff_resource_calendar':
    'Add Staff/Resource to Calendar',
  'calendar.customize_calendar_drawer._AddCalendarsDropdown.add_staff_calendar':
    'Add Staff to Calendar',
  'calendar.customize_calendar_drawer._CurrentViewSection.current_view':
    'Current view:',
  'calendar.customize_calendar_drawer._CurrentViewSection.create_new':
    'Create New',
  'calendar.customize_calendar_drawer._CurrentViewSection.new_view': 'New View',

  'calendar.customize_calendar_drawer._ViewProperties.view_name': 'View Name',
  'calendar.customize_calendar_drawer._ViewProperties.make_default_view':
    'Make default view',

  'calendar.views.shared._NoEventsOverlay.no_events': `No ${nouns.Event}s`,

  'calendar.views.shared._CustomizeButton.customize': 'Customize',

  'calendar.views.shared._CalendarViewMenu.title.calendar_views':
    'Calendar Views',
  'calendar.views.shared._CalendarViewMenu.title.calendar_settings':
    'Calendar Settings',
  'calendar.views.shared._CalendarViewMenu.labels.available_staff_only':
    'Available Staff Only',
  'calendar.views.shared._CalendarViewMenu.labels.show_availability':
    'Show Availability',
  'calendar.views.shared._CalendarViewMenu.default_label': '(Default)',

  'calendar.views.shared.event_card._AssignedStaff.no_staff':
    'No staff assigned',
  'calendar.views.shared.event_card._AssignedStaff.staff': 'Staff',
  'calendar.views.shared.event_card._AssignedResources.resources': 'Resources',
  'calendar.views.shared.event_card._ContextMenu.quick_pay': 'Quick Pay',
  'calendar.views.shared.event_card._ContextMenu.view_event': `View ${nouns.Event
    }`,
  'calendar.views.shared.event_card._ContextMenu.view_team': 'View Team',
  'calendar.views.shared.event_card._ContextMenu.view_attendees':
    'View Attendees',
  'calendar.views.shared.event_card._ContextMenu.view_session': 'View Session',
  'calendar.views.shared.event_card._ContextMenu.add_session_note': 'Add Session Note',
  'calendar.views.shared.event_card._ContextMenu.edit_session_note': 'Edit Session Note',
  'calendar.views.shared.event_card._MultipleAttendees.attendees': ' Attendees',
  'calendar.views.shared.event_card.EventCard.paid': 'Paid',
  'calendar.views.shared.event_card.EventCard.unpaid': 'Unpaid',
  'calendar.views.shared.event_card._EventTimeMeta.view_note': 'View Notes',
  'calendar.views.shared.event_card._EventTimeNote.staff_note_label': 'Staff Note',
  'calendar.views.shared.event_card._EventTimeNote.client_note_label': 'Client Session Note',
  'calendar.views.shared.event_card._EventTimeNote.client_note': 'Client',
  'calendar.views.shared.event_card._EventTimeMeta.title_cancelled': ' (Cancelled)',
  'calendar.views.shared.event_card._EventTimeMeta.title_draft': ' (Draft)',
  'calendar.views.shared.event_card._EventTimeMeta.title_deleted': ' (Deleted)',
  'calendar.views.shared.event_card._EventTitle.title_cancelled': ' (Cancelled)',
  'calendar.views.shared.event_card._EventTitle.title_draft': ' (Draft)',
  'calendar.views.shared.event_card._EventTitle.title_deleted': ' (Deleted)',
  'calendar.views.shared.event_card._StatusBar.n_unpaid_attendees':
    '{n, plural, one {Unpaid Attendee} other {{n} Unpaid Attendees}}',
  'calendar.views.shared.event_card._StatusBar.all_paid': 'All Paid',
    'calendar.views.shared.event_card.EventCard.n_unpaid_attendees':
    '{n, plural, one {Unpaid Attendee} other {{n} Unpaid Attendees}}',
  'calendar.views.shared.event_card.EventCard.all_paid': 'All Paid',
  'calendar.views.shared.event_card._UnpaidClients.n_unpaid':
    ' ({n, plural, one {Unpaid} other {{n} Unpaid}})',
  'calendar.views.shared.event_card._SingleAttendee.new_user': 'New',

  'calendar.quick_schedule.Amount.amount': 'Amount',

  'calendar.quick_schedule.Attendees.add_attendee': 'Add Attendee',
  'calendar.quick_schedule.Attendees.invalid_client_warning':
    'The selected client profile has not been completed and is missing required information. Please complete the profile in POS before continuing.',

  'calendar.quick_schedule.Checkout.card': 'Card',
  'calendar.quick_schedule.Checkout.cash': 'Cash',
  'calendar.quick_schedule.Checkout.check': 'Check',
  'calendar.quick_schedule.Checkout.choose_card': 'Choose Card:',
  'calendar.quick_schedule.Checkout.choose_payment_method':
    'Choose Payment Method',
  'calendar.quick_schedule.Checkout.coupon': 'Coupon',
  'calendar.quick_schedule.Checkout.membership_discount': `${nouns.Membership} Discount`,
  'calendar.quick_schedule.Checkout.new_card': '+ New Card',
  'calendar.quick_schedule.Checkout.pay_balance': 'Pay Balance',
  'calendar.quick_schedule.Checkout.pay_later': 'Pay Later',
  'calendar.quick_schedule.Checkout.payment_method': 'Payment Method',
  'calendar.quick_schedule.Checkout.total': 'Total',
  'calendar.quick_schedule.Checkout.processing_payment': 'Processing Payment',
  'calendar.quick_schedule.Checkout.purchase': 'Purchase',
  'calendar.quick_schedule.Checkout.subtotal': 'Subtotal',
  'calendar.quick_schedule.Checkout.tax': 'Sales Tax',
  'calendar.quick_schedule.Checkout.apply_account_credit': 'Apply Account Credit',
  'calendar.quick_schedule.Checkout.remove_account_credit': 'Remove Credit ',

  'calendar.quick_schedule.CheckoutEventBox.paid': 'PAID',
  'calendar.quick_schedule.CheckoutEventBox.unpaid': 'UNPAID',

  'calendar.quick_schedule.CheckoutSummary.continue': 'Continue',
  'calendar.quick_schedule.CheckoutSummary.card': 'Card',
  'calendar.quick_schedule.CheckoutSummary.card_present': 'Card',
  'calendar.quick_schedule.CheckoutSummary.default_card': 'Card',
  'calendar.quick_schedule.CheckoutSummary.new_card': 'Card',
  'calendar.quick_schedule.CheckoutSummary.handpoint_cloud': 'Card',
  'calendar.quick_schedule.CheckoutSummary.cash': 'Cash',
  'calendar.quick_schedule.CheckoutSummary.check': 'Check',
  'calendar.quick_schedule.CheckoutSummary.confirmation_sent_to_email':
    'Confirmation sent to email:',
  'calendar.quick_schedule.CheckoutSummary.paying_later': 'Paying Later',
  'calendar.quick_schedule.CheckoutSummary.payment_complete':
    'Payment Complete',
  'calendar.quick_schedule.CheckoutSummary.bank': 'Bank',

  'calendar.quick_schedule.CouponForm.apply': 'Apply',
  'calendar.quick_schedule.CouponForm.applying': 'Applying',
  'calendar.quick_schedule.CouponForm.coupon_code': 'Coupon Code',
  'calendar.quick_schedule.CouponForm.enter_code': 'Enter Coupon Code',

  'calendar.quick_schedule.EventSelector.search_events': `Search ${nouns.Event
    }s`,
  'calendar.quick_schedule.EventSelector.no_events_available': `No ${nouns.event
    }s available`,

  'calendar.quick_schedule.QuickSchedule.member_discount': 'Member Discount',
  'calendar.quick_schedule.QuickSchedule.quick_schedule': 'Quick Schedule',
  'calendar.quick_schedule.QuickSchedule.remaining_sessions':
    'Remaining sessions',
  'calendar.quick_schedule.QuickSchedule.processing_order': 'Processing Order',
  'calendar.quick_schedule.QuickSchedule.select_a_package':'Select a package:',


  'calendar.quick_schedule.Resources.choose_resource': 'Choose Resource',

  'calendar.quick_schedule.Staff.choose_staff': 'Choose Staff',

  'calendar.quick_schedule.successfully_scheduled': 'Successfully Scheduled',

  'calendar.CalendarLegend.btn_label': 'Legend',
  'calendar.CalendarLegend.menu_title': 'Calendar Legend',
  'calendar.CalendarLegend.client_birthday': 'Client Birthday',
  'calendar.CalendarLegend.no_waiver_on_file': 'No Waiver on File',
  'calendar.CalendarLegend.session_unpaid': 'Session Unpaid',
  'calendar.CalendarLegend.first_booked_event': 'First Booked Event',
  'calendar.CalendarLegend.client_or_session_note': 'Client or Session Note',
  'calendar.CalendarLegend.no_show': 'No Show',
  'calendar.CalendarLegend.checked_in': 'Checked-In',


  'cart.index._AddMoreEventCard.add_event': `Add ${nouns.Event}`,
  'cart.index._AddMoreEventCard.add_event_description': `Browse and add another ${nouns.event
    } to your cart.`,

  'cart.index._CheckoutCard.due_today': 'Due today',
  'cart.index._CheckoutCard.purchased': 'Purchased',
  'cart.index._CheckoutCard.total': 'Total',

  'cart.index._Cart.browse_events': `Browse ${nouns.Event}s`,
  'cart.index._Cart.empty_state_header': 'Empty Cart?',
  'cart.index._Cart.empty_state_message': `Not for long! There are plenty of ${nouns.event
    }s right up your alley.`,
  'cart.index._Cart.n_items': '{n} {n, plural, one {Item} other {Items}}',
  'cart.index._Cart.order_complete_header': 'Order Complete!',
  'cart.index._Cart.order_complete_message': `You’re registered and ready to go! View your schedule or browse ${nouns.event
    }s to continue shopping.`,
  'cart.index._Cart.view_details': `View ${nouns.Event}s`,
  'cart.index._Cart.view_my_schedule': 'View My Schedule',
  'cart.index._Cart.credit_pass_drawer_title': 'Credit passes',
  'cart.index._Cart.membership_drawer_title': 'Memberships',
  'cart.index._Cart.cart': 'Cart',
  'cart.index._Cart.checkout': 'Check Out',
  'cart.index._Cart.checkout_continue': 'Continue to Check Out',

  'cart.index._MembershipDiscount.membership': `${nouns.Membership}`,

  'cart.index._MembershipSubscriptionPackageSummary.billed': 'Billed',
  'cart.index._MembershipSubscriptionPackageSummary.charge_date': 'Charge/Date',
  'cart.index._MembershipSubscriptionPackageSummary.charge_and_date':
    '({charge}) {date}',
  'cart.index._MembershipSubscriptionPackageSummary.commitment':
    '{months} mo. commitment',
  'cart.index._MembershipSubscriptionPackageSummary.interval':
    '{months, select, 0 {Once} 1 {Monthly} 12 {Annually} other {}}',
  'cart.index._MembershipSubscriptionPackageSummary.join_fee':
    'Join Fee (One Time)',
  'cart.index._MembershipSubscriptionPackageSummary.membership': `${nouns.Membership}`,
  'cart.index._MembershipSubscriptionPackageSummary.quantity': 'Qty.',
  'cart.index._MembershipSubscriptionPackageSummary.agreement_complete': 'Membership Agreement Terms Accepted (view)',
  'cart.index._MembershipSubscriptionPackageSummary.fill_agreement': 'Accept Membership Agreement Terms',

  'cart.index._VariantSummary.quantity': 'Qty.',

  'cart.index._CreditPassPackageSummary.credit_pass': 'Credit Pass',
  'cart.index._CreditPassPackageSummary.quantity': 'Qty. {quantity}',

  'cart.index._RegistrationFormDrawer.register_name': 'Register {name}',
  'cart.index._RegistrationFormDrawer.of_text': '{index} of {ids}',

  'cart.index._RegistrationPackageSummary.fill_out_event_form': `Fill Out ${nouns.Event
    } Form`,
  'cart.index._RegistrationPackageSummary.form_complete':
    'Form Complete (View)',
  'cart.index._RegistrationPackageSummary.full_price': 'Full Price',
  'cart.index._RegistrationPackageSummary.prorate': 'Prorate',
  'cart.index._RegistrationPackageSummary.qty': 'Qty. {n}',
  'cart.index._RegistrationPackageSummary.registration_completed':
    'Registration Completed',
  'cart.index._RegistrationPackageSummary.schedule_sessions':
    'Schedule Sessions',
  'cart.index._RegistrationPackageSummary.scheduled': 'Scheduled',

  'cart.index._SessionList.all_days': 'All Days',
  'cart.index._SessionList.single_session': 'Single Session',

  'cart.index._AgreementFormDrawer.name_label':'Enter First and Last Name',
  'cart.index._AgreementFormDrawer.accept_date_label':'Date',
  'cart.index._AgreementFormDrawer.agreement_title':'Membership Agreement Terms',
  'cart.index._AgreementFormDrawer.download':'Download',
  'cart.index._AgreementFormDrawer.client_name': 'Client Name: {name}',
  'cart.index._AgreementFormDrawer.signed_at': 'Signed Date: {date}',

  // leave a space as the value to not have it display.
  'client_theme.ThemeSidebar.header': ' ',
  'client_theme.ThemeSidebar.subHeader': ' ',
  'client_theme.ThemeSidebar.mission': ' ',
  'client_theme.ThemeSidebar.mission1': ' ',
  'client_theme.ThemeSidebar.mission2': ' ',
  'client_theme.ThemeSidebar.mission3': ' ',
  'client_theme.ThemeSidebar.learn_more': ' ',

  'client_portal.BrowseEventsTab.empty_state_header': `No ${nouns.Event}s?`,
  'client_portal.BrowseEventsTab.empty_state_message':
    'No worries, we are currently creating some just for you. Check back soon.',
  'client_portal.BrowseEventsTab.filter_header_text': 'Refine your search.',

  'client_portal.EventList.clear_filters': 'Clear Filters',
  'client_portal.EventList.empty_state_header': `No ${nouns.Event}s?`,
  'client_portal.EventList.empty_state_message': `Sorry, we couldn't find any ${nouns.event
    }s.`,
  'client_portal.EventList.see_more': 'See More ↡',

  'client_portal.EventCard.schedule': 'Schedule',
  'client_portal.EventCard.session': 'Session',
  'client_portal.EventCard.spaces_left':
    '{spots, plural, one { space left!} other { spaces left!}}',

  'client_portal.FilterDrawer.clear_filters': 'Clear Filters',

  'client_portal.FilterHeader.clear_filters': 'Clear Filters',

  'client_portal.Filters.age': 'Age',
  'client_portal.Filters.attendee': 'Attendee',
  'client_portal.Filters.event_type': `${nouns.Event} Type`,
  'client_portal.Filters.location': 'Location',
  'client_portal.Filters.max': 'Max',
  'client_portal.Filters.min': 'Min',

  'client_portal.PortalTabs.browse_events': `Browse ${nouns.Event}s`,
  'client_portal.PortalTabs.beta_browse_events': `Beta Browse ${nouns.Event}s`,
  'client_portal.PortalTabs.memberships': `${nouns.Membership}s`,
  'client_portal.PortalTabs.my_profile': 'My Profile',
  'client_portal.PortalTabs.credit_passes': 'Passes',
  'client_portal.PortalTabs.teams': 'Teams',

  'contacts.index.ContactImportDrawer.import_contacts': 'Import Contacts',

  'contacts.index._ContactImportDesktop.complete':
    'Complete. {success_row_count, plural, one {# contact} other {# contacts}} imported.',
  'contacts.index._ContactImportDesktop.complete_with_errors':
    'Completed with errors. {success_row_count, plural, one {# contact} other {# contacts}} imported. {failed_row_count, plural, one {# error} other {# errors}}.',
  'contacts.index._ContactImportDesktop.download_template': 'Download Template',
  'contacts.index._ContactImportDesktop.download_template_csv':
    'Download Template (.csv)',
  'contacts.index._ContactImportDesktop.template_description':
    'Download the Contact Import spreadsheet (.csv), add your contacts, and upload below.',
  'contacts.index._ContactImportDesktop.uploading': 'Uploading.',
  'contacts.index._ContactImportDesktop.processing':
    'Processing. This may take a while.',
  'contacts.index._ContactImportDesktop.upload_csv': 'Upload (.csv)',
  'contacts.index._ContactImportDesktop.upload_another_csv':
    'Upload Another (.csv)',
  'contacts.index._ContactImportDesktop.upload_description':
    'Upload the updated spreadsheet below to import your contacts.',
  'contacts.index._ContactImportDesktop.email_warning':
    'Importing contacts will send an email invitation to your clients so that they can seamlessly begin creating their accounts with your business.',
  'contacts.index._ContactImportDesktop.upload_spreadsheet':
    'Upload Spreadsheet',
  'contacts.index._ContactImportDesktop.view_errors': 'View Errors',

  'contacts.index.ContactImportNative._ContactReview.staff': 'Staff',

  'contacts.index.ContactImportNative._StepSelect.ineligible_label':
    'Hide Ineligible Contacts',
  'contacts.index.ContactImportNative._StepSelect.empty_search':
    'No Contacts Found',
  'contacts.index.ContactImportNative._StepSelect.select':
    'Select {count} {count, plural, one {Contact} other {Contacts}}',

  'contacts.index.ContactImportNative._StepReview.invite':
    'Send {count} {count, plural, one {Invitation} other {Invitations}}',

  'contacts.index.ContactImportNative._ShowMoreButton.show_more': 'Show More',

  'contacts.index.ContactImportNative._Message.close': 'Close',

  'contacts.index.ContactImportNative._Content.loading_contacts':
    'Loading Contacts',
  'contacts.index.ContactImportNative._Content.inviting':
    'Inviting {count, plural, one {Contact} other {Contacts}}',
  'contacts.index.ContactImportNative._Content.success':
    'Successfully Sent {count, plural, one {Invitation} other {Invitations}}',

  'contacts.index.ContactImportNative._Contact.already_exists':
    'Contact Already Imported',
  'contacts.index.ContactImportNative._Contact.missing_first_name':
    'Missing First Name',
  'contacts.index.ContactImportNative._Contact.missing_last_name':
    'Missing Last Name',
  'contacts.index.ContactImportNative._Contact.missing_email':
    'Missing Email Address',

  'contacts.index.CreditExpander.all_events': `All ${nouns.Event}s`,
  'contacts.index.CreditExpander.credits': 'Credits',
  'contacts.index.CreditExpander.unlimited': 'Unlimited',

  'contacts.index._ClientMenu.archive_button': 'Archive',
  'contacts.index._ClientMenu.unarchive_button': 'Unarchive',

  'contacts.index.ClientsTab.create_event': `Create ${nouns.Event}`,
  'contacts.index.ClientsTab.empty_state_header': "No clients? Let's fix that.",
  'contacts.index.ClientsTab.empty_state_message': `Invite a client or create and share an ${nouns.event
    } by email or social media.`,
  'contacts.index.ClientsTab.invite_client': 'Invite Client',
  'contacts.index.ClientsTab.no_clients_found': 'No clients found.',
  'contacts.index.ClientsTab.send_invite': 'Send Invite',

  'contacts.index._ClientTable.client': 'CLIENT',
  'contacts.index._ClientTable.email': 'EMAIL',
  'contacts.index._ClientTable.phone': 'PHONE',
  'contacts.index._ClientTable.profiles': 'PROFILES',
  'contacts.index._ClientTable.remind': 'Remind',
  'contacts.index._ClientTable.unclaimed': 'Unclaimed Account',

  'contacts.index.Contacts.import': 'Import',
  'contacts.index.Contacts.invite': 'Invite',
  'contacts.index.Contacts.contacts': 'Contacts',
  'contacts.index.Contacts.groups': 'Groups',

  'contacts.index.InvitesTab.empty_state_header': 'Hey, no invites?',
  'contacts.index.InvitesTab.empty_state_message':
    'Invite a client or staff member and track their response.',
  'contacts.index.InvitesTab.import_contacts': 'Import Contacts',
  'contacts.index.InvitesTab.no_invites_pending': 'No invites pending.',
  'contacts.index.InvitesTab.send_invite': 'Send Invite',

  'contacts.index._InvitesTable.client': 'NAME',
  'contacts.index._InvitesTable.email': 'EMAIL',
  'contacts.index._InvitesTable.time': 'TIME',
  'contacts.index._InvitesTable.type': 'TYPE',
  'contacts.index._InvitesTable.remind': 'Remind',

  'contacts.index.stores.ContactImportDesktopStore.upload_error':
    'Uploading file failed. Please refresh the page and try again.',
  'contacts.index.stores.ContactImportDesktopStore.unknown_error':
    'Unknown error when uploading file. Please refresh the page and try again.',
  'contacts.index.stores.ContactImportDesktopStore.import_failed':
    'Contact import failed. {failureReason}',

  'contacts.index._UserDetailAttribute.none_provided': 'None provided',

  'contacts.index._UserDetails.default_payment': 'Default Payment',
  'contacts.index._UserDetails.default_payment_info': 'The Default card chosen is used for all recurring payments (memberships & payment plans) and will be the first to show in POS. Switching to a new default will transfer all recurring payments to the selected card. If a Client only has one card on file it will automatically be their default card.',
  'contacts.index._UserDetails.manage_methods': 'Manage Methods',
  'contacts.index._UserDetails.select_method': 'Select Method',
  'contacts.index._UserDetails.access_denied': 'Access Denied',
  'contacts.index._UserDetails.no_cards': 'No payment methods on file',
  'contacts.index._UserDetails.add_method': '+ Add New Method',
  'contacts.index._UserDetails.phone': 'Phone',
  'contacts.index._UserDetails.email': 'Email',
  'contacts.index._UserDetails.address': 'Address',
  'contacts.index._UserDetails.age_birthday': 'Age / Birthday',
  'contacts.index._UserDetails.managed_by': 'Managed By',
  'contacts.index._UserDetails.managing': 'Managed Profiles',
  'contacts.index._UserDetails.membership': `${nouns.Membership}`,
  'contacts.index._UserDetails.membership_active': 'Active',
  'contacts.index._UserDetails.membership_details': 'Details',
  'contacts.index._UserDetails.membership_cancelled': 'Cancelled',
  'contacts.index._UserDetails.membership_expires': 'Expires',
  'contacts.index._UserDetails.membership_next_payment': 'Next Payment',
  'contacts.index._UserDetails.membership_pricing_annually': '/y',
  'contacts.index._UserDetails.membership_pricing_monthly': '/m',
  'contacts.index._UserDetails.membership_pricing_once': ' (once)',
  'contacts.index._UserDetails.membership_suspends': 'Suspends',
  'contacts.index._UserDetails.membership_suspended': 'Suspended',
  'contacts.index._UserDetails.unpaid': 'UNPAID',
  'contacts.index._UserDetails.membership_reactivates': 'Reactivates',
  'contacts.index._UserDetails.sign_waiver': 'Sign',
  'contacts.index._UserDetails.no_signed_waiver': 'No waiver on file',

  'contacts.index.StaffDetailsDrawer.info': 'Info',
  'contacts.index.StaffDetailsDrawer.payroll': 'Payroll',
  'contacts.index.StaffDetailsDrawer.staff_profile': 'Staff Profile',

  'contacts.index.StaffDetailsDrawerEditing.availability_legend':
    'Available Hours',
  'contacts.index.StaffDetailsDrawerEditing.blackout': 'Blackout Days',
  'contacts.index.StaffDetailsDrawerEditing.date_specific_availability':
    'Date Specific Availability',
  'contacts.index.StaffDetailsDrawerEditing.end': 'End',
  'contacts.index.StaffDetailsDrawerEditing.indefinite': 'Indefinite',
  'contacts.index.StaffDetailsDrawerEditing.save': 'Save',
  'contacts.index.StaffDetailsDrawerEditing.start': 'Start',
  'contacts.index.StaffDetailsDrawerEditing.week': 'Days of Week',

  'contacts.index.StaffDetailsDrawerPayroll.hourly_rate': 'Hourly Rate',
  'contacts.index.StaffDetailsDrawerPayroll.add_event_rate': 'Add Rate',
  'contacts.index.StaffDetailsDrawerPayroll.remove_event_rate': `Remove ${nouns.Event
    } Rate`,
  'contacts.index.StaffDetailsDrawerPayroll.are_you_sure': `Are you sure you want to remove the ${nouns.event
    } rate?`,
  'contacts.index.StaffDetailsDrawerPayroll.add_commission_rate': 'Add Commission',
  'contacts.index.StaffDetailsDrawerPayroll.remove_commission_rate': `Remove Commission Rate`,
  'contacts.index.StaffDetailsDrawerPayroll.confirm_delete_commission': `Are you sure you want to remove this commission rate?`,

  'contacts.index.event_rates.EventRateForm.add_rate': 'Add Rate',
  'contacts.index.event_rates.EventRateForm.edit_rate': 'Edit Rate',
  'contacts.index.event_rates.EventRateForm.save': 'Save',
  'contacts.index.event_rates.EventRateForm.cancel': 'Cancel',
  'contacts.index.event_rates.EventRateForm.percent': 'Percentage',
  'contacts.index.event_rates.EventRateForm.amount': 'Amount',
  'contacts.index.event_rates.EventRateForm.event_name': `${nouns.Event} Name`,
  'contacts.index.event_rates.EventRateForm.choose_type': 'Choose Type',
  'contacts.index.event_rates.EventRateForm.type': 'Value Type',
  'contacts.index.event_rates.EventRateForm.value': 'Value',
  'contacts.index.event_rates.EventRateForm.booking': 'Booking ',
  'contacts.index.event_rates.EventRateForm.booking-info': `${nouns.event
    } rates are applied per session.`,
  'contacts.index.event_rates.EventRateForm.fixed': 'Fixed ',
  'contacts.index.event_rates.EventRateForm.fixed-info': `${nouns.event
    } rates are applied to total ${nouns.event} amount.`,

  'contacts.index.event_rates.EventRateForm.search_event':
    'Search (Auto-Complete)',
  'contacts.index.event_rates.EventRateForm.discount_sensitive_rate':
    'Discount-sensitive rate',
  'contacts.index.event_rates.EventRateForm.sensitive_rate':
    'Total monies owed will be calculated based on the total after discounts are applied. If a client uses credits to book this event it is considered 100% discount and no payroll entry will be added.',

  'contacts.index.commission_rates.CommissionRateForm.add_commission_rate': 'Add Commission Rate',
  'contacts.index.commission_rates.CommissionRateForm.edit_commission_rate': 'Edit Commission Rate',
  'contacts.index.commission_rates.CommissionRateForm.save': 'Save',
  'contacts.index.commission_rates.CommissionRateForm.cancel': 'Cancel',
  'contacts.index.commission_rates.CommissionRateForm.percent': 'Percentage',
  'contacts.index.commission_rates.CommissionRateForm.amount': 'Amount',
  'contacts.index.commission_rates.CommissionRateForm.type': 'Value Type',
  'contacts.index.commission_rates.CommissionRateForm.value': 'Value',
  'contacts.index.commission_rates.CommissionRateForm.search_items': 'Search Items & Memberships',
  'contacts.index.commission_rates.CommissionRateForm.discount_sensitive_rate': 'Discount-sensitive rate',
  'contacts.index.commission_rates.CommissionRateForm.sensitive_rate': 'Total monies owed will be calculated based on the total after discounts are applied. If a client uses credits to book this event it is considered 100% discount and no payroll entry will be added.',
  'contacts.index.commission_rates.CommissionRateForm.all_retail_categories': 'All Retail Categories',
  'contacts.index.commission_rates.CommissionRateForm.all_memberships': 'All Memberships',
  'contacts.index.commission_rates.CommissionRateForm.specific_retail_memberships': 'Specific Retail Categories & Memberships',

  'contacts.index.commission_rates.CommissionRateCard.commission': '{n, plural, one {Commission} other {Commission}}',
  'contacts.index.commission_rates.CommissionRateCard.all_memberships': 'All Memberships',
  'contacts.index.commission_rates.CommissionRateCard.all_retail_categories': 'All Retail Categories',
  'contacts.index.commission_rates.CommissionRateCard.value_type': 'Value Type',
  'contacts.index.commission_rates.CommissionRateCard.value': 'Value',
  'contacts.index.commission_rates.CommissionRateCard.dollar': 'Dollar',
  'contacts.index.commission_rates.CommissionRateCard.percentage': 'Percentage',
  'contacts.index.commission_rates.CommissionRateCard.discount_sensitive_rate': 'Discount-sensitive rate',
  'contacts.index.commission_rates.CommissionRateCard.sensitive_rate': 'Total monies owed will be calculated based on the total after discounts are applied. If a client uses credits to book this event it is considered 100% discount and no payroll entry will be added.',

  'contacts.shared.stores.commission_rates.CommissionRateEditingStore.updated': 'Commission rate entry was successfully updated',
  'contacts.shared.stores.commission_rates.CommissionRateCreationStore.created': 'Commission rate entry successfully created',
  'contacts.shared.stores.commission_rates.CommissionRateDeletionStore.deleted': 'Commission rate entry was successfully removed',

  'contacts.index.event_rates.EventRateCard.events':
    '{n, plural, one {Event} other {Events}}',
  'contacts.index.event_rates.EventRateCard.value_type': 'Value Type',
  'contacts.index.event_rates.EventRateCard.value': 'Value',
  'contacts.index.event_rates.EventRateCard.dollar': 'Dollar',
  'contacts.index.event_rates.EventRateCard.percentage': 'Percentage',
  'contacts.index.event_rates.EventRateCard.discount_sensitive_rate':
    'Discount-sensitive rate',
  'contacts.index.event_rates.EventRateCard.sensitive_rate':
    'Total monies owed will be calculated based on the total after discounts are applied. If a client uses credits to book this event it is considered 100% discount and no payroll entry will be added.',

  'contacts.shared.stores.event_rates.EventRateEditingStore.updated': `${nouns.Event
    } rate was successfully updated`,
  'contacts.shared.stores.event_rates.EventRateCreationStore.created': `${nouns.Event
    } rate entry was successfully created`,
  'contacts.shared.stores.event_rates.EventRateDeletionStore.deleted': `${nouns.Event
    } rate  entry was successfully removed`,

  'contacts.index.StaffDetailsDrawerView.availability': 'Availability',
  'contacts.index.StaffDetailsDrawerView.blackout': 'Blackout',
  'contacts.index.StaffDetailsDrawerView.blackout_days': 'Blackout Days',
  'contacts.index.StaffDetailsDrawerView.date_specific': 'Date Specific',
  'contacts.index.StaffDetailsDrawerView.indefinite': 'Indefinite',

  'contacts.index.StaffTab.no_staff': 'No staff found.',
  'contacts.index.StaffTab.invite_staff': 'Invite Staff',

  'contacts.index._StaffTable.client': 'CLIENT',
  'contacts.index._StaffTable.email': 'EMAIL',
  'contacts.index._StaffTable.name': 'NAME',
  'contacts.index._StaffTable.availability': 'AVAILABILITY',

  'containers.contacts.contactsFilters.steps.filterBlocks.Name.placeholder': 'Enter Filter Name',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_status_label': 'Account Status',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_type_label': 'Account Type',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_type_placeholder': 'No account type selected',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.member_status_label': 'Member Status',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.fpd_label': 'First Purchase Date',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.fpd_placeholder': 'Select',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.lpd_label': 'Last Purchase Date',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.lpd_placeholder': 'Select',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.purchased_product': 'Purchased Product',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.purchased_product_type': 'Purchased Product Type',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.product_type_placeholder': 'Search Product Type',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.no_product_types': 'No product types found',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_statuses.all': 'All',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_statuses.active': 'Active',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_statuses.invited': 'Invited',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_statuses.unclaimed': 'Unclaimed',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_types.all': 'All',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_types.primary': 'Primary',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_types.managed': 'Managed',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.account_types.lead': 'Lead',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.subscription_statuses.active': 'Active',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.subscription_statuses.cancelled': 'Cancelled',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.subscription_statuses.suspended': 'Suspended',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.subscription_statuses.past_due': 'Past Due',
  'containers.contacts.contactsFilters.steps.filterBlocks.Activity.subscription_statuses.non_member': 'Non Member',
  'containers.contacts.contactsFilters.ProductSelector.purchased_product_placeholder': 'Search Products',
  'containers.contacts.contactsFilters.ProductSelector.no_products': 'No product found',
  'containers.contacts.contactsFilters.steps.filterBlocks.SavedFilters.label': 'View Saved Filters',
  'containers.contacts.contactsFilters.steps.filterBlocks.SavedFilters.placeholder': 'Select save filter',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.label': 'Zip Code',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.placeholder': 'Enter Zip Code',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.age': 'Age',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.min_age_placeholder': 'Minimum Age',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.max_age_placeholder': 'Maximum Age',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.dob': 'Date of Birth',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.dob_placeholder': 'Select',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.mob': 'Birthday Month',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.gender': 'Gender',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.gender_placeholder': 'No gender selected',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.date_operators.is': 'Is',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.date_operators.before': 'Before',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.date_operators.after': 'After',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.date_operators.between': 'Between',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.genders.all': 'All',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.genders.male': 'Male',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.genders.female': 'Female',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.january': 'January',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.february': 'February',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.march': 'March',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.april': 'April',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.may': 'May',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.june': 'June',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.july': 'July',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.august': 'August',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.september': 'September',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.october': 'October',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.november': 'November',
  'containers.contacts.contactsFilters.steps.filterBlocks.Demographics.months.december': 'December',
  'containers.contacts.contactsFilters.steps.InitialStep.saved_filters': 'Saved Filters',
  'containers.contacts.contactsFilters.steps.InitialStep.loading_filters': 'Loading Filters...',
  'containers.contacts.contactsFilters.steps.InitialStep.no_saved_filters': ' No saved filters. Use the Create New Filter Button below to drill down your contact search.',
  'containers.contacts.contactsFilters.steps.CreationStep.clear_filters': 'Clear Filters',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.title': 'Contact Filters',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.delete_filter': 'Delete Filter',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.apply_filter': 'Apply Filter',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.save_filter': 'Save Filter',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.update_filter': 'Update Filter',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.create_filter': ' Create New Filter',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.clear_filters': 'Clear Filters',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.delete_confirmation_title': 'Delete confirmation',
  'containers.contacts.contactsFilters.ContactsFiltersDrawer.delete_confirmation_message': 'Are you sure that you wanted to delete "{filter_name}" filter?',
  'containers.contacts.contactsFilters.Store.save_success': 'Contact filter saved successfully.',
  'containers.contacts.contactsFilters.Store.update_success': 'Contact filter updated successfully.',
  'containers.contacts.contactsFilters.Store.delete_success': 'Successfully deleted "{name}" filter',






  'coupons.show_view.successfully_removed': 'Coupon was successfully removed.',

  'list.Table.Table.mobile_subtitle': '{totalCount, plural, one {{totalCount} Coupon} other {{totalCount} Coupons}}',
  'list.Table.TableHeaderDesktop.name': 'Name',
  'list.Table.TableHeaderDesktop.code': 'Code',
  'list.Table.TableHeaderDesktop.type': 'Payment Type',
  'list.Table.TableHeaderDesktop.discount': 'Discount',
  'list.Table.TableHeaderDesktop.start_date': 'Start Date',
  'list.Table.TableHeaderDesktop.end_date': 'End Date',
  'list.Table.TableHeaderDesktop.usage_limit': 'Usage Limit',
  'list.Table.EmptyState.title': 'Nothing to see here.',
  'list.Table.EmptyState.description': 'Coupons will show up when created.',

  'DateRangeDropdown.DateRangeDropdown.label': 'Time Range',
  'DateRangeDropdown.DateRangeDropdown.end_date': 'End Date',
  'DateRangeDropdown.DateRangeDropdown.start_date': 'Start Date',
  'DateRangeDropdown.DateRangeDropdown.item_today': 'Today',
  'DateRangeDropdown.DateRangeDropdown.item_yesterday': 'Yesterday',
  'DateRangeDropdown.DateRangeDropdown.item_this_week': 'This Week',
  'DateRangeDropdown.DateRangeDropdown.item_last_week': 'Last Week',
  'DateRangeDropdown.DateRangeDropdown.item_this_month': 'This Month',
  'DateRangeDropdown.DateRangeDropdown.item_last_month': 'Last Month',
  'DateRangeDropdown.DateRangeDropdown.item_this_quarter': 'This Quarter',
  'DateRangeDropdown.DateRangeDropdown.item_last_quarter': 'Last Quarter',
  'DateRangeDropdown.DateRangeDropdown.item_this_year': 'This Year',
  'DateRangeDropdown.DateRangeDropdown.item_last_year': 'Last Year',
  'DateRangeDropdown.DateRangeDropdown.item_custom': 'Custom',

  'ImageUpload.ImageUpload.menu_edit': 'Edit',
  'ImageUpload.ImageUpload.menu_remove': 'Remove',

  'ImageUpload.WrapperPreview.empty_text': 'Drag & Drop',
  'ImageUpload.WrapperPreview.empty_desc': 'image or',
  'ImageUpload.WrapperPreview.empty_link': 'browse',

  'ImageUpload.DefaultPreview.image_alt': 'Image',
  'ImageUpload.DefaultPreview.popup_button': 'Change Image',

  'ImageUpload.AvatarPreview.default_name': 'Profile Photo',

  'ImageUpload.ModalCrop.title': 'Select Image',
  'ImageUpload.ModalCrop.upload_button': 'Upload',
  'ImageUpload.ModalCrop.success_label': 'Confirm',
  'ImageUpload.ModalCrop.cancel_label': 'Cancel',

  'EventTypeEditor.Modal.cancel_button': 'Cancel',
  'EventTypeEditor.Modal.success_button': 'Submit',
  'EventTypeEditor.Modal.title_edit_mode': `Edit ${nouns.Event
    } Type`,
  'EventTypeEditor.Modal.title_create_mode': `New ${nouns.Event
    } Type`,
  'EventTypeEditor.EventTypeEditor.edit_button': 'Edit',
  'EventTypeEditor.EventTypeEditor.placeholder': `Select ${nouns.Event} Type`,
  'EventTypeEditor.EventTypeEditor.new_item': `+ New ${nouns.Event} Type`,

  'LocationEditor.Modal.cancel_button': 'Cancel',
  'LocationEditor.Modal.success_button': 'Submit',
  'LocationEditor.Modal.title_edit_mode': `Edit Location`,
  'LocationEditor.Modal.title_create_mode': `New Location`,
  'LocationEditor.LocationEditor.edit_button': 'Edit',
  'LocationEditor.LocationEditor.label': `Location`,
  'LocationEditor.LocationEditor.placeholder': `Select Location`,
  'LocationEditor.LocationEditor.new_item': `+ New Location`,
  'LocationEditor.LocationEditor.edit_message': 'Cannot edit a removed location.',

  'containers.accounts.createUser.ActionButtons.add_participant': 'Add Participant',

  'containers.accounts.createUser.ClientHeaderText.account_type':
    'What type of account will you be creating?',
  'containers.accounts.createUser.ClientHeaderText.create_account_header': 'Create Account',
    'containers.accounts.createUser.Header.header_modal_manager': 'Instructions: Enter your account information and click “complete” to create your account (Must be 13 or older). Use the “add participant” button to add your athletes or family members to the account. You can always add more participants later if needed.',
    'containers.accounts.createUser.Header.header_modal_participant': 'Instructions: Create a profile below to add a participant to your account. (e.g child, spouse, sibling). Profiles that you create will share the same payment method.',
  'shared.signUp.SignUpModal.create_account_header': 'Create Account',
  'shared.signUp.SignUpModal.create_participants_header': 'Create Participants Profiles',
  'shared.signUp.SignInModal.need_help': 'Need Help?',
  'containers.accounts.forgotPassword.DesktopResetRequest.forgot_password_info': 'Forgot Password: Enter email address associated with your account to send password reset email to your inbox.',
  'containers.accounts.forgotPassword.MobileResetRequest.forgot_password_info': 'Forgot Password: Enter email address associated with your account to send password reset email to your inbox.',
  'containers.accounts.createUser.ClientHeaderText.create_profile_header':
    'Create Participant Profile(s)',
  'containers.accounts.createUser.ClientHeaderText.create_profile_sub_header':
    'Create a profile below to add a participant to your account. (e.g child, spouse, sibling)',
  'containers.accounts.createUser.ClientHeaderText.create_sub_header':
    'Profiles that you create will share the same payment method',

  'containers.accounts.createUser.StaffHeaderText.staff_role':
    `You have been invited to {role}  `,
  'containers.accounts.createUser.StaffHeaderText.staff_message': 'Create your account.',

  'containers.accounts.createUser.DisplayText.parent_header': 'Parent/Guardian Information',
  'containers.accounts.createUser.DisplayText.parent_message':
    "Fill out the information below and we'll capture your kids' information on the next page",
  'containers.accounts.createUser.DisplayText.participant_header':
    'Participant Information',

    'containers.accounts.createUser.AccountType.add_participant':
    "Add participant",
    'containers.accounts.createUser.AccountType.go_back':
    "Back",
  'containers.accounts.createUser.AccountType.parent_label':
    "I'm parent/guardian who will be purchasing for a participant",
  'containers.accounts.createUser.AccountType.parent_span':
    "I'll be booking and purchasing for my kids",
  'containers.accounts.createUser.AccountType.participant_label': "I'm the participant",
  'containers.accounts.createUser.AccountType.participant_span': `I'll be booking and purchasing ${nouns.event
    }s myself (13 years or older)`,
  'containers.accounts.createUser.AccountType.participant_header':
    'Participant Information',

  'containers.accounts.createUser.Content.profile_required':
    'Add a profile to continue.',
  'containers.accounts.createUser.Content.your_date_of_birth': 'Your Date of Birth',

  'containers.accounts.forgotPassword.DesktopResetRequest.login_link': 'Return to Login',
  'containers.accounts.forgotPassword.DesktopResetRequest.back_to_login': 'Back To Login',

  'containers.accounts.forgotPassword.MobileResetRequest.login_link': 'Return to Login',
  'containers.accounts.forgotPassword.MobileResetRequest.back_to_login': 'Back To Login',

  'containers.accounts.verification_sent.VerificationSent.back_to_login': 'Back To Login',
  'containers.clientProfile.EditMembershipExpireModal.title': 'Edit Expire Date',
  'containers.clientProfile.EditMembershipExpireModal.loading': 'Updating...',
  'containers.clientProfile.EditMembershipExpireModal.update': 'Update',
  'containers.clientProfile.EditMembershipExpireModal.cancel': 'Cancel',
  'containers.clientProfile.EditMembershipExpireModal.description': 'Select a date to update expiry date.',
  'containers.clientProfile.EditMembershipExpireModal.prevDate': 'Previous Expiry Date:',
  'containers.clientProfile.EditMembershipExpireModal.end_date': 'New Expiry Date',
  'containers.clientProfile.EditMembershipCancellationModal.EditMembershipCancellationModal.title': 'Edit Membership Cancellation Date',
  'containers.clientProfile.EditMembershipCancellationModal.EditMembershipCancellationModal.cancellation_date': 'Cancellation Date',
  'containers.clientProfile.EditMembershipCancellationModal.EditMembershipCancellationModal.cancellation_reason': 'Cancellation Reason',
  'containers.clientProfile.EditMembershipCancellationModal.EditMembershipCancellationModal.cancellation_reason_placeholder': 'Select cancellation reason',
  'containers.clientProfile.EditMembershipCancellationModal.RemoveMembershipCancellationModal.title': 'Remove Cancellation',
  'containers.clientProfile.EditMembershipCancellationModal.RemoveMembershipCancellationModal.message': `Are you sure you want to remove {clientName} cancellation from {membershipName}`,
  'containers.clientProfile.Header.client_profile': 'Client Profile',
  'containers.clientProfile.Header.managed_profile': 'Managed Profile',
  'containers.clientProfile.Attachments.Attachments.header': 'Attachments',
  'containers.clientProfile.Attachments.Attachments.remove': 'Remove',
  'containers.clientProfile.Attachments.AtttachmentsUploadModal.create_title': 'Attachments',
  'containers.clientProfile.Attachments.AtttachmentsUploadModal.image_label': 'PDF Upload',
  'containers.clientProfile.Attachments.AtttachmentsUploadModal.upload_label': 'Upload',

  'PDFUpload.PDFUpload.empty_text': 'Drag & Drop',
  'PDFUpload.PDFUpload.empty_desc': 'Choose a file or',
  'PDFUpload.PDFUpload.empty_link': 'drag it here.',
  'PDFUpload.PDFUpload.drag_error': 'Only files with the following extensions are allowed: pdf.',


  'containers.clientProfile.ClientProfile.orders': 'Orders',
  'containers.clientProfile.ClientProfile.payments': 'Payments',
  'containers.clientProfile.ClientProfile.schedule': 'Schedule',

  'containers.clientProfile.MembershipInfo.MembershipInfo.active': 'Active',
  'containers.clientProfile.MembershipInfo.MembershipInfo.membership_info_title': 'Membership Subscription',
  'containers.clientProfile.MembershipInfo.MembershipInfo.unpaid': 'Unpaid',
  'containers.clientProfile.MembershipInfo.MembershipInfo.cancelled': 'Cancelled',
  'containers.clientProfile.MembershipInfo.MembershipInfo.cancelled_until': 'Cancelling on {date}',
  'containers.clientProfile.MembershipInfo.MembershipInfo.suspended': 'Suspended',
  'containers.clientProfile.MembershipInfo.MembershipInfo.edit': 'Edit',
  'containers.clientProfile.MembershipInfo.MembershipInfo.expired': 'Expired',
  'containers.clientProfile.MembershipInfo.MembershipInfo.suspended_until': 'Suspended until {date}',
  'containers.clientProfile.MembershipInfo.MembershipInfo.suspending': 'Suspending by {date}',
  'containers.clientProfile.MembershipInfo.MembershipInfo.charge': 'Charge',
  'containers.clientProfile.MembershipInfo.MembershipInfo.free': 'Free',
  'containers.clientProfile.MembershipInfo.MembershipInfo.next_payment': 'Next Payment',
  'containers.clientProfile.MembershipInfo.MembershipInfo.expires_on': 'Expires',
  'containers.clientProfile.MembershipInfo.MembershipInfo.no_expires': 'No expiration date',
  'containers.clientProfile.MembershipInfo.MembershipInfo.history': 'History',
  'containers.clientProfile.MembershipInfo.MembershipInfo.overview': 'Overview',
  'containers.clientProfile.MembershipInfo.MembershipInfo.suspend': 'Suspend',
  'containers.clientProfile.MembershipInfo.MembershipInfo.remove_suspension': 'Remove Suspension',
  'containers.clientProfile.MembershipInfo.MembershipInfo.reactivate': 'Reactivate',
  'containers.clientProfile.MembershipInfo.MembershipInfo.cancel': 'Cancel',
  'containers.clientProfile.MembershipInfo.MembershipInfo.remove_cancellation': 'Remove Cancellation',
  'containers.clientProfile.MembershipInfo.MembershipInfo.top_up': 'Top-Up',
  'containers.clientProfile.MembershipInfo.MembershipInfo.join_fee': 'Join Fee',
  'containers.clientProfile.MembershipInfo.MembershipInfo.last_payment': 'Last Payment',
  'containers.clientProfile.MembershipInfo.MembershipInfo.cancellation_date': 'Cancellation Date',
  'containers.clientProfile.MembershipInfo.MembershipInfo.n_a': 'N/A',

  'containers.clientProfile.MembershipInfo.OverviewDrawer.title': 'Membership Overview',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.n_a': 'N/A',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.view_membership_agreement': 'View Membership Agreement',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.no_membership_agreement': 'No membership agreement on file',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.tier_name': 'Tier: {name}',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.view_history': 'View Payment History',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.member_length': 'Member Length',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.status': 'Status',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.charge': 'Charge',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.next_payment': 'Next Payment',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.expiration_date': 'Expiration Date',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.details': 'Details',
  'containers.clientProfile.MembershipInfo.OverviewDrawer.description': 'Description',
  

  'containers.clientProfile.SubscriptionStatusText.cancelled_at': 'Cancelling on {date}',
  'containers.clientProfile.SubscriptionStatusText.suspended': 'Suspended',
  'containers.clientProfile.SubscriptionStatusText.expired': 'Expired',
  'containers.clientProfile.SubscriptionStatusText.suspending': 'Suspending',
  'containers.clientProfile.SubscriptionStatusText.cancel': 'Cancel',
  'containers.clientProfile.SubscriptionStatusText.active': 'Active',
  'containers.clientProfile.SubscriptionStatusText.unpaid': 'Unpaid',
  'containers.clientProfile.SubscriptionStatusText.cancelled': 'Cancelled',
  'containers.clientProfile.SubscriptionStatusText.suspended_until': 'Suspended until {date}',

  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.reactivate': 'Reactivate',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.activate': 'Activate',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.edit': 'Edit',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.cancel': 'Cancel',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.embed_card': 'Membership Card',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.embed_status': 'Status',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.embed_error': 'Error',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.card_active': 'Active',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.card_inactive': 'Inactive',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedInfo.top_up': 'Top-Up',
  'containers.clientProfile.MembershipEmbed.Store.top_up_success': 'Successfully added 10 credits to platinum card.',
  'containers.clientProfile.MembershipEmbed.Store.top_up_error': 'Error when added 10 credits to platinum card.',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.update_title': 'Update membership card',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.activate_title': 'Activate membership card',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.input_placeholder': 'Membership card',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.input_error': 'Membership card number should be 19 characters long',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.activate_action': 'Activate',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.update_action': 'Update',

  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem_title': 'Redeem Credits',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem': 'Redeem',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem_placeholder': 'Swing card',
  'containers.clientProfile.MembershipEmbed.MembershipEmbedEditModal.redeem_success': 'Successfully redeemed {n} swings.',

  'containers.clientProfile.ManagedProfiles.header': 'Managed Profiles',
  'containers.clientProfile.ManagedProfiles.add_profile': 'Add Profile',

  'containers.clientProfile.ContactInfo.header': 'Account Information',
  'containers.clientProfile.ContactInfo.phone': 'Phone',
  'containers.clientProfile.ContactInfo.phone_placeholder': 'Phone Number',
  'containers.clientProfile.ContactInfo.email': 'Email',
  'containers.clientProfile.ContactInfo.address': 'Address',
  'containers.clientProfile.ContactInfo.birthdate': 'Birthdate',
  'containers.clientProfile.ContactInfo.select_gender': 'Gender',
  'containers.clientProfile.ContactInfo.dob': '{age} y.o. • {dob}',
  'containers.clientProfile.ContactInfo.save': 'Save',
  'containers.clientProfile.ContactInfo.verify': 'Verify Email',
  'containers.clientProfile.ContactInfo.verified': 'Verified',
  'containers.clientProfile.ContactInfo.reset': 'Password Reset',

  'containers.clientProfile.Announcement.announcement_header':
    '{customerName} Announcement',
  'containers.clientProfile.Announcement.announcement_empty':
    'No announcement today.',

  'containers.creditListDrawer.CreditListDrawer.title': 'Credits',

  'containers.creditListDrawer.CreditGrantCard.credits': '{n, plural, one {{n} credit} other {{n} credits}}',
  'containers.creditListDrawer.CreditGrantCard.unlimited': 'Unlimited',
  'containers.creditListDrawer.CreditGrantCard.edit': 'Edit',
  'containers.creditListDrawer.CreditGrantCard.expires_at': 'expires at {expiresAt}',
  'containers.creditListDrawer.CreditGrantCard.expired_at': 'expired at {expiresAt}',
  'containers.creditListDrawer.CreditGrantCard.indefinite': '(No expiration)',
  'containers.creditListDrawer.CreditGrantCard.all_events': 'Event: All Events',

  'containers.creditListDrawer.RegistrationPackageCard.credits': '{n, plural, one {{n} credit} other {{n} credits}}',
  'containers.creditListDrawer.RegistrationPackageCard.edit': 'Edit',
  'containers.creditListDrawer.RegistrationPackageCard.no_packages': 'No available credits',

  'containers.creditListDrawer.CreditAdjustmentModal.title': 'Credit Adjustment',
  'containers.creditListDrawer.CreditAdjustmentModal.available_total': 'Available/Total',
  'containers.creditListDrawer.CreditAdjustmentModal.adjusted_qty': 'Adjusted Quantity',
  'containers.creditListDrawer.CreditAdjustmentModal.qty': '{available, select, Infinity {∞} other {{available}}} / {quantity, select, Infinity {∞} other {{quantity}}}',
  'containers.creditListDrawer.CreditAdjustmentModal.expires_at_error': 'Should have a valid format and not be in the past',
  'containers.creditListDrawer.CreditAdjustmentModal.expiration_restricted': 'Cannot change expiration of membership credits.',
  'containers.creditListDrawer.CreditAdjustmentModal.current_expiration': 'Current Expiration',
  'containers.creditListDrawer.CreditAdjustmentModal.adjusted_expiration': 'Adjusted Expiration',
  'containers.creditListDrawer.CreditAdjustmentModal.indefinite': 'No expiration',
  'containers.creditListDrawer.CreditAdjustmentModal.add': '+',
  'containers.creditListDrawer.CreditAdjustmentModal.sub': '-',
  'containers.creditListDrawer.CreditAdjustmentModal.cancel': 'Cancel',
  'containers.creditListDrawer.CreditAdjustmentModal.confirm': 'Confirm',

  'containers.creditListDrawer.RegistrationPackageAdjustmentModal.cancel': 'Cancel',
  'containers.creditListDrawer.RegistrationPackageAdjustmentModal.confirm': 'Confirm',
  'containers.creditListDrawer.RegistrationPackageAdjustmentModal.title': 'Package adjustment',
  'containers.creditListDrawer.RegistrationPackageAdjustmentModal.available_total': 'Available/Total',
  'containers.creditListDrawer.RegistrationPackageAdjustmentModal.adjusted_qty': 'Adjusted Quantity',

  'containers.creditListDrawer.EmptyState.title': 'Nothing to see here.',
  'containers.creditListDrawer.EmptyState.description': 'Credits will show up after purchase.',

  'containers.creditListDrawer.Store.grant_update_success': 'Credits were successfully adjusted.',
  'containers.creditListDrawer.Store.grant_update_error': 'Error while adjusting credits. Please try again',

  'containers.clientProfile.Notes.header': 'Notes',
  'containers.clientProfile.Notes.save': 'Save',

  'containers.clientProfile.Name.first_name': 'First Name',
  'containers.clientProfile.Name.last_name': 'Last Name',
  'containers.clientProfile.Name.email': 'Email',
  'containers.clientProfile.Name.no_waiver_on_file': 'No waiver on file.',
  'containers.clientProfile.Name.view_waiver': 'View Waiver',
  'containers.clientProfile.Name.view_membership_agreement': 'View Membership Agreement',
  'containers.clientProfile.Name.no_membership_agreement': 'No membership agreement on file',

  'containers.clientProfile.BalanceInfo.header': 'Outstanding Balance',
  'containers.clientProfile.BalanceInfo.view': 'View',

  'containers.clientProfile.AccountCredits.AccountCreditsInfo.header': 'Account Credit',
  'containers.clientProfile.AccountCredits.AccountCreditsInfo.view': 'View',

  'containers.clientProfile.AccountCredits.AccountCreditDrawer.title': 'Account Credit History',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.menu_create': 'Create Credit',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.menu_clear': 'Clear Credit',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.create_title': 'Account Credit',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.store_credit': 'Credit Loaded',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.gift_card': 'Credit Loaded',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.apply': 'Credit Applied',
  'containers.clientProfile.AccountCredits.AccountCreditDrawer.clear': 'Credit Cleared',

  'containers.clientProfile.AccountCredits.steps.ListStep.title': 'Total Credit Available',
  'containers.clientProfile.AccountCredits.steps.ListStep.list_title': 'Credit List',
  'containers.clientProfile.AccountCredits.steps.ListStep.store_credit': 'Store Credit',
  'containers.clientProfile.AccountCredits.steps.ListStep.gift_card': 'Gift Card',
  'containers.clientProfile.AccountCredits.steps.ListStep.clear': 'Credit Cleared',
  'containers.clientProfile.AccountCredits.steps.ListStep.apply': 'Credit Applied',
  'containers.clientProfile.AccountCredits.steps.ListStep.more_btn': 'Load more',

  'containers.clientProfile.AccountCredits.EmptyState.title': 'Nothing to see here.',
  'containers.clientProfile.AccountCredits.EmptyState.description': 'Credit List will populate when credits are added by an admin.',

  'containers.clientProfile.AccountCredits.steps.CreationStep.title': 'Add Credit',
  'containers.clientProfile.AccountCredits.steps.CreationStep.amount': 'Enter Credit Amount',
  'containers.clientProfile.AccountCredits.steps.CreationStep.note': 'Enter Notes (Optional)',
  'containers.clientProfile.AccountCredits.steps.CreationStep.credit_type': 'Credit Type',
  'containers.clientProfile.AccountCredits.steps.CreationStep.add_credit': 'Add Credit',
  'containers.clientProfile.AccountCredits.steps.CreationStep.gift': 'Gift Card',
  'containers.clientProfile.AccountCredits.steps.CreationStep.store': 'Store Credit',

  'containers.clientProfile.AccountCredits.steps.ClearStep.title': 'Clear Credit',
  'containers.clientProfile.AccountCredits.steps.ClearStep.amount_available': 'Available Credit',
  'containers.clientProfile.AccountCredits.steps.ClearStep.amount': 'Enter Credit Amount',
  'containers.clientProfile.AccountCredits.steps.ClearStep.note': 'Enter Notes (Optional)',
  'containers.clientProfile.AccountCredits.steps.ClearStep.clear_credit': 'Clear Credit',

  'containers.clientProfile.AccountCredits.steps.DetailsStep.store_credit': 'Total Credit Loaded',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.gift_card': 'Total Credit Loaded',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.apply': 'Credit Applied',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.clear': 'Total Credit Cleared',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.credit_info': 'Credit Info',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.cleared_by': 'Cleared By:',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.added_by': 'Added By:',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.date_applied': 'Date Applied',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.order_method': 'Order Method',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.order_number': 'Order Number',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.notes': 'Notes:',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.details': 'Details',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.order_method_all': 'All',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.order_method_online': 'Online',
  'containers.clientProfile.AccountCredits.steps.DetailsStep.order_method_in_store': 'In-Store (POS)',

  'containers.clientProfile.CreditInfo.header': 'Credits',
  'containers.clientProfile.CreditInfo.view': 'View All',
  'containers.clientProfile.CreditInfo.unlimited': 'Unlimited',

  'containers.clientProfile.CashCreditsInfo.header': 'Swings',
  'containers.clientProfile.CashCreditsInfo.view': 'View',
  'containers.clientProfile.CashCreditsInfo.redeem': 'Redeem',

  'containers.clientProfile.Payments.NotAvailableState.na_state': 'Not available',

  'containers.clientProfile.Orders.OrderItem.order_details': 'Order Details',
  'containers.clientProfile.Orders.OrderItem.date_today': 'Today • {date}',

  'containers.clientProfile.Schedule.Schedules.upcoming_sessions': 'Upcoming sessions',
  'containers.clientProfile.Schedule.Schedules.past_sessions': 'Past sessions',
  'containers.clientProfile.Schedule.SchedulesList.empty_sessions': 'No sessions',

  'containers.clientProfile.Schedule.ScheduleListItem.pay_balance': 'View Balance',
  'containers.clientProfile.Schedule.ScheduleListItem.order_details': 'Order Details',
  'containers.clientProfile.Schedule.ScheduleListItem.session_summary': 'View Session',
  'containers.clientProfile.Schedule.ScheduleListItem.cancel_registration': 'Cancel',
  'containers.clientProfile.Schedule.ScheduleListItem.unpaid': 'Unpaid',
  'containers.clientProfile.Schedule.ScheduleListItem.is_no_show': 'No Show',
  'containers.clientProfile.Schedule.ScheduleListItem.reschedule': 'Reschedule',
  'containers.clientProfile.Schedule.CancelRegistrationModal.title': 'Cancel registration',
  'containers.clientProfile.Schedule.CancelRegistrationModal.cancel_btn': 'No',
  'containers.clientProfile.Schedule.CancelRegistrationModal.confirm_btn': 'Yes',
  'containers.clientProfile.Schedule.CancelRegistrationModal.cancel_registration_confirmation': 'Cancelling will remove you from this scheduled session and a credit for the event will be deposited to your account for future use.',
  'containers.clientProfile.Schedule.CancelRegistrationModal.note_title': 'Important Note: ',
  'containers.clientProfile.Schedule.CancelRegistrationModal.note_subtitle': 'For sessions purchased using "pay later" canceling this session does not remove the balance owed. If you want to remove this session completely or wish to receive a refund, please contact the facility. Actions cannot be undone.',

  'containers.clientProfile.Drawers.ClientProfileDrawer.ClientProfileDrawer.title': 'Client profile',
  'containers.clientProfile.Drawers.ClientProfileDrawer.ClientProfileDrawer.schedule': 'Schedule',
  'containers.clientProfile.Drawers.ClientProfileDrawer.ClientProfileDrawer.orders': 'Orders',
  'containers.clientProfile.Drawers.ClientProfileDrawer.ClientProfileDrawer.payments': 'Payments',
  'containers.clientProfile.Drawers.OrdersDrawer.title': 'Orders',
  'containers.clientProfile.Drawers.SchedulesDrawer.title': 'Schedule',
  'containers.clientProfile.Drawers.PaymentsDrawer.title': 'Payments',

  'containers.clientProfile.Store.client_update_successful': 'Client updated successfully.',
  'containers.clientProfile.Store.all_balances_paid': 'Payment processing. Please wait for data refresh and check reporting for payment confirmation.',
  'containers.clientProfile.Store.registration_cancel_successful': 'Registration successfully cancelled.',
  'containers.clientProfile.Store.pay_all_balances_success': '',
  'containers.clientProfile.Store.verification_sent': 'Verify Account email sent to client inbox.',
  'containers.clientProfile.Store.reset_sent': 'Password Reset email sent to client inbox.',

  'containers.contacts.contactsArchived.ClientCard.unarchive': 'Unarchive',

  'containers.contacts.contactsClients.ContactsClients.btn_filter': 'Filters',
  'containers.contacts.contactsClients.ContactsClients.btn_filter_count': '{count}',
  'containers.contacts.contactsClients.ContactsClients.status_filter': 'Status: {status}',
  'containers.contacts.contactsClients.ClientCard.archive': 'Archive',
  'containers.contacts.contactsClients.ClientCard.delete': 'Delete',
  'containers.contacts.contactsClients.ClientCard.remind': 'Remind',
  'containers.contacts.contactsClients.ClientCard.updateEmail': 'Update Invite',

  'containers.contacts.contactsPage.ContactsPage.header': 'Contacts',

  'containers.contacts.contactsClients.AccountStatus.unclaimed_account':
    'Unclaimed Account',
  'containers.contacts.contactsClients.AccountStatus.invited_time':
    'Invited {time}',
   'containers.contacts.contactsClients.AccountStatus.lead_msg':
    'Lead Contact',

  'containers.contacts.contactsGroups.ContactGroupToolbar.action': 'Action',
  'containers.contacts.contactsGroups.ContactGroupToolbar.contact_group':
    'Contact Group',
  'containers.contacts.contactsGroups.ContactGroupToolbar.create_new_group':
    'Create New Group',
  'containers.contacts.contactsGroups.ContactGroupToolbar.group_name':
    'Group Name',
  'containers.contacts.contactsGroups.ContactGroupToolbar.remove': 'Remove',
  'containers.contacts.contactsGroups.ContactGroupToolbar.remove_group':
    'Remove Group',
  'containers.contacts.contactsGroups.ContactGroupToolbar.save': 'Save',

  'containers.contacts.contactsGroups.ContactsGroups.create_contact_group':
    'Create Contact Group',
  'containers.contacts.contactsGroups.ContactsGroups.empty_state_header':
    "No contact groups? Let's fix that.",
  'containers.contacts.contactsGroups.ContactsGroups.contact_group':
    'contact group',
  'containers.contacts.contactsGroups.ContactsGroups.empty_state_message':
    'Categorize and sort your contacts into marketing-ready lists.',
  'containers.contacts.contactsGroups.ContactsGroups.remove_contact_group':
    'Remove Contact Group',
  'containers.contacts.contactsGroups.ContactsGroups.create_email':
    'Create Marketing Email',

  'containers.contacts.contactsPage.ActionButtons.import': 'Import',
  'containers.contacts.contactsPage.ActionButtons.export': 'Export',
  'containers.contacts.contactsPage.ActionButtons.invite': 'Invite',
  'containers.contacts.contactsPage.ActionButtons.create_group': 'Create Group',

  'shared.stores.ContactGroupStore.create_group_filter_success': 'Contact Group Created Successfully. Navigate to Group to send a message',
  'shared.stores.ContactGroupStore.create_group_success': 'Contact group created successfully.',

  'containers.contacts.contactsStaff.StaffCard.invited_time': 'Invited {time}',
  'containers.contacts.contactsStaff.StaffCard.removed': 'Deleted',
  'containers.contacts.contactsStaff.StaffCard.archive': 'Archive',
  'containers.contacts.contactsStaff.StaffCard.remind': 'Remind',
  'containers.contacts.contactsStaff.StaffCard.updateEmail': 'Update Invite',
  'containers.contacts.contactsStaff.StaffCard.edit': 'Edit',
  'containers.contacts.contactsStaff.ContactsStaff.staff_types': 'staff types',

  'containers.contacts.contactsStaff.RevokeAccessModal.title': 'Delete staff member',
  'containers.contacts.contactsStaff.RevokeAccessModal.text': '{staffName} is currently scheduled for {sessions}. Removing the staff member will do the following:\n' +
    '\n' +
    '• Staff member will be unassigned from all future sessions\n' +
    '• Deleted staff members can no longer be assigned to events or sessions\n' +
    '• Staff members cannot be reinstated\n' +
    '\n' +
    '{important} If the user is the only staff member associated with a future session, the session will be canceled and client payment/credits for that session will be refunded.\n' +
    '\n' +
    'Click {confirm} to remove the staff member. {undone}.',
  'containers.contacts.contactsStaff.RevokeAccessModal.important': 'Important:',
  'containers.contacts.contactsStaff.RevokeAccessModal.btn_confirm': 'Confirm',
  'containers.contacts.contactsStaff.RevokeAccessModal.btn_cancel': 'Cancel',
  'containers.contacts.contactsStaff.RevokeAccessModal.undone_text': 'This cannot be undone',


  'containers.contacts.contactsGroups.GroupsTabSmall.cancel': 'Cancel',
  'containers.contacts.contactsGroups.GroupsTabSmall.remove': 'Remove',
  'containers.contacts.contactsGroups.GroupsTabSmall.save': 'Save',
  'containers.contacts.updateContact.UpdateContact.title': 'Update Contact',
  'containers.contacts.updateContact.UpdateContact.first_name': 'First Name',
  'containers.contacts.updateContact.UpdateContact.last_name': 'Last Name',
  'containers.contacts.updateContact.UpdateContact.email': 'Email',
  'containers.contacts.updateContact.Store.update_success':
    'Contact Updated Successfully',
  'containers.contacts.updateContact.UpdateContact.staff_member': 'Staff',
  'containers.contacts.updateContact.UpdateContact.instructor': 'Instructor',
  'containers.contacts.updateContact.UpdateContact.staff_admin': 'Admin',
  'containers.contacts.updateContact.UpdateContact.manager': 'Manager',
  'containers.contacts.updateContact.UpdateContact.coach': 'Coach',

  'containers.marketing.FiltersDrawer.filters': 'Filters',
  'containers.marketing.FiltersDrawer.date_created': 'Date Created',
  'containers.marketing.FiltersDrawer.date_sent': 'Date Sent',
  'containers.marketing.FiltersDrawer.status': 'Status',
  'containers.marketing.FiltersDrawer.apply': 'Apply Filters',
  'containers.marketing.FiltersDrawer.clear': 'Clear Filters',

  'containers.marketing.ActiveFilters.clear': 'Clear Filters',
  'containers.marketing.EmptyState.title': 'Nothing to see here.',
  'containers.marketing.EmptyState.description': 'Create an email template to send to your contacts.',

  'containers.marketing.emails.EmptyState.title': 'Nothing to see here.',
  'containers.marketing.emails.EmptyState.description': 'Create an email template to send to your contacts.',

  'containers.marketing.emails.EmailTemplateDrawer.title': 'Email Template',
  'containers.marketing.emails.EmailTemplateDrawer.create': 'Create Template',
  'containers.marketing.emails.EmailTemplateDrawer.back_to': 'Back To Edit',
  'containers.marketing.emails.EmailTemplateDrawer.cancel': 'Cancel',
  'containers.marketing.emails.EmailTemplateDrawer.preview': 'Preview', 

  'containers.marketing.emails.MarketingEmails.edit': 'Edit',
  'containers.marketing.emails.MarketingEmails.delete': 'Delete',
  'containers.marketing.emails.MarketingEmails.create': 'Create Template',
  'containers.marketing.emails.MarketingEmails.search_placeholder': 'Search Email Template',
  'containers.marketing.emails.MarketingEmails.table_name': 'Email template name',
  'containers.marketing.emails.MarketingEmails.table_created_by': 'Created By',
  'containers.marketing.emails.MarketingEmails.update_confirm': 'Update',
  'containers.marketing.emails.MarketingEmails.create_confirm': 'Create',
  'containers.marketing.emails.MarketingEmails.delete_confirm_title': 'Delete Email Template',
  'containers.marketing.emails.MarketingEmails.delete_confirm_text': 'Are you sure you want to delete "{name}" template?',
  'containers.marketing.emails.MarketingEmails.delete_confirm': 'Yes',
  'containers.marketing.emails.MarketingEmails.delete_cancel': 'Cancel',
  'containers.marketing.emails.MarketingEmails.filters': 'Filters',
  'containers.marketing.emails.MarketingEmails.export': 'Export',

  'containers.marketing.campaigns.CampaignsList.view': 'View',
  'containers.marketing.campaigns.CampaignsList.edit': 'Edit',
  'containers.marketing.campaigns.CampaignsList.delete': 'Delete',
  'containers.marketing.campaigns.CampaignsList.cancel': 'Cancel',
  'containers.marketing.campaigns.CampaignsList.delete_confirmation': 'Delete Confirmation',
  'containers.marketing.campaigns.CampaignsList.delete_confirmation_text': 'Are you sure you want to delete "{name}" campaign?',
  'containers.marketing.campaigns.CampaignsList.yes': 'Yes',
  'containers.marketing.campaigns.CampaignsList.no': 'Cancel',
  'containers.marketing.campaigns.CampaignsList.cancel_confirmation': 'Cancel Confirmation',
  'containers.marketing.campaigns.CampaignsList.cancel_confirmation_text': 'Are you sure you want to cancel "{name}" campaign?',

  'containers.marketing.campaigns.MarketingCampaigns.title': 'Nothing to see here.',
  'containers.marketing.campaigns.MarketingCampaigns.description': 'Create a campaign to send emails to your contacts.',
  'containers.marketing.campaigns.MarketingCampaigns.create_campaign': 'Create Campaign',
  'containers.marketing.campaigns.MarketingCampaigns.search_placeholder': 'Search Campaign Name',
  'containers.marketing.campaigns.MarketingCampaigns.filters': 'Filters',
  'containers.marketing.campaigns.MarketingCampaigns.export': 'Export',
  'containers.marketing.campaigns.MarketingCampaigns.table_name': 'Campaign Name',
  'containers.marketing.campaigns.MarketingCampaigns.table_template': 'Email Template',
  'containers.marketing.campaigns.MarketingCampaigns.table_date_created': 'Date • Time Created',
  'containers.marketing.campaigns.MarketingCampaigns.table_status': 'Status',
  'containers.marketing.campaigns.MarketingCampaigns.table_date_scheduled': 'Date • Time Scheduled',
  'containers.marketing.campaigns.MarketingCampaigns.create_confirm': 'Next',
  'containers.marketing.campaigns.MarketingCampaigns.template_drawer_title_create': 'Create Message',
  'containers.marketing.campaigns.MarketingCampaigns.template_drawer_title_edit': 'Edit Message',
  'containers.marketing.campaigns.MarketingCampaigns.template_drawer_title_view': 'View Message',
  'containers.marketing.campaigns.MarketingCampaigns.template_drawer_cancel': 'Back to Campaign Details',
  'containers.marketing.campaigns.MarketingCampaigns.template_drawer_confirm': 'Save Campaign',
  'containers.marketing.campaigns.MarketingCampaigns.template_drawer_confirm_now': 'Send Campaign',

  'containers.marketing.campaigns.CreateCampaignDrawer.title_create': 'Create Campaign',
  'containers.marketing.campaigns.CreateCampaignDrawer.title_edit': 'Edit Campaign',
  'containers.marketing.campaigns.CreateCampaignDrawer.title_view': 'View Campaign',
  'containers.marketing.campaigns.CreateCampaignDrawer.cancel': 'Cancel',
  'containers.marketing.campaigns.CreateCampaignDrawer.create': 'Create',
  'containers.marketing.campaigns.CreateCampaignDrawer.name': 'Campaign Name',
  'containers.marketing.campaigns.CreateCampaignDrawer.email_template': 'Select Email Template',
  'containers.marketing.campaigns.CreateCampaignDrawer.reply_to': 'Reply To',
  'containers.marketing.campaigns.CreateCampaignDrawer.contact_group': 'Select Segment',
  'containers.marketing.campaigns.CreateCampaignDrawer.schedule_email': 'Schedule Email',
  'containers.marketing.campaigns.CreateCampaignDrawer.send_later': 'Send Later',

  'containers.marketing.statistics.MarketingStatistics.search_placeholder': 'Search Campaign Name',
  'containers.marketing.statistics.MarketingStatistics.filters': 'Filters',
  'containers.marketing.statistics.MarketingStatistics.table_name': 'Campaign Name',
  'containers.marketing.statistics.MarketingStatistics.table_sent': 'Sent',
  'containers.marketing.statistics.MarketingStatistics.table_opened': 'Opened',
  'containers.marketing.statistics.MarketingStatistics.table_unsubscribed': 'Unsubscribed',
  'containers.marketing.statistics.MarketingStatistics.title': 'Nothing to see here.',
  'containers.marketing.statistics.MarketingStatistics.description': 'Create a campaign to see statistics info.',



  'containers.marketing.title': 'Email Marketing',
  'containers.marketing.emails': 'Emails',
  'containers.marketing.campaigns': 'Campaigns',
  'containers.marketing.statistics': 'Statistics',

  'containers.coupons.couponList.Coupons.header': 'Coupons',
  'containers.coupons.couponList.Header.header': 'Coupons',
  'containers.coupons.couponList.SearchField.search': 'Search Coupons',
  'containers.coupons.couponList.Table.HeaderDesktop.code': 'Code',
  'containers.coupons.couponList.Table.HeaderDesktop.type': 'Type',
  'containers.coupons.couponList.Table.HeaderDesktop.discount': 'Discount',
  'containers.coupons.couponList.Table.HeaderDesktop.start_date': 'Start Date',
  'containers.coupons.couponList.Table.HeaderDesktop.end_date': 'End Date',
  'containers.coupons.couponList.DesktopCard.one_time': 'Single Payment',
  'containers.coupons.couponList.DesktopCard.recurring': 'Recurring Payment',
  'containers.coupons.couponList.DesktopCard.recurring_once': 'Once Recurring Payment',
  'containers.coupons.couponList.DesktopCard.value': '{value} off',
  'containers.coupons.couponList.CouponUsageLimit.unlimited': 'Unlimited',
  'containers.coupons.couponList.CouponUsageLimit.per_client': '{limit} per Client',
  'containers.coupons.couponList.CouponUsageLimit.global': '{limit} total',
  'containers.coupons.couponList.MobileCard.one_time': 'Single Payment',
  'containers.coupons.couponList.MobileCard.recurring': 'Recurring Payment',
  'containers.coupons.couponList.MobileCard.recurring_once': 'Once Recurring Payment',
  'containers.coupons.couponList.MobileCard.value': '{value} off',
  'containers.coupons.couponList.EmptyState.title': 'Everybody loves a deal.',
  'containers.coupons.couponList.EmptyState.description': 'Coupons are a good way to incentivize purchases.',
  'containers.coupons.couponList.EmptyState.button': 'Create Coupon',

  'containers.coupons.couponView.Header.header': 'Coupons',
  'containers.coupons.couponView.Header.name': 'Coupon Name',
  'containers.coupons.couponView.Header.name_placeholder': 'Enter Coupon Name',
  'containers.coupons.couponView.Header.save': 'Save',
  'containers.coupons.couponView.Header.cancel': 'Cancel',
  'containers.coupons.couponView.Header.create': 'Create',
  'containers.coupons.couponView.ViewCard.value': '{value} off',
  'containers.coupons.couponView.ConfirmRemoveModal.btn.cancel': 'Cancel',
  'containers.coupons.couponView.ConfirmRemoveModal.btn.confirm': 'Confirm',
  'containers.coupons.couponView.ConfirmRemoveModal.title': 'Coupon Remove Confirmation',
  'containers.coupons.couponView.ConfirmRemoveModal.text': 'Are you sure you want to remove {name}?',
  'containers.coupons.couponView.Coupon.alert_title_no_items': 'Coupon Discountable Items',
  'containers.coupons.couponView.Coupon.alert_btn_text_no_items': 'Select More',
  'containers.coupons.couponView.Coupon.alert_text_no_items': 'There are no items selected.',
  'containers.coupons.couponView.Coupon.alert_title_unsupported_items': 'Unsupported items',
  'containers.coupons.couponView.Coupon.alert_btn_text_unsupported_items': 'Got It',
  'containers.coupons.couponView.Coupon.alert_text_unsupported_items': 'There are items applied to this coupon that cannot be used for another coupon type.',
  'containers.coupons.couponView.Coupon.confirmation_title': 'Are you sure?',
  'containers.coupons.couponView.Coupon.confirmation_body': 'You may have unsaved changes. Are you sure you want to leave this page?',

  'containers.coupons.couponView.ViewCard.name': 'Name',
  'containers.coupons.couponView.ViewCard.description': 'Description',
  'containers.coupons.couponView.ViewCard.type': 'Type',
  'containers.coupons.couponView.ViewCard.code': 'Code',
  'containers.coupons.couponView.ViewCard.one_time': 'Single Payment',
  'containers.coupons.couponView.ViewCard.recurring': 'Recurring Payment',
  'containers.coupons.couponView.ViewCard.recurring_once': 'Once Recurring Payment',
  'containers.coupons.couponView.ViewCard.discount': 'Discount',
  'containers.coupons.couponView.ViewCard.usage': '{redemption_count} / {redemption_limit}',

  'containers.coupons.couponView.ModalTitle.cancel': 'Cancel',
  'containers.coupons.couponView.ModalTitle.create': 'Create',
  'containers.coupons.couponView.ModalTitle.name': 'Coupon Name',
  'containers.coupons.couponView.ModalTitle.name_placeholder': 'Enter Coupon Name',

  'containers.coupons.couponView.EditForm.name': 'Name',
  'containers.coupons.couponView.EditForm.description': 'Description',
  'containers.coupons.couponView.EditForm.code': 'Code',
  'containers.coupons.couponView.EditForm.recurring': 'Recurring',
  'containers.coupons.couponView.EditForm.recurring_once': 'One Time',
  'containers.coupons.couponView.EditForm.coupon_type': 'Payment Type',
  'containers.coupons.couponView.EditForm.amount': 'Amount',
  'containers.coupons.couponView.EditForm.amount_placeholder': 'Enter Amount',
  'containers.coupons.couponView.EditForm.code_placeholder': 'Enter Code',
  'containers.coupons.couponView.EditForm.redemption_limit_placeholder': '∞',
  'containers.coupons.couponView.EditForm.description_placeholder': 'Enter Description Here...',
  'containers.coupons.couponView.EditForm.discount': 'Discount',
  'containers.coupons.couponView.EditForm.one_time': 'Single',
  'containers.coupons.couponView.EditForm.percent': 'Percent',
  'containers.coupons.couponView.EditForm.value': 'Discount Value',
  'containers.coupons.couponView.EditForm.duration': 'Duration',
  'containers.coupons.couponView.EditForm.start_date': 'Start Date',
  'containers.coupons.couponView.EditForm.end_date': 'End Date',
  'containers.coupons.couponView.EditForm.save': 'Save',
  'containers.coupons.couponView.EditForm.coupon_type_tooltip': '"Single" apply to all non-recurring payments and are only applicable to join fees for memberships. "Recurring" apply to all payment plans or memberships and will deduct from every future payment. These two types cannot be combined at checkout."One Time" is for all payment plans or memberships and will apply to the first payment only. It can not be combined with other two in checkout.',

  'containers.coupons.couponView.EditForm.coupon_usage_limit_tooltip': 'Usage limit sets the number of times the coupon can be used. This limit can apply per client or to the total number of coupon uses.',
  'containers.coupons.couponView.EditForm.coupon_uses_label': 'Use(s)',
  'containers.coupons.couponView.EditForm.redemption_limit': 'Usage Limit',
  'containers.coupons.couponView.EditForm.per_client': 'Per Client',
  'containers.coupons.couponView.EditForm.global': 'Uses Total',

  'containers.coupons.couponView.DiscountableTabs.CreditPass': 'Credit Passes',
  'containers.coupons.couponView.DiscountableTabs.Event': 'Events',
  'containers.coupons.couponView.DiscountableTabs.Membership': 'Memberships',
  'containers.coupons.couponView.DiscountableTabs.Variant': 'Retail',
  'containers.coupons.couponView.DiscountableTabs.Team': 'Teams',

  'containers.coupons.couponView.DiscountableCard.specific_event': 'Specific Events',
  'containers.coupons.couponView.DiscountableCard.all_event': 'All Events',
  'containers.coupons.couponView.DiscountableCard.search_event': 'Search Event',
  'containers.coupons.couponView.DiscountableCard.specific_membership': 'Specific Memberships',
  'containers.coupons.couponView.DiscountableCard.all_membership': 'All Memberships',
  'containers.coupons.couponView.DiscountableCard.search_membership': 'Search Membership',
  'containers.coupons.couponView.DiscountableCard.specific_creditpass': 'Specific Credit Passes',
  'containers.coupons.couponView.DiscountableCard.all_creditpass': 'All Credit Passes',
  'containers.coupons.couponView.DiscountableCard.search_creditpass': 'Search Credit Pass',
  'containers.coupons.couponView.DiscountableCard.specific_variant': 'Specific Variants',
  'containers.coupons.couponView.DiscountableCard.all_variant': 'All Variants',
  'containers.coupons.couponView.DiscountableCard.search_variant': 'Search Variant',
  'containers.coupons.couponView.DiscountableCard.all_team': 'All Teams',
  'containers.coupons.couponView.DiscountableCard.specific_team': 'Specific Teams',
  'containers.coupons.couponView.DiscountableCard.search_team': 'Search Team',

  'containers.coupons.couponView.DiscountableItems.all_CreditPass': 'All Credit Passes',
  'containers.coupons.couponView.DiscountableItems.all_Event': 'All Events',
  'containers.coupons.couponView.DiscountableItems.all_Membership': 'All Memberships',
  'containers.coupons.couponView.DiscountableItems.all_Variant': 'All Variants',
  'containers.coupons.couponView.DiscountableItems.all_items': 'All Items',
  'containers.coupons.couponView.DiscountableItems.all_Team': 'All Teams',


  'containers.events.admin.clients.ClientCard.female': 'F',
  'containers.events.admin.clients.ClientCard.male': 'M',
  'containers.events.admin.clients.ClientCard.remove': 'Remove',
  'containers.events.admin.clients.ClientCard.view': 'View',
  'containers.events.admin.clients.ClientCard.transfer': 'Transfer',

  'containers.events.admin.clients.EmptyState.no_results': 'No results found.',
  'containers.events.admin.clients.EmptyState.no_clients': `No clients registered for this ${nouns.event}.`,

  'containers.events.admin.clients.EmptyState.no_clients_team': 'No clients registered for this team',

  'containers.events.admin.schedule.completed_event_sessions.CompletedEventSessions.view_schedule': 'View Schedule',
  'containers.events.admin.schedule.completed_event_sessions.CompletedEventSessions.search': 'Search by attendee name',
  'containers.events.admin.schedule.completed_event_sessions.EmptyState.no_past_sessions': `No past sessions for this ${nouns.event}.`,
  'containers.events.admin.schedule.completed_event_sessions.EmptyState.no_past_sessions_team': 'No past sessions for this team.',
  'containers.events.admin.schedule.completed_event_sessions.EmptyState.no_results': 'No results found.',
  'containers.events.admin.schedule.completed_event_sessions.StaffSection.staff_count': 'staff',

  'containers.events.admin.schedule.completed_event_sessions.SessionsList.view_attendees': `View Attendees`,
  'containers.events.admin.schedule.completed_event_sessions.SessionsList.view_session': `View Session`,
  'containers.events.admin.schedule.completed_event_sessions.SessionsList.duration': `{startsAt} • {duration}m`,
  'containers.events.admin.schedule.completed_event_sessions.SessionsList.client_count': '{clientCount} Attendees',
  'containers.events.admin.schedule.completed_event_sessions.SessionsList.attendance_details': '{clientCount} / {maxClients, select, Infinity {∞} other {{maxClients}}}',
  'containers.events.admin.schedule.completed_event_sessions.SessionsList.cancel': 'Cancel Session',
  'containers.events.admin.schedule.completed_event_sessions.SessionsList.cancelled': 'Cancelled Session',

  'containers.events.admin.schedule.completeScheduleModal.CompleteScheduleModal.complete_schedule_modal_body': 'This recurring schedule has {count} future booking(s). Completing the schedule will unschedule the clients.',
  'containers.events.admin.schedule.completeScheduleModal.CompleteScheduleModal.complete_schedule_modal_title': '⚠️  Are you sure you want to complete the schedule?',
  'containers.events.admin.schedule.completeScheduleModal.CompleteScheduleModal.complete_schedule_modal_note_label': 'ADD NOTES (optional)',


  'containers.events.admin.schedule.future_event_sessions.FutureEventSessions.view_schedule': 'View Schedule',
  'containers.events.admin.schedule.future_event_sessions.FutureEventSessions.search': 'Search by attendee name',
  'containers.events.admin.schedule.future_event_sessions.EmptyState.no_upcoming_sessions': `No upcoming sessions for this ${nouns.event}.`,
  'containers.events.admin.schedule.future_event_sessions.EmptyState.no_results': 'No results found.',
  'containers.events.admin.schedule.future_event_sessions.StaffSection.staff_count': 'staff',

  'containers.events.admin.schedule.future_event_sessions.SessionsList.view_attendees': `View Attendees`,
  'containers.events.admin.schedule.future_event_sessions.SessionsList.view_session': `View Session`,
  'containers.events.admin.schedule.future_event_sessions.SessionsList.duration': `{startsAt} • {duration}m`,
  'containers.events.admin.schedule.future_event_sessions.SessionsList.client_count': '{clientCount} Attendees',
  'containers.events.admin.schedule.future_event_sessions.SessionsList.attendance_details': '{clientCount} / {maxClients, select, Infinity {∞} other {{maxClients}}}',
  'containers.events.admin.schedule.future_event_sessions.SessionsList.cancel': 'Cancel Session',
  'containers.events.admin.schedule.future_event_sessions.SessionsList.cancelled': 'Cancelled Session',

  'containers.events.admin.schedule.completed_event_schedules.CompletedEventSchedules.view_sessions': 'View Sessions',
  'containers.events.admin.schedule.completed_event_schedules.EmptyState.no_past_schedules': `No past schedules for this ${nouns.event}.`,
  'containers.events.admin.schedule.completed_event_schedules.EmptyState.no_past_schedules_team': 'No past schedules for this team.',
  'containers.events.admin.schedule.completed_event_schedules.EmptyState.no_results': 'No results found.',
  'containers.events.admin.schedule.completed_event_schedules.StaffSection.staff_count': 'staff',

  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.daily': 'Daily',
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.start_at': `{startsAt}`,
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.multiple_start_at':
    'Multiple times',
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.duration': ` • {duration}m`,
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.daily_repeat': `Every {interval} Days`,
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.every_day_repeat': `Every day`,
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.dates': '{start} - {end}',
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.edit_schedule': 'Edit Schedule',
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.cancel_schedule': 'Cancel Schedule',
  'containers.events.admin.schedule.completed_event_schedules.ScheduleList.status_cancelled': `Status: Cancelled`,

  'containers.events.admin.schedule.shared.EventScheduleHeader.view_sessions': 'View Sessions',
  'containers.events.admin.schedule.shared.EventScheduleHeader.create_session': 'Create Sessions',

  'containers.events.admin.schedule.shared.TeamScheduleHeader.view_sessions': 'View Sessions',
  'containers.events.admin.schedule.shared.TeamScheduleHeader.create_session': 'Create Sessions',
  'containers.events.admin.schedule.shared.TeamScheduleHeader.filters': 'Filters',

  'containers.events.admin.schedule.shared.EventSessionHeader.view_schedule': 'View Schedules',
  'containers.events.admin.schedule.shared.EventSessionHeader.create_session': 'Create Sessions',

  'containers.events.admin.schedule.shared.TeamSessionHeader.view_schedule': 'View Schedules',
  'containers.events.admin.schedule.shared.TeamSessionHeader.create_session': 'Create Sessions',

  'containers.events.admin.schedule.shared.TeamSessionHeader.search': 'Search by attendee name',
  'containers.events.admin.schedule.shared.TeamSessionHeader.search_mobile': 'Search',
  'containers.events.admin.schedule.shared.TeamSessionHeader.filters': 'Filters',
  'containers.events.admin.schedule.shared.EventSessionHeader.search': 'Search by attendee name',

  'containers.events.admin.schedule.future_event_schedules.FutureEventSchedules.view_sessions': 'View Sessions',
  'containers.events.admin.schedule.future_event_schedules.EmptyState.no_upcoming_schedules': `No upcoming schedules for this ${nouns.event}.`,
  'containers.events.admin.schedule.future_event_schedules.EmptyState.no_results': 'No results found.',
  'containers.events.admin.schedule.future_event_schedules.StaffSection.staff_count': 'staff',
  'containers.events.admin.schedule.future_event_schedules.EmptyState.no_upcoming_schedules_team': 'No upcoming schedules for this team.',
  'containers.events.admin.schedule.future_event_sessions.EmptyState.no_upcoming_sessions_team': 'No upcoming sessions for this team.',

  'containers.events.admin.schedule.future_event_schedules.ScheduleList.daily': 'Daily',
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.start_at': '{startsAt}',
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.multiple_start_at': 'Multiple times',
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.duration': ` • {duration}m`,
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.daily_repeat': `Every {interval} Days`,
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.every_day_repeat': `Every day`,
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.dates': '{start} - {end}',
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.edit_schedule': 'Edit Schedule',
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.cancel_schedule': 'Cancel Schedule',
  'containers.events.admin.schedule.future_event_schedules.ScheduleList.status_cancelled': `Status: Cancelled`,

  'containers.events.admin.schedule.future_event_schedules.FutureEventSessions.view_sessions': 'View Sessions',

  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.title': 'Cancel event session?',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.unschedule_warning': 'Cancelling will remove the selected session from the event schedule. {clientsCount, select, 0 {} other {The session will also be removed from all ({clientsCount}) participant’s upcoming events.}}',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.cancel_btn': 'No',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.confirm_btn': 'Yes',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.add_note': 'Add Note',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.options': 'Please select the appropriate option below:',

  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.refund_title': 'Return Session Credits',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.refund_subtitle': 'Compensation will be granted via credit to participant(s).',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.revoke_title': 'Revoke Session Credits',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.revoke_subtitle': 'Credit(s) will NOT be returned to participant(s).',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.waive_checkbox': 'Waive Outstanding Fee',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.note_title': 'Important Note: ',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.credits_waive_notice': 'Credits tied to a credit pass or membership cannot be waived.',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.note_subtitle': 'If compensation is granted in the form of credits or the waive outstanding fee checkbox is not marked, any “pay later” balances will remain on the Balances Report until they are fully waived or fully paid. Actions cannot be undone.',
  'containers.events.admin.schedule.cancelSessionModal.CancelSessionModal.notice_placeholder': 'Enter note here...',

  'containers.events.admin.schedule.shared.ScheduleTableHeader.date': 'Date',
  'containers.events.admin.schedule.shared.ScheduleTableHeader.days': 'Day(s)',
  'containers.events.admin.schedule.shared.ScheduleTableHeader.time': 'Time',
  'containers.events.admin.schedule.shared.ScheduleTableHeader.location': 'Location',
  'containers.events.admin.schedule.shared.ScheduleTableHeader.staff': 'Staff',
  'containers.events.admin.schedule.shared.ScheduleTableHeader.resource': 'Resource',
  'containers.events.admin.schedule.shared.ScheduleTableHeader.schedule': 'Schedule',

  'containers.events.admin.schedule.shared.SessionTableHeader.date': 'Date',
  'containers.events.admin.schedule.shared.SessionTableHeader.time': 'Time',
  'containers.events.admin.schedule.shared.SessionTableHeader.location': 'Location',
  'containers.events.admin.schedule.shared.SessionTableHeader.staff': 'Staff',
  'containers.events.admin.schedule.shared.SessionTableHeader.attendees': 'Attendees',
  'containers.events.admin.schedule.shared.SessionTableHeader.checked_in': 'Checked In',
  'containers.events.admin.schedule.shared.SessionTableHeader.resource': 'Resource',
  'containers.events.admin.schedule.shared.SessionTableHeader.schedule': 'Schedule',
  'containers.events.admin.schedule.shared.SessionTableHeader.roster': 'Roster',

  'containers.events.admin.sessionSummaryDrawer.EditMenu.edit': 'Edit',
  'containers.events.admin.sessionSummaryDrawer.EditMenu.cancel': 'Cancel',

  'containers.events.admin.sessionSummaryDrawer.ConfirmationButtons.save': 'Save',
  'containers.events.admin.sessionSummaryDrawer.ConfirmationButtons.cancel': 'Cancel',

  'containers.events.admin.sessionSummaryDrawer.details.store.attendee_added': 'Attendee successfully added',
  'containers.events.admin.sessionSummaryDrawer.details.store.attendee_error': 'Error while adding attendee',
  'containers.events.admin.sessionSummaryDrawer.details.Attendees.attendees': '{count, plural, one {{count} Attendee} other {{count} Attendees}}',
  'containers.events.admin.sessionSummaryDrawer.details.AddAttendee.add_attendee': 'Add Attendee',
  'containers.events.admin.sessionSummaryDrawer.details.AddAttendee.no_credits': 'No credits',
  'containers.events.admin.sessionSummaryDrawer.details.AddAttendee.placeholder': 'Attendee Name',
  'containers.events.admin.sessionSummaryDrawer.details.AddAttendee.hint': 'Type attendee name to search and add to roster.',
  'containers.events.admin.sessionSummaryDrawer.details.PurchaseModal.purchase_modal_title': 'No Credits Available',
  'containers.events.admin.sessionSummaryDrawer.details.PurchaseModal.purchase_modal_content': 'Client selected has 0 credits available for use. Please purchase credits in order to add client to class.',
  'containers.events.admin.sessionSummaryDrawer.details.PurchaseModal.purchase_modal_button': 'Buy Credits',
  'containers.events.admin.sessionSummaryDrawer.details.StaffList.staff': '{count} Staff',
  'containers.events.admin.sessionSummaryDrawer.details.StaffList.team_staff': 'Staff ({count})',
  'containers.events.admin.sessionSummaryDrawer.details.Resources.resources': '{count, plural, one {{count} Resource} other {{count} Resources}}',
  'containers.events.admin.sessionSummaryDrawer.details.Resources.team_resources': '{count, plural, one {Resource ({count})} other {Resources ({count})}}',
  'containers.events.admin.sessionSummaryDrawer.details.AttendeeListCard.remove': 'Remove',
  'containers.events.admin.sessionSummaryDrawer.details.AttendeeListCard.pay': 'Pay',
  'containers.events.admin.sessionSummaryDrawer.details.ResourceListCard.remove': 'Remove',
  'containers.events.admin.sessionSummaryDrawer.details.StaffListCard.remove': 'Remove',
  'containers.events.admin.sessionSummaryDrawer.details.LocationDisplay.tbd_location': 'TBD',
  'containers.events.admin.sessionSummaryDrawer.details.AddStaff.add_staff': 'Add Staff',
  'containers.events.admin.sessionSummaryDrawer.details.AddStaff.placeholder': 'Staff Name',
  'containers.events.admin.sessionSummaryDrawer.details.AddStaff.hint': 'Type staff name to search and add to roster.',

  'containers.events.admin.sessionSummaryDrawer.details.AddResource.add_resource': 'Add Resource',
  'containers.events.admin.sessionSummaryDrawer.details.AddResource.placeholder': 'Resource Name',
  'containers.events.admin.sessionSummaryDrawer.details.AddResource.hint': 'Type resource name to search and add to session.',

  'containers.events.admin.sessionSummaryDrawer.details.store.resource_added': 'Resource successfully added',
  'containers.events.admin.sessionSummaryDrawer.details.store.resource_removed': 'Resource successfully removed',
  'containers.events.admin.sessionSummaryDrawer.details.store.attendee_removed': 'Attendee successfully removed',
  'containers.events.admin.sessionSummaryDrawer.details.store.staff_added': 'Staff successfully added',
  'containers.events.admin.sessionSummaryDrawer.details.store.staff_removed': 'Staff successfully removed',

  'containers.events.admin.sessionSummaryDrawer.Footer.check_in': 'Check-In {total}',

  'containers.events.admin.sessionSummaryDrawer.SessionSummaryDrawer.title': 'Session',
  'containers.events.admin.sessionSummaryDrawer.SessionTabs.details': 'Details',
  'containers.events.admin.sessionSummaryDrawer.SessionTabs.checkIn': 'Check-in {total}',
  'containers.events.admin.sessionSummaryDrawer.SessionTabs.notes': 'Notes',
  'containers.events.admin.sessionSummaryDrawer.SessionTabs.waitList':
    'Waitlist {count}',
  'containers.events.admin.sessionSummaryDrawer.Header.cancelled': 'Cancelled',
  'containers.events.admin.sessionSummaryDrawer.Header.minutes': '{count}m ',
  'containers.events.admin.sessionSummaryDrawer.Header.class_size': 'Class Size',
  'containers.events.admin.sessionSummaryDrawer.Header.session_size': 'Session Size',

  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.title':
    'Edit Date + Time',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.labels.date':
    'Session Date',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.labels.start_time':
    'Start Time',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.labels.end_time':
    'End Time',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.labels.location':
    'Location',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.scheduling_deadline': 'Scheduling Deadline',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.cancellation_deadline': 'Cancellation Deadline',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.max_size': 'Max Size',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.before_session': 'Before session begins',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.scheduling_info': 'The scheduling deadline restricts scheduling based on a set amount of time before a session begins.',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.cancellation_info': 'Set a cancellation deadline to limit the amount of time a client can cancel before the start of a session.',

  'containers.events.admin.sessionSummaryDrawer.editDateTime.ConfirmChangesModal.title':
    'Confirm Session Changes',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.ConfirmChangesModal.text':
    'This session has {clientsCount} clients booked for it. An email will be sent to let them know about the change. Would you like to add a note explaining the changes?',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.ConfirmChangesModal.label.note':
    'ADD NOTE (optional)',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.ConfirmChangesModal.btn.confirm':
    'Confirm',
  'containers.events.admin.sessionSummaryDrawer.editDateTime.ConfirmChangesModal.btn.cancel':
    'Cancel',

  'containers.events.admin.sessionSummaryDrawer.notes.Notes.add_admin_note': 'Add Admin Note',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.add_client_note': 'Add Client Note',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.admin_session_note': 'Admin Session Note',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.client_session_note': 'Client Session Note',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.added_by': 'Added By: {name}',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.note_delete_confirmation': 'Delete {type} Session Note',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.delete': 'Yes',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.cancel': 'No',
  'containers.events.admin.sessionSummaryDrawer.notes.Notes.note_delete_text': 'Are you sure you want to delete this session note?',
  'containers.events.admin.sessionSummaryDrawer.notes.store.note_added': 'Session note successfully added',
  'containers.events.admin.sessionSummaryDrawer.notes.store.note_deleted': 'Session note successfully deleted',

  'containers.events.admin.payments.EmptyState.no_results': 'No results found.',
  'containers.events.admin.payments.EmptyState.no_transactions': `There are no payments for this ${nouns.event
    }.`,

  'containers.events.admin.payments.StatusSelector.cancel_payment':
    'Cancel payment',
  'containers.events.admin.payments.StatusSelector.refund': 'Refund',
  'containers.events.admin.payments.StatusSelector.submit_payment':
    'Submit\xa0Payment',
  'containers.events.admin.payments.StatusSelector.tx_cancelled_status':
    'Cancelled',
  'containers.events.admin.payments.StatusSelector.tx_failed_status': 'Failed',
  'containers.events.admin.payments.StatusSelector.tx_other_status': 'Other',
  'containers.events.admin.payments.StatusSelector.tx_partial_refunded_status':
    'Partial Refunded',
  'containers.events.admin.payments.StatusSelector.tx_past_due_status':
    'Past Due',
  'containers.events.admin.payments.StatusSelector.tx_refunded_status':
    'Refunded',
  'containers.events.admin.payments.StatusSelector.tx_succeeded_status': 'Paid',
  'containers.events.admin.payments.StatusSelector.tx_unpaid': 'Unpaid',

  'containers.events.admin.staff.EmptyState.no_results': 'No results found.',
  'containers.events.admin.staff.EmptyState.no_staff': `No staff assigned to this ${nouns.event
    }.`,

  'containers.events.admin.admin_page.Sidebar.cannot_view_revenue': 'NA',
  'containers.events.admin.admin_page.Sidebar.details': 'Details',
  'containers.events.admin.admin_page.Sidebar.private': 'Private',
  'containers.events.admin.admin_page.Sidebar.waitlist': 'Waitlist',
  'containers.events.admin.admin_page.Sidebar.roster_size': 'Roster Count',
  'containers.events.admin.admin_page.Sidebar.age_range': 'Age Range',
  'containers.events.admin.admin_page.Sidebar.seasons': 'Seasons',
  'containers.events.admin.admin_page.EventNav.event': 'Event',
  'containers.events.admin.admin_page.EventNav.send_email':
    'Send Marketing Email',
  'containers.events.admin.admin_page.EventNav.export_attendance':
    'Export Attendance',
  'containers.events.admin.admin_page.EventNav.edit_event': 'Edit Event',
  'containers.events.admin.admin_page.EventNav.team': 'Teams',
  'containers.events.admin.admin_page.EventNav.edit_team': 'Edit Team',


  'containers.sessionScheduling.SessionScheduling.sessions_title': 'Choose session(s) below:',
  'containers.sessionScheduling.SessionCard.n_staff': '{n} Staff',
  'containers.sessionScheduling.SessionCard.no_spots': 'Full',
  'containers.sessionScheduling.SessionCard.registration_closed': 'Closed',
  'containers.sessionScheduling.SessionCard.cancelled': 'Cancelled',
  'containers.sessionScheduling.SessionScheduling.btn_ok': 'BOOK',
  'containers.sessionScheduling.SessionScheduling.btn_ok_info':
    '{selectedCount} / {allCount} Credits',
  'containers.sessionScheduling.SessionScheduling.btn_cancel': 'Cancel',
  'containers.sessionScheduling.SessionScheduling.processing':
    '(Booking {countProcessed} / {countAll})',

  'containers.sessionScheduling.DayOfWeekFilter.choose_days': 'Choose Day of the Week',
  'containers.sessionScheduling.LocationFilter.choose_location': 'Choose Location',
  
  

  'containers.retail.retailVendors.RetailVendors.item_name': 'Retail Vendor',
  'containers.retail.retailVendors.RetailVendors.floating_label':
    'Retail Vendor',
  'containers.retail.retailVendors.Content.edit_button': 'Edit',
  'containers.retail.retailVendors.ModalEdit.cancel_button': 'Cancel',
  'containers.retail.retailVendors.ModalEdit.submit_button': 'Submit',
  'containers.retail.retailVendors.ModalEdit.title_create_mode':
    'New Retail Vendor',
  'containers.retail.retailVendors.ModalEdit.title_edit_mode':
    'Edit Retail Vendor',
  'containers.retail.retailVendors.ModalEdit.label': 'Retail Vendor Name',

  'containers.accountProfiles.AccountProfiles.title': 'Account Profiles',
  'containers.accountProfiles.AccountProfile.creditCtTooltip':
    'You currently have {creditsRemaining} credits available to use for booking.',
  'containers.accountProfiles.AccountProfile.unlimitedCtTooltip':
    'You currently have unlimited credits available to use for booking.',
  'containers.accountProfiles.AccountProfile.needAddtlCredits':
    'You need additional credits to book this session.',
  'containers.accountProfiles.AccountProfileButtons.scheduleSessions':
    'Schedule session(s)',
  'containers.accountProfiles.AccountProfileButtons.viewMemberships':
    `View ${nouns.Membership}s`,
  'containers.accountProfiles.AccountProfileButtons.viewPasses': 'View Passes',

  'containers.events.admin.sessionSummaryDrawer.checkIn.CheckIn.fraction': '{num}/{den}',
  'containers.events.admin.sessionSummaryDrawer.checkIn.CheckIn.search_hint_text': 'Search attendees',
  'containers.events.admin.sessionSummaryDrawer.checkIn.EmptyState.no_registrations': 'There are no registrations for this session.',
  'containers.events.admin.sessionSummaryDrawer.checkIn.AttendeeList.select_all': 'Select All',
  'containers.events.admin.sessionSummaryDrawer.checkIn.AttendeeList.new_user': 'New',
  'containers.events.admin.clients.NewMessageDrawer.create_message': 'Create Message',
  'containers.events.admin.clients.NewMessageDrawer.choose_date': 'Choose Date',
  'containers.events.admin.clients.NewMessageDrawer.choose_time': 'Choose Time',
  'containers.events.admin.clients.NewMessageDrawer.contact_group': 'Contact Group',
  'containers.events.admin.clients.NewMessageDrawer.message': 'Message',
  'containers.events.admin.clients.NewMessageDrawer.send_email': 'Send Email',
  'containers.events.admin.clients.NewMessageDrawer.send_later': 'Send Later',
  'containers.events.admin.clients.NewMessageDrawer.sending': 'Sending',
  'containers.events.admin.clients.NewMessageDrawer.subject': 'Subject',
  'containers.events.admin.clients.NewMessageDrawer.type_a_message': 'Type a message...',
  'containers.events.admin.clients.RosterTransferDrawer.transfer_client': 'Transfer Client',
  'containers.events.admin.clients.RosterTransferDrawer.save': 'Save',
  'containers.events.admin.clients.RosterTransferDrawer.transfer_message': 'Select which {eventName} you want to transfer the client to',
  'containers.events.admin.clients.RosterTransferDrawer.search_placeholder': 'Search {eventName} by name',

  'containers.accounts.createCustomer.DuplicateOrgNameModal.title': 'Duplicate account created through Chargify signup',
  'containers.accounts.createCustomer.DuplicateOrgNameModal.text': 'A business with the name {customerName} already exists. If you are concerned that your business has submitted payment information to Upper Hand more than once, please contact support. Otherwise, please register with Upper Hand using a different business name.',
  'containers.accounts.createCustomer.DuplicateOrgNameModal.button_1': 'Contact Support',
  'containers.accounts.createCustomer.DuplicateOrgNameModal.button_2': 'Continue',
  'containers.accounts.createCustomer.DuplicateOrgNameModal.mail_subject': 'Duplicate account created through Chargify signup.',
  'containers.accounts.createCustomer.CustomerAccountCreation.error_mail_subject': 'Error occurred creating customer account.',
  'containers.accounts.createCustomer.CustomerAccountCreation.create_account_btn': 'Create Account',
  'containers.accounts.createCustomer.validation.date_of_birth': 'Age should be at least 13 years',
  'containers.accounts.createCustomer.validation.ssn_last_4': 'Should be exactly 4 digits',
  'containers.accounts.createCustomer.validation.duplicate_org_name': 'A business with this name already exists',

  'customers.settings.accounting_tab.AccountingForm.accounting_code':
    'Accounting Code',
  'customers.settings.accounting_tab.AccountingForm.choose_accounting_code_type':
    'Choose Accounting Code Type',
  'customers.settings.accounting_tab.AccountingForm.choose_tax_rates':
    'Choose Tax Rate(s)',
  'customers.settings.accounting_tab.AccountingForm.code': 'Code',
  'customers.settings.accounting_tab.AccountingForm.code_type': 'Code Type',
  'customers.settings.accounting_tab.AccountingForm.create_accounting_code':
    'Create Accounting Code',
  'customers.settings.accounting_tab.AccountingForm.description':
    'Description',
  'customers.settings.accounting_tab.AccountingForm.edit_accounting_code':
    'Edit Accounting Code',
  'customers.settings.accounting_tab.AccountingForm.membership_code_type':
    `${nouns.Membership}s`,
  'customers.settings.accounting_tab.AccountingForm.new_tax_rate':
    '+ New Tax Rate',
  'customers.settings.accounting_tab.AccountingForm.new_fee_rate':
    '+ New Fee Rate',
  'customers.settings.accounting_tab.AccountingForm.percent_rate':
    '% Percentage',
  'customers.settings.accounting_tab.AccountingForm.retail_code_type':
    'Retail',
  'customers.settings.accounting_tab.AccountingForm.save_code': 'Save Code',
  'customers.settings.accounting_tab.AccountingForm.tax_rate': 'Tax Rate',
  'customers.settings.accounting_tab.AccountingForm.tax_rate_optional':
    'Tax Rate (Optional)',
  'customers.settings.accounting_tab.AccountingForm.fee_code':
    'Fee Code',
  'customers.settings.accounting_tab.AccountingForm.add_tax_rate':
    'Add Tax Rate',
  'customers.settings.accounting_tab.AccountingForm.add_fee_rate':
    'Add Fee Rate',
  'customers.settings.accounting_tab.AccountingForm.choose_service_fee_type':
    'Choose Service Fee Type',
  'customers.settings.accounting_tab.AccountingForm.choose_fee_rates':
    'Choose Fee Rate(s)',
  'customers.settings.accounting_tab.AccountingForm.fee_type': 'Fee Type',
  'customers.settings.accounting_tab.AccountingForm.create_service_fee':
    'Create Service Fee',
  'customers.settings.accounting_tab.AccountingForm.edit_service_fee':
    'Edit Service Fee',
  'customers.settings.accounting_tab.AccountingForm.fee_rate':
    'Fee Rate',
  'customers.settings.accounting_tab.EventTypeSelector.assign_event_types': `Assign ${nouns.Event
    } Types`,
  'customers.settings.accounting_tab.EventTypeSelector.choose_event_types': `Choose ${nouns.Event
    } Type(s)`,

  'customers.settings.accounting_tab.TeamTypeSelector.assign_team_types': 'Assign Tean Types',
  'customers.settings.accounting_tab.TeamTypeSelector.choose_team_types': 'Choose Team Type(s)',

  'customers.settings.accounting_tab.RetailCategorySelector.assign_retail_categories':
    'Assign Retail Categories',
  'customers.settings.accounting_tab.RetailCategorySelector.choose_retail_categories':
    'Choose Retail Categories',
  'customers.settings.accounting_tab.RateForm.name': 'Name',
  'customers.settings.accounting_tab.RateForm.percent_rate': '% Percentage',
  'customers.settings.accounting_tab.RateForm.rate': 'Rate',

  'customers.settings.accounting_tab.SelectedRates.name_and_rate':
    '{name} - {rate}',

  'customers.settings.AccountingTab.accounting_codes': 'Accounting Codes',
  'customers.settings.AccountingTab.accounting_explanation': `Create accounting codes that can be assigned to ${nouns.event
    } types, memberships, credit passes, and retail for various accounting purposes.`,
  'customers.settings.AccountingTab.archive_accounting_code':
    'Archive accounting code?',
  'customers.settings.AccountingTab.archive_service_fee':
    'Archive service fee?',
  'customers.settings.AccountingTab.archived': 'Archived ({count})',
  'customers.settings.AccountingTab.are_you_sure':
    'Are you sure you want to archive the accounting code {code}?',
  'customers.settings.AccountingTab.are_you_sure_fee':
    'Are you sure you want to archive the service fee {code}?',
  'customers.settings.AccountingTab.create_accounting_code':
    'Create Accounting Code',
  'customers.settings.AccountingTab.create_service_fee':
    'Create Service Fee',
  'customers.settings.AccountingTab.fee_rate':
  'Fee Rate',
  'customers.settings.AccountingTab.team_code_type': 'Team Type',

  'customers.settings.accounting_tab.AccountingForm.credit_pass_code_type': 'Credit Pass',
  'customers.settings.AccountingTab.credit_pass_code_type': 'Credit Pass',
  'customers.settings.accounting_tab.AccountingForm.event_code_type': `${nouns.Event} Type`,
  'customers.settings.accounting_tab.AccountingForm.team_code_type': 'Team Type',
  'customers.settings.AccountingTab.event_code_type': `${nouns.Event} Type`,
  'customers.settings.AccountingTab.membership_code_type': `${nouns.Membership}s`,
  'customers.settings.AccountingTab.retail_code_type': 'Retail',
  'customers.settings.AccountingTab.tax_rate': 'Tax Rate',
  'customers.settings.AccountingTab.service_fee': 'Service Fee',
  'customers.settings.AccountingTab.service_fee_explanation': 'Create a fee to be added to the cost on during checkout',

  'customers.settings._DirectDepositTab.paysafe_not_setup':
    'You must complete your account before you can view direct deposit.',
  'customers.settings._DirectDepositTab.no_bank_accounts':
    'No bank accounts are configured.',
  'customers.settings._DirectDepositTab.validate_bank_account':
    'Validate Bank Account',
  'customers.settings._DirectDepositTab.validation_instructions':
    'A small amount has been deposited into your bank account and will be available in the next few days. Enter the amount to validate your bank account.',
  'customers.settings._DirectDepositTab.deposit_label': 'Deposit Amount',
  'customers.settings._DirectDepositTab.saved_accounts': 'Saved Accounts',
  'customers.settings._DirectDepositTab.add_bank_account': 'Add Bank Account',
  'customers.settings._DirectDepositTab.remove_bank_account':
    'Remove this bank account',
  'customers.settings._DirectDepositTab.deposit_hint': '0.01',
  'customers.settings._DirectDepositTab.deposit_confirmation_label':
    'Confirm Amount',
  'customers.settings._DirectDepositTab.deposit_confirmation_hint': '0.01',
  'customers.settings._DirectDepositTab.deposit_confirmation_error':
    'The amounts do not match',
  'customers.settings._DirectDepositTab.submit': 'Submit',
  'customers.settings._DirectDepositTab.processing': 'Processing',
  'customers.settings._DirectDepositTab.default_text': 'Default',

  'customers.settings._DirectDepositTab.account_row': 'Account Number',
  'customers.settings._DirectDepositTab.routing_row': 'Routing Number',

  // Bank account form //
  'customers.settings._DirectDepositTab.routing_number': 'Routing Number',
  'customers.settings._DirectDepositTab.account_number': 'Account Number',
  'customers.settings._DirectDepositTab.account_holder_name':
    'Account Holder Name',
  'customers.settings._DirectDepositTab.account_holder_type':
    'Account Holder Type',
  'customers.settings._DirectDepositTab.currency': 'Currency',

  'customers.settings._DirectDepositTab.type_company': 'Business',
  'customers.settings._DirectDepositTab.type_individual': 'Personal',

  'customers.settings._DirectDepositTab.currency_usd': 'USD',

  'customers.settings._DirectDepositTab.default_for_currency':
    'Make Default for this Currency',
  // -- //

  'customers.settings.PolicyTermsTab.custom_waiver': 'Custom Waiver',
  'customers.settings.PolicyTermsTab.membership_agreement': 'Membership Agreement',
  'customers.settings.PolicyTermsTab.default_waiver':
    'Waiver, Release & Refund Policy (Default)',

  'customers.settings.PreferencesTab.card_checkbox_label':
    'Card',
  'customers.settings.PreferencesTab.cash_checkbox_label': 'Cash',
  'customers.settings.PreferencesTab.bank_checkbox_label': 'Bank',
  'customers.settings.PreferencesTab.check_checkbox_label': 'Check',
  'customers.settings.PreferencesTab.disable_client_scheduling_label':
    'Disable Client Scheduling',
  'customers.settings.PreferencesTab.disable_client_scheduling_help':
    `Disable your client’s ability to schedule and cancel sessions for open booking ${nouns.event}s.`,
  'customers.settings.PreferencesTab.all_emails': 'All',
  'customers.settings.PreferencesTab.email_settings': 'Client Email Settings',
  'customers.settings.PreferencesTab.email_settings_help': 'Select the emails you would like clients to receive from your business.',

  // Email types
  'customers.settings.PreferencesTab.billing_reminder': 'Billing reminders',
  'customers.settings.PreferencesTab.billing_receipt': 'Billing receipts',
  'customers.settings.PreferencesTab.business_invite': 'Business invites',
  'customers.settings.PreferencesTab.client_failed_payment': 'Client failed payment notifications',
  'customers.settings.PreferencesTab.items_in_cart_reminder': 'Items in cart reminders',
  'customers.settings.PreferencesTab.message_participants': 'Message participants',
  'customers.settings.PreferencesTab.registration_cancellation_notice': 'Registration cancellation notice',
  'customers.settings.PreferencesTab.reschedule_session': 'Rescheduled sessions notifications',
  'customers.settings.PreferencesTab.scheduled_payment_reminder': 'Scheduled payment reminders',
  'customers.settings.PreferencesTab.send_cash_check_payment_received': 'Cash/Check receipt emails',
  'customers.settings.PreferencesTab.send_credit_pass_receipt': 'Credit pass receipts',
  'customers.settings.PreferencesTab.send_event_invite': 'Event invites',
  'customers.settings.PreferencesTab.send_registration_booking_confirmation': 'Registration booking confirmations',
  'customers.settings.PreferencesTab.send_purchase_receipt': 'Send purchase receipt',
  'customers.settings.PreferencesTab.send_updated_schedule': 'Updated schedule notifications',
  'customers.settings.PreferencesTab.send_subscription_receipt': 'Subscription receipts',
  'customers.settings.PreferencesTab.waitlist_invite': 'Waitlist invites',

  'customers.settings.PreferencesTab.payment_method_header':
    'Client Payment Method',
  'customers.settings.PreferencesTab.payment_method_validation':
    'At least one method is required.',
  'customers.settings.PreferencesTab.payment_method_help': `Restrict the ways your client is able to purchase an ${nouns.event
    }.`,
  'customers.settings.PreferencesTab.scheduling_header': 'Scheduling',
  'customers.settings.PreferencesTab.event': 'Event',
  'customers.settings.PreferencesTab.memberships': 'Memberships',
  'customers.settings.PreferencesTab.creditPasses': 'Credit Passes',
  'customers.settings.PreferencesTab.payLater_checkbox_label': 'Pay Later',
  'customers.settings.PreferencesTab.ach_checkbox_label': 'ACH',

  'customers.settings.PayoutsTab.withdrawal_history': 'Withdrawal History',
  'customers.settings.PayoutsTab.available': 'Available ({currency})',
  'customers.settings.PayoutsTab.pending': 'Pending ({currency})',

  'customers.settings.Settings.accounting': 'Accounting',
  'customers.settings.Settings.header': 'Business Settings',
  'customers.settings.Settings.balance': 'Balance',
  'customers.settings.Settings.business_info': 'Business Info',
  'customers.settings.Settings.direct_deposit': 'Direct Deposit',
  'customers.settings.Settings.billing': 'Billing',
  'customers.settings.Settings.locations': 'Locations',
  'customers.settings.Settings.coupons': 'Coupons',
  'customers.settings.Settings.time_tracking': 'Time Tracking',
  'customers.settings.Settings.config': 'Software Settings',
  'customers.settings.Settings.policy_and_terms': 'Policy & Terms',
  'shared._AgreementTermsBanner.membership_agreement': 'Membership Agreement',
  'shared._AgreementTermsBanner.please_read':
    'Please read the terms and conditions in full before agreeing.',
  'customers.settings.Settings.preferences': 'Preferences',

  'customers.settings.TasksTab.are_you_sure':
    'Are you sure you want to remove the {name} task?',
  'customers.settings.TasksTab.create_task': 'Create Task',
  'customers.settings.TasksTab.edit_task': 'Edit Task',
  'customers.settings.TasksTab.name': 'Name',
  'customers.settings.TasksTab.remove_task': 'Remove Task?',
  'customers.settings.TasksTab.tasks': 'Tasks',
  'customers.settings.TasksTab.tasks_explanation':
    'Create tasks for your staff to choose when clocking-in. The task name will also be used to identify hours worked by your staff.',

  'customers.settings._PayoutsHistoryDrawer.header': 'Withdrawal History',
  'customers.settings._PayoutsHistoryDrawer.withdrawal': 'Withdrawal',
  'customers.settings._PayoutsHistoryDrawer.load_more': 'Load more',
  'customers.settings._PayoutsHistoryDrawer.end_of_history': 'No more results',
  'customers.settings._PayoutsHistoryDrawer.no_payouts': 'No withdrawals yet',
  'customers.settings._PayoutsHistoryDrawer.request': 'Request {payoutDate}',
  'customers.settings._PayoutsHistoryDrawer.deposit': 'Deposit {payoutDate}',

  'customers.settings.MachineSettingsDrawer.MachineSettingsDrawer.title': 'Machine Settings',
  'customers.settings.MachineSettingsDrawer.MachineSettingsDrawer.description': "Disabling Cage Machines will prevent next play session from starting and no swings will be deducted from the client's balance.",
  'customers.settings.MachineSettingsDrawer.MachineSettingsDrawer.active': 'Active',
  'customers.settings.MachineSettingsDrawer.MachineSettingsDrawer.disabled': 'Disabled',
  'customers.settings.MachineSettingsDrawer.MachineSettingsDrawer.current_status': 'Current status: {status}',

  'customers.AddOns.AddOns.heading': 'Subscription Add-Ons',
  'customers.AddOns.AddOns.explanation': "Select which product you'd like added on to your subscription",
  'customers.AddOns.AddOns.uh_explain': "If you're looking to improve business operations, increase revenue and growth, or make smarter decisions backed by data, upperhand.ai can help.",
  'customers.AddOns.AddOns.ap_explain': 'Athlete Progression Tracking allows you to create customized training programs for your athletes and monitor their progress over time.',
  'customers.AddOns.AddOns.uh_title': 'Upper Hand AI',
  'customers.AddOns.AddOns.ap_title': 'Athlete Progressions',
  'customers.AddOns.AddOns.wk_title': 'Custom Websites',
  'customers.AddOns.AddOns.wk_explain': 'Create high-performing, custom sports websites at an unprecedented speed for your sports facility or training business with our sports website design services.',
  'customers.AddOns.AddOns.learn_more': 'Learn More',
  'customers.AddOns.AddOnsTabs.header': 'Add-Ons',
  'customers.AddOns.AddOnsTabs.add_ons': 'Add-Ons',
  'customers.TermsDialog.view_terms': 'View Terms',
  'customers.TermsDialog.view_policy_and_terms': 'View Policy & Terms',
  'customers.TermsDialog.and': 'and',
  'customers.TermsDialog.view_privacy_and_cookies':
    'View Privacy & Cookies Policy',
  'customers.ClientTermDialog.view_privacy_and_cookies': 'View Privacy & Cookies Policy',
  'customers.ClientTermDialog.view_terms': 'View Terms',
  'customers.ClientTermDialog.and': 'and',
  'customers.ClientTermDialog.accept': 'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName} and Upper Hand Inc.',
  'customers.ClientTermDialog.view_policy_and_terms': 'View Policy & Terms',
  'dates.shortNumeric': 'MM-DD-YY',
  'dates.shortNumericFullYear': 'MM/DD/YYYY',
  'dates.medium': "MMM DD 'YY",
  'dates.full': 'MMMM DD, YYYY',

  currency: 'USD',
  'currency.starting_at': '{amount}+',

  'dashboards.CustomerDashboard.announcement': 'Announcement',
  'dashboards.CustomerDashboard.announcement_hint': 'What’s happening?',
  'dashboards.CustomerDashboard.announcement_info':
    "Have something to say? This message will appear on your clients' dashboard when they log in.",
  'dashboards.CustomerDashboard.events': `${nouns.Event}s`,
  'dashboards.CustomerDashboard.participants': 'Participants',
  'dashboards.CustomerDashboard.last_week': '{previousValue} last week',
  'dashboards.CustomerDashboard.new_clients': 'New Clients',
  'dashboards.CustomerDashboard.revenue': 'Revenue',
  'dashboards.CustomerDashboard.view_events': `View ${nouns.Event}s`,
  'dashboards.CustomerDashboard.view_reports': 'View Contacts Report',
  'dashboards.CustomerDashboard.welcome_back': 'Welcome back, {name}!',

  'dashboards.NextEventCard.duration_away': '{timeDelta} away',
  'dashboards.NextEventCard.next_event': `Next ${nouns.Event}`,

  'containers.events.admin.admin_page.Sidebar.revenue': 'Revenue',
  'containers.events.admin.admin_page.Sidebar.price': 'Price',
  'containers.events.admin.admin_page.AdminPage.roster': 'Roster',
  'containers.events.admin.admin_page.AdminPage.schedule': 'Schedule',
  'containers.events.admin.admin_page.AdminPage.staff': 'Staff',
  'containers.events.admin.admin_page.AdminPage.waitlist': 'Waitlist',
  'containers.events.admin.admin_page.AdminPage.completed': 'Completed',
  'containers.events.admin.admin_page.AdminPage.payments': 'Payments',
  'containers.events.admin.admin_page.AdminPage.paymentPlans': 'Payment Plans',
  'containers.events.admin.admin_page.AdminPage.registration': 'Registration',

  'containers.events.admin.admin_page.TeamDetailPage.overview': 'Overview',
  'containers.events.admin.admin_page.TeamDetailPage.roster': 'Roster',
  'containers.events.admin.admin_page.TeamDetailPage.schedule': 'Schedule',
  'containers.events.admin.admin_page.TeamDetailPage.completed': 'Completed',
  'containers.events.admin.admin_page.TeamDetailPage.payments': 'Payments',
  'containers.events.admin.admin_page.TeamDetailPage.paymentPlans': 'Payment Plans',
  'containers.events.admin.admin_page.TeamDetailPage.registration': 'Registration',

  'containers.events.admin.waitlist.ClientCard.email_na': 'N/A',
  'containers.events.admin.waitlist.ClientCard.invited': 'Invited',
  'containers.events.admin.waitlist.ClientCard.invite': 'Invite',
  'containers.events.admin.waitlist.TableHeader.name': 'Name',
  'containers.events.admin.waitlist.TableHeader.email': 'Email',
  'containers.events.admin.waitlist.EmptyState.no_clients':
    'No clients have been added to the waitlist yet.',
  'containers.events.admin.waitlist.EmptyState.no_waitlist':
    'Waitlist is not enabled for this event.',

  'event_mgmt.display._ClientEventHeader.spaces_left':
    '{spots} {spots, plural, one { space left. } other { spaces left. }} Register soon!',

  'event_mgmt.editing._CancellationDeadline.deadline_label':
    'Cancellation Deadline',
  'event_mgmt.editing._CancellationDeadline.deadline_none': 'None',
  'event_mgmt.editing._CancellationDeadline.deadline_before':
    'Before session begins:',
  'event_mgmt.editing._CancellationDeadline.explanation':
    'Set a cancellation deadline to limit the amount of time a client can cancel before the start of a session.',
  'event_mgmt.editing._CancellationDeadline.hour': 'Hour',
  'event_mgmt.editing._CancellationDeadline.min': 'Min',

  'event_mgmt.display._EventAthletes.event_athletes': `${nouns.Event} Athletes`,

  'event_mgmt.display._EventDescription.details': 'Details',

  'event_mgmt.display._EventLarge.spaces_left':
    '{spots} {spots, plural, one { space left. } other { spaces left. }} Register soon!',

  'event_mgmt.display._EventSeasons.seasons': 'Seasons',

  'event_mgmt.display._EventMarketingForm.cancel': 'Cancel',
  'event_mgmt.display._EventMarketingForm.contact_groups': 'Contact Groups',
  'event_mgmt.display._EventMarketingForm.contacts': 'Contacts',
  'event_mgmt.display._EventMarketingForm.date_to_send': 'Date to send email',
  'event_mgmt.display._EventMarketingForm.not_in_past': `Can't be in the past`,
  'event_mgmt.display._EventMarketingForm.select_date':
    'Select date to send email',
  'event_mgmt.display._EventMarketingForm.select_time':
    'Select time to send email',
  'event_mgmt.display._EventMarketingForm.submit': 'Submit',
  'event_mgmt.display._EventMarketingForm.time_to_send': 'Time to send email',

  'event_mgmt.display._EventRestrictions.age': 'Age',
  'event_mgmt.display._EventRestrictions.age_n_and_over': '{n} and over',
  'event_mgmt.display._EventRestrictions.age_n_and_under': '{n} and under',
  'event_mgmt.display._EventRestrictions.age_n_to_m': '{n} - {m}',
  'event_mgmt.display._EventRestrictions.all_ages': 'All Ages',
  'event_mgmt.display._EventRestrictions.gender': 'Gender',
  'event_mgmt.display._EventRestrictions.restriction':
    '{restrictionName} {restrictionValue}',
  'event_mgmt.display._EventRestrictions.restrictions': 'Restrictions',
  'event_mgmt.display._EventRestrictions.dob_restriction': '{dob}',

  'event_mgmt.display._EventSchedule.all_days': 'All Days',
  'event_mgmt.display._EventSchedule.schedule_header': 'My Schedule',
  'event_mgmt.display._EventSchedule.staff': 'Staff: {staff}',
  'event_mgmt.display._EventSchedule.cancel_registration': 'Cancel registration',
  'event_mgmt.display._EventSchedule.cancel_registration_confirmation': 'Cancelling will remove you from this scheduled session and a credit for the event will be deposited to your account for future use.',
  'event_mgmt.display._EventSchedule.cancel_registration_confirmation_admin': 'Cancelling will remove the client from this scheduled session and a credit for the event will be deposited to their account for future use.',
  'event_mgmt.display._EventSchedule.note_title': 'Important Note: ',
  'event_mgmt.display._EventSchedule.note_subtitle': 'For sessions purchased using "pay later" canceling this session does not remove the balance owed. If you want to remove this session completely or wish to receive a refund, please contact the facility. Actions cannot be undone.',
  'event_mgmt.display._EventSchedule.confirm_btn': 'Yes',
  'event_mgmt.display._EventSchedule.cancel_btn': 'No',

  'event_mgmt.display._EventSmall.details': 'Details',
  'event_mgmt.display._EventSmall.registrations': 'Registrations',

  'event_mgmt.display._EventWaitingList.waiting_list': 'Waiting List',

  'event_mgmt.display._Location.address': 'Address',

  'event_mgmt.display._MembershipDiscountList.membership_discounts':
    `${nouns.Membership} Discounts`,

  'event_mgmt.display._MembershipDiscountList.value_off': '{value} off',

  'event_mgmt.display._PurchaseButton.buy_more': 'Buy More',
  'event_mgmt.display._PurchaseButton.event_unavailable': `${nouns.Event
    } Unavailable`,
  'event_mgmt.display._PurchaseButton.join_waiting_list': 'Join Waiting List!',
  'event_mgmt.display._PurchaseButton.purchase': 'Purchase',
  'event_mgmt.display._PurchaseButton.waiting_list_full': 'Full',
  'event_mgmt.display._PurchaseButton.reserve': 'Reserve',
  'event_mgmt.display._PurchaseButton.closed': 'Registration Closed',

  'event_mgmt.display._RegistrationScheduler.book': 'Book',
  'event_mgmt.display._RegistrationScheduler.check_availabilty':
    'Checking Availability',
  'event_mgmt.display._RegistrationScheduler.choose_date_time':
    'Choose Date & Time',
  'event_mgmt.display._RegistrationScheduler.no_availabilty': 'No Availability',
  'event_mgmt.display._RegistrationScheduler.of_text':
    '{scheduleCount} of {registrationCount}',
  'event_mgmt.display._RegistrationScheduler.select_date': 'Select Date',
  'event_mgmt.display._RegistrationScheduler.select_time': 'Select Time',

  'event_mgmt.editing._SchedulingDeadline.deadline_label':
    'Scheduling Deadline',
  'event_mgmt.editing._SchedulingDeadline.deadline_none': 'None',
  'event_mgmt.editing._SchedulingDeadline.deadline_before':
    'Before session begins:',
  'event_mgmt.editing._SchedulingDeadline.explanation':
    'The scheduling deadline restricts scheduling based on a set amount of time before a session begins.',
  'event_mgmt.editing._SchedulingDeadline.hour': 'Hour',
  'event_mgmt.editing._SchedulingDeadline.min': 'Min',
  'event_mgmt.editing._SchedulingDeadline.day': 'Day',

  'event_mgmt.editing._SchedulingTimeFrame.label': 'Scheduling Time frame',
  'event_mgmt.editing._SchedulingTimeFrame.from_label': 'From Today',
  'event_mgmt.editing._SchedulingTimeFrame.day': '{n, plural, one {Day} other {Days}}',
  'event_mgmt.editing._SchedulingTimeFrame.week': '{n, plural, one {Week} other {Weeks}}',
  'event_mgmt.editing._SchedulingTimeFrame.month': '{n, plural, one {Month} other {Months}}',
  'event_mgmt.editing._SchedulingTimeFrame.explanation': 'The scheduling time frame sets restrictions on how far in advance clients can book.',

  'event_mgmt.editing._SchedulingInterval.every': 'Every',
  'event_mgmt.editing._SchedulingInterval.scheduling_interval':
    'Scheduling Interval',
  'event_mgmt.editing._SchedulingInterval.explanation': `Specify the block of time within or on the hour that your clients can book this ${nouns.event
    }.`,
  'event_mgmt.editing._SchedulingInterval.15_min': '15 min',
  'event_mgmt.editing._SchedulingInterval.30_min': '30 min',
  'event_mgmt.editing._SchedulingInterval.1_hour': '1 hour',

  'event_mgmt.display._StaffList.staff': 'Staff',
  'event_mgmt.display._StaffList.show_less': 'Show Less',
  'event_mgmt.display._StaffList.n_more': '+{n} more',

  'event_mgmt.display._WaitlistEntry.are_you_sure':
    'Are you sure you would like to remove {name} from the waiting list?',
  'event_mgmt.display._WaitlistEntry.no': 'No',
  'event_mgmt.display._WaitlistEntry.dialog_title': 'Remove from Waiting List',
  'event_mgmt.display._WaitlistEntry.yes': 'Yes',

  'event_mgmt.display.attendance_drawer._AttendeeList.no_registrations':
    'There are no registrations for this session.',
  'event_mgmt.display.attendance_drawer._AttendeeList.select_all': 'Select All',
  'event_mgmt.display.attendance_drawer._AttendeeListItem.new_user': 'New',

  'event_mgmt.display.attendance_drawer._Header.check_in': 'Check In',

  'event_mgmt.display.purchase_drawer.PurchaseDrawer.purchase': 'Purchase',

  'event_mgmt.display.purchase_drawer._FixedScheduleContent.profile': 'Profile',

  'event_mgmt.display.purchase_drawer._OpenBookingContent.n_minutes': '{n}m',
  'event_mgmt.display.purchase_drawer._OpenBookingContent.package': 'Package',

  'event_mgmt.display.purchase_drawer._OpenBookingContent.scheduled':
    'Scheduled',
  'event_mgmt.display.purchase_drawer._OpenBookingContent.session_duration':
    'Session Duration',

  'event_mgmt.display.purchase_drawer.single_session_purchase._SessionCheckBoxList.all_day_checkbox':
    'All Days',
  'event_mgmt.display.purchase_drawer.single_session_purchase._SessionCheckBoxList.all_days':
    'All Days',
  'event_mgmt.display.purchase_drawer.single_session_purchase._SessionCheckBoxList.purchased':
    '{allDays} Purchased',
  'event_mgmt.display.purchase_drawer.single_session_purchase._SessionCheckBoxList.full':
    'Full',
  'event_mgmt.display.purchase_drawer.single_session_purchase._SessionCheckBoxList.remove_attendee':
    'Remove Attendee',

  'event_mgmt.display.purchase_drawer._SimpleFixedScheduleContent.event_is_full': `${nouns.Event
    } is full`,
  'event_mgmt.display.purchase_drawer._SimpleFixedScheduleContent.total':
    'Total',

  'event_mgmt.display.waitlist_drawer.WaitlistDrawer.waiting_list':
    'Waiting List',
  'event_mgmt.display.waitlist_drawer.WaitlistCommitButton.add_to_waitlist':
    'Join the Waiting List!',
  'event_mgmt.display.waitlist_drawer._WDFixedScheduleContent.waiting_list_full': `This ${nouns.event
    } is currently full. Please choose the attendees you would like to be added to the waiting list. You’ll be notified when a spot opens up.`,

  'event_mgmt.display.schedule_drawer.ScheduleDrawer.title_add': 'Add Schedule',
  'event_mgmt.display.schedule_drawer.ScheduleDrawer.title_edit': 'Edit Schedule',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.date_times': 'Schedule Date and Times',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.dates': '{start} - {end}',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.repeat_TBD': 'TBD',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.times': `{startsAt} - {endsAt} `,
  'event_mgmt.display.schedule_drawer.ScheduleInfo.multiple_start_at': 'Multiple times',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.ScheduleList.daily': 'Daily',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.daily_repeat': `Repeating every {interval} days`,
  'event_mgmt.display.schedule_drawer.ScheduleInfo.weekly_repeat': `Repeating every {interval} weeks`,
  'event_mgmt.display.schedule_drawer.ScheduleInfo.every_day_repeat': `Repeating every day`,
  'event_mgmt.display.schedule_drawer.ScheduleInfo.every_week_repeat': `Repeating every week`,
  'event_mgmt.display.schedule_drawer.ScheduleInfo.note': 'Note: Schedule date and times cannot be edited because clients are currently booked on future sessions.',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.daily': 'Every Day',
  'event_mgmt.display.schedule_drawer.ScheduleInfo.multiple_times': 'Multiple times',

  'event_mgmt.display.schedule_drawer.ClassSize.labels.class_size':
    'Class Size',
  'event_mgmt.display.schedule_drawer.ClassSize.floating_labels.class_size':
    'Max',

  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.create_schedule':
    'Create Schedule',
  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.duration_units':
    'Every:',
  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.end_time':
    'End Time',
  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.repeat': 'Repeat',
  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.repeats': 'Repeats',
  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.start_date':
    'Start Date',
  'event_mgmt.display.schedule_drawer.CreateSchedule.labels.start_time':
    'Start Time',
  'event_mgmt.display.schedule_drawer.CreateSchedule.repeat_duration_units.days':
    'day(s)',
  'event_mgmt.display.schedule_drawer.CreateSchedule.repeat_duration_units.weeks':
    'week(s)',

  'event_mgmt.display.schedule_drawer.Ends.floating_labels.ends': 'End',
  'event_mgmt.display.schedule_drawer.Ends.labels.ends': 'Ends',
  'event_mgmt.display.schedule_drawer.Ends.labels.stops_by_date': 'End Date',
  'event_mgmt.display.schedule_drawer.Ends.repeat_mode.never': 'Never',
  'event_mgmt.display.schedule_drawer.Ends.repeat_mode.on_date': 'On a Date',
  'event_mgmt.display.schedule_drawer.Ends.repeat_mode.of_times': '# of Times',
  'event_mgmt.display.schedule_drawer.Ends.after': 'After',
  'event_mgmt.display.schedule_drawer.Ends.after_label': 'time{ending}',

  'event_mgmt.display.schedule_drawer.DailyTime.label': 'Set Daily Time',

  'event_mgmt.display.schedule_drawer.Location.floating_labels.add_location':
    'Add Location',
  'event_mgmt.display.schedule_drawer.Location.labels.add_location':
    'Select Location',

  'event_mgmt.display.schedule_drawer.RepeatOn.labels.repeat_on': 'Repeat on',

  'event_mgmt.display.schedule_drawer.Resources.floating_labels.add_resource':
    'Add Resource',
  'event_mgmt.display.schedule_drawer.Resources.labels.add_resource':
    'Choose Resource',

  'event_mgmt.display.schedule_drawer.Staff.floating_labels.add_staff':
    'Add Staff',
  'event_mgmt.display.schedule_drawer.Staff.labels.add_staff': 'Choose Staff',

  'event_mgmt.display.schedule_drawer.Content.validation.is_required':
    'This field is required',
  'event_mgmt.display.schedule_drawer.Content.validation.early_start_time':
    'Start date and time cannot be before current time',
  'event_mgmt.display.schedule_drawer.Content.validation.end_date':
    'End date cannot be before start date',
  'event_mgmt.display.schedule_drawer.Content.validation.end_time':
    'End time must be after start time',

  'event_mgmt.display.schedule_drawer.Footer.btn.add': 'Add Schedule',
  'event_mgmt.display.schedule_drawer.Footer.btn.edit': 'Edit Schedule',
  'event_mgmt.display.schedule_drawer.Footer.btn.cancel': 'Cancel',

  'event_mgmt.display.schedule_drawer.ConfirmUpdateScheduleModal.title': 'Confirm Schedule Changes',
  'event_mgmt.display.schedule_drawer.ConfirmUpdateScheduleModal.description': 'Are you sure you want to update schedule?',
  'event_mgmt.display.schedule_drawer.ConfirmUpdateScheduleModal.reschedule_clients': 'The proposed schedule changes will affect {futureRegistrationCount} {futureRegistrationCount, plural, one {booking} other {bookings}}.  Confirming the schedule change will reschedule these client(s) for these booking(s). An email will be sent to let them know about the change.  Would you like to add a note?',
  'event_mgmt.display.schedule_drawer.ConfirmUpdateScheduleModal.note_label': 'Add note (Optional)',
  'event_mgmt.display.schedule_drawer.ConfirmUpdateScheduleModal.cancel_btn': 'Cancel',
  'event_mgmt.display.schedule_drawer.ConfirmUpdateScheduleModal.confirm_btn': 'Confirm',

  'event_mgmt.editing._EventResourceDrawer.add_resource': 'Add Resource',
  'event_mgmt.editing._EventResourceDrawer.create': 'Create',
  'event_mgmt.editing._EventResourceDrawer.unavailable': 'Unavailable',

  'event_mgmt.editing._EventStaffDrawer.add_staff': 'Add Staff',
  'event_mgmt.editing._EventStaffDrawer.available': 'Available',
  'event_mgmt.editing._EventStaffDrawer.no_results': 'No results.',
  'event_mgmt.editing._EventStaffDrawer.not_scheduled':
    'Adding staff requires a set schedule. You can set a schedule in the schedule tab.',
  'event_mgmt.editing._EventStaffDrawer.unavailable': 'Unavailable',

  'event_mgmt.editing._StaffCard.available': 'Available',
  'event_mgmt.editing._StaffCard.booked_unavailable': 'Alert: Double Booked',
  'event_mgmt.editing._StaffCard.conflict_unavailable': 'Conflict: Unavailable',

  'event_mgmt.editing._StaffPreferenceMenu.none': 'None',
  'event_mgmt.editing._StaffPreferenceMenu.preferred_by': 'Preferred by:',

  'event_mgmt.editing._StaffColumn.single': 'Single',
  'event_mgmt.editing._StaffColumn.all': 'All',
  'event_mgmt.editing._ResourceColumn.all': 'All',
  'event_mgmt.editing._ResourceColumn.loading_resources':
    'Loading resources...',
  'event_mgmt.editing._ResourceColumn.no_resources_assigned':
    'No resources assigned.',
  'event_mgmt.editing._ResourceColumn.resources': 'Resources',
  'event_mgmt.editing._ResourceColumn.single': 'Single',
  'event_mgmt.editing._ResourceColumn.unavailable': 'Unavailable',
  'event_mgmt.editing._ResourceColumn.usage': 'Usage',
  'event_mgmt.editing._ResourceColumn.usage_information_all':
    'This will reserve all of the resources listed below for use during a booked session.',
  'event_mgmt.editing._ResourceColumn.usage_information_one':
    'This will reserve the first available resource (in descending priority) for use during a booked session.',

  'event_mgmt.editing._StaffColumn.usage_information_all': 'This will reserver all staff members during booking',
  'event_mgmt.editing._StaffColumn.usage_information_one': 'This will reserve the first available staff member during booking.',

  'event_mgmt.editing._StaffColumn.allow_staff_selection':
    'Allow client to choose staff.',
  'event_mgmt.editing._StaffColumn.loading_staff': 'Loading staff...',
  'event_mgmt.editing._StaffColumn.no_staff_assigned': 'No staff assigned.',
  'event_mgmt.editing._StaffColumn.staff': 'Staff',

  'event_mgmt.editing._TabEventAndLocation.event_schedule_type_header': `What type of ${nouns.event
    } schedule would you like ?`,
  'event_mgmt.editing._TabEventAndLocation.event_title': `${nouns.Event} Title`,
  'event_mgmt.editing._TabEventAndLocation.event_type': `${nouns.Event} Type`,
  'event_mgmt.editing._TabEventAndLocation.fixed_schedule': 'Fixed Schedule',
  'event_mgmt.editing._TabEventAndLocation.fixed_schedule_description': `A specific start and end date ${nouns.event
    }.`,
  'event_mgmt.editing._TabEventAndLocation.fixed_schedule_example':
    'eg. Camps, Clinics and pre-scheduled training programs.',
  'event_mgmt.editing._TabEventAndLocation.open_booking': 'Open Booking',
  'event_mgmt.editing._TabEventAndLocation.open_booking_description': `A session based ${nouns.event
    } with open scheduling.`,
  'event_mgmt.editing._TabEventAndLocation.open_booking_example':
    'eg. Ongoing Private Lessons and Small Group Training.',
  'event_mgmt.editing._TabEventAndLocation.class_schedule': 'Class',
  'event_mgmt.editing._TabEventAndLocation.class_schedule_description': `An ${nouns.event
    } with flexible or recurring schedules.`,
  'event_mgmt.editing._TabEventAndLocation.class_schedule_example':
    'eg. Fitness classes, workouts, etc.',
  'event_mgmt.editing._TabEventAndLocation.private_event': `Private ${nouns.Event
    }`,
    'event_mgmt.editing._TabEventAndLocation.sport_type': 'Sport Type',
    'event_mgmt.editing._TabEventAndLocation.sport_type_placeholder': 'Select Sport Type',
    'event_mgmt.editing._TabEventAndLocation.sport_type_tooltip': 'This information will be used for insurance and data purposes. If you do not see your sport listed please select Other',
  'event_mgmt.editing._TabEventAndLocation.seasons': 'Seasons',

  'event_mgmt.editing._TabMarketing.event_description': `${nouns.Event
    } Description`,
  'event_mgmt.editing._TabMarketing.event_image': `${nouns.Event} Image`,
    'event_mgmt.editing._TabMarketing.event_title': `Save ${nouns.event
    } with a title to create url.`,
  'event_mgmt.editing._TabMarketing.event_url': `${nouns.Event} URL`,
  'event_mgmt.editing._TabMarketing.remove_img': 'Remove Image',
  'event_mgmt.editing._TabMarketing.external_event_url': 'External URL',

  'event_mgmt.editing._TabParticipants.age': 'AGE',
  'event_mgmt.editing._TabParticipants.participants_size': 'Participants Size',
  'event_mgmt.editing._TabParticipants.coed': 'Coed',
  'event_mgmt.editing._TabParticipants.event_size': `${nouns.event.toUpperCase()} SIZE`,
  'event_mgmt.editing._TabParticipants.event_staff': `${nouns.Event} Staff`,
  'event_mgmt.editing._TabParticipants.female': 'Female',
  'event_mgmt.editing._TabParticipants.gender': 'GENDER',
  'event_mgmt.editing._TabParticipants.male': 'Male',
  'event_mgmt.editing._TabParticipants.set_date': 'Set Date',
  'event_mgmt.editing._TabParticipants.title.waitlist': 'WAITLIST',
  'event_mgmt.editing._TabParticipants.allow_waitlist': 'Allow Waiting List',
  'event_mgmt.editing._TabParticipants.allow_waitlist_help_text': `Allow clients to join a waiting list when the ${nouns.event
    } is full`,

  'shared.RestrictionsSectionDobRows.dob': 'DATE OF BIRTH',
  'shared.RestrictionsSectionDobRows.is_before': 'Is Before',
  'shared.RestrictionsSectionDobRows.is_after': 'Is After',
  'shared.RestrictionsSectionDobRows.is_between': 'Is Between',
  'shared.RestrictionsSectionDobRows.clear_selection': 'Clear Selections',
  'shared.RestrictionsSectionDobRows.dob_info': `<p>During checkout, attendee date of birth will be checked to determine whether they can register for the event.<p>
  <p>This restriction cannot be used at the same time as Age restriction.</p>
  <span>Note</span>: Admins can override date of birth restrictions in POS`,
    'shared.RestrictionsSectionDobRows.dob_feature_info': `<p><span>When to use this feature</span>: Date of Birth restriction is useful for allowing clients who will be the within age range for this event or team by the start date to register. It can also be helpful for events or teams that require registration based on specific date of birth and not just age.<p/>`,

  'event_mgmt.editing._TabPricing.bank': 'Bank',
  'event_mgmt.editing._TabPricing.add_automation': 'Add Automation',
  'event_mgmt.editing._TabPricing.add_warning': 'Save event "{eventTitle}" to add automation.',
  'event_mgmt.editing._TabPricing.automation': 'Automation',
  'event_mgmt.editing._TabPricing.enable_dynamic_pricing':
    'Enable Dynamic Pricing',
  'event_mgmt.editing._TabPricing.free_event': `Free ${nouns.Event}`,
  'event_mgmt.editing._TabPricing.list_price': 'List Price',
  'event_mgmt.editing._TabPricing.processing_fee': 'Processing Fee',
  'event_mgmt.editing._TabPricing.total_earnings': 'Total Earnings',

  'event_mgmt.editing._TabPricing.enable_dynamic_pricing_help_text': `Enabling dynamic pricing will automatically prorate the price of your ${nouns.event
    } so that clients purchasing after the start of of the ${nouns.event
    } are only charged for remaining days / sessions.`,
  'event_mgmt.editing._TabPricing.single_session_automation_help_text':
    'Automations are disabled when Single Session purchases are active.',
  'event_mgmt.editing._TabPricing.ssp_warning_title': 'Are you sure you want to do this?',
  'event_mgmt.editing._TabPricing.ssp_warning_text_1': 'If you have participants who signed up for one or multiple sessions proceeding with this action will not charge those participants the difference.',
  'event_mgmt.editing._TabPricing.ssp_warning_text_2': 'Once disabled all current participants that have purchased single sessions will be automatically added to the remaining sessions in the fixed event for the number of single sessions purchases.',
  'event_mgmt.editing._TabPricing.ssp_warning_note_title': 'Important Note: ',
  'event_mgmt.editing._TabPricing.ssp_warning_note_subtitle': 'Disabling this feature within fixed events does not charge any additional costs to current registered participants of single session purchase. If you wish to charge those who previously purchased single sessions for the full amount you must take action.',
  'event_mgmt.editing._TabPricing.ssp_warning_yes': 'Yes',
  'event_mgmt.editing._TabPricing.ssp_warning_no': 'No',
  'event_mgmt.editing._TabPricing.card': 'Card',
  'event_mgmt.editing._TabPricing.pay_later': 'Pay Later',
  'event_mgmt.editing._TabPricing.payment_methods': 'Payment Methods',
  'event_mgmt.editing._TabPricing.info': 'Select which payment methods your clients can choose during checkout. This will override the default ones set in Business Settings. ',

  'event_mgmt.editing._TabClassPricing.pricing_disabled': 'Pricing disabled: ',
  'event_mgmt.editing._TabClassPricing.description':
    `This event is booked using credits the client can purchase using Credit Passes or ${nouns.Membership}s. `,
  'event_mgmt.editing._TabClassPricing.following_credits':
    'The following credits are associated with this event:',
  'event_mgmt.editing._TabClassPricing.description_empty_state':
    'Credit Passes and Memberships that this event is associated with will be displayed on the Pricing page after clicking save or posting the event.',

  'event_mgmt.editing._TabClassPricing.credit_pack_price':
    '{creditCount} {creditCount, plural, one {credit} other {credits}} • {price}',
  'event_mgmt.editing._TabClassPricing.view_all_credit_passes':
    'View All Credit Passes →',

  'event_mgmt.editing._SingleSessionPurchaseSection.checkbox':
    'Allow single session purchase',
  'event_mgmt.editing._PricingScheduleSection.checkbox':
    'Select the date in which you would like the price of the event to change',
  'event_mgmt.editing._PricingScheduleSection.price_field_label': 'Price',
  'event_mgmt.editing._PricingScheduleSection.schedule_price_date_label': "Pick Date",
  'event_mgmt.editing._SingleSessionPurchaseSection.help_text': `Allowing single session purchases enables your clients to purchase individual sessions of your ${nouns.event
    }. A schedule is required to use this feature.`,
  'event_mgmt.editing._SingleSessionPurchaseSection.single_session_price_header':
    'Set single session price',
  'event_mgmt.editing._PricingScheduleSection.pricing_schedule_header':
    'Pricing Schedule',
  'event_mgmt.editing._SingleSessionPricingTiers.divider_more': 'or more',
  'event_mgmt.editing._SingleSessionPricingTiers.divider_to': 'to {count}',
  'event_mgmt.editing._SingleSessionPricingTiers.add_tier': 'Add Pricing Tier',
  'event_mgmt.editing._SingleSessionPricingTiers.save_tier': 'Save',
  'event_mgmt.editing._SingleSessionPricingTiers.qty': 'Quantity',
  'event_mgmt.editing._SingleSessionPricingTiers.session_price': 'Price Per Session',

  'event_mgmt.editing._TabRegistrationForm.event_form_directions': `Check a field below to make it mandatory for ${nouns.event
    } registration.`,
  'event_mgmt.editing._TabRegistrationForm.add_fields_directions':
    "Choose what information you'd like to collect about athletes when they register. Select a category below and add a field from the dropdown.",
  'event_mgmt.editing._TabRegistrationForm.event_form_fields': `${nouns.Event
    } Form Fields `,
  'event_mgmt.editing._TabRegistrationForm.event_form_fields_check':
    '(check required)',
  'event_mgmt.editing._TabRegistrationForm.field_categories':
    'Registration Field Categories:',
  'event_mgmt.editing._TabRegistrationForm.field_options':
    'Field Options (Separate by comma)',

  'event_mgmt.editing._TabSchedule.after': 'After',
  'event_mgmt.editing._TabSchedule.cannot_blackout_dsd':
    'Cannot blackout date with specific daytimes.',
  'event_mgmt.editing._TabSchedule.date': 'Date',
  'event_mgmt.editing._TabSchedule.dates_of_availability':
    'Dates of Availability',
  'event_mgmt.editing._TabSchedule.date_specific_legend': 'Date Specific Time',
  'event_mgmt.editing._TabSchedule.end': 'End',
  'event_mgmt.editing._TabSchedule.end_date': 'End Date',
  'event_mgmt.editing._TabSchedule.every': 'Every',
  'event_mgmt.editing._TabSchedule.for': 'For',
  'event_mgmt.editing._TabSchedule.hour': 'Hour',
  'event_mgmt.editing._TabSchedule.indefinite': 'Indefinite',
  'event_mgmt.editing._TabSchedule.min': 'Min',
  'event_mgmt.editing._TabSchedule.num_of_times': '# of times',
  'event_mgmt.editing._TabSchedule.on_a_date': 'On a Date',
  'event_mgmt.editing._TabSchedule.repeat_weekly': 'Repeat Weekly',
  'event_mgmt.editing._TabSchedule.session_duration': 'Session Duration',
  'event_mgmt.editing._TabSchedule.start_date': 'Start Date',
  'event_mgmt.editing._TabSchedule.weekly_availability': 'Weekly Availability',
  'event_mgmt.editing._TabSchedule.registration_deadline': 'Registration Deadline',
  'event_mgmt.editing._TabSchedule.registration_deadline_info': 'Setting a registration deadline will close the registration after 11:59pm of the date selected',

  'event_mgmt.index._Header.manage_events': `manage ${nouns.event}s`,

  'event_mgmt.index._Content.create_event': `Create ${nouns.Event}`,
  'event_mgmt.index._Content.staff_admin_header': `You have no ${nouns.event
    }s... but so much to offer.`,
  'event_mgmt.index._Content.staff_admin_message': `Create an ${nouns.event
    } and start taking enrollments in minutes.`,
  'event_mgmt.index._Content.staff_member_header': `No ${nouns.Event}s?`,
  'event_mgmt.index._Content.staff_member_message':
    "No worries, we're currently creating some just for you. Check back soon.",

  'event_mgmt.index._ContentTable.clients': 'clients',
  'event_mgmt.index._ContentTable.date_status': 'date/status',
  'event_mgmt.index._ContentTable.location': 'location',
  'event_mgmt.index._ContentTable.name': 'name',
  'event_mgmt.index._ContentTable.price': 'price',
  'event_mgmt.index._ContentTable.private': 'private',

  'event_mgmt.index._ContentToolbar.action': 'Action',
  'event_mgmt.index._ContentToolbar.all': 'All',
  'event_mgmt.index._ContentToolbar.cancel': 'Cancel',
  'event_mgmt.index._ContentToolbar.complete': 'Complete',
  'event_mgmt.index._ContentToolbar.edit': 'Edit',
  'event_mgmt.index._ContentToolbar.clone': 'Clone',
  'event_mgmt.index._ContentToolbar.enrolled': 'Enrolled',
  'event_mgmt.index._ContentToolbar.search': 'Search',
  'event_mgmt.index._ContentToolbar.show': 'Show: ',
  'event_mgmt.index._ContentToolbar.view': 'View',
  'event_mgmt.index._ContentToolbar.delete': 'Delete',
  'event_mgmt.index._ContentToolbar.undelete': 'Undelete',
  'event_mgmt.index._ContentToolbar.delete_message': `If there are active registrants for this ${nouns.event
    }, they will not be notified of this ${nouns.event
    } deletion. Are you sure you want to delete this ${nouns.event}?`,
  'event_mgmt.index._ContentToolbar.event_title': 'Delete {event}?',

  'event_mgmt.index._ContentCards.clients': 'Clients',
  'event_mgmt.index._ContentCards.price': 'Price',
  'event_mgmt.index._ContentCards.private': 'Private',
  'event_mgmt.index._FilterDrawer.event_status': `${nouns.Event} Status`,
  'event_mgmt.index._FilterDrawer.event_type': `${nouns.Event} Type`,

  'event_mgmt.shared.post_success': `${nouns.Event} posted successfully.`,
  'event_mgmt.shared.clone_success': `${nouns.Event} cloned successfully.`,
  'event_mgmt.shared.delete_success': `${nouns.Event}(s) deleted successfully.`,

  'event_mgmt.shared._PricingFields.price_field_label': 'List Price',
  'event_mgmt.shared._PricingFields.fee_field_label': 'Processing Fee',
  'event_mgmt.shared._PricingFields.earnings_field_label': 'Total Earnings',
  'event_mgmt.shared._PricingFields.fee_info': '({n}% + ${m} per transaction)', // eslint-disable-line
  'event_mgmt.shared._PricingFields.interchange_price_label': `${nouns.Event
    } Price`,

  'event_mgmt.shared._EventTitleAndDate.event_title': `${nouns.Event} Title`,

  'event_mgmt.shared._EventToolbar.events': `${nouns.Event}`,
  'event_mgmt.shared._EventToolbar.browse_events': `Browse Events`,

  'event_mgmt.shared.ParticipantClientEventList.ParticipantClientEventList.no_events': `No registered ${nouns.event
    }s.`,
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModal.title': 'Cancel participant registration?',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModal.confirm_btn': 'Yes',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModal.cancel_btn': 'No',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModal.ssp_off_warning': 'Removing client from this session will remove them from all sessions of this fixed event. Do you want to proceed?',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.unschedule_warning': 'Cancelling will remove the participant from the selected session. The session will also be removed from the participant’s upcoming events.',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.options': 'Please select the appropriate option below:',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.refund_title': 'Return Session Credits',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.refund_subtitle': 'Compensation will be granted via credit to participant(s).',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.revoke_title': 'Revoke Session Credits',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.revoke_subtitle': 'Credit(s) will NOT be returned to participant(s).',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.waive_checkbox': 'Waive Outstanding Fee',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.credits_waive_notice': 'Credits tied to a credit pass or membership cannot be waived.',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.note_title': 'Important Note: ',
  'event_mgmt.shared.ParticipantClientEventList.CancelRegistrationModalContent.note_subtitle': 'If compensation is granted in the form of credits or the waive outstanding fee checkbox is not marked, any “pay later” balances will remain on the Balances Report until they are fully waived or fully paid. Actions cannot be undone.',

  'memberships._CancellationDialog.are_you_sure':
    `Are you sure you want to remove {clientName} from {membershipName} ${nouns.membership}?`,
  'memberships._CancellationDialog.cancel_at_end':
    'Cancel at end of period {paymentPeriodEnd}',
  'memberships._CancellationDialog.cancel_immediately': 'Cancel immediately',
  'memberships.CancellationDialogModal.title': 'Future Cancellation Date',
  'memberships.CancellationDialogModal.description': 'Select future cancellation date',
  'memberships.CancellationDialogModal.future_date': 'Future Cancellation Date:',
  'memberships.CancellationDialogModal.cancel': 'cancel',
  'memberships.CancellationDialogModal.confirm': 'confirm',
  'memberships._CancellationDialog.cancel_membership': `Cancel ${nouns.Membership}`,
  'memberships._CancellationDialog.cancellation_reason': 'Cancellation Reason',
  'memberships._CancellationDialog.cancellation_reason_placeholder': 'Select a cancellation reason',
  'memberships._CancellationDialog.cancellation_notes': 'Cancellation Note (optional)',
  'memberships._CancellationDialog.unschedule_label': 'Unschedule all future registrations upon membership cancellation.',
  'memberships._CancellationDialog.unschedule_warning': 'This action will completely remove registrations and it cannot be undone.',
  'memberships._CancellationDialog.cancellation_date': 'Cancellation date:',
  'memberships._CancellationDialog.confirm_cancellation':
    'Yes',
  'memberships._CancellationDialog.discard_cancellation':
    'No',
  'memberships._CancellationDialog.cancelling': 'Cancelling',
  'memberships._CancellationDialog.cancel_future': 'Cancel on future date',

  'memberships._SuspensionDialog.are_you_sure':
    `Are you sure you want to suspend {membershipName} ${nouns.Membership} for {clientName}?`,
  'memberships._SuspensionDialog.suspend_at_end':
    'Suspend at end of the period',
  'memberships._SuspensionDialog.suspend_immediately': 'Suspend immediately',
  'memberships._SuspensionDialog.suspend_membership': `Suspend ${nouns.Membership}`,
  'memberships._SuspensionDialog.reactivation_date': 'Reactivation Date:',
  'memberships._SuspensionDialog.suspend_date': 'Suspension Start Date:',
  'memberships._SuspensionDialog.confirm_suspension': 'Confirm Suspension',
  'memberships._SuspensionDialog.suspending': 'Suspending',
  'memberships._SuspensionDialog.reactivate_on_future':
    'Reactivate on a future date',
  'memberships._SuspensionDialog.choose_date': 'Choose date',

  'memberships._ReactivationDialog.confirm_reactivation':
    'Confirm Reactivation',
  'memberships._ReactivationDialog.are_you_sure':
    `Are you sure you want to reactivate {membershipName} ${nouns.membership} for {clientName}?`,
  'memberships._ReactivationDialog.reactivate_membership':
    `Reactivate ${nouns.Membership}`,
  'memberships._ReactivationDialog.cancelling': 'Cancelling',

  'memberships._ColorSelector.badge_color': 'Badge Color',
  'memberships._ColorSelector.select_color': 'Select Color',

  'memberships._CreditBenefits.day': 'day',
  'memberships._CreditBenefits.week': 'week',
  'memberships._CreditBenefits.month': 'month',
  'memberships._CreditBenefits.year': 'year',
  'memberships._CreditBenefits.unlimited_sessions': 'Unlimited Sessions:',
  'memberships._CreditBenefits.any_event': `Any ${nouns.Event}`,
  'memberships._CreditBenefits.n_sessions':
    '{n} {n, plural, one {session} other {sessions}}',
  'memberships._CreditBenefits.sessions_per_frequency':
    '{sessions} a {frequency}:',

  'memberships._DiscountBenefits._EventTypeDiscountBenefits.all_events': `All ${nouns.Event
    }s`,
    'memberships._DiscountBenefits._CreditPassDiscountBenifits.all_passes': `All Credit Passes`,

  'memberships._DiscountBenefits._RetailCategoryDiscountBenefits.all_retail_products':
    'All Products',

  'memberships._DiscountBenefits._GenericDiscount.value_off_retail':
    '{value} off for retail categories:',
  'memberships._DiscountBenefits._GenericDiscount.value_off_types': `{value} off for ${nouns.event
    } types:`,
    'memberships._DiscountBenefits._GenericDiscount.value_off_passes': `{value} off for credit Passes:`,
  'memberships._ExclusiveEventBenefits.exclusive_event_access': `Exclusive ${
    nouns.Event
  } Access:`,

  'memberships._MemberCard.cancel_membership': 'Cancel',
  'memberships._MemberCard.cancelled': 'Cancelled',
  'memberships._MemberCard.active': 'Active',
  'memberships._MemberCard.edit': 'Edit',
  'memberships._MemberCard.expired': 'Expired',
  'memberships._MemberCard.invited': 'Invited',
  'memberships._MemberCard.balances': 'History',
  'memberships._MemberCard.pre_sale': 'Pre Sale',
  'memberships._MemberCard.remove_suspension': 'Remove Suspension',
  'memberships._MemberCard.reactivate_membership': 'Reactivate',
  'memberships._MemberCard.remind': 'Remind',
  'memberships._MemberCard.suspend_membership': 'Suspend',
  'memberships._MemberCard.suspended': 'Suspended',
  'memberships._MemberCard.suspended_until': 'Suspended until',
  'memberships._MemberCard.suspending': 'Suspending',
  'memberships._MemberCard.expires_at': '{expireAt}',
  'memberships._MemberCard.suspend_at': '{suspendedAt}',
  'memberships._MemberCard.reactivate_on': '{reactivateOn}',
  'memberships._MemberCard.cancelled_at': '{dateTime}',
  'memberships._MemberCard.invited_at': '{invitedAt}',
  'memberships._MemberCard.commitment_length':'{commitment}',

  'memberships.EventDiscountSelector.all_events': `All ${nouns.Event}s`,
  'memberships.EventDiscountSelector.additional_discount':
    'Additional Discount',
  'memberships.EventDiscountSelector.choose_event_types': `Choose ${nouns.event
    } type(s)`,
  'memberships.EventDiscountSelector.create_discount': 'Create Discount',
  'memberships.EventDiscountSelector.event_types': `${nouns.Event} Type(s)`,
  'memberships.EventDiscountSelector.explanatory_text': `Create a percentage discount for all or any ${nouns.event
    } types.`,
  'memberships.EventDiscountSelector.header_text': `${nouns.Event} Discounts`,
  'memberships.EventDiscountSelector.how_should_this_be_applied':
    'How should this discount be applied?',
  'memberships.EventCreditSelector.allow_credit_rollover':
    'Allow credits to roll over',
  'memberships.EventDiscountSelector.percentage_amount': 'Discount Value',
  'memberships.EventDiscountSelector.value': 'Value',
  'memberships.EventDiscountSelector.percentage': '% Percent',
  'memberships.EventDiscountSelector.amount': '$ Amount',

  'memberships.CreditPassDiscountSelector.header_text':'Credit Pass Discounts',
  'memberships.CreditPassDiscountSelector.explanatory_text': `Create a discount for all or any of your credit passes.`,
  'memberships.CreditPassDiscountSelector.create_discount': 'Create Discount',
  'memberships.CreditPassDiscountSelector.percentage_amount': 'Discount Value',
  'memberships.CreditPassDiscountSelector.value': 'Value',
  'memberships.CreditPassDiscountSelector.percentage': '% Percent',
  'memberships.CreditPassDiscountSelector.amount': '$ Amount',
  'memberships.CreditPassDiscountSelector.how_should_this_be_applied':
  'How should this discount be applied?',
  'memberships.CreditPassDiscountSelector.all_credit_passes': `All Passes`,
  'memberships.CreditPassDiscountSelector.additional_discount':
    'Additional Discount',
  'memberships.CreditPassDiscountSelector.choose_credit_passes': `Choose Credit Pass(s)`,
  'memberships.CreditPassDiscountSelector.credit_pass': `Credit Pass(s)`,

  'memberships.MembershipCard.edit': 'Edit',
  'memberships.MembershipCard.tiers_count': '{count} Tiered Pricing {count, plural, one {Option} other {Options}}',
  'memberships.MembershipCard.join_fee': '{fee} Join Fee',
  'memberships.MembershipCard.free': 'Free',
  'memberships.MembershipCard.month': 'Monthly',
  'memberships.MembershipCard.year': 'Annually',
  'memberships.MembershipCard.one_time': 'One Time',

  'memberships.EventCreditSelector.all_events': `All ${nouns.Event}s`,
  'memberships.EventCreditSelector.search_event': `Search for ${nouns.Event}`,
  'memberships.EventCreditSelector.additional_credits': 'Additional Credits',
  'memberships.EventCreditSelector.choose_event_types': `Choose ${nouns.event
    } type(s)`,
  'memberships.EventCreditSelector.create_discount': 'Create Session Credits',
  'memberships.EventCreditSelector.event_types': `${nouns.Event} Type(s)`,
  'memberships.EventCreditSelector.event_specific': `${nouns.Event} Specific`,
  'memberships.EventCreditSelector.explanatory_text': `Offer free session credits for open booking ${nouns.event
    }s.`,
  'memberships.EventCreditSelector.header_text':
    'Session Credits',
  'memberships.EventCreditSelector.expiration_tooltip': 'Credits must be scheduled and used before the expiration date.',
  'memberships.EventCreditSelector.how_should_this_be_applied':
    'How should these credits be applied?',
  'memberships.EventCreditSelector.percentage_amount': 'Percentage Amount',
  'memberships.EventCreditSelector.value': 'Value',
  'memberships.EventCreditSelector.recurring': 'Recurring',
  'memberships.EventCreditSelector.daily': 'Daily',
  'memberships.EventCreditSelector.weekly': 'Weekly',
  'memberships.EventCreditSelector.monthly': 'Monthly',
  'memberships.EventCreditSelector.yearly': 'Yearly',
  'memberships.EventCreditSelector.unlimited': 'Unlimited',
  'memberships.EventCreditSelector.qty': 'Qty',
  'memberships.EventCreditSelector.session_credits': 'Session Credits',
  'memberships.EventCreditSelector.hint_text_all_events': `Credits can be applied for any available ${nouns.event
    }.`,
  'memberships.EventCreditSelector.hint_text_event_types': `Credits can be used for any available ${nouns.event
    } within selected ${nouns.event} types. If multiple ${nouns.event
    } types are set credits will be shared across them`,
  'memberships.EventCreditSelector.hint_text_event_specific': `Credits can be used for selected ${nouns.events
    }. If multiple ${nouns.events} are set credits will be shared across them.`,

  'memberships.EventCreditSelector.allow_future_scheduling': 'Allow scheduling outside of billing cycle',
  'memberships.EventCreditSelector.grant_ahead': 'Grant ahead',
  'memberships.EventCreditSelector.grant_ahead_tooltip': 'Granting ahead will provide clients with their credits before their next billing cycle',
  'memberships.EventCreditSelector.no_grant_ahead': 'No grant ahead',
  'memberships.EventCreditSelector.grant_ahead_week': '{weekCount} {weekCount, plural, one {week} other {weeks}}',
  'memberships.EventCreditSelector.grant_ahead_month': '{monthCount} {monthCount, plural, one {month} other {months}}',

  'memberships.RetailDiscountSelector.additional_discount':
    'Additional Discount',
  'memberships.RetailDiscountSelector.all_retail_products': 'All Products',
  'memberships.RetailDiscountSelector.choose_retail_categories':
    'Choose retail category',
  'memberships.RetailDiscountSelector.create_discount': 'Create Discount',
  'memberships.RetailDiscountSelector.header_text': 'Retail Discounts',
  'memberships.RetailDiscountSelector.how_should_this_be_applied':
    'How should this discount be applied?',
  'memberships.RetailDiscountSelector.percentage_amount': 'Percentage Amount',
  'memberships.RetailDiscountSelector.retail_categories': 'Retail Category',
  'memberships.RetailDiscountSelector.explanatory_text':
    'Create a percentage discount for retail items.',
  'memberships.RetailDiscountSelector.value': 'Value',

  'memberships.ExclusiveEventTypeSelector.choose_event_types': `Choose ${nouns.event
    } type(s)`,
  'memberships.ExclusiveEventTypeSelector.define_an_exclusive_event_type': `Define an Exclusive ${nouns.Event
    } Type`,
  'memberships.ExclusiveEventTypeSelector.explanatory_text': `Offer member only ${nouns.event
    }s by defining which ${nouns.event
    } types are made available with this ${nouns.membership}. Non-members will not be able to view or access the ${nouns.event
    } type.`,
  'memberships.ExclusiveEventTypeSelector.header_text': `Exclusive ${nouns.Event
    } Types`,
  'memberships.ExclusiveEventTypeSelector.instructions': `Choose which ${nouns.event
    } types will be exclusive to this ${nouns.membership}.`,

  'memberships.DiscountsSelector.DiscountsSelector.discounts': 'Discounts',
  'memberships.DiscountsSelector.DiscountsSelector.discounts_description': 'Create a percentage discount for all or any event types, credit passes, or retail.',
  'memberships.DiscountsSelector.DiscountsSelector.add_discount': 'Add additional discount',
  'memberships.DiscountsSelector.DiscountsSelector.create_discount': 'Create Discount',
  'memberships.DiscountsSelector.DiscountCard.how_should_apply': 'How should this discount be applied?',
  'memberships.DiscountsSelector.DiscountCard.choose_event_type': 'Choose event type(s)',
  'memberships.DiscountsSelector.DiscountCard.choose_credit_pass': 'Choose credit pass',
  'memberships.DiscountsSelector.DiscountCard.choose_retail_category': 'Choose retail category',
  'memberships.DiscountsSelector.DiscountCard.discount_type': 'Discount Type',
  'memberships.DiscountsSelector.DiscountCard.discount_value': 'Discount Value',
  'memberships.DiscountsSelector.DiscountCard.EVENT': 'Event Discount',
  'memberships.DiscountsSelector.DiscountCard.CREDIT_PASS': 'Credit Passes',
  'memberships.DiscountsSelector.DiscountCard.RETAIL': 'Retail',
  'memberships.DiscountsSelector.DiscountCard.amount': 'Amount',
  'memberships.DiscountsSelector.DiscountCard.percent': 'Percent',

  'memberships.MembershipTiers.MembershipTiers.title': 'Membership Tiers',
  'memberships.MembershipTiers.MembershipTiers.add-tiered-pricing-tooltip': `A tier is a pricing option that is available within a membership. Each tier you create will be listed here for you to view and edit. Note: To delete a tier there can't be any clients enrolled in that specific tier. `,
  'memberships.MembershipTiers.MembershipTiers.add_tier': 'Add Tier',
  'memberships.MembershipTiers.MembershipTiers.cancel_tier': 'Cancel',
  'memberships.MembershipTiers.MembershipTiers.update_tier': 'Update Tier',
  'memberships.MembershipTiers.MembershipTiers.save_tier': 'Save Tier',
  'memberships.MembershipTiers.MembershipTiers.warning': 'Adding tiers disabled. Please save your membership.',

  'memberships.MembershipTiers.MembershipTiersCreationDrawer.title': 'Create Membership Tier',
  'memberships.MembershipTiers.MembershipTiersCreationDrawer.cancel': 'Cancel',
  'memberships.MembershipTiers.MembershipTiersCreationDrawer.save': 'Save',
  'memberships.MembershipTiers.MembershipTiersCreationDrawer.update': 'Update',
  'memberships.MembershipTiers.MembershipTiersCreationDrawer.back': 'Back',
  'memberships.MembershipTiers.MembershipTiersCreationDrawer.next': 'Continue to Add Benefits',

  'memberships.MembershipTiers.MembershipTierConfig.tier_name': 'Tier Name',
  'memberships.MembershipTiers.MembershipTierConfig.tier_pricing': 'Pricing',
  'memberships.MembershipTiers.MembershipTierConfig.tier_join_fee': 'Add One-Time Join Fee',
  'memberships.MembershipTiers.MembershipTierConfig.tier_join_fee_info': 'This fee will be in addition to the membership price determined above.',
  'memberships.MembershipTiers.MembershipTierConfig.tier_join_fee_label': 'One-time join fee',
  'memberships.MembershipTiers.MembershipTierConfig.tier_require_commitment': 'Require Commitment Length',
  'memberships.MembershipTiers.MembershipTierConfig.tier_commitment_length': 'Commitment Length',
  'memberships.MembershipTiers.MembershipTierConfig.tier_commitment_length_info': 'This will require your clients to agree to a specified membership length.',
  'memberships.MembershipTiers.MembershipTierConfig.tier_cancel_at_end_commitment': 'Cancel at End of Commitment',

  'memberships.MembershipTiers.MembershipTierDeleteConfirmation.info': 'Are you sure you want to delete this membership tier?',
  'memberships.MembershipTiers.MembershipTierDeleteConfirmation.confirm': 'Yes',
  'memberships.MembershipTiers.MembershipTierDeleteConfirmation.cancel': 'No',

  'memberships.MembershipTiers.MembershipTiersList.set_best_value': 'Set Best Value',
  'memberships.MembershipTiers.MembershipTiersList.remove_best_value': 'Remove as Best Value',
  'memberships.MembershipTiers.MembershipTiersList.best_value_info': 'Tier set as Best Value. Clients will see this offer first on this membership landing page.',
  'memberships.MembershipTiers.MembershipTiersList.edit': 'Edit',
  'memberships.MembershipTiers.MembershipTiersList.delete': 'Delete',
  'memberships.MembershipTiers.MembershipTiersList.clone': 'Clone',
  'memberships.MembershipTiers.MembershipTiersList.price': 'Price',
  'memberships.MembershipTiers.MembershipTiersList.join_fee': 'Join Fee',
  'memberships.MembershipTiers.MembershipTiersList.no_join_fee': 'No Join Fee',

  'memberships.MembershipTiers.MembershipViewingTiers.price': 'Price {price}',
  'memberships.MembershipTiers.MembershipViewingTiers.commitment': 'Commitment {commitment}',
  'memberships.MembershipTiers.MembershipViewingTiers.no_commitment': 'No Commitment',
  'memberships.MembershipTiers.MembershipViewingTiers.view_benefits': 'View {tier_name} Benefits',

  'memberships.MembershipEditing.create_header': `Create ${nouns.Membership}`,
  'memberships.MembershipEditing.description': 'Description',
  'memberships.MembershipEditing.hide_in_client_checkout':
    'Hide in client checkout',
  'memberships.MembershipEditing.hide_in_point_of_sale':
    'Hide in Point of Sale',
  'memberships.MembershipEditing.pricing': 'Pricing',
  'memberships.MembershipEditing.commitmentChecked': 'Cancel Membership at the end of commitment',
  'memberships.MembershipEditing.edit_header': `Edit ${nouns.Membership}`,
  'memberships.MembershipEditing.invite_only': `Invite-only ${nouns.membership}`,
  'memberships.MembershipEditing.name_floating': 'Name',
  'memberships.MembershipEditing.benefit_type': 'Benefit Type',
  'memberships.MembershipEditing.name_hint':
    'Membership Name (e.g. Gold, Silver)',
  'memberships.MembershipEditing.save_membership': `Save ${nouns.Membership}?`,
  'memberships.MembershipEditing.confirm_save': 'Confirm Changes',
  'memberships.MembershipEditing.cancel_save': 'Cancel',
  'memberships.MembershipEditing.warning':
    'You have changed the price of this membership. Any active members will be charged the new price moving forward. This action cannot be undone.',
  'memberships.MembershipListing.annually': 'Annually',
  'memberships.MembershipEditing.success': 'Membership Saved Successfully',

  'memberships.MembershipListing.commitment':
    '{length} mo. commitment required',
  'memberships.MembershipListing.create_new': `Create New ${nouns.Membership}`,
  'memberships.MembershipListing.enroll_more': 'Enroll More',
  'memberships.MembershipListing.free': 'Free',
  'memberships.MembershipListing.header': `${nouns.Membership}s`,
  'memberships.MembershipListing.invite_only': 'Invite Only',
  'memberships.MembershipListing.join_fee': 'Join Fee',
  'memberships.MembershipListing.lifetime': 'One Time',
  'memberships.MembershipListing.members_count':
    '{count, plural, =0 {No Members} one {# Member} other {# Members}}',
  'memberships.MembershipListing.monthly': 'Monthly',
  'memberships.MembershipListing.purchase_more': 'Purchase More',
  'memberships.MembershipListing.view': 'View',
  'memberships.MembershipListing.hidden_in_checkout': 'Hidden in client checkout',
  'memberships.MembershipListing.hidden_in_pos': 'Hidden in POS',

  'memberships._PreSale.allow_pre_sale': 'Allow pre-sale',
  'memberships.cancel_membership': 'Cancel Membership at the end of commitment',
  'memberships._PreSale.choose_date': 'Choose Date',
  'memberships._PreSale.set_active_date': 'Set active date:',

  'memberships._Pricing.1_month': '1 month',
  'memberships._Pricing.3_months': '3 months',
  'memberships._Pricing.6_months': '6 months',
  'memberships._Pricing.1_year': '1 year',
  'memberships._Pricing.header': 'Pricing',
  'memberships._Pricing.commitment_length': 'Commitment length',
  'memberships._Pricing.commitment_length_checkbox_label':
    'Require commitment length',
  'memberships._Pricing.commitment_length_explanatory_text':
    `This will require your clients to agree to a specified ${nouns.membership} length.`,
  'memberships._Pricing.join_fee': 'One-time join fee',
  'memberships._Pricing.join_fee_checkbox_label': 'Add a one-time join fee',
  'memberships._Pricing.join_fee_explanatory_text':
    `This fee will be in addition to the ${nouns.membership} price determined above.`,
  'memberships._Pricing.monthly': 'Monthly',
  'memberships._Pricing.once': 'Once',
  'memberships._Pricing.yearly': 'Yearly',
  'memberships._Pricing.pricing_tooltip': 'Tiered Pricing will allow you to create multiple pricing options for this membership',
  'memberships._Pricing.tiered_pricing': 'Tiered Pricing',

  'memberships.PurchaseDrawer.annually': 'annually',
  'memberships.PurchaseDrawer.benefits': 'Benefits:',
  'memberships.PurchaseDrawer.for_whom': 'Select Profile',
  'memberships.PurchaseDrawer.free': 'Free',
  'memberships.PurchaseDrawer.lifetime': 'One Time',
  'memberships.PurchaseDrawer.monthly': 'monthly',
  'memberships.PurchaseDrawer.view': 'view',
  'memberships.PurchaseDrawer.invite_only_title':
    `{membership_name} is an Invite-Only ${nouns.Membership}`,
  'memberships.PurchaseDrawer.invite_only_description':
    `This ${nouns.membership} requires an invite from {customer}.`,
    'memberships.MembershipViewing.commitment_months': '{months} mo. commitment',
    'memberships.MembershipViewing.clients': 'Active Clients',
  'memberships.MembershipViewing.event_exclusivities': `Exclusive ${nouns.Event
    }s:`,
  'memberships.MembershipViewing.event_type_exclusivities': `Exclusive ${nouns.Event
    } Types:`,
  'memberships.MembershipViewing.free': 'Free',
  'memberships.MembershipViewing.hidden': 'Hidden',
  'memberships.MembershipViewing.invite_client_field_hint_text':
    'Invite Client',
  'memberships.MembershipViewing.invite_only': 'Invite Only',
  'memberships.MembershipViewing.no_clients': 'No clients found',
  'memberships.MembershipViewing.no_revenue': 'No revenue',
  'memberships.MembershipViewing.remove': 'Remove',
  'memberships.MembershipViewing.remove_membership': `Remove ${nouns.membership}`,
  'memberships.MembershipViewing.remove_message':
    `Are you sure you want to remove this ${nouns.membership}? This action cannot be undone.`,
  'memberships.MembershipViewing.revenue_per_month': 'revenue per month',
  'memberships.MembershipViewing.total_revenue': 'total revenue',
  'memberships.MembershipViewing.url_copied': 'URL copied to clipboard.',
  'memberships.MembershipViewing.view_header': `${nouns.Membership}s`,
  'memberships.MembershipViewing.hidden_in_checkout': 'Hidden in client checkout',
  'memberships.MembershipViewing.hidden_in_pos': 'Hidden in POS',
  'memberships.MembershipViewing.tiered_membership_pricing': 'Tiered Membership Pricing',
  'memberships.MembershipViewing.view_tier_benefits': 'View {tier_name} Benefits',
  'memberships.MembershipViewing.tiers_count': '{count} Tiered Pricing {count, plural, one {Option} other {Options}}',
  'memberships.MembershipViewing.join_fee': '{fee} Join Fee',

  'memberships._ChargeDateChangeDialog.title': 'Membership Charge Date',
  'memberships._ChargeDateChangeDialog.description': "Select the date you'd like to change the charge date to",
  'memberships._ChargeDateChangeDialog.charge_date': 'Charge Date',
  'memberships._ChargeDateChangeDialog.cancel': 'Cancel',
  'memberships._ChargeDateChangeDialog.confirm': 'Confirm',

  'credit_passes.CreditPassEditing.create_header': 'Create Credit Pass',
  'credit_passes.CreditPassEditing.edit_header': 'Edit Credit Pass',

  'credit_passes._ClientCard.days':
    '{daysCount, plural, =0 {immediately} one {{daysCount} day} other {{daysCount} days}}',
  'credit_passes._ClientCard.expires': 'Expires: ',
  'credit_passes._ClientCard.n_of_m_credits': '{n}/{m} credits',
  'credit_passes._ClientCard.balances': 'History',
  'credit_passes._ClientCard.unlimited': 'Unlimited credits',

  'credit_passes.editing._EditingForm.name': 'Name',
  'credit_passes.editing._EditingForm.header_text': 'Pass Details',
  'credit_passes.editing._EditingForm.name_hint': 'Credit Pass Name',
  'credit_passes.editing._EditingForm.description': 'Description',
  'credit_passes.editing._EditingForm.pricing': 'Pricing',
  'credit_passes.editing._EditingForm.limit_header': 'Usage Limit',
  'credit_passes.editing._EditingForm.set_expiration': 'Set Expiration',
  'credit_passes.editing._EditingForm.expiration_tooltip': 'Credits must be scheduled and used before the specified expiration date.',
  'credit_passes.editing._EditingForm.max_num_of_purchases_info': 'Note: If the limit is blank it will be unlimited.',
  'credit_passes.editing._EditingForm.set_published': 'Hide in client checkout',
  'credit_passes.editing._EditingForm.hide_in_pos': 'Hide in Point of Sale',
  'credit_passes.editing._EditingForm.max_num_of_purchases_label': 'Per Client',

  'credit_passes.editing._CreditSelector.all_events': 'All Events',
  'credit_passes.editing._CreditSelector.search_event': 'Search for Event',
  'credit_passes.editing._CreditSelector.additional_credits':
    'Additional Credits',
  'credit_passes.editing._CreditSelector.choose_event_types':
    'Choose event type(s)',
  'credit_passes.editing._CreditSelector.create_credits':
    'Create Session Credits',
  'credit_passes.editing._CreditSelector.event_types': 'Event Type(s)',
  'credit_passes.editing._CreditSelector.event_specific': 'Event Specific',
  'credit_passes.editing._CreditSelector.explanatory_text':
    'Offer free session credits for open booking events and classes.',
  'credit_passes.editing._CreditSelector.header_text': 'Session Credits',
  'credit_passes.editing._CreditSelector.how_should_this_be_applied':
    'How should these credits be applied?',
  'credit_passes.editing._CreditSelector.percentage_amount':
    'Percentage Amount',
  'credit_passes.editing._CreditSelector.value': 'Value',
  'credit_passes.editing._CreditSelector.recurring': 'Recurring',
  'credit_passes.editing._CreditSelector.daily': 'Daily',
  'credit_passes.editing._CreditSelector.weekly': 'Weekly',
  'credit_passes.editing._CreditSelector.monthly': 'Monthly',
  'credit_passes.editing._CreditSelector.yearly': 'Yearly',
  'credit_passes.editing._CreditSelector.unlimited': 'Unlimited',
  'credit_passes.editing._CreditSelector.qty': 'Qty',
  'credit_passes.editing._CreditSelector.session_credits': 'Session Credits',
  'credit_passes.editing._CreditSelector.hint_text_all_events':
    'Credits can be applied for any available event.',
  'credit_passes.editing._CreditSelector.hint_text_event_types':
    'Credits can be used for any available event within selected event types. If multiple event types are set credits will be shared across them',
  'credit_passes.editing._CreditSelector.hint_text_event_specific':
    'Credits can be used for selected events. If multiple events are set credits will be shared across them.',
  'credit_passes.editing._EditingForm.on_expire_date': 'On',
  'credit_passes.editing._EditingForm.expire_date':
    ' {expirationDate, select, null {a date} other {{expirationDate}}}',
  'credit_passes.editing._EditingForm.expire_from_first_use': 'from first use',
  'credit_passes.editing._EditingForm.expire_from_purchase': 'from purchase',
  'credit_passes.editing._DaySelector.days': 'day(s)',
  'credit_passes.editing._DaySelector.weeks': 'week(s)',
  'credit_passes.editing._DaySelector.months': 'month(s)',
  'credit_passes.editing._DaySelector.rounding':
    'Months are rounded to 30 days.',

  'credit_passes.CreditPassListing.create_new': 'Create New Credit Pass',
  'credit_passes.CreditPassListing.header': 'Credit Passes',
  'credit_passes.CreditPassListing.clients_count':
    '{count, plural, =0 {No Clients} one {{count} Client} other {{count} Clients}}',
  'credit_passes.CreditPassListing.no_credits': 'No',
  'credit_passes.CreditPassListing.unlimited_credits': 'Unlimited',
  'credit_passes.CreditPassListing.credits_count':
    ' {count, plural, =0 {Credits} one {Credit} other {Credits}}',
  'credit_passes.CreditPassListing.view': 'View',
  'credit_passes.CreditPassListing.credit_pass_price': ' · {price}',
  'credit_passes.CreditPassListing.view_details': 'View Details',
  'credit_passes.CreditPassListing.hidden_in_checkout': 'Credit Pass hidden in client checkout',
  'credit_passes.CreditPassListing.hidden_in_pos': 'Credit Pass hidden in point of sale',

  'credit_passes.CreditPassViewing.search': 'Search',
  'credit_passes.CreditPassViewing.client_count': 'clients',
  'credit_passes.CreditPassViewing.commitment_months':
    '{months} mo. commitment',
  'credit_passes.CreditPassViewing.copy_url': 'Copy URL',
  'credit_passes.CreditPassViewing.empty_state_header': 'No clients, yet.',
  'credit_passes.CreditPassViewing.empty_state_message':
    'Not for long! Copy and share your credit pass page url.',
  'credit_passes.CreditPassViewing.event_exclusivities': 'Exclusive Events:',
  'credit_passes.CreditPassViewing.event_type_exclusivities':
    'Exclusive Event Types:',
  'credit_passes.CreditPassViewing.free': 'Free',
  'credit_passes.CreditPassViewing.unlisted': 'Unlisted',
  'credit_passes.CreditPassViewing.maxPurchases': ' Use per client',
  'credit_passes.CreditPassViewing.hidden': 'Hidden',
  'credit_passes.CreditPassViewing.invite_client_field_hint_text':
    'Invite Client',
  'credit_passes.CreditPassViewing.no_clients': 'No clients found',
  'credit_passes.CreditPassViewing.no_revenue': 'No revenue',
  'credit_passes.CreditPassViewing.revenue': 'revenue',
  'credit_passes.CreditPassViewing.total_revenue': 'total revenue',
  'credit_passes.CreditPassViewing.url_copied': 'URL copied to clipboard.',
  'credit_passes.CreditPassViewing.view_header': 'Credit Pass',
  'credit_passes.CreditPassViewing.remove_credit_pass': 'Remove Credit Pass',
  'credit_passes.CreditPassViewing.hidden_in_checkout': 'Hidden in client checkout',
  'credit_passes.CreditPassViewing.hidden_in_pos': 'Hidden in POS',
  'credit_passes.CreditPassViewing.remove': 'Remove',
  'credit_passes.CreditPassViewing.remove_message':
    'Are you sure you want to remove this credit pass? This action cannot be undone.',

  'credit_passes._CreditPassCredits.unlimited': 'Unlimited',
  'credit_passes._CreditPassCredits.all_events': 'All Events',
  'credit_passes._CreditPassCredits.credits_for':
    '{quantity, plural, =0 {Unlimited} other {{quantity}}}  {quantity, plural, one {credits} other {credits}} for:',
  'credit_passes._CreditPassCredits.days_from_purchase': 'from purchase',
  'credit_passes._CreditPassCredits.days_from_first_use': 'from first use',

  'credit_passes._CreditPassExpiration.never': 'No expiration',
  'credit_passes._CreditPassExpiration.expires_at': 'Expires on ',
  'credit_passes._CreditPassExpiration.expiration_days':
    '{days} day expiration ',
  'credit_passes._CreditPassExpiration.days_from_purchase': 'from purchase',
  'credit_passes._CreditPassExpiration.days_from_first_use': 'from first use',

  'credit_passes.PurchaseDrawer.benefits': 'Benefits',
  'credit_passes.PurchaseDrawer.for_whom':
    'For whom would you like to purchase this pass?',
  'credit_passes.PurchaseDrawer.free': 'Free',
  'credit_passes.PurchaseDrawer.choose_profile': 'Choose Profile',
  'credit_passes.PurchaseDrawer.Purchased': 'Purchased',
  'credit_passes.PurchaseDrawer.PurchasedTooltip': 'User has met the credit pass purchase limit',

  'onboarding.AccountSetupIncompleteBanner.UNSUBMITTED':
    'Credit card payments {migratingFromStripe, select, true {via UP Payments} other {}} cannot be accepted until account setup is complete. Complete account →',
  'onboarding.AccountSetupIncompleteBanner.PENDING':
    'Credit card payments {migratingFromStripe, select, true {via UP Payments} other {}} cannot be accepted until account setup is complete. Complete account →',
  'onboarding.AccountSetupIncompleteBanner.APPROVED':
    'Credit card processing {migratingFromStripe, select, true {via UP Payments} other {}} has been approved but has not been enabled. Please check back later or contact us →',
  'onboarding.AccountSetupIncompleteBanner.PROCESSING':
    'The application to process credit cards {migratingFromStripe, select, true {via UP Payments} other {}} is being reviewed. Please check back later or contact us →',
  'onboarding.AccountSetupIncompleteBanner.DEFERRED':
    'The application to process credit cards {migratingFromStripe, select, true {via UP Payments} other {}} is being reviewed. Please check back later or contact us →',
  'onboarding.AccountSetupIncompleteBanner.SUBMITTED':
    'The application to process credit cards {migratingFromStripe, select, true {via UP Payments} other {}} is being reviewed. Please check back later or contact us →',
  'onboarding.AccountSetupIncompleteBanner.RETURNED':
    'The application to process credit cards {migratingFromStripe, select, true {via UP Payments} other {}} is being reviewed. Please check back later or contact us →',
  'onboarding.AccountSetupIncompleteBanner.WAITING':
    'Additional information is required to activate credit card processing {migratingFromStripe, select, true {via UP Payments} other {}}. Contact us →',
  'onboarding.AccountSetupIncompleteBanner.DISABLED':
    'Credit card processing {migratingFromStripe, select, true {via UP Payments} other {}} has been disabled due to suspension or termination. Contact us →',
  'onboarding.AccountSetupIncompleteBanner.REJECTED':
    'The application to process credit cards {migratingFromStripe, select, true {via UP Payments} other {}} has been rejected. Contact us →',
  'onboarding.AccountSetupIncompleteBanner.WITHDRAWN':
    'The application to process credit cards {migratingFromStripe, select, true {via UP Payments} other {}} has been withdrawn. Contact us →',
  'onboarding.AccountSetupIncompleteBanner.ENABLED':
    'Credit card processing {migratingFromStripe, select, true {via UP Payments} other {}} is available.',

  'onboarding.AccountSetupIncompleteAlert.message':
    'Credit card payments {migratingFromStripe, select, true {via UP Payments} other {}} cannot be accepted until account setup is complete.',
  'onboarding.AccountSetupIncompleteAlert.complete_account': 'Complete Account',

  'onboarding.AccountSetupPaysafe._Header.complete_account': 'Complete Account',
  'onboarding.AccountSetupPaysafe.steps._Helpers.form_error':
    'Some required fields are missing or invalid',
  'onboarding.AccountSetupPaysafe.steps._CreateMerchantStep.next_button':
    'Next',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessName':
    'Doing Business As',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessLegalName':
    'Legal Name',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.LegalEntityType':
    'Ownership Type',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessPhone':
    'Phone',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.EIN': 'EIN',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessStreet':
    'Street Address',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessStreet2': ' ',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessCity': 'City',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessState':
    'State',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.BusinessZip':
    'Zip Code',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.AccountNumber':
    'Account Number',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.RoutingNumber':
    'Routing Number',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.FirstName':
    'First Name',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.LastName':
    'Last Name',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.JobTitle':
    'Job Title',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerPhone': 'Phone',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerEmail': 'Email',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.SSN': 'SSN',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.DateOfBirth':
    'Date of Birth',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerStreet':
    'Street Address',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerStreet2': ' ',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerCity': 'City',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerState': 'State',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.OwnerZip': 'Zip Code',
  'onboarding.AccountSetupPaysafe.forms._ConfirmationForm.review_changes':
    'Review Changes',
  'onboarding.AccountSetupPaysafe.steps._ConfirmationStep.back': 'Edit',
  'onboarding.AccountSetupPaysafe.steps._ConfirmationStep.submit': 'Submit',
  'onboarding.AccountSetupPaysafe.forms._PaysafeTOSForm.header':
    'Paysafe Terms of Service',
  'onboarding.AccountSetupPaysafe.forms._PaysafeTOSForm.accept_tos':
    'Accept Terms of Service',
  'onboarding.AccountSetupPaysafe.forms._IdentityQuestionsForm.header':
    'Verify Your Identity',
  'onboarding.AccountSetupPaysafe.steps._TosAndIdentityStep.submit':
    'Finish Account',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.header':
    'Business Information',
  'onboarding.AccountSetupPaysafe.fields._AddressField.address': 'Address',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.business_name_tooltip':
    "Doing Business As (DBA) is your publicly visible business name. If you aren't confident that you have the proper legal name that matches your Federal Employer Identification Number (FEIN), please do not submit this application as it can delay the onboarding process.",
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.legal_entity_tooltip':
    'Legal entity name is the name that you put on your tax returns.',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.ein_ssn_tooltip':
    "If Business Type is Sole Proprietor or 5013c please enter the owner’s SSN",
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.type_corp': 'Corporation',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.type_llc':
    'Limited Liability Company',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.type_llp':
    'Limited Liability Partnership',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.type_partnership':
    'Partnership',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.type_npcorp':
    'Non-Profit',
  'onboarding.AccountSetupPaysafe.forms._BusinessForm.type_soleprop':
    'Individual/Sole Proprietorship',
  'onboarding.AccountSetupPaysafe.forms._BankAccountForm.header':
    'Bank Account',
  'onboarding.AccountSetupPaysafe.forms._BankAccountForm.warning':
    'This information should not be your personal bank information, it should be the bank account that is tied to your business/business name.',
  'onboarding.AccountSetupPaysafe.forms._BankAccountForm.note':
    'You cannot go back after submitting this step. When your account is approved a micro deposit will be made and need to be validated to avoid funds being held.  To avoid funding delays please forward to Upper Hand your voided check to submit with your application. Voided Check may be required upon approval.',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessName_label':
    'Doing Business As',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessName_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessLegalName_label':
    'Legal Entity Name',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessLegalName_hint':
    'Business name on income tax return',
  'onboarding.AccountSetupPaysafe.fields._TextField.required': 'Required',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessPhone_label':
    'Business Phone',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessPhone_hint':
    '111-111-1111',
  'onboarding.AccountSetupPaysafe.fields._TextField.EIN_label': 'EIN or SSN',
  'onboarding.AccountSetupPaysafe.fields._TextField.EIN_invalid':
    'Must be a 9 digit number',
  'onboarding.AccountSetupPaysafe.fields._TextField.EIN_hint': 'Federal Tax ID #',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessStreet_label':
    'Line 1',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessStreet_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessStreet2_label':
    'Line 2',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessStreet2_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessCity_label': 'City',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessCity_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessZip_label':
    'Zip Code',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessZip_invalid':
    'Must be formatted as 12345 or 12345-1234',
  'onboarding.AccountSetupPaysafe.fields._TextField.BusinessZip_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.AccountNumber_label':
    'Account Number',
  'onboarding.AccountSetupPaysafe.fields._TextField.AccountNumber_hint':
    '123454321',
  'onboarding.AccountSetupPaysafe.fields._TextField.AccountNumber_invalid':
    'Only numbers are allowed',
  'onboarding.AccountSetupPaysafe.fields._TextField.AccountNumberConfirmation_label':
    'Confirm Account Number',
  'onboarding.AccountSetupPaysafe.fields._TextField.AccountNumberConfirmation_hint':
    '123454321',
  'onboarding.AccountSetupPaysafe.fields._TextField.AccountNumberConfirmation_invalid':
    'Must Match Account Number',
  'onboarding.AccountSetupPaysafe.fields._TextField.RoutingNumber_label':
    'Routing Number',
  'onboarding.AccountSetupPaysafe.fields._TextField.RoutingNumber_hint':
    '123454321',
  'onboarding.AccountSetupPaysafe.fields._TextField.RoutingNumber_invalid':
    'Must be a 9 digit number',
  'onboarding.AccountSetupPaysafe.fields._TextField.FirstName_label':
    'First Name',
  'onboarding.AccountSetupPaysafe.fields._TextField.FirstName_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.LastName_label':
    'Last Name',
  'onboarding.AccountSetupPaysafe.fields._TextField.LastName_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.JobTitle_label':
    'Job Title',
  'onboarding.AccountSetupPaysafe.fields._TextField.JobTitle_hint': 'Owner',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerPhone_label': 'Phone',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerPhone_hint':
    '111-111-1111',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerPhone_invalid':
    'Only numbers and dashes are allowed',
  'onboarding.AccountSetupPaysafe.fields._TextField.PercentOwnership_label':
    'Percent of Ownership',
  'onboarding.AccountSetupPaysafe.fields._TextField.PercentOwnership_hint':
    'Percent of Ownership',
  'onboarding.AccountSetupPaysafe.fields._TextField.PercentOwnership_invalid':
    'Only numbers are allowed',
  'onboarding.AccountSetupPaysafe.fields._TextField.SSN_label': 'SSN',
  'onboarding.AccountSetupPaysafe.fields._TextField.SSN_invalid':
    'Must be formatted as all numbers or 000-00-0000',
  'onboarding.AccountSetupPaysafe.fields._TextField.SSN_hint': '000-00-0000',
  'onboarding.AccountSetupPaysafe.fields._TextField.DateOfBirth_label':
    'Date of Birth',
  'onboarding.AccountSetupPaysafe.fields._TextField.DateOfBirth_hint':
    'MM/DD/YYYY',
  'onboarding.AccountSetupPaysafe.fields._TextField.DateOfBirth_invalid':
    'Must be formatted as MM/DD/YYYY',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerStreet_label':
    'Line 1',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerStreet_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerStreet2_label':
    'Line 2',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerStreet2_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerCity_label': 'City',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerCity_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerZip_label': 'Zip Code',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerZip_invalid':
    'Must be formatted as 12345 or 12345-1234',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerZip_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerEmail_label': 'Email',
  'onboarding.AccountSetupPaysafe.fields._TextField.OwnerEmail_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._SelectField.BusinessState_label':
    'State',
  'onboarding.AccountSetupPaysafe.fields._SelectField.BusinessState_hint':
    'Select a State',
  'onboarding.AccountSetupPaysafe.fields._SelectField.OwnerState_label':
    'State',
  'onboarding.AccountSetupPaysafe.fields._SelectField.OwnerState_hint':
    'Select a State',
  'onboarding.AccountSetupPaysafe.fields._SelectField.LegalEntityType_label':
    'Type',
  'onboarding.AccountSetupPaysafe.fields._SelectField.LegalEntityType_hint':
    'Ownership Type',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.instructions':
    'You must be the business owner or authorized to act on behalf of the business to complete account setup.',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.warning':
    'You cannot move forward until you have added each person that own 25% or more - must equal at least 76% or more.',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.ubos_with_25_percent':
    'Add each individual who directly or indirectly owns 25% or more of equity interest of the within named legal entity.',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.header':
    'Ownership Information',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.note':
    ' Social Security Number is required to verify your identity.  This information is encrypted and securely transmitted to our payment processor. We do not store your SSN in our databases.',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.header': 'Your Information',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.instructions':
    'You must be the business owner or authorized to act on behalf of the business to complete account setup.',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.controlling_interest':
    'I represent and warrant that I am a controlling interest of the business filling out this application.',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.control_prong':
    'I represent a single individual with significant responsibility to control, manage or direct a legal entity.',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.first_name_label':
    'First Name',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.first_name_hint': ' ',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.last_name_label':
    'Last Name',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.last_name_hint': ' ',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.job_title_label':
    'Title',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.job_title_hint': 'Owner',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.state_label': 'State',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.state_hint': ' ',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.phone_label': 'Phone',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.phone_hint': '111-111-1111',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.phone_invalid':
    'Only numbers and dashes are allowed',

  'onboarding.AccountSetupPaysafe.forms._OwnerForm.percent_ownership_label':
    'Percent of Ownership',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.percent_ownership_hint':
    'Percent of Ownership',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.percent_ownership_invalid':
    'Only numbers are allowed',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.ssn_label': 'SSN',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.ssn_invalid':
    'Must be formatted as all numbers or 000-00-0000',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.ssn_hint': '000-00-0000',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.date_of_birth_label':
    'Date of Birth',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.date_of_birth_hint':
    'MM/DD/YYYY',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.date_of_birth_invalid':
    'Must be formatted as MM/DD/YYYY',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.street_label': 'Line 1',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.street_hint': ' ',
  'onboarding.AccountSetupPaysafe.forms._OwnerForm.additional_header':
    'Other Owner Information',
  'onboarding.AccountSetupPaysafe.fields._AddressField.street_label': 'Street',
  'onboarding.AccountSetupPaysafe.fields._AddressField.street_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._AddressField.street2_label':
    'Street 2',
  'onboarding.AccountSetupPaysafe.fields._AddressField.street2_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._AddressField.city_label': 'City',
  'onboarding.AccountSetupPaysafe.fields._AddressField.city_hint': ' ',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.additional_header':
    'Other Owner Information',
  'onboarding.AccountSetupPaysafe.steps._IdentityStep.add_owner':
    'Add Owner',

  'onboarding.AccountSetupPaysafe.fields._AddressField.state_label': 'State',
  'onboarding.AccountSetupPaysafe.fields._AddressField.state_hint':
    'Select a State',
  'onboarding.AccountSetupPaysafe.fields._AddressField.zip_label': 'Zip Code',
  'onboarding.AccountSetupPaysafe.fields._AddressField.zip_hint': ' ',
  'onboarding.AccountSetupPaysafe.fields._AddressField.zip_invalid':
    'Must be formatted as 12345 or 12345-1234',
  'onboarding.AccountSetupPaysafe.fields._AddressField.business_address_tooltip':
    'This is the address that the business is registered to; the address listed on your tax form.',
  'onboarding.AccountSetupPaysafe.steps._CompleteStep.complete':
    'The application to process credit cards was successfully submitted and is being reviewed. Please check back later.',
  'onboarding.AccountSetupPaysafe.steps._CompleteStep.bank_account_validation':
    'Before you can receive funds, you must validate your bank account. To do so, a member from UP Payments will contact you for a voided check.',
  'onboarding.AccountSetupPaysafe.steps._CompleteStep.open_dashboard':
    'Return to Dashboard',
  'onboarding.AccountSetupPaysafe.AccountSetupPaysafe.bank_account':
    'Bank Account',
  'onboarding.AccountSetupPaysafe.AccountSetupPaysafe.business':
    'Business Information',
  'onboarding.AccountSetupPaysafe.AccountSetupPaysafe.ownership_information': 'Ownership Information',
  'onboarding.AccountSetupPaysafe.AccountSetupPaysafe.tos': 'Terms of Service',
  'onboarding.AccountSetupPaysafe._Stepper.finish': 'Finish',
  'onboarding.AccountSetupPaysafe._Stepper.next': 'Next',

  'containers.payments.messages.CashMessage.first_session': 'At first session.',
  'containers.payments.messages.CashMessage.payment_due': 'Payment Due',
  'containers.payments.messages.CheckMessage.check_payable_to': 'Make check payable to:',
  'containers.payments.messages.CheckMessage.first_session': 'At first session.',
  'containers.payments.messages.CheckMessage.payment_due': 'Payment Due',
  'containers.payments.messages.PayLaterMessage.payment_due_upon_arrival':
    'Payment due upon arrival.',
  'containers.payments.messages.SavedCardMessage.default_card': 'Default',
  'containers.payments.messages.WillTokenizeMessage.charged_now': 'Charged Now',
  'containers.payments.messages.WillTokenizeMessage.charged_later_today':
    'Charged Later Today',
  'containers.payments.messages.WillTokenizeMessage.membership_monthly': '/month',
  'containers.payments.messages.WillTokenizeMessage.membership_yearly': '/year',
  'containers.payments.messages.WillTokenizeMessage.will_tokenize':
    'This order has a future billable item. After payment is collected, the credit card will be securely stored and the recurring item(s) will be automatically billed.',

  'containers.payments.fields.CardHolderField.card_holder': 'Card Holder',
  'containers.payments.fields.CardHolderField.name': 'Name',
  'containers.payments.fields.CardNumberField.card_number': 'Card Number',
  'containers.payments.fields.CardSelectField.choose_card': 'Choose Card:',
  'containers.payments.fields.CardSelectField.card_method_is_not_allowed':
    'Using a Card is Not Allowed',
  'containers.payments.fields.CardSelectField.new_card': 'New Card',
  'containers.payments.fields.CardSelectField.default': 'Default - ',
  'containers.payments.fields.CardTypeSegment.reader': 'Reader',
  'containers.payments.fields.CardTypeSegment.manual': 'Manual',
  'containers.payments.fields.CvcField.cvc': 'CVC',
  'containers.payments.fields.CvcField.zero': '000',
  'containers.payments.fields.PaymentMethodUsageField.save_card': 'Save Card',
  'containers.payments.fields.PaymentMethodUsageField.save_ach': 'Save Bank',
  'containers.payments.fields.PaymentMethodUsageField.tooltip': 'Client must turn on Staff Access to save during Point of Sale (POS).',
  'containers.payments.fields.ExpiryMonthYearField.expiration_date': 'Exp. Date',
  'containers.payments.fields.ExpiryMonthYearField.mmyy': 'MM / YY',
  'containers.payments.fields.PaymentButton.charge': 'Charge ',
  'containers.payments.fields.PaymentButton.continue': 'Continue',
  'containers.payments.fields.PaymentButton.purchase': 'Purchase',
  'containers.payments.fields.PaymentMethodSegment.payment_method': 'Payment Method',
  'containers.payments.fields.PaymentMethodSegment.card': 'Card',
  'containers.payments.fields.PaymentMethodSegment.default_card': 'Default Card',
  'containers.payments.fields.PaymentMethodSegment.new_card': 'New Card',
  'containers.payments.fields.PaymentMethodSegment.card_not_present': 'Credit Card',
  'containers.payments.fields.PaymentMethodSegment.saved_card': 'Saved Card',
  'containers.payments.fields.PaymentMethodSegment.card_present': 'Reader',
  'containers.payments.fields.PaymentMethodSegment.cash': 'Cash',
  'containers.payments.fields.PaymentMethodSegment.check': 'Check',
  'containers.payments.fields.PaymentMethodSegment.pay_later': 'Pay Later',
  'containers.payments.fields.PaymentMethodSegment.bank': 'Bank',
  'containers.payments.fields.PaymentMethodSegment.waive': 'Waive',
  'containers.payments.fields.ZipCodeField.zeros': '00000',
  'containers.payments.fields.ZipCodeField.zipcode': 'Zipcode',
  'containers.payments.fields.ACHSelectField.new': 'New Bank',
  'containers.payments.fields.ACHSelectField.choose': 'Choose Bank',

  'containers.payments.messages.CardPresentProgress.card_present_prompt':
    'Insert or Swipe Card',
  'containers.payments.messages.CardPresentProgress.card_present_remove':
    'Please Remove Card',
  'containers.payments.messages.CardPresentProgress.card_present_auth':
    'Authenticating Device',
  'containers.payments.messages.CardPresentProgress.card_present_payment_code':
    'Select Credit or Debit',
  'containers.payments.messages.CardPresentProgress.card_present_processing':
    'Processing Data',
  'containers.payments.messages.CardPresentProgress.card_present_updating':
    'Updating Device Firmware',
  'containers.payments.messages.CardPresentProgress.card_present_signature':
    'Waiting for Signature',
  'containers.payments.messages.CardPresentProgress.card_present_waiting':
    'Waiting',
  'containers.payments.forms.CardPresentWrapper.device': 'Device',
  'containers.payments.forms.CardPresentWrapper.no_device': 'No Device Connected',
  'containers.payments.forms.CardPresentWrapper.timeout':
    'Timeout Connecting to Device',
  'containers.payments.forms.CardPresentWrapper.scan': 'Scan',
  'containers.payments.forms.CardPresentWrapper.update_reader': 'Update Reader',
  'containers.payments.forms.CardPresentWrapper.charge': 'Charge',

  'containers.payments.forms.PaymentCardForm.credit_card': 'Credit Card',

  'containers.payments.forms.PaymentCashForm.toggle_change': 'Use Change Calculator',
  'containers.payments.forms.PaymentCashForm.amount_paid_label': 'Amount Paid (optional)',
  'containers.payments.forms.PaymentCashForm.amount_paid_error': 'Amount paid is too low.',
  'containers.payments.forms.PaymentCashForm.change_due_label': 'Change Due',

  'containers.payments.forms.PaysafeCardForm.card_number': 'Card Number',
  'containers.payments.forms.PaysafeCardForm.expiration_date_placeholder': 'MM / YY',
  'containers.payments.forms.PaysafeCardForm.zero': '000',
  'containers.payments.forms.PaysafeCardForm.card_info': 'Card Info',
  'containers.payments.forms.PaysafeCardForm.card_details': 'Billing Details',
  'containers.payments.forms.PaysafeCardForm.street_address': 'Street Address',
  'containers.payments.forms.PaysafeCardForm.city': 'City',
  'containers.payments.forms.PaysafeCardForm.state': 'State',
  'containers.payments.forms.PaysafeCardForm.address_line_2': 'Address Line 2',

  'containers.payments.forms.AccountCreditForm.apply': 'Apply Account Credit',
  'containers.payments.forms.AccountCreditForm.remove': 'Remove Credit',
  'containers.payments.forms.AccountCreditForm.account_credit': 'Account Credit',
  'containers.payments.forms.AccountCreditForm.amount_available': 'Amount Available:',
  'containers.payments.forms.AccountCreditForm.apply_btn': 'Apply Credit',

  'containers.payments.forms.PaymentACHForm.account_holder_name': 'Account Holder Name',
  'containers.payments.forms.PaymentACHForm.account_number': 'Account Number',
  'containers.payments.forms.PaymentACHForm.routing_number': 'Routing Number',
  'containers.payments.forms.PaymentACHForm.street': 'Street',
  'containers.payments.forms.PaymentACHForm.city': 'City',
  'containers.payments.forms.PaymentACHForm.zip': 'Zip',

  'containers.payments.Payment.processing_payment': 'Processing Payment',
  'containers.payments.Payment.loading_methods': 'Loading Payment Methods',
  'containers.payments.Payment.waive_next': 'Next',

  'containers.payments.PaymentForm.continue': 'Continue',
  'containers.payments.PaymentForm.purchase': 'Purchase',
  'point_of_sale.ProRateMembership.bill_date': 'Bill Date:',
  'point_of_sale.ProRateMembership.memberships': 'Memberships:',
  'point_of_sale.ProRateMembership.due_today': 'Due Today:',
  'point_of_sale.ProRateMembership.continue_to_cart': 'Continue To Cart',
  'point_of_sale.ProRateMembership.chargeNow': 'Charge Now',
  'point_of_sale.AddClient.add_client': 'Add Client',
  'point_of_sale.AddClient.add_managed_profile': 'Add Managed Profile',
  'point_of_sale.AddClient.client_exists_message':
    "Tap 'Continue' to add this client or 'Cancel' to use another email address.",
  'point_of_sale.AddClient.client_archived_message':
    "This client is currently archived.  To reactivate the client account click 'Unarchive' below and continue with your transaction.",
  'point_of_sale.AddClient.date_of_birth': 'DOB: {date}',
  'point_of_sale.AddClient.date_of_birth_missing': 'DOB: None provided',
  'point_of_sale.AddClient.more_information_instructions':
    'To continue, fill out the required fields below.',
  'point_of_sale.AddClient.new_client': 'New Client',
  'point_of_sale.AddClient.profiles': 'Profiles',
  'point_of_sale.AddClient.save_and_continue': 'Save & Continue',

  'point_of_sale.CartBar.empty_cart_message': 'Empty',
  'point_of_sale.CartBar.n_variant':
    '{n} {n, plural, one {product} other {products}}',
  'point_of_sale.CartBar.n_credit_pass_package':
    '{n} {n, plural, one {credit pass} other {credit passes}}',
  'point_of_sale.CartBar.n_membership_subscription_package':
    '{n} {n, plural, one {membership subscription} other {membership subscriptions}}',
  'point_of_sale.CartBar.n_registration_package': `{n} {n, plural, one {${nouns.event
    }} other {${nouns.event}s}}`,

  'point_of_sale.CartSummary.add_product': `Add Product`,

  'point_of_sale.Checkout.close': 'Close',
  'point_of_sale.Checkout.embed_warning_modal_title': 'Embed card warning',
  'point_of_sale.Checkout.embed_warning_modal_confirm_label': 'OK',

  'point_of_sale.EventScheduling.add_to_cart': 'Add To Cart',
  'point_of_sale.EventScheduling.event_is_full': `${nouns.Event} is full`,
  'point_of_sale.EventScheduling.remove_booking_confirmation':
    'Are you sure? You will remove {clientName} from this scheduled session. They will not be notified of this cancellation.',
  'point_of_sale.EventScheduling.credit_refunded':
    '{clientName} will be refunded the credit for future use.',
  'point_of_sale.EventScheduling.credit_expired':
    'Unfortunately credit expired on {expDate} and {clientName} will not be refunded the credit for future use.',
  'point_of_sale.EventScheduling.sessions_available': 'Sessions Available',
  'point_of_sale.EventScheduling.scheduled': 'Scheduled',
  'point_of_sale.EventScheduling.schedule': 'Schedule',
  'point_of_sale.EventScheduling.select_package_label': 'Select Package',
  'point_of_sale.EventScheduling.total_label': 'Total',
  'point_of_sale.EventScheduling.unscheduled_session_count': '{n}',
  'point_of_sale.EventScheduling.unscheduled_session': 'Schedule Remaining',
  'point_of_sale.EventScheduling.book_success': 'Session(s) booked successfully.',
  'point_of_sale._NoClientCredits.credits_needed_header': 'Credits Needed!',
  'point_of_sale._NoClientCredits.credits_needed_text':
    `Credits are needed to schedule this class. Purchase a Credit Pass or ${nouns.Membership}s to continue.`,
  'point_of_sale._NoClientCredits.view_passes': 'View Passes',
  'point_of_sale._NoClientCredits.view_memberships': `View ${nouns.Membership}s`,

  'point_of_sale.MembershipCommitment.commitment_months':
    '{months} mo. commitment',

  'point_of_sale.MembershipPrice.price_with_join_fee':
    '{price}{interval} + {joinFee}',
  'point_of_sale.MembershipPrice.free': 'Free',
  'point_of_sale.MembershipPrice.price': '{price}{interval}',
  'point_of_sale.MembershipPrice.pricing_annually': '/y',
  'point_of_sale.MembershipPrice.pricing_monthly': '/m',
  'point_of_sale.MembershipPrice.pricing_once': ' (once)',
  'point_of_sale.MembershipPrice.tiers_count': '{count} Tiered Pricing {count, plural, one {Option} other {Options}}',

  'point_of_sale.MembershipRegistering.benefits': 'Benefits:',
  'point_of_sale.MembershipRegistering.membership': `${nouns.Membership}`,
  'point_of_sale.MembershipRegistering.for_whom': 'Select Profile',

  'point_of_sale.CreditPassRegistering.benefits': 'Benefits:',
  'point_of_sale.CreditPassRegistering.credit_pass': 'Pass',
  'point_of_sale.CreditPassRegistering.choose_profile': 'Choose Profile',
  'point_of_sale.CreditPassRegistering.for_whom':
    'For whom would you like to purchase this pass?',
  'point_of_sale.CreditPassRegistering.Purchased': 'Purchased',
  'point_of_sale.CreditPassRegistering.PurchasedTooltip': 'User has met the credit pass purchase limit',


  'point_of_sale.CreditPassBenefits.quantity':
    '{value, select, Infinity {Unlimited} other {{value}} } sessions per month:',
  'point_of_sale.CreditPassBenefits.any_event': `Any ${nouns.Event}`,

  'point_of_sale.CreditPassExpiration.never': 'No expiration',
  'point_of_sale.CreditPassExpiration.from_purchase':
    '{days} day(s) from purchase',
  'point_of_sale.CreditPassExpiration.from_first_use':
    '{days} day(s) from first use',
  'point_of_sale.CreditPassExpiration.on_date': 'Expires {date}',

  'point_of_sale.products.list.ProductList.clear_filters': 'Clear Filters',
  'point_of_sale.products.list.ProductList.search_hint_text': 'Search',
  'point_of_sale.products.list.ProductList.price_range': '{min} - {max}',
  'point_of_sale.products.list.cards._CreditPassCard.total_credits':
    ' • {total, select, Infinity {Unlimited} other {{total}} } Credits',

  'point_of_sale.products.list.cards._RetailProductCard.price_range':
    '{min} - {max}',

  'point_of_sale.ProductView.description': 'Description',
  'point_of_sale.ProductView.quantity': 'Quantity',
  'point_of_sale.ProductView.price_range': '{min} - {max}',
  'point_of_sale.ProductView.swing_card': 'Swing Card',
  'point_of_sale.ProductView.swing_card_placeholder': 'Card Number',
  'point_of_sale.ProductView.swing_card_error': 'Swing card number should be 19 characters long',
  'point_of_sale.ProductView.profile_required': 'Required field',
  'point_of_sale.ProductView.profile': 'Profile',

  'point_of_sale.ReceiptDialog.print_receipt': 'Print Receipt',
  'point_of_sale.ReceiptDialog.printing': 'Printing',

  'quickpay.QuickpayModal.card': 'Card',
  'quickpay.QuickpayModal.cash': 'Cash',
  'quickpay.QuickpayModal.check': 'Check',
  'quickpay.QuickpayModal.loading': 'Loading...',
  'quickpay.QuickpayModal.pay_balance': 'Pay Balance',
  'quickpay.QuickpayModal.payment_method': 'Pay Method',
  'quickpay.QuickpayModal.processing_order': 'Processing Order',
  'quickpay.QuickpayModal.unpaid': 'Unpaid',
  'quickpay.QuickpayModal.add_product_btn': 'Add Product',
  'quickpay.QuickpayModal.total': 'Total Amount:',
  'quickpay.ItemDetails.due_date': 'Due Date: {formattedDate}',
  'quickpay.AddonProductsModal.title': 'Add product',
  'quickpay.AddonProductsModal.product_selector_placeholder': 'Search product',
  'quickpay.AddonProductsModal.qty': 'Quantity',
  'quickpay.AddonProductsModal.add_btn': 'Add to Cart',
  'quickpay.AddonProductsModal.cancel_btn': 'Cancel',
  'quickpay.AddonProductsModal.amount': 'Amount',

  'records.errors.AccountingCode.event_type_ids_present_with_invalid_type': `Cannot associate ${nouns.event
    } types to a non ${nouns.event} accounting code.`,
  'records.errors.AccountingCode.retail_category_ids_present_with_invalid_type':
    'Cannot associate retail categories to a non retail accounting code.',
    'records.errors.AccountingCode.team_type_ids_present_with_invalid_type':
    'Cannot associate team type to a non team accounting code.',

  'records.errors.below_stripe_minimum': 'Must be at least $0.50.',
  'records.errors.required': 'Required',
  'records.errors.number': 'Must be in digits',
  'records.errors.invalid': 'Invalid',
  'records.errors.buyer_invalid_age':
    'Buyer must be over the age of 13. Participants under the age of 13 can be added as a profile.',
  'records.errors.date_in_future': 'Date should be in past.',
  'records.errors.date_invalid': 'Invalid format. Must be MM/DD/YYYY',
  'records.errors.time_invalid': 'Invalid format. Must be hh:mm A',
  'records.errors.account_creation_invalid_age': `You must be over the age of 13 to create an account.  If you're registering a minor for an ${nouns.event
    }, please use your information here.  You will enter their information after hitting "Continue"`,
  'records.errors.cant_be_more_than_100': 'must be between 0.0 and 100.0',
  'records.errors.cant_be_more_than_item_price': 'cannot exceed item price',
  'records.errors.cant_be_before_start_date': 'Must be after Clock In',

  'records.PaysafeUBO.errors.invalid_dob_format':
    'Invalid format. Must be MM/DD/YYYY',
  'records.PaysafeUBO.errors.invalid_ssn_format':
    'Invalid format. Must be 9 digits',
  'records.PaysafeUBO.errors.invalid_zip_format':
    'Invalid format. Must be 12345 or 12345-1234',

  'records.MembershipEventDiscount.errors.no_event_types_when_all_events':
    'may not be true when event_type_ids are present',
  'records.MembershipEventDiscount.errors.value_out_of_range':
    'must be between 0.0 and 100.0',
    'records.MembershipEventDiscount.errors.membership_price_validation':
    'Discount must be greater than 0',

    'records.MembershipCreditPassDiscount.errors.no_credit_passes_when_all_credit_passes':
    'may not be true when credit_pass_ids are present',
  'records.MembershipCreditPassDiscount.errors.value_out_of_range':
    'must be between 0.0 and 100.0',
    'records.MembershipCreditPassDiscount.errors.membership_price_validation':
    'Discount must be greater than 0',

  'records.MembershipEventCredit.errors.no_event_types_or_events_when_all_events':
    'may not be true when event_type_ids or event_ids are present',
  'records.MembershipEventCredit.errors.one_type_should_be_selected':
    'One type should selected',
  'records.MembershipEventCredit.errors.at_least_one_event_type_should_be_selected': `At least one ${nouns.event
    } type should be selected`,

  'records.MembershipEventCredit.errors.at_least_one_event_should_be_selected': `At least one ${nouns.event
    } should be selected`,
  'records.MembershipEventCredit.errors.quantity_should_be_positive':
    'Should be > 0',

  'records.CreditPass.errors.max_num_of_purchases_should_be_positive':
    'Should be >= 1',

  'records.CreditPassCredit.errors.no_event_types_or_events_when_all_events':
    'may not be true when event_type_ids or event_ids are present',
  'records.CreditPassCredit.errors.one_type_should_be_selected':
    'One type should selected',
  'records.CreditPassCredit.errors.at_least_one_event_type_should_be_selected':
    'At least one event type should be selected',
  'records.CreditPassCredit.errors.at_least_one_event_should_be_selected':
    'At least one event should be selected',
  'records.CreditPassCredit.errors.quantity_should_be_positive':
    'Should be > 0',

  'records.MembershipRetailDiscount.errors.no_retail_categories_when_all_products':
    'may not be true when retail_category_ids are present',
  'records.MembershipRetailDiscount.errors.value_out_of_range':
    'must be between 0.0 and 100.0',

  'records.EventRate.errors.event_must_be_selected': `At least one ${nouns.event
    } must be selected from search list`,
  'records.EventRate.errors.value_out_of_range': 'Incorrect value',
  'records.EventRate.errors.more_than_zero': 'Must be more that 0',

'records.CommissionRate.errors.value_out_of_range': 'Incorrect value',
'records.CommissionRate.errors.more_than_zero': 'Must be more that 0',
'records.CommissionRate.errors.commission_must_be_selected': 'At least one commission item must be selected from search list',

  'records.PayrollEntry.errors.event_must_be_selected': `At least one ${nouns.event
    } must be selected from search list`,
  'records.PayrollEntry.errors.value_out_of_range': 'Incorrect value',
  'records.PayrollEntry.errors.more_than_zero': 'Must be more than 0',

  'records.Variant.errors.gtin.invalid': 'Invalid code',

  'records.PurchaseOrder.errors.purchase_order_items': 'Incorrect values',
  'records.PurchaseOrder.errors.order_date': 'Should be present',
  'records.PurchaseOrderItem.errors.notEmpty': 'Must be greater than 0',

  'reporting.index._ContactsReport.header': 'Contacts Report',
  'reporting.index._ContactsFilters.title': 'Filters',
  'reporting.index._ContactsFilters.neverLoggedIn': 'Never logged in',
  'reporting.index._ContactsFilters.apply': 'Apply',
  'reporting.index._ContactsFilters.applying': 'Applying',
  'reporting.index._ContactsFilters.clear': 'Clear',
  'reporting.index._ContactsFilters.franchise_location': 'Franchise Location',
  'reporting.index._ContactsFilters.lastLoginAtAfter': 'Last logged in after',
  'reporting.index._ContactsFilters.lastLoginAtBefore': 'Last logged in before',
  'reporting.index._ContactsFilters.lastLoginAtBeforePlaceholder': '(any)',
  'reporting.index._ContactsFilters.lastLoginAtAfterPlaceholder': '(any)',

  'reporting.index._ContactsList.title': 'Contacts List',
  'reporting.index._ContactsList.first_name': 'First Name',
  'reporting.index._ContactsList.last_name': 'Last Name',
  'reporting.index._ContactsList.email': 'Email',
  'reporting.index._ContactsList.phone_number': 'Phone Number',
  'reporting.index._ContactsList.last_login_date': 'Last Login Date',
  'reporting.index._ContactsList.date_of_birth': 'Date Of Birth',
  'reporting.index._ContactsList.added_date': 'Added Date',
  'reporting.index._ContactsMap.title': 'Contact Locations',

  'reporting.index.shared.ReportWidget.loading': 'Loading...',

  'reporting.payroll._Filters.attendee': 'Attendee',
  'reporting.payroll._Filters.clear_filters': 'Clear Filters',
  'reporting.payroll._Filters.item': 'Item',
  'reporting.payroll._Filters.name': 'Search Attendees',
  'reporting.payroll._Filters.none_available': 'None available.',
  'reporting.payroll._Filters.paid': 'Paid',
  'reporting.payroll._Filters.unpaid': 'Unpaid',
  'reporting.payroll._Filters.btn_filter': 'Filters',
  'reporting.payroll._Filters.btn_export': 'Export',

  'reporting.payroll.PayrollReport.active': 'ACTIVE',
  'reporting.payroll.PayrollReport.dash': '-',
  'reporting.payroll.PayrollReport.in': 'IN',
  'reporting.payroll.PayrollReport.log_hourly_rate': '{rate} / h',
  'reporting.payroll.PayrollReport.n_hours': '{n} h',
  'reporting.payroll.PayrollReport.out': 'OUT',
  'reporting.payroll.PayrollReport.payroll': 'Payroll',
  'reporting.payroll.PayrollReport.add_entry': 'Add Entry',
  'reporting.payroll.PayrollReport.add_staff': 'Add Staff',
  'reporting.payroll.PayrollReport.add_task': 'Add Task',
  'reporting.payroll.PayrollReport.staff_hourly_rate': '({rate}/h)',
  'reporting.payroll.PayrollReport.unpaid_and_total':
    '{unpaid} Unpaid / {total} Total',
  'reporting.payroll.PayrollReport.paid_and_total': '{paid} Paid / {total} Total',
  'reporting.payroll.PayrollReport.total_estimated_payment':
    'Total / Estimated Payment*',
  'reporting.payroll.PayrollReport.empty_state_header': 'No entries found.',
  'reporting.payroll.PayrollReport.empty_state_message':
    'Matching employee time entries will appear here.',
  'reporting.payroll.PayrollReport.missing_data_header': 'A bit empty?',
  'reporting.payroll.PayrollReport.missing_data_message':
    'Add staff or task to activate payroll tracking',
  'reporting.payroll.PayrollReport.remove_timelog': 'Remove Time Log',
  'reporting.payroll.PayrollReport.are_you_sure':
    'Are you sure you want to remove the time log?',
  'reporting.payroll.PayrollReport.attendees': '{n} Attendees',
  'reporting.payroll.PayrollReport.btn_export': 'Export',
  'reporting.payroll.PayrollReport.task': 'Task',
  'reporting.payroll.PayrollReport.commission': 'Commission',
  'reporting.payroll.PayrollReport.add_commission': 'Add Commission',
  'reporting.payroll.PayrollReport.paid': 'Paid',
  'reporting.payroll.PayrollReport.unpaid': 'Unpaid',
  'reporting.payroll.PayrollReport.select': 'Select',


  'reporting.payroll.stores.TimeLogEditingStore.updated':
    'Time log was successfully updated',
  'reporting.payroll.stores.TimeLogCreationStore.created':
    'Time log was successfully created',
  'reporting.payroll.stores.TimeLogDeletionStore.deleted':
    'Time log was successfully removed',

  'reporting.payroll.stores.PayrollEntryEditingStore.updated': `${nouns.Event
    } payroll entry was successfully updated`,

  'reporting.payroll.stores.Commissions.CommissionsEditingStore.updated': 'Commission payroll entry was successfully updated',
  'reporting.payroll.stores.Commissions.CommissionsCreationStore.created': 'Commission payroll entry was successfully created',

  'reporting.payroll.PayrollEntryModal.edit_entry': 'Edit Entry',
  'reporting.payroll.PayrollEntryModal.save': 'Save',
  'reporting.payroll.PayrollEntryModal.cancel': 'Cancel',

  'reporting.payroll.PayrollEntryModal.start_time': 'Start Time',
  'reporting.payroll.PayrollEntryModal.duration': 'Duration',
  'reporting.payroll.PayrollEntryModal.choose_type': 'Choose Type',
  'reporting.payroll.PayrollEntryModal.type': 'Value Type',
  'reporting.payroll.PayrollEntryModal.amount': 'Amount',
  'reporting.payroll.PayrollEntryModal.percent': 'Percent',
  'reporting.payroll.PayrollEntryModal.value': 'Value',
  'reporting.payroll.PayrollEntryModal.event_name': `${nouns.Event} Name`,
  'reporting.payroll.PayrollEntryModal.event_type_name': `${nouns.Event
    } Type`,

  'reporting.payroll.TimeLogModal.add_entry': 'Add Entry',
  'reporting.payroll.TimeLogModal.edit_entry': 'Edit Entry',
  'reporting.payroll.TimeLogModal.add': 'Add',
  'reporting.payroll.TimeLogModal.save': 'Save',
  'reporting.payroll.TimeLogModal.cancel': 'Cancel',

  'reporting.payroll.TimeLogModal.search_staff': 'Search staff (Auto-complete)',
  'reporting.payroll.TimeLogModal.date': 'Date',
  'reporting.payroll.TimeLogModal.select_date': 'Select Date',
  'reporting.payroll.TimeLogModal.task': 'Task',
  'reporting.payroll.TimeLogModal.choose_task': 'Choose Task',
  'reporting.payroll.TimeLogModal.location': 'Location',
  'reporting.payroll.TimeLogModal.choose_location': 'Choose Location',
  'reporting.payroll.TimeLogModal.clock_in': 'Clock In',
  'reporting.payroll.TimeLogModal.clock_out': 'Clock Out',
  'reporting.payroll.TimeLogModal.hourly_rate': 'Hourly Rate',
  'reporting.payroll.TimeLogModal.total': 'Total Hours',
  'reporting.payroll.TimeLogModal.total_hint': 'Total Hours',

  'reporting.payroll.PayrollCommissionsEntryModal.add_entry': 'Add Entry',
  'reporting.payroll.PayrollCommissionsEntryModal.edit_entry': 'Edit Entry',
  'reporting.payroll.PayrollCommissionsEntryModal.add': 'Add',
  'reporting.payroll.PayrollCommissionsEntryModal.save': 'Save',
  'reporting.payroll.PayrollCommissionsEntryModal.cancel': 'Cancel',
  'reporting.payroll.PayrollCommissionsEntryModal.search_staff': 'Search staff (Auto-complete)',
  'reporting.payroll.PayrollCommissionsEntryModal.date': 'Date',
  'reporting.payroll.PayrollCommissionsEntryModal.select_date': 'Select Date',
  'reporting.payroll.PayrollCommissionsEntryModal.pay_type': 'Pay Type',
  'reporting.payroll.PayrollCommissionsEntryModal.choose_pay_type': 'Choose Pay Type',
  'reporting.payroll.PayrollCommissionsEntryModal.item': 'Item',
  'reporting.payroll.PayrollCommissionsEntryModal.choose_item': 'Choose Item',
  'reporting.payroll.PayrollCommissionsEntryModal.rate': 'Rate',
  'reporting.payroll.PayrollCommissionsEntryModal.client': 'Client',
  'reporting.payroll.PayrollCommissionsEntryModal.choose_client': 'Choose Client',

  'reporting.payroll._PayrollFilterDrawer.title': 'Filters',
  'reporting.payroll._PayrollFilterDrawer.clear': 'Clear Filters',
  'reporting.payroll._PayrollFilterDrawer.staff': 'Staff',
  'reporting.payroll._PayrollFilterDrawer.payout_types': 'Payout Types',
  'reporting.payroll._PayrollFilterDrawer.commission_types': 'Commission Types',
  'reporting.payroll._PayrollFilterDrawer.event_types': 'Event Types',
  'reporting.payroll._PayrollFilterDrawer.payment_status': 'Payment Status',

  'reporting.revenue._RevenueReport.header': 'Revenue Report',

  'reporting.revenue._CumulativeTransactionsByMonth.title':
    'Cumulative Transactions by Month',
  'reporting.revenue._TransactionsByMonth.title': 'Transactions by Month',
  'reporting.revenue._CumulativeRevenueByMonth.title':
    'Cumulative Revenue by Month',
  'reporting.revenue._RevenueByMonth.title': 'Revenue by Month',
  'reporting.revenue._RevenueYearOverYear.title': 'Revenue YoY',
  'reporting.revenue._TransactionsYearOverYear.title': 'Transactions YoY',

  'reporting.revenue._RevenueFilters.title': 'Filters',
  'reporting.revenue._RevenueFilters.paymentStatus': 'Payment Status',
  'reporting.revenue._RevenueFilters.paymentStatusPlaceholder': '(any)',
  'reporting.revenue._RevenueFilters.franchise_location': 'Franchise Location',
  'reporting.revenue._RevenueFilters.buyer': 'Buyer',
  'reporting.revenue._RevenueFilters.client': 'Client',
  'reporting.revenue._RevenueFilters.product': 'Product',
  'reporting.revenue._RevenueFilters.productType': 'Product Type',
  'reporting.revenue._RevenueFilters.startDate': 'Start Date',
  'reporting.revenue._RevenueFilters.startDatePlaceholder': 'MM/DD/YYYY',
  'reporting.revenue._RevenueFilters.endDate': 'End Date',
  'reporting.revenue._RevenueFilters.endDatePlaceholder': 'MM/DD/YYYY',
  'reporting.revenue._RevenueFilters.apply': 'Apply',
  'reporting.revenue._RevenueFilters.applying': 'Applying...',
  'reporting.revenue._RevenueFilters.clear': 'Clear',

  'reporting.revenue._CumulativeRevenueByMonth.dateFormat': 'MMM YY',
  'reporting.revenue._CumulativeTransactionsByMonth.dateFormat': 'MMM YY',
  'reporting.revenue._RevenueByMonth.dateFormat': 'MMM YY',
  'reporting.revenue._TransactionsByMonth.dateFormat': 'MMM YY',

  'reporting.transactions.Refund.success': 'Transaction refunded successfully',

  'reporting.transactions._TransactionsList.accounting_code': 'Accounting Code',
  'reporting.transactions._TransactionsList.buyer': 'Buyer',
  'reporting.transactions._TransactionsList.client_profile': 'Client Profile',
  'reporting.transactions._TransactionsList.coupon_code': 'Coupon Code',
  'reporting.transactions._TransactionsList.discounts': 'Discounts',
  'reporting.transactions._TransactionsList.event_start_date': `${nouns.Event
    } Date`,
  'reporting.transactions._TransactionsList.price': 'Price',
  'reporting.transactions._TransactionsList.product': 'Product',
  'reporting.transactions._TransactionsList.product_type': 'Product Type',
  'reporting.transactions._TransactionsList.quantity': 'Quantity',
  'reporting.transactions._TransactionsList.staff': 'Staff',
  'reporting.transactions._TransactionsList.tax': 'Tax',
  'reporting.transactions._TransactionsList.title': 'Transactions',
  'reporting.transactions._TransactionsList.refunded_amount': 'Refunded Amount',
  'reporting.transactions._TransactionsList.total_earnings': 'Total Earnings',
  'reporting.transactions._TransactionsList.payment_type': 'Payment Type',
  'reporting.transactions._TransactionsList.payment_status': 'Payment Status',
  'reporting.transactions._TransactionsList.refund': 'Refund',
  'reporting.transactions._TransactionsList.send_receipt': 'Send Receipt',
  'reporting.transactions._TransactionsList.transaction_date':
    'Transaction Date',

  'reporting.transactions._TransactionsFilters.title': 'Filters',
  'reporting.transactions._TransactionsFilters.paymentStatus': 'Payment Status',
  'reporting.transactions._TransactionsFilters.paymentStatusPlaceholder':
    '(any)',
  'reporting.transactions._TransactionsFilters.franchise_location':
    'Franchise Location',
  'reporting.transactions._TransactionsFilters.buyer': 'Buyer',
  'reporting.transactions._TransactionsFilters.client': 'Client',
  'reporting.transactions._TransactionsFilters.product': 'Product',
  'reporting.transactions._TransactionsFilters.productType': 'Product Type',
  'reporting.transactions._TransactionsFilters.startDate': 'Start Date',
  'reporting.transactions._TransactionsFilters.startDatePlaceholder': '(any)',
  'reporting.transactions._TransactionsFilters.endDate': 'End Date',
  'reporting.transactions._TransactionsFilters.endDatePlaceholder': '(any)',
  'reporting.transactions._TransactionsFilters.apply': 'Apply',
  'reporting.transactions._TransactionsFilters.applying': 'Applying...',
  'reporting.transactions._TransactionsFilters.clear': 'Clear',

  'reporting.transactions._ReceiptConfirmationModal.title': 'Send receipt',
  'reporting.transactions._ReceiptConfirmationModal.body': 'Are you sure you want to re-send the email receipt to {clientName}?',
  'reporting.transactions._ReceiptConfirmationModal.confirm': 'Yes',
  'reporting.transactions._ReceiptConfirmationModal.cancel': 'Cancel',
  'reporting.transactions._ReceiptConfirmationModal.success': 'Receipt was successfully sent',

  'reporting.transactions._FranchiseLocationsFilter.all_location':
    'All Locations',

  'reporting.monthly_recurring_revenue.EmptyState.btn': 'View Memberships',
  'reporting.monthly_recurring_revenue.EmptyState.description': 'Enroll clients to see membership analytics!',
  'reporting.monthly_recurring_revenue.EmptyState.title': 'No membership data.',

  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueAnalytics.avg_length_of_membership': 'Average Length of Membership',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueAnalytics.member_vs_non_member_spending': 'Member vs Non-Member Spending',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueAnalytics.n_months': '{n} {n, plural, one {Month} other {Months}}',

  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.dateFormat': 'MMM Do YYYY h:mm a',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.dateFormatDays': 'MMM Do YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.dateFormatMonths': 'MMMM YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.dateFormatQuarters': 'Qo [Quarter] of YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.dateFormatWeeks': 'MMM Do YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.dateFormatYears': 'MMMM YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueDetails.export_details': 'Export Details',

  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueFilters.export_report': 'Export Report',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueFilters.time_span': 'Time Span',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueFilters.membership_type': 'Membership Type',

  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueHeader.header': 'Membership Analytics',

  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Clients': 'Total ending number of clients tied to a membership.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Cost of Cancellations': 'Sum of all charges for the clients who cancelled their membership.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Cost of Suspensions': 'Sum of the monthly membership charges for clients who suspended their membership.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.dateFormatDays': 'MMM Do YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.dateFormatMonths': 'MMM YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.dateFormatQuarters': '[Q]Q YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.dateFormatWeeks': 'MMM Do YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.dateFormatYears': 'MMM YYYY',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Expansions / Contractions': 'Total amount of increase or decrease in revenue from price changes.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.MRR': 'MRR: Total amount of revenue generated by a membership per month.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Net MRR Change': 'Difference of current MRR & previous MRR.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.New Members Revenue': 'Sum of revenue from all new clients with no membership history.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Percentage Change': 'Month Over Month change percentage between MRR.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueMetrics.Reactivation Revenue': 'Sum of all clients who have reactivated their cancelled or suspended membership.',

  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.dateFormatDays': 'MMM Do',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.dateFormatMonths': 'MMM',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.dateFormatQuarters': '[Q]Q',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.dateFormatWeeks': 'MMM Do',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.dateFormatYears': 'MMM',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_calculation_body': 'With monthly plans the MRR is simply the price paid each month for the membership. If clients are paying for more than one month up front (e.g. 12 months) Upper Hand simply divides the amount paid for the membership by the number of months in the subscription period.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_calculation_header': 'Calculation:',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_description_body': 'The most important metric a membership business needs to calculate. MRR is a calculation of your monthly membership revenue.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_description_header': 'Description:',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_note_header': 'Note:',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_note_one_time_charges': 'One-time payments and metered charges are excluded.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_note_refunds_waives': '  Refunds, Waives and Free Memberships are excluded.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_note_discounts': 'Discounts/coupons are deducted prior to calculation.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.tooltip_note_fees': 'Credit card and billing system transaction fees are not deducted.',
  'reporting.monthly_recurring_revenue.MonthlyRecurringRevenueByPeriod.title': 'Monthly Recurring Revenue (MRR)',


  'reporting.sales_detail.SalesDetail.sales_detail': 'Sales Details Report',
  'reporting.sales_detail._SalesFilters.reset_filters': 'Reset Filters',

  'reporting.sales_detail.SalesDetail.PaymentTypeTable.payment_type':
    'Payment Type',
  'reporting.sales_detail.SalesDetail.PaymentTypeTable.receipts': 'Receipts',
  'reporting.sales_detail.SalesDetail.PaymentTypeTable.tax': 'Tax',
  'reporting.sales_detail.SalesDetail.PaymentTypeTable.returns': 'Returns',
  'reporting.sales_detail.SalesDetail.PaymentTypeTable.returns_tax':
    'Returns Tax',
  'reporting.sales_detail.SalesDetail.PaymentTypeTable.total': 'Total',

  'reporting.sales_detail.SalesDetail.ProductTypeTable.product_type':
    'Product Type',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.sales': 'Receipts',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.tax': 'Tax',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.qty': 'Qty.',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.returns': 'Returns',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.returns_qty':
    'Returns Qty.',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.returns_tax':
    'Returns Tax',
  'reporting.sales_detail.SalesDetail.ProductTypeTable.total': 'Total',

  'reporting.sales_detail.SalesDetail.RetailCategoryTable.retail_category':
    'Retail Category',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.sales': 'Sales',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.tax': 'Tax',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.qty': 'Qty.',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.returns': 'Returns',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.returns_qty':
    'Returns Qty.',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.returns_tax':
    'Returns Tax',
  'reporting.sales_detail.SalesDetail.RetailCategoryTable.total': 'Total',

  'reporting.sales_detail.SalesDetail.OnlineInStoreTable.payment_type':
    'Payment Type',
  'reporting.sales_detail.SalesDetail.OnlineInStoreTable.sales': 'Receipts',
  'reporting.sales_detail.SalesDetail.OnlineInStoreTable.tax': 'Tax',
  'reporting.sales_detail.SalesDetail.OnlineInStoreTable.returns': 'Returns',
  'reporting.sales_detail.SalesDetail.OnlineInStoreTable.returns_tax':
    'Returns Tax',
  'reporting.sales_detail.SalesDetail.OnlineInStoreTable.total': 'Total',

  'reporting.sales_detail._SalesFilters.time_span': 'Time Span',
  'reporting.sales_detail._SalesFilters.time_span_placeholder': 'Time Span',
  'reporting.sales_detail._SalesFilters.startDate': 'Start Date',
  'reporting.sales_detail._SalesFilters.startDatePlaceholder': 'Start Date',
  'reporting.sales_detail._SalesFilters.endDate': 'End Date',
  'reporting.sales_detail._SalesFilters.endDatePlaceholder': 'End Date',

  'reporting.sales_detail.tables.PaymentTypeTable.title':
    'Sales by Payment Type',
  'reporting.sales_detail.tables.ProductTypeTable.title':
    'Sales by Product Type',
  'reporting.sales_detail.tables.RetailCategoryTable.title':
    'Sales by Retail Category',
  'reporting.sales_detail.tables.OnlineInStoreTable.title':
    'Online vs. In-Store Sales',
  'reporting.sales_detail.tables.PaymentTypeTable.subtotal':
    'Cash Equivalent Receipt Totals:',
  'reporting.sales_detail.tables.ProductTypeTable.subtotal':
    'Product Type Totals:',
  'reporting.sales_detail.tables.RetailCategoryTable.subtotal':
    'Retail Category Subtotals:',
  'reporting.sales_detail.tables.OnlineInStoreTable.subtotal':
    'Online vs In-Store Sales Totals:',

  'reporting.waivers.WaiversReport.waivers': 'Waiver Report',
  'reporting.waivers._WaiversBlock.custom_acceptances':
    'Custom Waivers Acceptances',
  'reporting.waivers._WaiversBlock.uh_acceptances':
    'Upper Hand Policy Acceptances',
  'reporting.waivers._WaiversBlock.past_due_acceptances':
    'Past Due Custom Waivers',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.title':
    'Custom Waiver and Upper Hand Policy Report',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.full_name':
    'Full Name',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.last_accepted_custom_waiver':
    'Last Accepted Custom Waivers',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.days_past_due':
    'Days Past Due',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.last_accepted_terms':
    'Last Accepted Terms',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.last_login':
    'Last Login',
  'reporting.waivers._CustomWaiverandUpperHandPolicyReport.no_data_available':
    'No Data Available',
  'reporting.waivers._CustomWaiverCountsforBilling.title':
    'Custom Waivers Count for Billing ({dateRange})',
  'reporting.waivers._CustomWaiverCountsforBilling.name': 'Name',
  'reporting.waivers._CustomWaiverCountsforBilling.count': 'Count',
  'reporting.waivers._CustomWaiverCountsforBilling.no_data_available':
    'Custom Waivers Count for Billing - No Data Available',
  'reporting.waivers._Filters.clear_filters': 'Clear Filters',

  'reporting.time_periods.today': 'Today',
  'reporting.time_periods.yesterday': 'Yesterday',
  'reporting.time_periods.thisMonth': 'This Month',
  'reporting.time_periods.lastMonth': 'Last Month',
  'reporting.time_periods.thisQuarter': 'This Quarter',
  'reporting.time_periods.lastQuarter': 'Last Quarter',
  'reporting.time_periods.thisYear': 'This Year',
  'reporting.time_periods.lastYear': 'Last Year',
  'reporting.time_periods.mtd': 'MTD',
  'reporting.time_periods.qtd': 'QTD',
  'reporting.time_periods.ytd': 'YTD',

  'reporting.payment_statuses.paid': 'Paid',
  'reporting.payment_statuses.unpaid': 'Unpaid',
  'reporting.payment_statuses.pending': 'Pending',
  'reporting.payment_statuses.failed': 'Failed',
  'reporting.payment_statuses.cancelled': 'Cancelled',
  'reporting.payment_statuses.refunded': 'Refunded',
  'reporting.payment_statuses.past_due': 'Past Due',
  'reporting.payment_statuses.partial_refunded': 'Partial Refund',
  'reporting.payment_statuses.other': 'Other',

  'reporting.inventory.Header.title': 'Inventory Report',

  'reporting.inventory.Content.empty_header': 'No data for report',
  'reporting.inventory.Content.empty_message':
    'Matching inventory information will appear here',
  'reporting.inventory.InventoryAdjustmentModal.title': 'Inventory Adjustments',
  'reporting.inventory.InventoryAdjustmentModal.adjustmentInventory': 'Actual Quantity:',
  'reporting.inventory.InventoryAdjustmentModal.adjustmentReason': 'Adjustment Reason (Optional):',
  'reporting.inventory.InventoryAdjustmentModal.confirm': 'Confirm',
  'reporting.inventory.InventoryAdjustmentModal.cancel': 'Cancel',
  'reporting.inventory.InventoryAdjustmentModal.description': 'description',
  'reporting.inventory.InventoryAdjustmentModal.expected_quantity': 'Expected Quantity: ',
  'reporting.inventory.InventoryAdjustmentModal.product': 'Product: ',
  'reporting.inventory.InventoryAdjustmentModal.amount': 'Enter Amount',
  'reporting.inventory.InventoryAdjustmentModal.placeholder': 'Adjustment Reason',
  'reporting.inventory.InventoryAdjustmentModal.damaged': 'Damaged',
  'reporting.inventory.InventoryAdjustmentModal.stolen': 'Stolen',
  'reporting.inventory.InventoryAdjustmentModal.input_error': 'Input Error',

  'reporting.inventory.ContentTable.adjustInventory': 'Adjust Inventory',
  'reporting.inventory.ContentTable.tooltip_live_qty':
    'Current in-store quantity',
  'reporting.inventory.ContentTable.tooltip_total': 'Net profit',
  'reporting.inventory.ContentTable.status.in_stock': 'In stock',
  'reporting.inventory.ContentTable.status.out_of_stock': 'Out of stock',
  'reporting.inventory.ContentTable.status.low_stock': 'Low stock',

  'reporting.inventory.ContentFilters.date': 'Time Range',
  'reporting.inventory.ContentFilters.retail_category': 'Retail Category',
  'reporting.inventory.ContentFilters.retail_vendor': 'Retail Vendor',
  'reporting.inventory.ContentFilters.out_of_stock': 'Out of stock',
  'reporting.inventory.ContentFilters.low_stock': 'Low stock',
  'reporting.inventory.ContentFilters.search':
    'Search (Product / Variant, SKU, Barcode)',
  'reporting.inventory.ContentFilters.btn_export': 'Export',

  'reporting.inventory.ColumnsMenu.all': 'Select All',
  'reporting.inventory.ColumnsMenu.label': 'Product / Variant',

  'reporting.inventory.ExportMenu.all': 'Select All',
  'reporting.inventory.ExportMenu.label': 'Export Report',
  'reporting.inventory.ExportMenu.btn': 'Export',
  'reporting.inventory.stores.InventoryReportStore.column.vendor_name': 'Vendor Name',
  'reporting.inventory.stores.InventoryReportStore.column.sku': 'SKU',
  'reporting.inventory.stores.InventoryReportStore.column.gtin': 'Barcode',
  'reporting.inventory.stores.InventoryReportStore.column.live_qty':
    'Live Quantity',
  'reporting.inventory.stores.InventoryReportStore.column.status': 'Status',
  'reporting.inventory.stores.InventoryReportStore.column.received': 'Quantity',
  'reporting.inventory.stores.InventoryReportStore.column.sold': 'Sold',
  'reporting.inventory.stores.InventoryReportStore.column.returned': 'Returned',
  'reporting.inventory.stores.InventoryReportStore.column.avg_cost':
    'Avg. Cost',
  'reporting.inventory.stores.InventoryReportStore.column.avg_price':
    'Avg. Price',
  'reporting.inventory.stores.InventoryReportStore.column.adjustment':
    'Adjustment',
  'reporting.inventory.stores.InventoryReportStore.column.adjustment_reasons':
    'Adjustment Reasons',
  'reporting.inventory.stores.InventoryReportStore.column.cost':
    'Cost',
    'reporting.inventory.stores.InventoryReportStore.column.price':
    'Price',
  'reporting.inventory.stores.InventoryReportStore.column.total_profit':
    'Total Profits',

  'resources.DeletionConfirmation.are_you_sure':
    'Are you sure you want to remove this resource?',
  'resources.DeletionConfirmation.remove_resource': 'Remove Resource?',

  'resources.ResourceDrawer.create_resource': 'Create Resource',
  'resources.ResourceDrawer.edit_resource': 'Edit Resource',

  'resources.ResourceForm.create_new_resource': 'Create New Resource',
  'resources.ResourceForm.edit_resource': 'Edit Resource',
  'resources.ResourceForm.remove_resource': 'Remove Resource',
  'resources.ResourceForm.resource_name': 'Resource Name',
  'resources.ResourceForm.area_square_feet': 'Square Footage',

  'resources.PreferredStaffSelector.allow_staff_preference': 'Allow staff preference',
  'resources.PreferredStaffSelector.choose_staff': 'Choose staff',
  'resources.PreferredStaffSelector.preferred_by': 'Preferred By',

  'resources.LocationSelector.choose_location': 'Choose location',
  'resources.LocationSelector.link_location': 'Link resource to a location',
  'resources.LocationSelector.resource_location': 'Resource Location',


  'resources.ResourceListing.search': 'Search Resources',
  'resources.ResourceListing.clear_search': 'Clear Search',
  'resources.ResourceListing.create_resource': 'Create Resource',
  'resources.ResourceListing.empty_state_header': 'You have no resources...',
  'resources.ResourceListing.empty_state_message': `Create a resource you can assign to an ${nouns.event
    }.`,
  'resources.ResourceListing.empty_state_search_header': 'No resources found.',
  'resources.ResourceListing.resource_calendar': 'Resource Calendar',
  'resources.ResourceListing.resources': 'Resources',

  'resources.stores.ResourceDeletionStore.deleted': 'Resource deleted.',

  'resources.stores.ResourceEditingStore.created': 'Resource created.',
  'resources.stores.ResourceEditingStore.updated': 'Resource updated.',

  'resources._StaffExpander.n_staff': '{n} Staff',

  'retail.Header.text': 'Retail',

  'retail.RetailContainer.tab.orders': 'Orders',
  'retail.RetailContainer.tab.products': 'Products',
  'retail.RetailContainer.tab.report': 'Report',
  'retail.RetailContainer.retail': 'Retail',

  'retail.TabMenu.button_menu.inventory': 'Inventory',
  'retail.TabMenu.menu.add': 'Add Inventory',

  'retail._ProductImageEditing.add_image': 'Add Image',
  'retail._ProductImageEditing.are_you_sure':
    'Are you sure you want to remove this image?',
  'retail._ProductImageEditing.featured': 'Featured',
  'retail._ProductImageEditing.product_images': 'Product Images',
  'retail._ProductImageEditing.remove_image': 'Remove Image?',
  'retail._ProductImageEditing.uploading': 'Uploading...',

  'retail.stores.ProductListingStore.has_been_published':
    'Product has been published',
  'retail.stores.ProductListingStore.has_been_unpublished':
    'Product has been unpublished',

  'retail.stores.LabelPrintingStore.printing_n_labels':
    'Printing {n} {n, plural, one {label} other {labels}}.',

  'retail.LabelPrintDialog.print': 'Print',
  'retail.LabelPrintDialog.print_labels': 'Print Labels',
  'retail.LabelPrintDialog.printing': 'Printing',
  'retail.LabelPrintDialog.qty': 'Qty',
  'retail.LabelPrintDialog.sku': 'SKU',
  'retail.LabelPrintDialog.variant': 'Variant',

  'retail.TabProducts.add_products': 'Products',
  'retail.TabProducts.categories': 'CATEGORIES',
  'retail.TabProducts.cost': 'Cost:',
  'retail.TabProducts.clear_search': 'Clear Search',
  'retail.TabProducts.create_product': 'Create Product',
  'retail.TabProducts.empty_state_header': 'You have no products...',
  'retail.TabProducts.empty_state_message': 'Create a new product.',
  'retail.TabProducts.empty_state_search_header': 'No products found.',
  'retail.TabProducts.free': 'Free',
  'retail.TabProducts.header': 'Retail',
  'retail.TabProducts.header_description':
    '| Search and add products to the inventory.',
  'retail.TabProducts.no_filter': 'All',
  'retail.TabProducts.published_filter': 'Published',
  'retail.TabProducts.publish': 'Publish',
  'retail.TabProducts.price': 'Price:',
  'retail.TabProducts.supplier': 'SUPPLIER',
  'retail.TabProducts.unpublished': 'UNPUBLISHED',
  'retail.TabProducts.unpublish': 'Unpublish',
  'retail.TabProducts.unpublished_filter': 'Unpublished',
  'retail.TabProducts.variants': 'Variants:',
  'retail.TabProducts.search': 'Search Product/Variant, SKU, Barcode',

  'retail.OrderHistory.Header.title': 'Order History',
  'retail.OrderHistory.Content.date_range': 'Time Span (Order Date)',
  'retail.OrderHistory.Content.search': 'Search (Order # or Submitted By)',
  'retail.OrderHistory.Content.empty_header': 'No data for purchase orders',
  'retail.OrderHistory.Content.empty_message':
    'Matching purchase orders information will appear here',
  'retail.OrderHistory.ContentTable.order_number': 'Order ID: #',
  'retail.OrderHistory.ContentTable.order_date': 'Order Date',
  'retail.OrderHistory.ContentTable.submitted_by': 'Submitted By',
  'retail.OrderHistory.ContentTable.submission_date': 'Submission Date',
  'retail.OrderHistory.ContentTable.items_added': 'Items Added',
  'retail.OrderHistory.ContentTable.view_modal': 'View',

  'shared.TimeRangeDropdown.end_date': 'End Date',
  'shared.TimeRangeDropdown.start_date': 'Start Date',
  'shared.TimeRangeDropdown.item_today': 'Today',
  'shared.TimeRangeDropdown.item_yesterday': 'Yesterday',
  'shared.TimeRangeDropdown.item_this_week': 'This Week',
  'shared.TimeRangeDropdown.item_last_week': 'Last Week',
  'shared.TimeRangeDropdown.item_this_month': 'This Month',
  'shared.TimeRangeDropdown.item_last_month': 'Last Month',
  'shared.TimeRangeDropdown.item_this_quarter': 'This Quarter',
  'shared.TimeRangeDropdown.item_last_quarter': 'Last Quarter',
  'shared.TimeRangeDropdown.item_this_year': 'This Year',
  'shared.TimeRangeDropdown.item_last_year': 'Last Year',
  'shared.TimeRangeDropdown.item_custom': 'Custom',

  'shared._UserAvatar.unpaid_membership': 'Unpaid Membership',

  'shared._SelectedProfileList.embed_note': 'Note: If you using a card reader to fill membership card number you need to focus on the corresponding field.',
  'shared._SelectedProfileList.embed_input_placeholder': 'Membership card',
  'shared._SelectedProfileList.embed_input_error': 'Membership card number should be 19 characters long',

  'retail.TabProduct.header_create': 'Create Product',
  'retail.TabProduct.header_edit': 'Edit Product',
  'retail.TabProduct.product': 'Product',
  'retail.TabProduct.product_active': 'Active',
  'retail.TabProduct.product_inactive': 'Inactive',
  'retail.TabProduct.description_floating': 'Product Description',
  'retail.TabProduct.description_hint': 'Description',
  'retail.TabProduct.category_hint': 'Category',
  'retail.TabProduct.category_floating': 'Category',
  'retail.TabProduct.manufacturerId_hint': 'Manufacturer ID',
  'retail.TabProduct.manufacturerId_floating': 'Manufacturer ID',
  'retail.TabProduct.supplier_hint': 'Supplier',
  'retail.TabProduct.supplier_floating': 'Supplier',
  'retail.TabProduct.currency_floating': 'Currency',
  'retail.TabProduct.USD': 'USD',
  'retail.TabProduct.product_options': 'Product Options',
  'retail.TabProduct.add_option': 'Add Option',
  'retail.TabProduct.cost_hint': '0.00',
  'retail.TabProduct.cost_floating': 'Cost',
  'retail.TabProduct.price_hint': '0.00',
  'retail.TabProduct.price_floating': 'Price',
  'retail.TabProduct.print_labels': 'Print Labels',
  'retail.TabProduct.printing': 'Printing',
  'retail.TabProduct.name_hint': 'Name',
  'retail.TabProduct.name_floating': 'Name',
  'retail.TabProduct.retail_categories': 'Retail Category',
  'retail.TabProduct.option_type_hint': 'Option Name (e.g. size, color)',
  'retail.TabProduct.option_value_hint': 'Add Value',
  'retail.TabProduct.swing_card': 'Swing Card',

  'retail.RetailCategoryEditing.required':
    'Category is required to save the product',
  'retail.RetailCategoryEditing.swing_option_required': 'Swing card should have upper-most product option which has only-integer values',

  'retail.InventoryDialog.title': 'Add Inventory',
  'retail.InventoryDialog.btn.close': 'Close',
  'retail.InventoryDialog.btn.save': 'Save',
  'retail.InventoryDialog.labels.order_number': 'Order:',
  'retail.InventoryDialog.labels.order_date': 'Order Date:',
  'retail.InventoryDialog.labels.submitted': 'Submitted: {date}{divider}{user}',
  'retail.InventoryDialog.labels.editing': '(Editing)',
  'retail.InventoryDialog.hints.order_number': 'Order # (optional)',
  'retail.InventoryDialog.hints.order_date': 'Order Date',
  'retail.InventoryDialog.search-bar': 'Search product name, barcodes, sku...',
  'retail.InventoryAdjustmentDialog.search-bar': 'Search product name, barcodes, sku...',
  'retail.InventoryAdjustmentDialog.btn.close': 'close',
  'retail.InventoryAdjustmentDialog.btn.save': 'save',
  'retail.InventoryAdjustmentDialog.title': 'Inventory Adjustment',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.name': 'name',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.variant': 'Variant',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.sku': 'SKU',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.expected_quantity': 'Expected Quantity',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.placeholder': 'Enter Reason',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.damaged': 'damaged',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.stolen': 'stolen',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.input_error': 'input error',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.actual_quantity': '+ Actual Quantity',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.adjustment_reason': 'Adjustment Reason',
  'retail.InventoryAdjustmentDialog.VariantAdjustmentTable.empty_variant_table': 'There are no results',
  'retail.InventoryDialog.FilterMenu.all-categories': 'All Retail Categories',

  'retail.InventoryDialog.DownloadMenu.export-all-columns':
    'Export All Columns',
  'retail.InventoryDialog.DownloadMenu.product-variant': 'Product / Variant',
  'retail.InventoryDialog.DownloadMenu.sku': 'SKU',
  'retail.InventoryDialog.DownloadMenu.barcode': 'Barcode',
  'retail.InventoryDialog.DownloadMenu.lqty': 'Live Quantity',
  'retail.InventoryDialog.DownloadMenu.qty': 'Quantity',
  'retail.InventoryDialog.DownloadMenu.sold': 'Sold',
  'retail.InventoryDialog.DownloadMenu.returned': 'Returned',
  'retail.InventoryDialog.DownloadMenu.cost': 'Cost',
  'retail.InventoryDialog.DownloadMenu.price': 'Price',
  'retail.InventoryDialog.DownloadMenu.total-profits': 'Total Profits',
  'retail.InventoryDialog.DownloadMenu.export-btn': 'Export',


  'retail.InventoryDialog.VariantTable.variant': 'Variant',
  'retail.InventoryDialog.VariantTable.sku': 'SKU',
  'retail.InventoryDialog.VariantTable.unit_cost': 'Unit Cost',
  'retail.InventoryDialog.VariantTable.empty_variant_table':
    'There are no results',
  'retail.InventoryDialog.VariantTable.qty': '+Qty',
  'retail.InventoryDialog.VariantTable.name': 'name',

  'retail._VariantEditing.barcode': 'Barcode',
  'retail._VariantEditing.cost': 'Cost',
  'retail._VariantEditing.dirty_option_type_warning':
    'Please save to see updated product variants.',
  'retail._VariantEditing.qty': 'Qty',
  'retail._VariantEditing.trigger_qty': 'Trigger Qty*',
  'retail._VariantEditing.price': 'Price',
  'retail._VariantEditing.product_variants': 'Product Variants',
  'retail._VariantEditing.sku': 'SKU',
  'retail._VariantEditing.qty_on_hand_character': 'Q',
  'retail._VariantEditing.inventory_link': 'Add Inventory',
  'retail._VariantEditing.trigger_qty_tooltip':
    "Set a quantity and we'll send you an email letting you know it an item needs to be reordered!",

  'retail._VariantImageSelector.choose_image': 'Choose Image',

  'shared.ErrorDialog.error_title': 'Oops! Something went wrong.',
  'shared.ErrorDialog.error_dismiss': 'OK',
  'shared.ErrorDialog.report': 'Report',

  'shared.important_note': 'Important Note: ',

  'shared._CustomerTermsBanner.please_read':
    'Please read the terms and conditions in full before agreeing.',

  'shared._ProfileSelector.hint_text': 'Select Profile',
  'shared._ProfileSelector.new_item': '+ New Profile',

  'shared._SelectWithNewItem.select_item_hint': 'Select {item_name}',
  'shared._SelectWithNewItem.new_item_text': '+ New {item_name}',

  'shared._SpinWhileLoading.loading': 'Loading...',

  'shared._TermsBanner.policy_and_terms': 'policy & terms',
  'shared._TermsBanner.membership_agreement': 'Membership Agreement',

  'shared.AddressFields.address_two': 'Address Line 2',
  'shared.AddressFields.city': 'City',
  'shared.AddressFields.postal_code': 'Postal Code',
  'shared.AddressFields.state': 'State',
  'shared.AddressFields.street_address': 'Street Address',

  'shared.CartIndicator.client_message': `This ${nouns.event
    } is in your cart. {link}`,
  'shared.CartIndicator.customer_message': `This ${nouns.event
    } is in the cart. {link}`,

  'shared.ContactAutocomplete.add_contact': 'Add Contact',

  'shared.checkout._AttendeeSessionExpander.member_discount': 'Member Discount',
  'shared.checkout._SessionCheckBoxList.all_day_checkbox': 'All Days',
  'shared.checkout._SessionCheckBoxList.all_days': 'All Days',
  'shared.checkout._SessionCheckBoxList.purchased': 'Purchased',
  'shared.checkout._SessionCheckBoxList.full': 'Full',
  'shared.checkout._SessionCheckBoxList.remove_attendee': 'Remove Attendee',

  'shared.checkout._ViewEditLink.edit_item': 'Edit Item',
  'shared.checkout._ViewEditLink.view_item': 'View Item',

  'shared.checkout._AddRemoveQuickDiscount.add_discount': 'Add Discount',
  'shared.checkout._AddRemoveQuickDiscount.remove_discount': 'Remove Discount',

  'shared.checkout.CartCommitButton.add_to_cart': 'Add To Cart',
  'shared.checkout.CartCommitButton.update_cart': 'Update Cart',
  'shared.checkout.CartCommitButton.view_in_cart': 'View in Cart',

  'shared.checkout.CheckoutStepper.complete_event_form': `Complete ${nouns.event
    } forms to continue.`,
  'shared.checkout.CheckoutStepper.complete_form_warning':
    'Review cart summary and fill out forms to proceed with payment.',
  'shared.checkout.CheckoutStepper.confirmation_email_sent_to':
    'Confirmation email sent to',
  'shared.checkout.CheckoutStepper.continue_to_payment': 'Continue to Payment',
  'shared.checkout.CheckoutStepper.summary': 'Summary',
  'shared.checkout.CheckoutStepper.due_today': 'Due today',
  'shared.checkout.CheckoutStepper.purchase': 'Purchase',
  'shared.checkout.CheckoutStepper.insurance_amount': 'Insurance Amount',
  'shared.checkout.CheckoutStepper.total_payment': 'Total Payment',
  'shared.checkout.CheckoutStepper.total': 'Total',
  'shared.checkout.CheckoutStepper.processing_payment': 'Processing payment.',
  'shared.checkout.CheckoutStepper.purchased': 'Purchased',
  'shared.checkout.CheckoutStepper.payment_method': 'Payment Method',
  'shared.checkout.CheckoutStepper.payment_complete': 'Payment Complete',
  'shared.checkout.CheckoutStepper.payment': 'Payment',
  'shared.checkout.CheckoutStepper.protections': 'Protections',
  'shared.checkout.CheckoutStepper.protections_info': 'Add protections for all activities you registered your children to:',
  'shared.checkout.CheckoutStepper.powered_by': 'Powered by Pattern',

  'shared.checkout.CouponSummary.removing': 'Removing...',
  'shared.checkout.CouponSummary.invalid_coupon_type': 'Coupon not valid for any items in cart',
  'shared.checkout.CouponSummary.value': '{value} off',
  'shared.checkout.OrderTotals.coupon': 'Coupon',
  'shared.checkout.OrderTotals.manual': 'Discounts',
  'shared.checkout.OrderTotals.account_credit': 'Credit Applied',
  'shared.checkout.OrderTotals.due_today': 'Due Today',
  'shared.checkout.OrderTotals.membership_discounts': `${nouns.Membership} Discounts`,
  'shared.checkout.OrderTotals.sales_tax': 'Sales Tax',
  'shared.checkout.OrderTotals.service_fee': 'Service Fee',
  'shared.checkout.OrderTotals.insurance_amount': 'Insurance Amount',
  'shared.checkout.OrderTotals.subtotal': 'Subtotal',
  'shared.checkout.OrderTotals.total': 'Total',

  'shared.checkout._ManageOrderItem.type': 'Type',
  'shared.checkout._ManageOrderItem.amount': '$',
  'shared.checkout._ManageOrderItem.percentage': '%',
  'shared.checkout._ManageOrderItem.discount': 'Discount',
  'shared.checkout._ManageOrderItem.apply_discount': 'Apply Discount',

  'shared.checkout.CouponForm.apply': 'Apply',
  'shared.checkout.CouponForm.applying': 'Applying Coupon',
  'shared.checkout.CouponForm.coupon_code': 'Coupon Code',
  'shared.checkout.CouponForm.enter_code': 'Enter Coupon Code',

  'shared.checkout.ItemCard.payment_plan_summary':
    '({paymentsDueAtCheckout} of {totalPayments}: {displayedTotal})',

  'shared.checkout.SingleSessionPurchasableContent.start':
    'Please choose an Attendee to start',
  'shared.checkout.SingleSessionPurchasableContent.total': 'Total',

  'shared.checkout.TaxExemptToggle.remove_sales_tax': 'Remove Sales Tax',
  'shared.checkout.ServiceFeeExemptToggle.remove_service_fee': 'Remove Service Fee',
  'shared.checkout.proRateToggle.proRate': 'Prorate',
  'shared.checkout.TaxExemptToggle.tax_id': 'Tax ID (Optional)',
  'shared.checkout.TaxExemptToggle.enter_tax_id': 'Enter Tax ID',

  'shared.checkout.QuickDiscountForm.discount': 'Discount',
  'shared.checkout.QuickDiscountForm.apply_discount': 'Apply Discount',
  'shared.checkout.QuickDiscountForm.amount': '$',
  'shared.checkout.QuickDiscountForm.percentage': '%',
  'shared.checkout.QuickDiscountForm.type': 'Type',
  'shared.checkout.QuickDiscountForm.tooltip': 'Adding discounts to memberships only applies toward the initial one-time fee',

  'shared.checkout.AccountCreditsForm.account_credit': 'Account Credit',
  'shared.checkout.AccountCreditsForm.amount_available': 'Amount Available:',
  'shared.checkout.AccountCreditsForm.apply_credit': 'Apply Credit',

  'shared.checkout.FixedScheduleCardContent.event_dates': 'Event Dates',
  'shared.checkout.FixedScheduleCardContent.view_details': 'View Details',

  'shared.checkout.OpenBookingCardContent.no_session': 'No session scheduled',
  'shared.checkout.OpenBookingCardContent.profile_selected': 'Profile selected',
  'shared.checkout.OpenBookingCardContent.scheduled_session': '{scheduledSessionCount} Session Scheduled',
  'shared.checkout.OpenBookingCardContent.credit_need': '{scheduledSessionCount} {scheduledSessionCount, plural, one {Credit} other {Credits}} Needed',
  'shared.checkout.OpenBookingCardContent.credit_need_info': 'You need {scheduledSessionCount} {scheduledSessionCount, plural, one {credit} other {credits}} to complete your registration. Select a credit package below.',
  'shared.checkout.OpenBookingCardContent.select_pkg_label': 'Select a Package',
  'shared.checkout.OpenBookingCardContent.view_details': 'View Details',
  'shared.checkout.OpenBookingCardContent.any_available': 'Any available staff',

  'shared.Alert.alert': 'Alert',

  'shared.DateSelect.jan': 'Jan.',
  'shared.DateSelect.1': '1',
  'shared.DateSelect.1950': '1950',

  'shared.DateRange.date_range': '{start} - {end}',

  'shared.EventTypeForm.color_label': `${nouns.Event} Color`,
  'shared.EventTypeForm.name_label': `${nouns.Event} Type Name`,

  'shared.EventTypeInlineEditor.dialog_cancel_button': 'Cancel',
  'shared.EventTypeInlineEditor.dialog_submit_button': 'Submit',
  'shared.EventTypeInlineEditor.dialog_title_edit_mode': `Edit ${nouns.Event
    } Type`,
  'shared.EventTypeInlineEditor.dialog_title_create_mode': `New ${nouns.Event
    } Type`,
  'shared.EventTypeInlineEditor.edit_button': 'Edit',
  'shared.EventTypeInlineEditor.item_name': `${nouns.Event} Type`,

  'shared.TeamTypeInlineEditor.dialog_cancel_button': 'Cancel',
  'shared.TeamTypeInlineEditor.dialog_submit_button': 'Submit',
  'shared.TeamTypeInlineEditor.dialog_title_edit_mode': 'Edit Team Type',
  'shared.TeamTypeInlineEditor.dialog_title_create_mode': 'New Team Type',
  'shared.TeamTypeInlineEditor.edit_button': 'Edit',
  'shared.TeamTypeInlineEditor.item_name': 'Team Type',

  'shared.RetailCategoryInlineEditor.name_label': 'Retail Category Name',

  'shared.RetailCategoryInlineEditor.dialog_cancel_button': 'Cancel',
  'shared.RetailCategoryInlineEditor.dialog_submit_button': 'Submit',
  'shared.RetailCategoryInlineEditor.dialog_title_edit_mode':
    'Edit Retail Category',
  'shared.RetailCategoryInlineEditor.dialog_title_create_mode':
    'New Retail Category',
  'shared.RetailCategoryInlineEditor.edit_button': 'Edit',
  'shared.RetailCategoryInlineEditor.item_name': 'Retail Category',

  'shared.IncompatibilityBanner.help': 'Help',
  'shared.IncompatibilityBanner.warning':
    'Your browser or hardware is incompatible. You may experience problems using this site.',

  'shared.LocationFormFields.address_two': 'Address Line 2',
  'shared.LocationFormFields.address_type': 'Address Type',
  'shared.LocationFormFields.al': 'AL',
  'shared.LocationFormFields.city': 'City',
  'shared.LocationFormFields.location_name': 'Location Name',
  'shared.LocationFormFields.state': 'State',
  'shared.LocationFormFields.street_address': 'Street Address',
  'shared.LocationFormFields.zeros': '00000',
  'shared.LocationFormFields.zipcode': 'Zipcode',

  'shared.login.CustomerAccountSelection.no_businesses_found':
    'No businesses found.',
  'shared.login.CustomerAccountSelection.search': 'Search',
  'shared.login.CustomerAccountSelection.select_a_business':
    'Select a business',

  'shared.login.UserLogin.account_already_setup':
    'It looks like an account with that email address has already been setup. Please {sign_in} below or {reset_password}.',
  'shared.login.UserLogin.authenticating': 'Authenticating...',
  'shared.login.UserLogin.awaiting_verification':
    'The account you tried to log in with is awaiting verification. Please verify your account using the email we’ve sent to your inbox. To resend your verification email use the button below.',
  'shared.login.UserLogin.email_address': 'E-mail address',
  'shared.login.UserLogin.forgot_password': 'Forgot Password?',
  'shared.login.UserLogin.not_recognized':
    'That password or email was not recognized.',
  'shared.login.UserLogin.password': 'Password',
  'shared.login.UserLogin._reset_password': 'reset your password',
  // eslint-disable-next-line no-multi-str
  'shared.login.UserLogin.empty_roles': 'Your login is no longer affiliated with any of our customers. Please contact your admin to receive another invite.',

  'shared.login._ResendVerificationLink.resend_verification':
    'Resend verification email',

  'shared.memberships.DuplicateMembershipWarning.already_present_warning':
    '{interjection} {profileName} is currently associated to {existingMembershipName} in the cart. Adding {newMembershipName} to the cart will replace {existingMembershipName}.',
  'shared.memberships.DuplicateMembershipWarning.warning': 'Warning:',
  'shared.memberships.MembershipTierSelector.free': 'Free',
  'shared.memberships.MembershipTierSelector.commitment': '{value} Commitment',
  'shared.memberships.MembershipTierSelector.join_fee': '{fee} Join Fee',
  'shared.memberships.MembershipTierSelector.placeholder': 'Select membership tier',
  'shared.memberships.MembershipTierSelector.label': 'Select membership tier',

  'shared.MessagingDrawer.choose_date': 'Choose Date',
  'shared.MessagingDrawer.choose_time': 'Choose Time',
  'shared.MessagingDrawer.contact_group': 'Contact Group',
  'shared.MessagingDrawer.create_message': 'Create Message',
  'shared.MessagingDrawer.event': `${nouns.Event}`,
  'shared.MessagingDrawer.message': 'Message',
  'shared.MessagingDrawer.send_email': 'Send Email',
  'shared.MessagingDrawer.send_later': 'Send Later',
  'shared.MessagingDrawer.sending': 'Sending',
  'shared.MessagingDrawer.subject': 'Subject',
  'shared.MessagingDrawer.type_a_message': 'Type a message...',
  'shared.MessagingDrawer.preview': 'Preview',
  'shared.MessagingDrawer.return_to_edit': 'Return to Edit',

  'shared.WysiwygEditor.file_size_alert':  'The size of the uploaded image is more than 2 MB. Please try uploading a different image.',

  'shared.MultiSelectField.all': 'All',
  'shared.MultiSelectField.n_items': `{n} {itemName}'s`,
  'shared.MultiSelectField.none': 'None',

  'shared.navigation.QuickAccessButton.new_event': `New ${nouns.Event}`,
  'shared.navigation.QuickAccessButton.point_of_sale': 'Point of Sale',
  'shared.navigation.QuickAccessButton.send_invite': 'Send Invite',

  'shared.navigation.SiteHeader.create_account_button': 'Create Account',
  'shared.navigation.SiteHeader.login_button': 'Login',

  'shared.navigation.UserMenu.account_settings': 'Account Settings',
  'shared.navigation.UserMenu.device_settings': 'Device Settings',
  'shared.navigation.UserMenu.admin_ui': 'Admin UI',
  'shared.navigation.UserMenu.customer_selection': 'Change Customer',
  'shared.navigation.UserMenu.customer_ui': 'Customer UI',
  'shared.navigation.UserMenu.printer_settings': 'Printer Settings',
  'shared.navigation.UserMenu.privacy_policy': 'Privacy Policy',
  'shared.navigation.UserMenu.profile': 'Profile',
  'shared.navigation.UserMenu.sign_out': 'Sign Out',
  'shared.navigation.UserMenu.support': 'Support',
  'shared.navigation.UserMenu.submit_idea': 'Submit Idea',

  'shared.NewReleaseBanner.title': "There's a software update.",
  'shared.NewReleaseBanner.link': 'Tap to refresh',

  'shared.OpenBookingScheduling.add_another_attendee': 'Add Another Attendee',
  'shared.OpenBookingScheduling.any_available': 'Staff: Any available',
  'shared.OpenBookingScheduling.continue_to_cart': 'Continue to Cart →',
  'shared.OpenBookingScheduling.repeatBooking': 'Repeat Booking',
  'shared.OpenBookingScheduling.continue_to_save_sessions':
    'Continue to cart to save session(s)',
  'shared.OpenBookingScheduling.edit_item': 'Edit Item',
  'shared.OpenBookingScheduling.member_discount': 'Member Discount',
  'shared.OpenBookingScheduling.n_of_m': '{n} of {m}',
  'shared.OpenBookingScheduling.save_session': 'Save Session',
  'shared.OpenBookingScheduling.schedule_later': 'Schedule Later',
  'shared.OpenBookingScheduling.schedule_now': 'Schedule Now',
  'shared.OpenBookingScheduling.scheduling_instructions':
    'Pick a date, select an available time and click save to continue.',
  'shared.OpenBookingScheduling.select_a_package': 'Select a package:',
  'shared.OpenBookingScheduling.select_an_attendee':
    'Select an attendee to continue:',
  'shared.OpenBookingScheduling.selected_staff': 'Staff: {staff}',

  'shared.PermissionDeniedDialog.permission_denied': 'Permission Denied',
  'shared.PermissionDeniedDialog.open_settings': 'Open Settings',
  'shared.PermissionDeniedDialog.close': 'Close',

  'shared.Permission.contacts_instructions':
    'To import contacts, you will need to allow access to Contacts in the Settings App.',

  'shared._QRDialog.check_in': 'CHECK-IN',

  'shared.registration.FixedScheduleProfileList.member_price': 'Member Price',

  'shared.registration.ScheduleButtons.sessions_available':
    'Sessions Available: {count}',

  'shared.registration.PackageSelector.select_package': 'Select Package',

  'shared.registration.PaymentPlanSelector.n_payments':
    '{n} {n, plural, one {payment} other {payments}}',
  'shared.registration.PaymentPlanSelector.payment_schedule':
    'Payment Schedule',
  'shared.registration.PaymentPlanSelector.plan_selector_hint_text':
    'Choose Payment Schedule',

  'shared.registration.RegistrationScheduler.book': 'Book',
  'shared.registration.RegistrationScheduler.check_availabilty':
    'Checking Availability',
  'shared.registration.RegistrationScheduler.choose_staff': 'Choose Staff:',
  'shared.registration.RegistrationScheduler.default_label':
    'Choose Date & Time',
  'shared.registration.RegistrationScheduler.no_availabilty': 'No Availability',
  'shared.registration.RegistrationScheduler.of_text':
    '{scheduleCount} of {registrationCount}',
  'shared.registration.RegistrationScheduler.pick_a_date_and_time':
    'Pick a date and time:',
  'shared.registration.RegistrationScheduler.select_date': 'Select Date',
  'shared.registration.RegistrationScheduler.select_time': 'Select Time',
  'shared.registration.RegistrationScheduler.instructions_staff_select':
    'Choose staff, pick a date, select an available time, and click save to continue.',
  'shared.registration.RegistrationScheduler.instructions_no_staff_select':
    'Pick a date, select an available time, and click save to continue.',
  'shared.registration.RegistrationScheduler.unavailable_warning':
    'The date and time is not available for the staff above. Please choose another.',

  'shared.registration.ScheduledSession.any_available': 'Staff: Any available',
  'shared.registration.ScheduledSession.selected_staff': 'Staff: {staff}',


  'shared.Scheduler.n_of_m': '{n} of {m}',
  'shared.Scheduler.unlimited_credits': 'Unlimited Credits',

  'shared.Scheduler.no_sessions_remaining': 'No Sessions Remaining',
  'shared.Scheduler.remove_booking': 'Remove booking',
  'shared.Scheduler.schedule': 'Schedule',
  'shared.Scheduler.scheduled': 'Scheduled',

  'containers.client.settings.billing.Billing.pos_access': 'Allow Access',
  'containers.client.settings.billing.Billing.pos_info':
    'This switch allows staff access to charge and manage payment methods during Point of Sale (POS). Toggling the switch off will restrict staff access to charge and manage your payment methods. If you are enrolled in a membership or a payment plan you will not be able to toggle the switch off until the recurring payment has been cancelled or completed.',
  'containers.client.settings.billing.EmptyState.title': 'No methods saved.',
  'containers.client.settings.billing.EmptyState.title_no_access': 'Access denied.',
  'containers.client.settings.billing.EmptyState.description': 'Make checkout easy by saving payment details!',
  'containers.client.settings.billing.EmptyState.description_no_access': 'Client must turn on Staff Access to view.',
  'containers.client.settings.billing.EmptyState.btn': 'Add Payment Method',

  'containers.client.settings.billing.PaymentMethodInfo.menu.set_default': 'Set Default',
  'containers.client.settings.billing.PaymentMethodInfo.menu.delete': 'Delete',
  'containers.client.settings.billing.PaymentMethodInfo.menu.edit': 'Edit',
  'containers.client.settings.billing.PaymentMethodInfo.label_default': 'Default',
  'containers.client.settings.billing.PaymentMethodInfo.credit_card': 'Credit Card',
  'containers.client.settings.billing.PaymentMethodInfo.bank': 'Bank',
  'containers.client.settings.billing.PaymentMethodInfo.no_address_on_file': ' No billing address on file for this card. Please edit card to update billing address.',

  'containers.client.settings.paymentMethodModal.PaymentMethodModal.btn_upd': 'Update Payment Method',
  'containers.client.settings.paymentMethodModal.PaymentMethodModal.btn': 'Save Payment Method',
  'containers.client.settings.paymentMethodModal.PaymentMethodModal.add_payment_method': 'Add Payment Method',
  'containers.client.settings.paymentMethodModal.PaymentMethodModal.ach': 'ACH',
  'containers.client.settings.paymentMethodModal.PaymentMethodModal.card': 'Card',

  'containers.cardReaderEdit.CardReaderEdit.device_name': 'Device Name',
  'containers.cardReaderEdit.CardReaderEdit.device_type': 'Type',
  'containers.cardReaderEdit.CardReaderEdit.type_hilite': 'HiLite',
  'containers.cardReaderEdit.CardReaderEdit.type_paxa920': 'Pax A920',
  'containers.cardReaderEdit.CardReaderEdit.type_paxa920pro': 'Pax A920 Pro',
  'containers.cardReaderEdit.CardReaderEdit.type_paxa60': 'Pax A60',
  'containers.cardReaderEdit.CardReaderEdit.type_hipro': 'HiPro',
  'containers.cardReaderEdit.CardReaderEdit.activation_status':
    'Activation Status',
  'containers.cardReaderEdit.CardReaderEdit.activation_status_default':
    'Default',
  'containers.cardReaderEdit.CardReaderEdit.activation_status_enabled':
    'Enabled',
  'containers.cardReaderEdit.CardReaderEdit.activation_status_disabled':
    'Disabled',

  'containers.cardReaderDropdown.CardReaderDropdown.placeholder':
    'Select a device',
  'containers.cardReaderDropdown.CardReaderDropdown.noReaders':
    'No devices available',
  'containers.cardReaderDropdown.CardReaderDropdown.refresh':
    'Refresh',

  'containers.deviceList.DeviceList.save': 'Save',
  'containers.deviceList.DeviceList.cancel': 'Cancel',

  'containers.deviceList.Header.header': 'Devices',
  'containers.deviceList.Header.search': 'Search',
  'containers.deviceList.Header.btn_refresh': 'Refresh',

  'containers.deviceList.EmptyState.title': 'No devices connected.',
  'containers.deviceList.EmptyState.description':
    'Click refresh or contact your account rep about our new devices!',
  'containers.deviceList.EmptyState.btn': 'Refresh',

  'containers.deviceList.DeviceInfo.type': 'Type',
  'containers.deviceList.DeviceInfo.serial_number': 'Serial #',
  'containers.deviceList.DeviceInfo.status': 'Status',
  'containers.deviceList.DeviceInfo.label_default': 'Default',
  'containers.deviceList.DeviceInfo.enable': 'Enable',
  'containers.deviceList.DeviceInfo.disable': 'Disable',
  'containers.deviceList.DeviceInfo.edit': 'Rename',
  'containers.deviceList.DeviceInfo.set_default': 'Set Default',
  'containers.deviceList.DeviceInfo.activation_status_enabled': 'Enabled',
  'containers.deviceList.DeviceInfo.activation_status_disabled': 'Disabled',
  'containers.deviceList.DeviceInfo.activation_status_default': 'Default',

  'containers.deviceList.Header.devices': 'Devices',

  'containers.reports.paymentPlans.Header.Header.header': 'Payment Plans',
  'containers.reports.paymentPlans.Header.Header.search': 'Search Buyer',

  'containers.reports.paymentPlans.Table.EmptyState.title': 'Nothing to see here.',
  'containers.reports.paymentPlans.Table.EmptyState.description': 'Payment Plans will show up when created.',
  'containers.reports.paymentPlans.Table.HeaderDesktop.client': 'Buyer',
  'containers.reports.paymentPlans.Table.HeaderDesktop.initial_amount': 'Initial',
  'containers.reports.paymentPlans.Table.HeaderDesktop.remaining_amount': 'Remaining',
  'containers.reports.paymentPlans.Table.HeaderDesktop.start_date': 'Start Date',
  'containers.reports.paymentPlans.Table.HeaderDesktop.end_date': 'End Date',
  'containers.reports.paymentPlans.Table.HeaderDesktop.next_payment_date': 'Next Payment',

  'containers.reports.paymentPlanDetailsDrawer.Content.order_item': 'Order Item',
  'containers.reports.paymentPlanDetailsDrawer.Content.date_time': '{date} • {time}',

  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanDetailsDrawer.title': 'Payment Plan',
  'containers.reports.paymentPlanDetailsDrawer.DotsMenu.pay_balance': 'Pay Balance',
  'containers.reports.paymentPlanDetailsDrawer.DotsMenu.waive_balance': 'Waive Balance',
  'containers.reports.paymentPlanDetailsDrawer.DotsMenu.edit_payments': 'Edit Payments',
  'containers.reports.paymentPlanDetailsDrawer.DotsMenu.cancel_all_payments': 'Cancel All Payments',
  'containers.reports.paymentPlanDetailsDrawer.DotsMenu.pay_ahead': 'Pay Ahead',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.header': 'Outstanding Balance',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.title': 'Payment Plan Info',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.title_purchase_date': 'Purchased',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.purchase_date': '{date} • {time}',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.title_next_due': 'Next Payment Due',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.next_due': '{date}',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.title_product_type': 'Product Type(s)',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.title_product': 'Product(s)',
  'containers.reports.paymentPlanDetailsDrawer.PaymentPlanInfo.title_total': 'Total',

  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.cancel_all_payments': 'Cancel All Payments',
  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.are_you_sure':
    `Are you sure you want to remove cancel all payments under this payment plan for {clientName}?`,
  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.discard_cancellation': 'No',
  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.confirm_cancellation': 'Yes',
  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.plans_installments': `{count} Payment Installments`,
  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.reason_label': 'Cancellation Reason (Optional)',
  'containers.reports.paymentPlanDetailsDrawer._CancellationDialog.no_reason_selected': 'select cancellation reason',

  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.edit_payment_plan': 'Edit Payment Plan',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.discard_cancellation': 'Cancel',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.confirm_cancellation': 'Save',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.plan_name': `Payment Plan {planName}`,
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.paid_item': `{date} . {amount}`,
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.empty_date': 'Date is required.',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.empty_amount': 'Amount is required.',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.empty_all': 'Date and amount are required.',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.cancel_payment': 'Cancel Payment',
  'containers.reports.paymentPlanDetailsDrawer.EditPaymentPlanDrawer.undo_cancellation': 'Undo Cancellation',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_payment_scheduled': 'Scheduled Payment',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_payment_cancelled': 'Cancelled Payment',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_payment_outstanding': 'Past Due Payment',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_payment_partially_waived': 'Partially Waived Payment',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_payment_fully_waived': 'Fully Waived Payment',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_payment_completed': 'Payment',
  'containers.reports.paymentPlanDetailsDrawer.PaymentTitle.title_amount_waived': 'Waived Amount',

  'containers.reports.paymentPlanDetailsDrawer.ScheduledPaymentsInfo.title_details': 'Details',
  'containers.reports.paymentPlanDetailsDrawer.ScheduledPaymentsInfo.view': 'View',

  'containers.browseEvents.BrowseEventsWrapped.no_results': 'No results',
  'containers.browseEvents.BrowseEventsWrapped.view_week': 'Week View',
  'containers.browseEvents.BrowseEventsWrapped.view_list': 'List View',

  'containers.browseEvents.BrowseEventsRoomy.announcement': 'Announcement',
  'containers.browseEvents.BrowseEventsRoomy.announcement_empty': 'No announcement today.',
  'containers.browseEvents.BrowseEventsRoomy.read_announcement': 'Read {customerName} Announcement',
  'containers.browseEvents.BrowseEventsRoomy.filter_events': 'Filter Events',
  'containers.browseEvents.BrowseEventsRoomy.category': 'Category',
  'containers.browseEvents.BrowseEventsRoomy.staff': 'Staff',
  'containers.browseEvents.BrowseEventsRoomy.location': 'Location',
  'containers.browseEvents.BrowseEventsRoomy.sport_type': 'Sport Type',
  'containers.browseEvents.BrowseEventsRoomy.season': 'Season',
  'containers.browseEvents.BrowseEventsRoomy.all_categories': 'All Categories',
  'containers.browseEvents.BrowseEventsRoomy.all_staff': 'All Staff',
  'containers.browseEvents.BrowseEventsRoomy.all_locations': 'All Locations',
  'containers.browseEvents.BrowseEventsRoomy.all_sport_types': 'All Sport Types',
  'containers.browseEvents.BrowseEventsRoomy.all_seasons': 'All Seasons',
  'containers.browseEvents.BrowseEventsRoomy.age': 'Age',
  'containers.browseEvents.BrowseEventsRoomy.apply': 'apply',
  'containers.browseEvents.BrowseEventsRoomy.min': 'Min',
  'containers.browseEvents.BrowseEventsRoomy.max': 'Max',
  'containers.browseEvents.BrowseEventsRoomy.clear_filters': 'Clear Filters',
  'containers.browseEvents.BrowseEventsRoomy.clear_filter': 'Clear Filter',


  'containers.browseEvents.BrowseEventsCompact.filters': 'Filters',
  'containers.browseEvents.BrowseEventsCompact.clear_filters': 'Clear Filters',
  'containers.browseEvents.BrowseEventsCompact.clear_filter': 'Clear Filter',
  'containers.browseEvents.BrowseEventsCompact.category': 'Category',
  'containers.browseEvents.BrowseEventsCompact.staff': 'Staff',
  'containers.browseEvents.BrowseEventsCompact.location': 'Location',
  'containers.browseEvents.BrowseEventsCompact.sport_type': 'Sport Type',
  'containers.browseEvents.BrowseEventsCompact.season': 'Season',
  'containers.browseEvents.BrowseEventsCompact.all_categories': 'All Categories',
  'containers.browseEvents.BrowseEventsCompact.all_staff': 'All Staff',
  'containers.browseEvents.BrowseEventsCompact.all_locations': 'All Locations',
  'containers.browseEvents.BrowseEventsCompact.all_sport_types': 'All Sport Types',
  'containers.browseEvents.BrowseEventsCompact.all_seasons': 'All Seasons',
  'containers.browseEvents.BrowseEventsCompact.age': 'Age',
  'containers.browseEvents.BrowseEventsCompact.apply': 'apply',
  'containers.browseEvents.BrowseEventsCompact.min': 'Min',
  'containers.browseEvents.BrowseEventsCompact.max': 'Max',
  

  'containers.browseEvents.OpenBookingCard.btn': 'Book',
  'containers.browseEvents.OpenBookingCard.spot': '1 spot left!',
  'containers.browseEvents.OpenBookingCard.spots': '{count} spots left!',

  'containers.browseEvents.FixedScheduleCard.btn': 'Buy',
  'containers.browseEvents.FixedScheduleCard.full_btn': 'Full',
  'containers.browseEvents.FixedScheduleCard.waitlist_btn': 'Join Waitlist',
  'containers.browseEvents.FixedScheduleCard.spot': '1 spot left!',
  'containers.browseEvents.FixedScheduleCard.spots': '{count} spots left!',
  'containers.browseEvents.FixedScheduleCard.registration_closed': 'Registration Closed',

  'containers.browseEvents.ClassScheduleCard.btn': 'Reserve',
  'containers.browseEvents.ClassScheduleCard.full_btn': 'Full',
  'containers.browseEvents.ClassScheduleCard.registration_closed': 'Registration Closed',
  'containers.browseEvents.ClassScheduleCard.spot': '1 spot left!',
  'containers.browseEvents.ClassScheduleCard.spots': '{count} spots left!',
  'containers.browseEvents.ClassScheduleCard.session': 'Session',
  'containers.browseEvents.ClassScheduleCard.staff_count': '{count} instructors',

  'containers.reports.ordersReport.OrdersFilterDrawer.title': 'Filters',
  'containers.reports.ordersReport.OrdersFilterDrawer.btn_apply': 'Apply Filters',
  'containers.reports.ordersReport.OrdersFilterDrawer.order_method': 'Order Method',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method': 'Payment Method',
  'containers.reports.ordersReport.OrdersFilterDrawer.clear': 'Clear Filters',
  'containers.reports.ordersReport.OrdersFilterDrawer.order_method_all': 'All',
  'containers.reports.ordersReport.OrdersFilterDrawer.order_method_online': 'Online',
  'containers.reports.ordersReport.OrdersFilterDrawer.order_method_in_store': 'In-Store (POS)',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_all': 'All',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_check': 'Check',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_cash': 'Cash',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_card': 'Card',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_bank': 'Bank',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_card_present': 'Card Present',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_handpoint_cloud': 'Handpoint Cloud',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_credit': 'Credit',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_pay_later': 'Pay Later',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.ordersReport.OrdersFilterDrawer.payment_method_payment_plan': 'Payment Plan',

  'containers.reports.refundModal.RefundModal.back_action': 'Back',
  'containers.reports.refundModal.RefundModal.continue': 'Issue Waive + Continue to Payment',
  'containers.reports.refundModal.RefundModal.issue_refund': 'Issue Refund',
  'containers.reports.refundModal.RefundModal.issue_waive': 'Issue Waive',
  'containers.reports.refundModal.RefundModal.issue_waive_payment_plan': 'Issue Waive',
  'containers.reports.refundModal.RefundModal.refund': 'Refund',
  'containers.reports.refundModal.RefundModal.ach_warning': 'Client do not have an ACH payment method set by default. Please make sure that client has an ACH payment method set before issuing a refund.',
  'containers.reports.refundModal.RefundModal.paysafe_warning': 'This transaction has not settled and will be voided. Only a full void can be made.',
  'containers.reports.refundModal.Store.refund_exceeds_maximum': 'Refund amount exceeds the maximum',
  'containers.reports.refundModal.Store.refund_is_zero': 'Refund amount is zero',
  'containers.reports.refundModal.RefundTo.card': 'Card',
  'containers.reports.refundModal.RefundTo.pay_later': 'Pay Later',
  'containers.reports.refundModal.RefundTo.credit': 'Credit',
  'containers.reports.refundModal.RefundTo.cash': 'Cash',
  'containers.reports.refundModal.RefundTo.check': 'Check',
  'containers.reports.refundModal.RefundTo.refund_to': 'Refund to:',
  'containers.reports.refundModal.RefundAmount.refund': 'Refund',
  'containers.reports.refundModal.RefundAmount.waive': 'Waive',
  'containers.reports.refundModal.RefundAmount.waive_payment_plan': 'Waive',
  'containers.reports.refundModal.RefundAmount.out_of': 'Max',
  'containers.reports.refundModal.RefundAmount.payment_method_card': 'Card',
  'containers.reports.refundModal.RefundAmount.payment_method_card_present': 'Card',
  'containers.reports.refundModal.RefundAmount.payment_method_handpoint_cloud': 'Card',
  'containers.reports.refundModal.RefundAmount.payment_method_cash': 'Cash',
  'containers.reports.refundModal.RefundAmount.payment_method_check': 'Check',
  'containers.reports.refundModal.RefundAmount.payment_method_bank': 'Bank',

  'containers.reports.exportModal.ExportModal.title': 'Download CSV',
  'containers.reports.exportModal.ExportModal.description':
    'Select report to include in the CSV and then click the "Download" button',
  'containers.reports.exportModal.ExportModal.select_report': 'Select Report',
  'containers.reports.exportModal.ExportModal.start_date': 'Start Date',
  'containers.reports.exportModal.ExportModal.end_date': 'End Date',
  'containers.reports.exportModal.ExportModal.cancel': 'Cancel',
  'containers.reports.exportModal.ExportModal.download': 'Download',
  'containers.reports.exportModal.ExportModal.report': 'Report',
  'containers.reports.exportModal.ExportModal.payment_allocations_report':
    'Payment Allocations',
  'containers.reports.exportModal.ExportModal.ledger_report': 'Ledger',
  'containers.reports.exportModal.ExportModal.loading': 'Downloading...',
  'containers.reports.exportModal.ExportModal.order_report': 'Orders',
  'containers.reports.exportModal.ExportModal.balance_report': 'Balances',
  'containers.reports.exportModal.ExportModal.placeholder': 'Select A Report',
  'containers.reports.exportModal.ExportModal.cost_of_goods': 'Cost of Goods',
  'containers.reports.exportModal.ExportModal.no_show': 'No Show',

  'containers.reports.orderSummaryDrawer.OrderSummaryDrawer.title': 'Order',
  'containers.reports.orderSummaryDrawer.OrderDetails.order_number': 'Order: {n}',
  'containers.reports.orderSummaryDrawer.OrderDetails.order_details': 'Order Details',
  'containers.reports.orderSummaryDrawer.OrderDetails.date': 'Date',
  'containers.reports.orderSummaryDrawer.OrderDetails.order_date': '{date} • {time}',

  'containers.reports.orderSummaryDrawer.OrderDetails.order_method': 'Order Method',
  'containers.reports.orderSummaryDrawer.OrderDetails.in_store': 'In-Store (POS)',
  'containers.reports.orderSummaryDrawer.OrderDetails.online': 'Online',
  'containers.reports.orderSummaryDrawer.OrderDetails.sold_by': 'Sold By',
  'containers.reports.orderSummaryDrawer.OrderDetails.in_progress': 'In Progress',
  'containers.reports.orderSummaryDrawer.OrderDetails.completed': 'Completed',
  'containers.reports.orderSummaryDrawer.OrderDetails.cancelled': 'Canceled',
  'containers.reports.orderSummaryDrawer.OrderDetails.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.OrderDetails.partially_refunded': 'Partially Refunded',
  'containers.reports.orderSummaryDrawer.OrderDetails.waived': 'Waived',
  'containers.reports.orderSummaryDrawer.OrderDetails.partially_waived': 'Partially Waived',
  'containers.reports.orderSummaryDrawer.OrderDetails.pending_completion': 'Pending Completion',

  'containers.reports.orderSummaryDrawer.OrderDetails.card': 'Card',
  'containers.reports.orderSummaryDrawer.OrderDetails.cash': 'Cash',
  'containers.reports.orderSummaryDrawer.OrderDetails.check': 'Check',
  'containers.reports.orderSummaryDrawer.OrderDetails.pay_later': 'Pay Later',
  'containers.reports.orderSummaryDrawer.OrderDetails.credit': 'Credit',
  'containers.reports.orderSummaryDrawer.OrderDetails.card_present': 'Card Present',
  'containers.reports.orderSummaryDrawer.OrderDetails.null': '-',

  'containers.reports.orderSummaryDrawer.OrderDetails.total': 'Total',
  'containers.reports.orderSummaryDrawer.OrderDetails.subtotal': 'Subtotal',
  'containers.reports.orderSummaryDrawer.OrderDetails.discounts': 'Discounts',
  'containers.reports.orderSummaryDrawer.OrderDetails.taxes': 'Taxes',
  'containers.reports.orderSummaryDrawer.OrderDetails.fees': 'Service Fee',
  'containers.reports.orderSummaryDrawer.OrderDetails.insurance_amount': 'Insurance Amount',
  'containers.reports.orderSummaryDrawer.OrderDetails.payment_method': 'Payment Method',
  'containers.reports.orderSummaryDrawer.OrderDetails.status': 'Status',
  'containers.reports.orderSummaryDrawer.OrderDetails.remaining': 'Remaining',
  'containers.reports.orderSummaryDrawer.OrderDetails.refunds': 'Refunds',
  'containers.reports.orderSummaryDrawer.OrderDetails.waives': 'Waives',
  'containers.reports.orderSummaryDrawer.OrderDetails.paid': 'Paid',

  'containers.reports.orderSummaryDrawer.DotsMenu.send_receipt': 'Send Receipt',

  'containers.reports.orderSummaryDrawer.Content.items_title': 'Item(s)',

  'containers.reports.orderSummaryDrawer.OrderItemCard.refund': 'Refund',
  'containers.reports.orderSummaryDrawer.OrderItemCard.waive': 'Waive',
  'containers.reports.orderSummaryDrawer.OrderItemCard.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.OrderItemCard.waived': 'Waived',
  'containers.reports.orderSummaryDrawer.OrderItemCard.view_balance': 'View Balance',
  'containers.reports.orderSummaryDrawer.OrderItemCard.partial_status':
    'Partially {status, select, waived {Waived} refunded {Refunded} other {Refunded / Waived}}',

  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.refund': 'Refund',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.waive': 'Waive',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.price': 'Price',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.client': '{clientsCount, plural, one {Client} other {Clients}}',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.billed': 'Billed',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.membership_monthly': 'Monthly',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.membership_yearly': 'Yearly',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.qty': 'Qty. {qty}',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.orderItemCards.MembershipCard.waived': 'Waived',

  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.refund': 'Refund',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.waive': 'Waive',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.client': 'Client',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.price': 'Price',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.qty': 'Qty. {qty}',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.credits_for': 'Credit For',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.expires': 'Expires',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.waived': 'Waived',
  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCard.na': '-',

  'containers.reports.orderSummaryDrawer.orderItemCards.CreditPassCredits.all_event': 'All Events',

  'containers.reports.orderSummaryDrawer.orderItemCards.ExpirationMessage.never': 'No Expiration',
  'containers.reports.orderSummaryDrawer.orderItemCards.ExpirationMessage.expires_at': 'Expires On ',
  'containers.reports.orderSummaryDrawer.orderItemCards.ExpirationMessage.expiration_days':
    '{days} Days ',
  'containers.reports.orderSummaryDrawer.orderItemCards.ExpirationMessage.days_from_purchase': 'From Purchase',
  'containers.reports.orderSummaryDrawer.orderItemCards.ExpirationMessage.days_from_first_use': 'From First Use',

  'containers.reports.orderSummaryDrawer.orderItemCards.RetailCard.refund': 'Refund',
  'containers.reports.orderSummaryDrawer.orderItemCards.RetailCard.waive': 'Waive',
  'containers.reports.orderSummaryDrawer.orderItemCards.RetailCard.header': 'Retail',
  'containers.reports.orderSummaryDrawer.orderItemCards.RetailCard.qty': 'Qty. {qty}',
  'containers.reports.orderSummaryDrawer.orderItemCards.RetailCard.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.orderItemCards.RetailCard.waived': 'Waived',

  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.refund': 'Refund',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.waive': 'Waive',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.client': 'Client',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.qty': 'Qty. {qty}',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.credit_qty': '{qty} Credit(s)',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.credits': 'Credits',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.orderItemCards.OpenBookingClassEventCard.waived': 'Waived',

  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.refund': 'Refund',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.waive': 'Waive',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.client': '{clientsCount, plural, one {Client} other {Clients}}',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.qty': 'Qty. {qty}',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.dates': 'Dates',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.refunded': 'Refunded',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.waived': 'Waived',
  'containers.reports.orderSummaryDrawer.orderItemCards.FixedEventCard.plan_indicator': 'Payment Plan',

  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.clear': 'Clear Filters',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.online': 'Online',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.in_store': 'In-Store (POS)',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.card': 'Card',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.bank': 'Bank',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.card_present': 'Card Present',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.handpoint_cloud': 'Handpoint Cloud',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.cash': 'Cash',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.check': 'Check',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.credit': 'Credit',
  'containers.reports.ordersReport.OrdersReportHeader.ActiveFilters.pay_later': 'Pay Later',

  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.end_date': 'End Date',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.start_date': 'Start Date',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_today': 'Today',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_yesterday': 'Yesterday',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_this_week': 'This Week',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_last_week': 'Last Week',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_this_month': 'This Month',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_last_month': 'Last Month',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_this_quarter': 'This Quarter',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_last_quarter': 'Last Quarter',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_this_year': 'This Year',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_last_year': 'Last Year',
  'containers.reports.ordersReport.OrdersReportHeader.TimeRangeDropdown.item_custom': 'Custom',

  'containers.reports.ordersReport.OrdersReportHeader.OrdersReportHeader.header': 'Orders Report',
  'containers.reports.ordersReport.OrdersReportHeader.OrdersReportHeader.btn_filter': 'Filters',
  'containers.reports.ordersReport.OrdersReportHeader.OrdersReportHeader.btn_export': 'Export',
  'containers.reports.ordersReport.OrdersReportHeader.OrdersReportHeader.tooltip':
    `<p>
      This report is used to show the details of each Order, based on the
      original purchase date. An Order occurs when a client completes
      checkout via POS or Online.
    </p>
    <p>
      <b>This report only shows new orders</b>, so you will not see a new line within this report until they
      complete another checkout.
    </p>
    <p>
      After clicking on an individual order, you will have the ability to
      send a receipt and refund a specific item within that order.
    </p>`,
  'containers.reports.ordersReport.OrdersReportHeader.OrdersReportHeader.search': 'Search Order ID or Buyer',

  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.orders': 'Orders',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.amount': 'Amount',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.in_store': 'In-Store',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.online': 'Online',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.orders_tooltip': 'Total number of unique Orders',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.amount_tooltip': 'Total $ amount of Orders',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.in_store_tooltip': 'Total number of Orders received In-Store',
  'containers.reports.ordersReport.OrdersReportHeader.OrderStatistics.online_tooltip': 'Total number of Orders received Online',

  'containers.reports.ordersReport.OrdersColumnDrawer.title': 'Columns',
  'containers.reports.ordersReport.OrdersColumnDrawer.orderNumber': 'Order ID',
  'containers.reports.ordersReport.OrdersColumnDrawer.buyer': 'Buyer',
  'containers.reports.ordersReport.OrdersColumnDrawer.channel': 'Channel',
  'containers.reports.ordersReport.OrdersColumnDrawer.amount': 'Amount',
  'containers.reports.ordersReport.OrdersColumnDrawer.method': 'Method',

  'containers.reports.ordersReport.OrdersReportTable.HeaderDesktop.dateTime': 'Date • Time',
  'containers.reports.ordersReport.OrdersReportTable.HeaderDesktop.orderNumber': 'Order ID',
  'containers.reports.ordersReport.OrdersReportTable.HeaderDesktop.buyer': 'Buyer',
  'containers.reports.ordersReport.OrdersReportTable.HeaderDesktop.channel': 'Channel',
  'containers.reports.ordersReport.OrdersReportTable.HeaderDesktop.amount': 'Amount',
  'containers.reports.ordersReport.OrdersReportTable.HeaderDesktop.method': 'Method',

  'containers.reports.ordersReport.OrdersReportTable.HeaderMobile.statistic': '{count} • {amount}',

  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.date_today': 'Today • {date}',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.order_method_online': 'Online',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.order_method_in_store': 'In-Store (POS)',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_check': 'Check',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_cash': 'Cash',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_card': 'Card',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_credit': 'Credit',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_pay_later': 'Pay Later',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_card_present': 'Card',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_handpoint_cloud': 'Card',
  'containers.reports.ordersReport.OrdersReportTable.RowDesktop.payment_method_bank': 'Bank',

  'containers.reports.ordersReport.OrdersReportTable.RowMobile.date_today': 'Today • {date}',

  'containers.reports.ordersReport.OrdersReportTable.EmptyState.title': 'Nothing to see here.',
  'containers.reports.ordersReport.OrdersReportTable.EmptyState.description': 'Orders will show up when completed.',

  'containers.reports.balancesReport.Header.Header.search': 'Search Buyer',

  'containers.reports.balancesReport.Header.ActiveFilters.clear': 'Clear Filters',
  'containers.reports.balancesReport.Header.ActiveFilters.balance_max': 'Balance < {value}',
  'containers.reports.balancesReport.Header.ActiveFilters.balance_min': 'Balance > {value}',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_check': 'Check',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_cash': 'Cash',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_card': 'Card',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_bank': 'Bank',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_card_present': 'Card Present',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_payment_plan': 'Payment Plan',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_credit': 'Credit',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_pay_later': 'Pay Later',
  'containers.reports.balancesReport.Header.ActiveFilters.payment_method_handpoint_cloud': 'Handpoint Cloud',
  'containers.reports.balancesReport.Header.ActiveFilters.all_time': 'All Time',

  'containers.reports.balancesReport.Header.TimeRangeDropdown.end_date': 'End Date',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.start_date': 'Start Date',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_today': 'Today',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_yesterday': 'Yesterday',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_this_week': 'This Week',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_last_week': 'Last Week',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_this_month': 'This Month',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_last_month': 'Last Month',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_this_quarter': 'This Quarter',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_last_quarter': 'Last Quarter',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_this_year': 'This Year',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_last_year': 'Last Year',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_custom': 'Custom',
  'containers.reports.balancesReport.Header.TimeRangeDropdown.item_all_time': 'All Time',

  'containers.reports.balancesReport.Header.Header.header': 'Balances Report',
  'containers.reports.balancesReport.Header.Header.btn_filter': 'Filters',
  'containers.reports.balancesReport.Header.Header.btn_export': 'Export',
  'containers.reports.balancesReport.Header.Header.tooltip':
    `<p>
      The purpose of this report is to <b>show all money owed to your facility</b>
      and who owes it. Within the specified timeframe, it also allows you to
      see all orders with outstanding balances. From this report is where
      admin and staff can go to pay down a balance for the client.
    </p>`,

  'containers.reports.balancesReport.Header.Statistics.buyers': 'Unique Buyers',
  'containers.reports.balancesReport.Header.Statistics.buyers_tooltip': 'Total amount of all clients with outstanding balances',
  'containers.reports.balancesReport.Header.Statistics.total_paid': 'Paid Balance',
  'containers.reports.balancesReport.Header.Statistics.total_paid_tooltip': 'Total amount paid by all clients with outstanding balances',
  'containers.reports.balancesReport.Header.Statistics.outstanding_balance': 'Outstanding Balance',
  'containers.reports.balancesReport.Header.Statistics.outstanding_balance_tooltip': 'Total amount of all existing balances owed by clients',

  'containers.reports.balancesReport.ColumnDrawer.title': 'Columns',
  'containers.reports.balancesReport.ColumnDrawer.buyer': 'Buyer',
  'containers.reports.balancesReport.ColumnDrawer.product': 'Product',
  'containers.reports.balancesReport.ColumnDrawer.productType': 'Product Type',
  'containers.reports.balancesReport.ColumnDrawer.paymentType': 'Payment Type',
  'containers.reports.balancesReport.ColumnDrawer.paid': 'Paid',
  'containers.reports.balancesReport.ColumnDrawer.balance': 'Balance',
  'containers.reports.balancesReport.ColumnDrawer.quantity': 'Quantity',

  'containers.reports.balancesReport.Table.HeaderDesktop.dateTime': 'Purchase Date',
  'containers.reports.balancesReport.Table.HeaderDesktop.buyer': 'Buyer',
  'containers.reports.balancesReport.Table.HeaderDesktop.product': 'Product',
  'containers.reports.balancesReport.Table.HeaderDesktop.productType': 'Product Type',
  'containers.reports.balancesReport.Table.HeaderDesktop.paymentType': 'Payment Type',
  'containers.reports.balancesReport.Table.HeaderDesktop.paid': 'Paid',
  'containers.reports.balancesReport.Table.HeaderDesktop.balance': 'Balance',
  'containers.reports.balancesReport.Table.HeaderDesktop.quantity': 'Quantity',

  'containers.reports.balancesReport.Table.HeaderMobile.statistic': '{count} Unique Buyers • {amount}',

  'containers.reports.balancesReport.Table.RowDesktop.date_today': 'Today • {date}',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_check': 'Check',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_cash': 'Cash',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_card': 'Card',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_bank': 'Bank',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_card_present': 'Card',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_handpoint_cloud': 'Card',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_credit': 'Credit',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_pay_later': 'Pay Later',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.balancesReport.Table.RowDesktop.payment_method_payment_plan': 'Payment Plan',
  'containers.reports.balancesReport.Table.RowDesktop.scheduled_payment': 'Scheduled Payment',
  'containers.reports.balancesReport.Table.RowDesktop.credit_pass': 'Credit Pass',
  'containers.reports.balancesReport.Table.RowDesktop.membership': 'Membership',
  'containers.reports.balancesReport.Table.RowDesktop.membership_renewal': 'Membership Renewal',

  'containers.reports.balancesReport.Table.RowMobile.date_today': 'Today • {date}',

  'containers.reports.balancesReport.Table.EmptyState.title': 'Nothing to see here.',
  'containers.reports.balancesReport.Table.EmptyState.description_report': 'Balances will show up when outstanding.',
  'containers.reports.balancesReport.Table.EmptyState.description': 'Payments will show up when transacted.',

  'containers.reports.balancesReport.FilterDrawer.title': 'Filters',
  'containers.reports.balancesReport.FilterDrawer.clear': 'Clear Filters',
  'containers.reports.balancesReport.FilterDrawer.btn_apply': 'Apply Filters',
  'containers.reports.balancesReport.FilterDrawer.product': 'Product',
  'containers.reports.balancesReport.FilterDrawer.product_placeholder': 'Enter Product',
  'containers.reports.balancesReport.FilterDrawer.product_type': 'Product Type',
  'containers.reports.balancesReport.FilterDrawer.product_type_placeholder': 'Enter Product Type',
  'containers.reports.balancesReport.FilterDrawer.payment_type_placeholder': 'payment types',
  'containers.reports.balancesReport.FilterDrawer.payment_types': 'Payment Types',
  'containers.reports.balancesReport.FilterDrawer.date_range': 'Date Range',
  'containers.reports.balancesReport.FilterDrawer.location': 'Location',
  'containers.reports.balancesReport.FilterDrawer.location_placeholder': 'locations',
  'containers.reports.balancesReport.FilterDrawer.amount_range': 'Balance Range',
  'containers.reports.balancesReport.FilterDrawer.amount_min_placeholder': 'Start Amount',
  'containers.reports.balancesReport.FilterDrawer.amount_max_placeholder': 'End Amount',
  'containers.reports.balancesReport.FilterDrawer.balance_remaining_min': 'Min Balance Remaining',
  'containers.reports.balancesReport.FilterDrawer.balance_remaining_min_placeholder': 'Enter Min Balance Remaining',
  'containers.reports.balancesReport.FilterDrawer.balance_remaining_max': 'Max Balance Remaining',
  'containers.reports.balancesReport.FilterDrawer.balance_remaining_max_placeholder': 'Enter Max Balance Remaining',
  'containers.reports.balancesReport.FilterDrawer.payment_method_check': 'Check',
  'containers.reports.balancesReport.FilterDrawer.payment_method_cash': 'Cash',
  'containers.reports.balancesReport.FilterDrawer.payment_method_card': 'Card',
  'containers.reports.balancesReport.FilterDrawer.payment_method_bank': 'Bank',
  'containers.reports.balancesReport.FilterDrawer.payment_method_card_present': 'Card Present',
  'containers.reports.balancesReport.FilterDrawer.payment_method_handpoint_cloud': 'Handpoint Cloud',
  'containers.reports.balancesReport.FilterDrawer.payment_method_credit': 'Credit',
  'containers.reports.balancesReport.FilterDrawer.payment_method_pay_later': 'Pay Later',
  'containers.reports.balancesReport.FilterDrawer.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.balancesReport.FilterDrawer.payment_method_payment_plan': 'Payment Plan',

  'containers.reports.balancesReport.errors.balance_remaining_min': 'Min value is greater than the max value',
  'containers.reports.balancesReport.errors.balance_remaining_max': 'Max value is less than the min value',

  'containers.reports.balanceDetailsDrawer.BalanceDetailsDrawer.title': 'Balance',

  'containers.reports.balanceDetailsDrawer.DotsMenu.send_receipt': 'Send Receipt',
  'containers.reports.balanceDetailsDrawer.DotsMenu.pay_balance': 'Pay Balance',
  'containers.reports.balanceDetailsDrawer.DotsMenu.refund': 'Refund',
  'containers.reports.balanceDetailsDrawer.DotsMenu.waive': 'Waive',

  'containers.reports.balanceDetailsDrawer.Content.details': 'Details',
  'containers.reports.balanceDetailsDrawer.Content.plan_details': 'Payment Plan Details',
  'containers.reports.balanceDetailsDrawer.Content.order_details': 'Order Details',
  'containers.reports.balanceDetailsDrawer.Content.scheduled_payment': 'Scheduled Payment',

  'containers.reports.balanceDetailsDrawer.BalanceInfo.header': 'Outstanding Balance',
  'containers.reports.balanceDetailsDrawer.BalanceInfo.title': 'Balance Info',
  'containers.reports.balanceDetailsDrawer.BalanceInfo.title_date': 'Date',
  'containers.reports.balanceDetailsDrawer.BalanceInfo.date': '{date} • {time}',
  'containers.reports.balanceDetailsDrawer.BalanceInfo.title_paid': 'Paid',
  'containers.reports.balanceDetailsDrawer.BalanceInfo.title_total': 'Total',
  'containers.reports.balanceDetailsDrawer.BalanceInfo.quantity': 'Quantity',

  'containers.reports.balanceDetailsDrawer.DetailItem.date': '{date}',
  'containers.reports.balanceDetailsDrawer.DetailItem.date_time': '{date} • {time}',
  'containers.reports.balanceDetailsDrawer.DetailItem.view': 'View',
  'containers.reports.balanceDetailsDrawer.DetailItem.DebitForgiveness': 'Waived Fee',
  'containers.reports.balanceDetailsDrawer.DetailItem.Refund': 'Refund',
  'containers.reports.balanceDetailsDrawer.DetailItem.PaymentPlan': 'Payment Plan',
  'containers.reports.balanceDetailsDrawer.DetailItem.Payment': 'Payment',
  'containers.reports.balanceDetailsDrawer.DetailItem.failed_payment': 'Failed Payment',
  'containers.reports.balanceDetailsDrawer.DetailItem.OrderItem': 'Order Item',
  'containers.reports.balanceDetailsDrawer.DetailItem.MembershipRenewal': 'Membership Renewal',
  'containers.reports.balanceDetailsDrawer.DetailItem.ScheduledPayment': 'Scheduled Payment',
  'containers.reports.balanceDetailsDrawer.DetailItem.OrderFailure': 'Failed Order',
  'containers.reports.balanceDetailsDrawer.DetailItem.remaining_scheduled_payments': '{count} Scheduled Payments',

  'containers.reports.balanceListDrawer.BalanceListDrawer.title': 'History',

  'containers.reports.balanceListDrawer.Content.details_title': 'Balance List',

  'containers.reports.balanceListDrawer.Info.header': 'Total Outstanding',
  'containers.reports.balanceListDrawer.Info.title': 'History Info',
  'containers.reports.balanceListDrawer.Info.quantity': 'Quantity',
  'containers.reports.balanceListDrawer.Info.total': 'Total',
  'containers.reports.balanceListDrawer.Info.paid': 'Paid',

  'containers.reports.balanceListDrawer.EmptyState.title': 'Nothing to see here.',
  'containers.reports.balanceListDrawer.EmptyState.description': 'Balances will show up when outstanding.',

  'containers.reports.balanceListDrawer.DetailItem.date_today': 'Today • {date}',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_check': 'Check',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_cash': 'Cash',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_card': 'Card',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_credit': 'Credit',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_pay_later': 'Pay Later',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_payment_plan': 'Payment Plan',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_card_present': 'Card',
  'containers.reports.balanceListDrawer.DetailItem.payment_method_handpoint_cloud': 'Card',

  'containers.reports.payAllBalances.PayAllBalances.pay_all_balance': 'Pay Balance',
  'containers.reports.payAllBalances.PayAllBalances.continue': 'Continue',
  'containers.reports.payAllBalances.BalanceCard.date_today': 'Today • {date}',
  'containers.reports.payAllBalances.BalanceCard.payment_method_check': 'Check',
  'containers.reports.payAllBalances.BalanceCard.payment_method_cash': 'Cash',
  'containers.reports.payAllBalances.BalanceCard.payment_method_card': 'Card',
  'containers.reports.payAllBalances.BalanceCard.payment_method_credit': 'Credit',
  'containers.reports.payAllBalances.BalanceCard.payment_method_pay_later': 'Pay Later',
  'containers.reports.payAllBalances.BalanceCard.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.payAllBalances.BalanceCard.payment_method_payment_plan': 'Payment Plan',
  'containers.reports.payAllBalances.BalanceCard.payment_method_card_present': 'Card',
  'containers.reports.payAllBalances.BalanceCard.payment_method_handpoint_cloud': 'Card',

  'containers.reports.transactionsLedgerReport.Header.Header.search': 'Search Transaction ID or Buyer or Card #',

  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.clear': 'Clear Filters',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.amount_max': 'Amount < {value}',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.amount_min': 'Amount > {value}',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_Payment': 'Payment',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_Refund': 'Refund',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_PaymentPlan': 'Payment Plan',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_OrderItem': 'Order Item',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_MembershipRenewal': 'Membership Renewal',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_ScheduledPayment': 'Scheduled Payment',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_DebitForgiveness': 'Waived Fee',
  'containers.reports.transactionsLedgerReport.Header.ActiveFilters.source_type_OrderFailure': 'Failed Order',

  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.end_date': 'End Date',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.start_date': 'Start Date',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_today': 'Today',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_yesterday': 'Yesterday',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_this_week': 'This Week',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_last_week': 'Last Week',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_this_month': 'This Month',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_last_month': 'Last Month',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_this_quarter': 'This Quarter',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_last_quarter': 'Last Quarter',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_this_year': 'This Year',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_last_year': 'Last Year',
  'containers.reports.transactionsLedgerReport.Header.TimeRangeDropdown.item_custom': 'Custom',

  'containers.reports.transactionsLedgerReport.Header.Header.header': 'Ledger Report',
  'containers.reports.transactionsLedgerReport.Header.Header.btn_filter': 'Filters',
  'containers.reports.transactionsLedgerReport.Header.Header.btn_export': 'Export',
  'containers.reports.transactionsLedgerReport.Header.Header.tooltip':
    `<p>
      This report shows all transactions that occur within a facility during
      a specified timeframe. Giving a quick view or <b>all money coming in</b>,
      payments & returns, and <b>all money going out</b>, purchases & refunds, of a facility.
    </p>`,

  'containers.reports.transactionsLedgerReport.Header.Statistics.transaction_count': 'Transactions',
  'containers.reports.transactionsLedgerReport.Header.Statistics.buyers_count': 'Unique Buyers',

  'containers.reports.transactionsLedgerReport.Header.Statistics.transaction_count_tooltip': 'Total number of times money was exchanged (Debits and Credits)',
  'containers.reports.transactionsLedgerReport.Header.Statistics.buyers_count_tooltip': 'Total number of all Clients who made transactions',

  'containers.reports.transactionsLedgerReport.ColumnDrawer.title': 'Columns',
  'containers.reports.transactionsLedgerReport.ColumnDrawer.clientName': 'Buyer',
  'containers.reports.transactionsLedgerReport.ColumnDrawer.sourceType': 'Type',
  'containers.reports.transactionsLedgerReport.ColumnDrawer.amount': 'Amount',
  'containers.reports.transactionsLedgerReport.ColumnDrawer.transactionId': 'Transaction ID',
  'containers.reports.transactionsLedgerReport.ColumnDrawer.paymentMethod': 'Method',
  'containers.reports.transactionsLedgerReport.ColumnDrawer.last4': 'Card #',

  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.effectiveAt': 'Date • Time',
  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.clientName': 'Buyer',
  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.transactionId': 'Transaction ID',
  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.sourceType': 'Type',
  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.paymentMethod': 'Method',
  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.amount': 'Amount',
  'containers.reports.transactionsLedgerReport.Table.HeaderDesktop.last4': 'Card #',

  'containers.reports.transactionsLedgerReport.Table.HeaderMobile.statistic': '{count} Unique Buyers',

  'containers.reports.transactionsLedgerReport.Table.RowDesktop.date_today': 'Today • {date}',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_check': 'Check',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_cash': 'Cash',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_card': 'Card',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_credit': 'Credit',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_pay_later': 'Pay Later',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_credit_pass': 'Credit Pass',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_card_present': 'Card',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.payment_method_handpoint_cloud': 'Card',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_Payment': 'Payment',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_OrderItem': 'Order Item',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_Refund': 'Refund',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_PaymentPlan': 'Payment Plan',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_MembershipRenewal': 'Membership Renewal',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_ScheduledPayment': 'Scheduled Payment',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_DebitForgiveness': 'Waived Fee',
  'containers.reports.transactionsLedgerReport.Table.RowDesktop.source_OrderFailure': 'Failed Order',

  'containers.reports.transactionsLedgerReport.Table.RowMobile.date_today': 'Today • {date}',

  'containers.reports.transactionsLedgerReport.Table.EmptyState.title': 'Nothing to see here.',
  'containers.reports.transactionsLedgerReport.Table.EmptyState.description_report': 'Balances will show up when outstanding.',
  'containers.reports.transactionsLedgerReport.Table.EmptyState.description': 'Entries will show up when transacted.',

  'containers.reports.transactionsLedgerReport.FilterDrawer.title': 'Filters',
  'containers.reports.transactionsLedgerReport.FilterDrawer.clear': 'Clear Filters',
  'containers.reports.transactionsLedgerReport.FilterDrawer.btn_apply': 'Apply Filters',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_types_placeholder': 'types',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_types': 'Types',
  'containers.reports.transactionsLedgerReport.FilterDrawer.amount_min': 'Min Amount',
  'containers.reports.transactionsLedgerReport.FilterDrawer.amount_min_placeholder': 'Enter Min Amount',
  'containers.reports.transactionsLedgerReport.FilterDrawer.amount_max': 'Max Amount',
  'containers.reports.transactionsLedgerReport.FilterDrawer.amount_max_placeholder': 'Enter Max Amount',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_Payment': 'Payment',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_Refund': 'Refund',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_PaymentPlan': 'Payment Plan',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_OrderItem': 'Order Item',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_MembershipRenewal': 'Membership Renewal',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_ScheduledPayment': 'Scheduled Payment',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_DebitForgiveness': 'Waived Fee',
  'containers.reports.transactionsLedgerReport.FilterDrawer.source_type_OrderFailure': 'Failed Order',

  'containers.reports.transactionsLedgerReport.errors.amount_min': 'Min value is greater than the max value',
  'containers.reports.transactionsLedgerReport.errors.amount_max': 'Max value is less than the min value',

  'containers.reports.modeReport.ModeReport.export_btn': 'Export',
  'containers.reports.modeReport.ModeReport.export_csv': 'CSV',
  'containers.reports.modeReport.ModeReport.export_pdf': 'PDF',
  'containers.reports.modeReport.ModeReport.iframe_title': 'Mode report',
  'containers.reports.modeReport.ModeReport.error': 'Oops. Something went wrong while fetching report.',

  'containers.reports.dailyCloseoutReport.Header.CloseoutHeader.title': 'Daily Closeout Report',
  'containers.reports.dailyCloseoutReport.Header.Filters.today': 'Today',
  'containers.reports.dailyCloseoutReport.Header.Filters.yesterday': 'Yesterday',
  'containers.reports.dailyCloseoutReport.Header.Filters.calculator': 'Calculator',
  'containers.reports.dailyCloseoutReport.Header.Filters.filters': 'Filters',
  'containers.reports.dailyCloseoutReport.Header.Filters.search': 'Search',
  'containers.reports.dailyCloseoutReport.Header.Filters.export': 'Export',
  'containers.reports.dailyCloseoutReport.Header.Statistics.total': 'Total Cash Amount',
  'containers.reports.dailyCloseoutReport.Header.Statistics.total_tooltip': 'The total amount of cash payments received.',
  'containers.reports.dailyCloseoutReport.Header.Statistics.transactions': 'Transactions',
  'containers.reports.dailyCloseoutReport.Header.Statistics.transactions_tooltip': 'The total number of transactions.',
  'containers.reports.dailyCloseoutReport.Header.Statistics.unique_buyers': 'Unique Buyers',
  'containers.reports.dailyCloseoutReport.Header.Statistics.unique_buyers_tooltip': 'The total number of unique buyers.',
  'containers.reports.dailyCloseoutReport.Table.Header.date_time': 'Date • Time',
  'containers.reports.dailyCloseoutReport.Table.Header.order_number': 'Order ID',
  'containers.reports.dailyCloseoutReport.Table.Header.buyer': 'Buyer',
  'containers.reports.dailyCloseoutReport.Table.Header.product': 'Product',
  'containers.reports.dailyCloseoutReport.Table.Header.payment_method': 'Payment Method',
  'containers.reports.dailyCloseoutReport.Table.Header.amount': 'Amount',
  'containers.reports.dailyCloseoutReport.Calculator.CalculatorDrawer.title': 'Calculator',
  'containers.reports.dailyCloseoutReport.Calculator.CalculatorDrawer.total': 'Total',
  'containers.reports.dailyCloseoutReport.Calculator.CalculatorDrawer.cash_in': 'Cash In',
  'containers.reports.dailyCloseoutReport.Calculator.CalculatorDrawer.cash_in_placeholder': 'Enter Starting Cash Amount',
  'containers.reports.dailyCloseoutReport.Calculator.CalculatorDrawer.cash_today': 'Cash Payments Today',

  'containers.reports.dailyCloseoutReport.Filters.FiltersDrawer.title': 'Filters',
  'containers.reports.dailyCloseoutReport.Filters.FiltersDrawer.min_total': 'Min Amount',
  'containers.reports.dailyCloseoutReport.Filters.FiltersDrawer.min_total_placeholder': 'Enter Min Amount',
  'containers.reports.dailyCloseoutReport.Filters.FiltersDrawer.max_total': 'Max Amount',
  'containers.reports.dailyCloseoutReport.Filters.FiltersDrawer.max_total_placeholder': 'Enter Max Amount',
  'containers.reports.dailyCloseoutReport.Filters.FiltersDrawer.apply': 'Apply Filters',


  'containers.browseEvents.FixedScheduleCard.day_count': '{count} days',
  'containers.browseEvents.FixedScheduleCard.date_fallback': '-',

  'containers.browseEvents.OpenBookingCard.session': 'Session',
  'containers.browseEvents.OpenBookingCard.staff_count': '{count} instructors',

  'containers.browseEvents.ExpandableLocationFilter.location': 'Location',
  'containers.browseEvents.ExpandableLocationFilter.all': 'All',

  'containers.browseEventsBookDrawer.Header.title': 'Reserve',
  'containers.browseEventsBookDrawer.session_booked':
    'Your session was successfully booked.',

  'containers.openBookingDrawer.AuthOpenBookingDrawer.book': 'Book',
  'containers.openBookingDrawer.AccountProfileButtons.buy_credits': 'Buy Credits',
  'containers.openBookingDrawer.AccountProfileButtons.schedule_sessions': 'Schedule Sessions',
  'containers.openBookingDrawer.AccountProfile.creditCtTooltip': 'You currently have {creditsRemaining} credits available to use for booking.',
  'containers.openBookingDrawer.AccountProfile.unlimitedCtTooltip':
    'You currently have unlimited credits available to use for booking.',
  'containers.openBookingDrawer.AccountProfiles.title': 'Account Profiles',

  'containers.classes.classesList.ClassesList.title': 'Classes',
  'containers.classes.classesList.ClassesList.create_session_title': 'Create Sessions',
  'containers.classes.classesList.ClassesList.no_class_selected': 'Please select a class to see additional info.',

  'containers.classes.classesList.Classes.status_all': `Status: All`,
  'containers.classes.classesList.Classes.status_active': `Status: Active`,
  'containers.classes.classesList.Classes.status_completed': `Status: Completed`,
  'containers.classes.classesList.Classes.status_cancelled': `Status: Cancelled`,
  'containers.classes.classesList.Classes.status_draft': `Status: Draft`,
  'containers.classes.classesList.Classes.status_deleted': `Status: Deleted`,
  'containers.classes.classesList.Classes.view_more': 'View more',
  'containers.classes.classesList.ClassCard.no_title': 'No title',
  'containers.classes.classesList.AdvancedSearch.staff_all': `Staff: All`,
  'containers.classes.classesList.AdvancedSearch.staff': `Staff: {name}`,
  'containers.classes.classesList.AdvancedSearch.resource': `Resource: {name}`,
  'containers.classes.classesList.AdvancedSearch.resource_all': `Resource: All`,
  'containers.classes.classesList.AdvancedSearch.location': `Location: {name}`,
  'containers.classes.classesList.AdvancedSearch.location_all': `Location: All`,
  'containers.classes.classesList.AdvancedSearch.view_schedule': 'View Schedules',
  'containers.classes.classesList.AdvancedSearch.view_sessions': 'View Sessions',
  'containers.classes.classesList.Sessions.EmptyState.no_upcoming_sessions': `No upcoming sessions for this class.`,
  'containers.classes.classesList.Sessions.EmptyState.no_past_sessions': `No past sessions for this class.`,
  'containers.classes.classesList.Sessions.EmptyState.no_class_selected': `Please select a class to see sessions list.`,
  'containers.classes.classesList.Sessions.SessionsList.view_attendees': `View Attendees`,
  'containers.classes.classesList.Sessions.SessionsList.view_session': `View Session`,
  'containers.classes.classesList.Sessions.SessionsList.duration': `{startsAt} • {duration}m`,
  'containers.classes.classesList.Sessions.SessionsList.client_count': '{clientCount} Attendees',
  'containers.classes.classesList.Sessions.SessionsList.attendance_details': '{clientCount} / {maxClients, select, Infinity {∞} other {{maxClients}}}',
  'containers.classes.classesList.Sessions.SessionsList.cancel': 'Cancel Session',
  'containers.classes.classesList.Sessions.SessionsList.cancelled': 'Cancelled Session',
  'containers.classes.classesList.Sessions.SessionsList.conflicting': 'Conflicting Session',
  'containers.classes.classesList.Sessions.SessionsList.date': 'Date',
  'containers.classes.classesList.Sessions.SessionsList.time': 'Time',
  'containers.classes.classesList.Sessions.SessionsList.location': 'Location',
  'containers.classes.classesList.Sessions.SessionsList.staff': 'Staff',
  'containers.classes.classesList.Sessions.SessionsList.attendees': 'Attendees',
  'containers.classes.classesList.Sessions.SessionsList.checked_in': 'Checked In',
  'containers.classes.classesList.Sessions.SessionsList.resources': 'Resources',
  'containers.classes.classesList.Sessions.StaffSection.staff_count': 'staff',

  'containers.classes.classesList.Schedules.ScheduleCard.daily': 'Daily',
  'containers.classes.classesList.Schedules.ScheduleCard.start_at': '{startsAt}',
  'containers.classes.classesList.Schedules.ScheduleCard.multiple_start_at': 'Multiple times',
  'containers.classes.classesList.Schedules.ScheduleCard.duration': ` • {duration}m`,
  'containers.classes.classesList.Schedules.ScheduleCard.daily_repeat': `Every {interval} Days`,
  'containers.classes.classesList.Schedules.ScheduleCard.every_day_repeat': `Every day`,
  'containers.classes.classesList.Schedules.ScheduleCard.dates': '{start} - {end}',
  'containers.classes.classesList.Schedules.ScheduleCard.edit_schedule': 'Edit Schedule',
  'containers.classes.classesList.Schedules.ScheduleCard.cancel_schedule': 'Cancel Schedule',
  'containers.classes.classesList.Schedules.ScheduleCard.status_cancelled': 'Cancelled Schedule',

  'containers.classes.classesList.Schedules.SchedulesList.date': 'Date',
  'containers.classes.classesList.Schedules.SchedulesList.time': 'Time',
  'containers.classes.classesList.Schedules.SchedulesList.location': 'Location',
  'containers.classes.classesList.Schedules.SchedulesList.staff': 'Staff',
  'containers.classes.classesList.Schedules.SchedulesList.days': 'Day(s)',
  'containers.classes.classesList.Schedules.SchedulesList.resources': 'Resources',

  'containers.classes.classesList.Schedules.EmptyState.no_upcoming_schedules': `No upcoming schedules for this class.`,
  'containers.classes.classesList.Schedules.EmptyState.no_past_schedules': `No past schedules for this class.`,
  'containers.classes.classesList.Schedules.StaffSection.staff_count': 'staff',

  'containers.classes.classesList.Roster.ClientCard.female': 'F',
  'containers.classes.classesList.Roster.ClientCard.male': 'M',
  'containers.classes.classesList.Roster.ClientCard.remove': 'Remove',
  'containers.classes.classesList.Roster.ClientCard.view': 'View',
  'containers.classes.classesList.Roster.EmptyState.no_results': 'No results found.',
  'containers.classes.classesList.Roster.EmptyState.no_clients': 'No clients registered for this class.',
  'containers.classes.classesList.Tabs.Roster.name': 'Name',
  'containers.classes.classesList.Tabs.Roster.managed_by': 'Managed By',
  'containers.classes.classesList.Tabs.Roster.age': 'Age',
  'containers.classes.classesList.Tabs.Roster.gender': 'Gender',
  'containers.classes.classesList.Tabs.Roster.phone': 'Phone',
  'containers.classes.classesList.Tabs.Roster.search': 'Search Roster',

  'containers.classes.classesList.Staff.EmptyState.no_staff': 'No staff assigned for this class.',
  'containers.classes.classesList.Tabs.Staff.name': 'Name',
  'containers.classes.classesList.Tabs.Staff.phone': 'Phone',
  'containers.classes.classesList.Tabs.Staff.email': 'Email',

  'containers.classes.classesList.Header.edit': 'Edit',
  'containers.classes.classesList.Header.completed': 'Complete',
  'containers.classes.classesList.Header.cancel': 'Cancel',
  'containers.classes.classesList.Header.export_attendance': 'Export Attendance',
  'containers.classes.classesList.Header.send_email': 'Send Marketing Email',
  'containers.classes.classesList.Header.create_class': '+ Create Class',

  'containers.classes.classesList.Tabs.Schedule.show_all_cancelled': 'Show Cancelled Sessions',

  'containers.classes.classesCreation.ClassCreateModal.create_title': 'Create Class',
  'containers.classes.classesCreation.ClassCreateModal.update_title': 'Update Class',
  'containers.classes.classesCreation.ClassCreateModal.create_label': 'Create',
  'containers.classes.classesCreation.ClassCreateModal.create_label_loading': 'Creating...',
  'containers.classes.classesCreation.ClassCreateModal.update_label': `Update`,
  'containers.classes.classesCreation.ClassCreateModal.update_label_loading': 'Updating...',
  'containers.classes.classesCreation.ClassCreateModal.title_label': 'Title',
  'containers.classes.classesCreation.ClassCreateModal.title_placeholder': 'Enter Class Title',
  'containers.classes.classesCreation.ClassCreateModal.event_type_label': 'Event Type',
  'containers.classes.classesCreation.ClassCreateModal.description_label': 'Description',
  'containers.classes.classesCreation.ClassCreateModal.image_label': 'Image',
  'containers.classes.classesCreation.ClassCreateModal.warning_message': 'This class will not be public until you create sessions.',
  'containers.classes.classesCreation.ClassCreateModal.create_success': 'Class successfully created!',
  'containers.classes.classesCreation.ClassCreateModal.update_success': 'Class successfully updated!',
  'containers.classes.classesCreation.ClassCreateModal.complete_class_success': 'Your class status has been changed to completed!',
  'containers.classes.classesCreation.ClassCreateModal.validation.required_field': 'Required field',

  'containers.classes.classesCancellation.ClassCancelModal.title': 'Cancel Сlass?',
  'containers.classes.classesCancellation.ClassCancelModal.warning': 'Cancelling will remove all sessions from the schedule. The sessions will also be removed from all participants upcoming events.',
  'containers.classes.classesCancellation.ClassCancelModal.options': 'Please select the appropriate option below:',
  'containers.classes.classesCancellation.ClassCancelModal.refund_title': 'Return Session Credits',
  'containers.classes.classesCancellation.ClassCancelModal.refund_subtitle': 'Compensation will be granted via credit to participant(s).',
  'containers.classes.classesCancellation.ClassCancelModal.revoke_title': 'Revoke Session Credits',
  'containers.classes.classesCancellation.ClassCancelModal.revoke_subtitle': 'Credit(s) will NOT be returned to participant(s).',
  'containers.classes.classesCancellation.ClassCancelModal.success_message': 'Class canceled successfully!',
  'containers.classes.classesCancellation.ClassCancelModal.note_title': 'Important note:',
  'containers.classes.classesCancellation.ClassCancelModal.note_subtitle': 'If compensation is granted in the form of credits, any “pay later” balances will remain on the Balances Report until they are fully paid. Actions cannot be undone.',

  'containers.eventSessionCreation.SessionCreationDrawer.team_title': 'Add Team Schedule',
  'containers.eventSessionCreation.SessionCreationDrawer.team_name': 'Name Team Schedule',
  'containers.eventSessionCreation.SessionCreationDrawer.title': 'Add Class Schedule',
  'containers.eventSessionCreation.SessionCreationDrawer.title_edit': 'Edit Class Schedule',
  'containers.eventSessionCreation.SessionCreationDrawer.team_title_edit': 'Edit Team Schedule',
  'containers.eventSessionCreation.Schedule.create_schedule': 'Create Schedule',
  'containers.eventSessionCreation.Schedule.start_date': 'Start Date',
  'containers.eventSessionCreation.Schedule.repeat': 'Repeat',
  'containers.eventSessionCreation.Schedule.repeats': 'Repeats',
  'containers.eventSessionCreation.Schedule.every': 'Every',
  'containers.eventSessionCreation.Schedule.repeat_on': 'Repeat On',
  'containers.eventSessionCreation.Schedule.ends': 'Ends',
  'containers.eventSessionCreation.Schedule.repeat_never': 'Never',
  'containers.eventSessionCreation.Schedule.repeat_until': 'On a Date',
  'containers.eventSessionCreation.Schedule.repeat_occurrences': '# of Times',
  'containers.eventSessionCreation.Schedule.location': 'Location',
  'containers.eventSessionCreation.Schedule.after': 'After Time(s)',
  'containers.eventSessionCreation.Schedule.daily': 'Set Daily Time',
  'containers.eventSessionCreation.Schedule.day_specific': 'Day specific',
  'containers.eventSessionCreation.Schedule.dates': 'Dates',
  'containers.eventSessionCreation.Schedule.name_team_schedule': 'Name Team Schedule',
  'containers.eventSessionCreation.Schedule.schedule_type': 'Schedule Type',
  'containers.eventSessionCreation.Schedule.select_schedule_type': 'Select Schedule Type',

  'containers.eventSessionCreation.BlackoutDates.blackout': 'Blackout Dates',
  'containers.eventSessionCreation.BlackoutDates.add': 'Add Blackout Day',

  'containers.eventSessionCreation.Participants.class_size': 'Class Size',
  'containers.eventSessionCreation.Participants.age': 'Age',
  'containers.eventSessionCreation.Participants.gender': 'Gender',
  'containers.eventSessionCreation.Participants.scheduling_deadline': 'Scheduling Deadline',
  'containers.eventSessionCreation.Participants.cancellation_deadline': 'Cancellation Deadline',
  'containers.eventSessionCreation.Participants.before_session': 'Before session begins',
  'containers.eventSessionCreation.Participants.scheduling_info': 'The scheduling deadline restricts scheduling based on a set amount of time before a session begins.',
  'containers.eventSessionCreation.Participants.cancellation_info': 'Set a cancellation deadline to limit the amount of time a client can cancel before the start of a session.',

  'containers.eventSessionCreation.StaffAndResources.staff': 'Staff',
  'containers.eventSessionCreation.StaffAndResources.no_staff': 'No staff assigned',
  'containers.eventSessionCreation.StaffAndResources.staff_available': 'Available',
  'containers.eventSessionCreation.StaffAndResources.resources': 'Resources',
  'containers.eventSessionCreation.StaffAndResources.no_resources': 'No resources assigned',

  'containers.eventSessionCreation.StaffDrawer.title': 'Add Staff',
  'containers.eventSessionCreation.StaffDrawer.search': 'Search',
  'containers.eventSessionCreation.StaffDrawer.available': 'Available',
  'containers.eventSessionCreation.StaffDrawer.save_btn': 'Save',

  'containers.eventSessionCreation.ResourcesDrawer.title': 'Add Resources',
  'containers.eventSessionCreation.ResourcesDrawer.search': 'Search',
  'containers.eventSessionCreation.ResourcesDrawer.available': 'Available',
  'containers.eventSessionCreation.ResourcesDrawer.save_btn': 'Save',

  'containers.eventSessionCreation.Schedule.validation.required_field': 'Required field',
  'containers.eventSessionCreation.Schedule.validation.start_time': 'Start time cannot be before current time',
  'containers.eventSessionCreation.Schedule.validation.stops_by_date': 'End date cannot be before start date',
  'containers.eventSessionCreation.Schedule.validation.start_date': 'Start date cannot be after end date',
  'containers.eventSessionCreation.Schedule.validation.end_time': 'End time cannot be before current time',

  'containers.eventSessionCreation.DayTimes.add_another': 'Add another',

  'containers.eventSessionCreation.EditConfirm.team_title': 'Team schedule edit confirm',
  'containers.eventSessionCreation.EditConfirm.title': 'Class schedule edit confirm',
  'containers.eventSessionCreation.EditConfirm.ok_btn': 'OK',
  'containers.eventSessionCreation.EditConfirm.cancel_btn': 'Cancel',
  'containers.eventSessionCreation.EditConfirm.message': 'Schedule Updated: Attendees will be removed and credit returned to their account',

  'containers.eventScheduleCancellation.CancelScheduleModal.team_title': 'Cancel Team schedule?',
  'containers.eventScheduleCancellation.CancelScheduleModal.title': 'Cancel class schedule?',
  'containers.eventScheduleCancellation.CancelScheduleModal.warning': 'Cancelling will remove all sessions from the schedule. The sessions will also be removed from all participants upcoming events.',
  'containers.eventScheduleCancellation.CancelScheduleModal.options': 'Please select the appropriate option below:',
  'containers.eventScheduleCancellation.CancelScheduleModal.refund_title': 'Return Session Credits',
  'containers.eventScheduleCancellation.CancelScheduleModal.refund_subtitle': 'Compensation will be granted via credit to participant(s).',
  'containers.eventScheduleCancellation.CancelScheduleModal.revoke_title': 'Revoke Session Credits',
  'containers.eventScheduleCancellation.CancelScheduleModal.revoke_subtitle': 'Credit(s) will NOT be returned to participant(s).',
  'containers.eventScheduleCancellation.CancelScheduleModal.success_message': 'Schedule canceled successfully!',
  'containers.eventScheduleCancellation.CancelScheduleModal.note_title': 'Important note:',
  'containers.eventScheduleCancellation.CancelScheduleModal.note_subtitle': 'If compensation is granted in the form of credits, any “pay later” balances will remain on the Balances Report until they are fully paid. Actions cannot be undone.',

  'shared.scheduling.DateSpecificDaytimePicker.add_date_specific_date':
    'Add Date',
  'shared.scheduling.DateSpecificDaytimePicker.date_specific_availability_description':
    'Use date specific availability to specify a block of time as available or unavailable.',
  'shared.scheduling.TimeRangeList.add_time_range': 'Add Another',
  'shared.scheduling.TimeRangeList.end_time': 'End time',
  'shared.scheduling.TimeRangeList.remove_time': 'Remove time',
  'shared.scheduling.TimeRangeList.start_time': 'Start time',

  'shared.scheduling.WeekdayPicker.friday': 'Fr',
  'shared.scheduling.WeekdayPicker.monday': 'Mo',
  'shared.scheduling.WeekdayPicker.saturday': 'Sa',
  'shared.scheduling.WeekdayPicker.sunday': 'Su',
  'shared.scheduling.WeekdayPicker.thursday': 'Th',
  'shared.scheduling.WeekdayPicker.tuesday': 'Tu',
  'shared.scheduling.WeekdayPicker.wednesday': 'We',

  'shared.OpenBookingRegistrations.remove_booking': 'Remove Booking',
  'shared.OpenBookingRegistrations.return': 'Return to Booking',
  'shared.OpenBookingRegistrations.complete': 'Complete',
  'shared.OpenBookingRegistrations.remove_booking_confirmation':
    'Are you sure?  You will be removed from this scheduled session.',
  'shared.OpenBookingRegistrations.credit_expired':
    'Unfortunately your credit expired on {expDate} and you will not be refunded the credit for future use.',
  'shared.OpenBookingRegistrations.credit_refunded':
    'You will be refunded the credit for future use.',

  'shared.OpenBookingScheduler.info': 'Choose staff, select a profile, pick a date, and select available sessions to add to your cart.',
  'shared.OpenBookingScheduler.choose_staff': 'Choose Staff:',
  'shared.OpenBookingScheduler.any_staff': 'Staff: Any available',
  'shared.OpenBookingScheduler.choose_profile': 'Choose Profile:',
  'shared.OpenBookingScheduler.new_profile': '+ New Profile',
  'shared.OpenBookingScheduler.select_profile': 'Select Profile',
  'shared.OpenBookingScheduler.available_credits': 'Available credits: {creditsRemaining}',
  'shared.OpenBookingScheduler.date_warning': 'Select date to view available times',
  'shared.OpenBookingScheduler.package_pricing_warning': 'Package pricing enabled. You only have the option to purchase the given amount of sessions:',
  'shared.OpenBookingScheduler.package_pricing_warning_sessions':'{sessionsCount} {sessionsCount, plural, one {session} other {sessions}}',
  'shared.OpenBookingScheduler.pkg_pre_or':'or ',
  'shared.OpenBookingScheduler.pkg_after_or':' or',
  'shared.OpenBookingScheduler.credits_warning': '{sessionsCount} {sessionsCount, plural, one {session} other {sessions}} selected but only {creditsRemaining} {creditsRemaining, plural, one {credit is} other {credits are}} available. {creditsRemaining} {creditsRemaining, plural, one {session} other {sessions}} will be booked, and {sessionBooked} will be added to the cart.',
  'shared.OpenBookingScheduler.credits_warning_pkg_pricing': '{sessionsCount} {sessionsCount, plural, one {session} other {sessions}} selected. {creditsRemaining} {creditsRemaining, plural, one {credit} other {credits}} available. {sessionBooked} {sessionBooked, plural, one {session} other {sessions}} will be booked. Please remove or add more sessions to complete package requirements.',
  'shared.OpenBookingScheduler.add_to_cart': 'Add {sessionsCount} Session(s) to Cart',
  'shared.OpenBookingScheduler.adding_to_cart': 'Adding to cart...',
  'shared.OpenBookingScheduler.book': 'Book {sessionsCount} Session(s)',
  'shared.OpenBookingScheduler.booking': 'Booking Session(s)...',
  'shared.OpenBookingScheduler.skip_scheduling': 'Skip Scheduling',

  'shared.OpenBookingScheduler.reschedule_current_session': 'Current session',
  'shared.OpenBookingScheduler.reschedule_profile': 'Profile:',
  'shared.OpenBookingScheduler.reschedule_choose_staff': 'Staff:',
  'shared.OpenBookingScheduler.reschedule_multiple_staff': 'Multiple staff',
  'shared.OpenBookingScheduler.reschedule_cancel': 'Cancel',
  'shared.OpenBookingScheduler.reschedule_confirm': 'Confirm Updates',
  'shared.OpenBookingScheduler.reschedule_confirmed': 'Updating...',

  'shared.OpenBookingSchedulerNoScheduling.choose_profile': 'Choose Profile',
  'shared.OpenBookingSchedulerNoScheduling.choose_package': 'Select a Package',
  'shared.OpenBookingSchedulerNoScheduling.add_to_cart': 'Add Credits to Cart',
  'shared.OpenBookingSchedulerNoScheduling.return_to_scheduling': 'Return to Scheduling',

  'shared.stores.accounting_codes.AccountingCodeArchivingStore.archived':
    'Accounting code archived.',

  'shared.stores.accounting_codes.AccountingCodeCreationStore.created':
    'Accounting code created.',

  'shared.stores.accounting_codes.AccountingCodeEditingStore.updated':
    'Accounting code updated.',
  'shared.stores.service_fee.ServiceFeeArchivingStore.archived':
    'Service fee archived.',
  'shared.stores.service_fee.ServiceFeeCreationStore.created':
    'Service fee created.',

  'shared.stores.service_fee.ServiceFeeEditingStore.updated':
    'Service fee updated.',

  'shared.stores.tax_rates.TaxRateCreationStore.created': 'Tax rate created.',

  'shared.stores.tax_rates.TaxRateEditingStore.updated': 'Tax rate updated.',

  'shared.stores.TaskCreationStore.created': 'Task created.',

  'shared.stores.TaskDeletionStore.deleted': 'Task deleted.',

  'shared.stores.TaskEditingStore.updated': 'Task updated.',
  'shared.stores.fee_rates.FeeRateCreationStore.created': 'Fee rate created.',

  'shared.stores.fee_rates.FeeRateEditingStore.updated': 'Fee rate updated.',

  'shared.stores.ZebraStore.connection_error': 'Failed to fetch settings',
  'shared.stores.ZebraStore.failed_to_connect': 'Failed to connect Printer',
  'shared.stores.ZebraStore.failed_to_get_sn': 'Falied to save the Printer',
  'shared.stores.ZebraStore.no_printer_selected': 'No printer selected.',
  'shared.stores.ZebraStore.printer_connected': 'Printer Connected',
  'shared.stores.ZebraStore.printer_error': 'Printer Error',
  'shared.stores.ZebraStore.printer_not_found':
    'Could not Connect to printer. Attempting to Reconnect.',
  'shared.stores.ZebraStore.printer_not_found_bluetooth':
    'Could not find network printer. Searching for bluetooth printers.',

  'shared._SignInPrompt.create_an_account': 'Create an Account',
  'shared._SignInPrompt.login_button_label': 'Login',
  'shared._SignInPrompt.login_to_purchase_message':
    'Login or create account to purchase.',
  'shared._SignInPrompt.login_to_join_waitlist': `This ${nouns.event
    } is currently full. Login or create an account to join the waiting list.`,
  'shared._SignInPrompt.or_create_account_message': 'or {link}',

  'shared.stores.ClientStoreInterface.client_update_success':
    'Client updated successfully.',

  'shared.TimeTrackingMenu.choose_task': 'Choose Task',
  'shared.TimeTrackingMenu.choose_location': 'Choose Location',
  'shared.TimeTrackingMenu.clock_in': 'Clock In',
  'shared.TimeTrackingMenu.clocking_in': 'Clocking in...',
  'shared.TimeTrackingMenu.clock_out': 'Clock Out',
  'shared.TimeTrackingMenu.clocking_out': 'Clocking out...',
  'shared.TimeTrackingMenu.task': 'Task',
  'shared.TimeTrackingMenu.location': 'Location',

  'shared.Unauthorized.message': 'Unauthorized',

  'shared.zebra.PrintSettingsDrawer.bluetooth': 'Bluetooth Connected',
  'shared.zebra.PrintSettingsDrawer.choose_printer': 'Choose Printer',
  'shared.zebra.PrintSettingsDrawer.connection_error': 'Connection Error',
  'shared.zebra.PrintSettingsDrawer.cutter_fault': 'Cutter Fault',
  'shared.zebra.PrintSettingsDrawer.empty_state_message':
    "You don't have a printer setup!",
  'shared.zebra.PrintSettingsDrawer.incorrect_printhead': 'Incorrect Printhead',
  'shared.zebra.PrintSettingsDrawer.media_door_open': 'Media Door Open',
  'shared.zebra.PrintSettingsDrawer.motor_overheating': 'Motor Overheating',
  'shared.zebra.PrintSettingsDrawer.none': 'None',
  'shared.zebra.PrintSettingsDrawer.paper_out': 'Paper Out',
  'shared.zebra.PrintSettingsDrawer.printer': 'Printer',
  'shared.zebra.PrintSettingsDrawer.printing_error': 'Printing Error',
  'shared.zebra.PrintSettingsDrawer.printer_settings': 'Printer Settings',
  'shared.zebra.PrintSettingsDrawer.printer_status': 'Printer Status:',
  'shared.zebra.PrintSettingsDrawer.printhead_fault': 'Printhead Fault',
  'shared.zebra.PrintSettingsDrawer.printhead_overheating':
    'Printhead Overheating',
  'shared.zebra.PrintSettingsDrawer.printer_paused': 'Printer Paused',
  'shared.zebra.PrintSettingsDrawer.ready_to_print': 'Ready To Print',
  'shared.zebra.PrintSettingsDrawer.test': 'Test',
  'shared.zebra.PrintSettingsDrawer.test_label': 'Print Label',
  'shared.zebra.PrintSettingsDrawer.printing': 'Printing',
  'shared.zebra.PrintSettingsDrawer.test_receipt': 'Print Receipt',
  'shared.zebra.PrintSettingsDrawer.ribbon_out': 'Ribbon Out',
  'shared.zebra.PrintSettingsDrawer.search_for_printers': 'Search for Printer',
  'shared.zebra.PrintSettingsDrawer.unknown_error': 'Unknown Error',

  'shared.zebra.ZebraMediaSettings.media_setting': 'Media Settings',
  'shared.zebra.ZebraMediaSettings.label_dimensions': 'Label Dimensions',
  'shared.zebra.ZebraMediaSettings.label_description':
    'Place the label width and height in the boxes below',
  'shared.zebra.ZebraMediaSettings.width': 'Width (Inches)',
  'shared.zebra.ZebraMediaSettings.height': 'Height (Inches)',
  'shared.zebra.ZebraMediaSettings.receipt_dimensions': 'Receipt Dimensions',
  'shared.zebra.ZebraMediaSettings.receipt_description':
    'Place the width of the receipt in the box below',
  'shared.zebra.ZebraMediaSettings.media': 'Media',

  'shared.zebra.ZebraWifiSetup.network': 'Network',
  'shared.zebra.ZebraWifiSetup.network_setup': 'Network Setup',
  'shared.zebra.ZebraWifiSetup.printer_name': 'Printer Name',
  'shared.zebra.ZebraWifiSetup.ssid': 'SSID',
  'shared.zebra.ZebraWifiSetup.password': 'Password',
  'shared.zebra.ZebraWifiSetup.reset': 'Reset',
  'shared.zebra.ZebraWifiSetup.reset_warning':
    'This will require the printer to reset. Is this okay?',

  'shared.Scrollbar.loading': 'Loading...',

  "shared.constants.sportTypes.after_school_sports": "After School Sports",
  "shared.constants.sportTypes.archery": "Archery",
  "shared.constants.sportTypes.badminton": "Badminton",
  "shared.constants.sportTypes.baseball": "Baseball",
  "shared.constants.sportTypes.baseball_t_ball": "Baseball & T-Ball",
  "shared.constants.sportTypes.basketball": "Basketball",
  "shared.constants.sportTypes.bowling": "Bowling",
  "shared.constants.sportTypes.cheerleading": "Cheerleading",
  "shared.constants.sportTypes.crew_rowing": "Crew/Rowing",
  "shared.constants.sportTypes.cross_country": "Cross Country",
  "shared.constants.sportTypes.curling": "Curling",
  "shared.constants.sportTypes.cricket": "Cricket",
  "shared.constants.sportTypes.dance": "Dance",
  "shared.constants.sportTypes.diving": "Diving",
  "shared.constants.sportTypes.fencing": "Fencing",
  "shared.constants.sportTypes.field_hockey": "Field Hockey",
  "shared.constants.sportTypes.figure_skating": "Figure Skating",
  "shared.constants.sportTypes.flag_football": "Flag Football",
  "shared.constants.sportTypes.floor_hockey_deck_hockey": "Floor Hockey & Deck Hockey",
  "shared.constants.sportTypes.football": "Football",
  "shared.constants.sportTypes.golf": "Golf",
  "shared.constants.sportTypes.gymnastics": "Gymnastics",
  "shared.constants.sportTypes.hockey": "Hockey",
  "shared.constants.sportTypes.horseback_riding": "Horseback Riding",
  "shared.constants.sportTypes.lacrosse": "Lacrosse",
  "shared.constants.sportTypes.martial_arts": "Martial Arts",
  "shared.constants.sportTypes.multi_sport": "Multi-Sport",
  "shared.constants.sportTypes.pickleball": "Pickleball",
  "shared.constants.sportTypes.polo": "Polo",
  "shared.constants.sportTypes.preschool_sports": "Preschool Sports",
  "shared.constants.sportTypes.rugby": "Rugby",
  "shared.constants.sportTypes.sailing": "Sailing",
  "shared.constants.sportTypes.ski_club": "Ski Club",
  "shared.constants.sportTypes.soccer": "Soccer",
  "shared.constants.sportTypes.softball": "Softball",
  "shared.constants.sportTypes.swimming": "Swimming",
  "shared.constants.sportTypes.tennis": "Tennis",
  "shared.constants.sportTypes.track_and_field": "Track and Field",
  "shared.constants.sportTypes.volleyball": "Volleyball",
  "shared.constants.sportTypes.weightlifting": "Weightlifting",
  "shared.constants.sportTypes.wrestling": "Wrestling",
  "shared.constants.sportTypes.other": "Other",
  "shared.constants.eventSeasons.spring": "Spring",
  "shared.constants.eventSeasons.summer": "Summer",
  "shared.constants.eventSeasons.fall": "Fall",
  "shared.constants.eventSeasons.winter": "Winter",

  'side_nav._UserMenuItem.profile': 'Profile',
  'side_nav._UserMenuItem.settings': 'Settings',
  'side_nav._HelpItem.help': 'Help',
  'side_nav._HelpItem.support': 'Support',
  'side_nav._HelpItem.privacy_policy': 'Privacy Policy',
  'side_nav._SignOutItem.sign_out': 'Sign Out',
  'side_nav._NonLoggedInItems.login': 'Login',
  'side_nav._NonLoggedInItems.create_account': 'Create Account',

  // outside of react object. this is actually in the SideNavItems.js file (title: '.<name>')
  'side_nav._SideNavMenuItem.dashboard': 'Dashboard',
  'side_nav._SideNavMenuItem.events': 'Events',
  'side_nav._SideNavMenuItem.classes': 'Classes',
  'side_nav._SideNavMenuItem.calendar': 'Calendar',
  'side_nav._SideNavMenuItem.marketing': 'Marketing',
  'side_nav._SideNavMenuItem.contacts': 'Contacts',
  'side_nav._SideNavMenuItem.memberships': `${nouns.Membership}s`,
  'side_nav._SideNavMenuItem.credit_passes': 'Credit Passes',
  'side_nav._SideNavMenuItem.reports': 'Reports',
  'side_nav._SideNavMenuItem.resources': 'Resources',
  'side_nav._SideNavMenuItem.retail': 'Retail',
  'side_nav._SideNavMenuItem.coupons': 'Coupons',
  'side_nav._SideNavMenuItem.settings': 'Settings',
  'side_nav._SideNavMenuItem.contacts_report': 'Contacts',
  'side_nav._SideNavMenuItem.payroll_report': 'Payroll',
  'side_nav._SideNavMenuItem.orders_report': 'Orders',
  'side_nav._SideNavMenuItem.balances_report': 'Balances',
  'side_nav._SideNavMenuItem.mode_report': 'Intelligence',
  'side_nav._SideNavMenuItem.payment_plans': 'Payment Plans',
  'side_nav._SideNavMenuItem.transactions_report': 'Transactions',
  'side_nav._SideNavMenuItem.ledger_report': 'Ledger',
  'side_nav._SideNavMenuItem.sales_detail': 'Sales',
  'side_nav._SideNavMenuItem.revenue_report': 'Revenue',
  'side_nav._SideNavMenuItem.monthly_recurring_revenue_report':
    'Membership Analytics',
  'side_nav._SideNavMenuItem.waivers_report': 'Waivers',
  'side_nav._SideNavMenuItem.inventory_report': 'Inventory',
  'side_nav._SideNavMenuItem.daily_closeout_report': 'Closeout Report',
  'side_nav._SideNavMenuItem.ar_vision': 'AR Vision',
  'side_nav._SideNavMenuItem.assessment': 'Assessment',
  'side_nav._SideNavMenuItem.add_ons': 'Add-Ons',
  'side_nav._SideNavMenuItem.teams': 'Teams',

  'StaffDisplay.name': '{firstName} {lastName}.',

  'users.settings.Settings.header': 'Account Settings',

  'users.settings.NotificationsTab.title_global': 'Global Notifications',
  'users.settings.NotificationsTab.desc_global':
    '(Registration confirmation, payment receipts, cancellations, etc.)',
  'users.settings.NotificationsTab.title_reminder': 'Reminder Notifications',
  'users.settings.NotificationsTab.desc_reminder':
    'Choose the method and interval for which you would like to be notified of an upcoming event.',
  'users.settings.NotificationsTab.title_send_reminder': 'Send Reminder',
  'users.settings.NotificationsTab.label_email': 'Email',
  'users.settings.NotificationsTab.label_text': 'Text (SMS)',
  'users.settings.NotificationsTab.label_send_reminder': 'before event',
  'users.settings.NotificationsTab.session_reminder_time_value': '{hours} hr',

  'user_management.shared._AccountInformationEditor.current_password':
    'Current Password',
  'user_management.shared._AccountInformationEditor.new_password':
    'New Password',
  'user_management.shared._AccountInformationEditor.new_password_confirmation':
    'New Password Confirmation',
  'user_management.shared._AccountInformationEditor.password': 'Password',
  'user_management.shared._AccountInformationEditor.password_confirmation':
    'Password Confirmation',
  'user_management.shared._AccountInformationEditor.your_email':
    'Your Email Address',
  'user_management.shared._AccountInformationEditor.your_first_name':
    'Your First Name',
  'user_management.shared._AccountInformationEditor.your_last_name':
    'Your Last Name',

  'user_management.shared.BusinessProfileFields.business': 'Business',
  'user_management.shared.BusinessProfileFields.business_name': 'Business Name',
  'user_management.shared.BusinessProfileFields.business_type': 'Business Type',
  'user_management.shared.BusinessProfileFields.default_currency':
    'Default Currency',
  'user_management.shared.BusinessProfileFields.personal': 'Personal',
  'user_management.shared.BusinessProfileFields.select_timezone':
    'Select Timezone',
  'user_management.shared.BusinessProfileFields.timezone': 'Timezone',
  'user_management.shared.BusinessProfileFields.url': 'URL',
  'user_management.shared.BusinessProfileFields.usd': 'USD',
  'user_management.shared.BusinessProfileFields.sports_offered': 'Sports Offered',

  'user_management.shared.ClientDetailsDrawer.manage_methods': 'Manage Methods',
  'user_management.shared.ClientDetailsDrawer.client_profile': 'Client Profile',
  'user_management.shared.ClientDetailsDrawer.edit_client_profile':
    'Edit Client Profile',
  'user_management.shared.ClientDetailsDrawer.event': `${nouns.Event}`,
  'user_management.shared.ClientDetailsDrawer.info': 'Info',
  'user_management.shared.ClientDetailsDrawer.notes': 'Notes',

  'user_management.shared.UserProfileFields.date_of_birth': 'Date of Birth',
  'user_management.shared.UserProfileFields.required_by_law':
    '(Required by law)',
  'user_management.shared.UserProfileFields.email_address': 'Email Address',
  'user_management.shared.UserProfileFields.example_email': 'email@example.com',
  'user_management.shared.UserProfileFields.female': 'Female',
  'user_management.shared.UserProfileFields.first': 'First',
  'user_management.shared.UserProfileFields.first_name': 'First Name',
  'user_management.shared.UserProfileFields.gender': 'Gender',
  'user_management.shared.UserProfileFields.last': 'Last',
  'user_management.shared.UserProfileFields.last_name': 'Last Name',
  'user_management.shared.UserProfileFields.male': 'Male',
  'user_management.shared.UserProfileFields.select_gender': 'Select Gender',
  'user_management.shared.UserProfileFields.select_timezone': 'Select Timezone',
  'user_management.shared.UserProfileFields.ssn': 'SSN (last 4 digits)',
  'user_management.shared.UserProfileFields.separate_login':
    'Enable Separate Login For Profile',
  'user_management.shared.UserProfileFields.timezone': 'Timezone',
  'user_management.shared.UserProfileFields.zero': '0000',
  'user_management.shared.UserProfileFields.phone': 'Phone',
  'user_management.shared.UserProfileFields.phone_number': 'Phone Number',

  'user_management.shared.InviteDialog.title': 'Invite',
  'user_management.shared.InviteDialog.confirm_label': 'Invite',
  'user_management.shared.InviteDialog.cancel_label': 'Cancel',
  'user_management.shared.InviteDialog.client': 'Client',
  'user_management.shared.InviteDialog.staff': 'Staff',
  'user_management.shared.InviteDialog.instructor': 'Instructor',
  'user_management.shared.InviteDialog.manager': 'Manager',
  'user_management.shared.InviteDialog.admin': 'Admin',
  'user_management.shared.InviteDialog.coach': 'Coach',

  'validation.customer.at_least_one_payment_method':
    'At least one method is required',

  'validation.customer.at_least_one_payment_method_event':
    'At least one method is required in event',

  'validation.customer.at_least_one_payment_method_membership':
    'At least one method is required in membership',

  'validation.customer.at_least_one_payment_method_creditPasses':
    'At least one method is required in credit passes',
  'validation.email.in_use': 'An account already exists for this email',
  'validation.email.invalid': 'Invalid email address',

  'validation.account.settings.password.confirmation': '* Must match New Password',
  'validation.account.settings.password.complexity':
  '* Requires at least 1 numeric and 1 uppercase character',
  'validation.account.settings.current_password':
    '* Required field',

  'validation.password.complexity':
    'requires at least 1 numeric and 1 uppercase character',
  'validation.password.confirmation': 'must match password',
  'validation.password.length': 'must be between 8 and 100 characters',
  'validation.password.reset.no_such_email':
    "We don't have a record for that email address",
  'validation.password.reset.unknown':
    "We're sorry, but something went wrong trying to reset your password. Please try again",
  'client_portal.PortalTabs.ar_vision': 'AR Vision',
  'client_portal.ARVisionTab.header': 'AR Vision',
  'client_portal.ARVisionTab.login': 'Login',
  'client_portal.ARVisionTab.card_text': 'Athlete videos and reviews',

  'list.Table.TableHeaderDesktop.member_name':'Member Name',
  'list.Table.TableHeaderDesktop.active_membership_tier_name':'Tier',
  'list.Table.TableHeaderDesktop.status':'Status',
  'list.Table.TableHeaderDesktop.member_length':'Member Length',
  'list.Table.TableHeaderDesktop.commitment':'Commitment',
  'list.Table.TableHeaderDesktop.price':'Price',
  'list.Table.TableHeaderDesktop.cancellation_date_time': 'Cancellation Date & Time',
  'list.Table.TableHeaderDesktop.next_payment': 'Next Payment',
  'list.Table.TableHeaderDesktop.suspension_date': 'Suspension Date',
  'list.Table.TableHeaderDesktop.reactivation_date': 'Reactivation Date',
  'list.Table.TableHeaderDesktop.invitation_date': 'Invitation Date',

  'memberships.MembershipViewFilterDrawer.membership_status': 'Membership Status',
  'memberships.MembershipViewFilterDrawer.active': 'Active',
  'memberships.MembershipViewFilterDrawer.cancelled': 'Cancelled',
  'memberships.MembershipViewFilterDrawer.suspended': 'Suspended',
  'memberships.MembershipViewFilterDrawer.invited': 'Invited',
  'memberships.MembershipViewFilterDrawer.all': 'All',
  'memberships.MembershipViewFilterDrawer.title': 'Filters',

  'memberships.MembershipMembers.Header.search': 'Search Clients',
  'memberships.MembershipMembers.MembersList.clients': 'Active Clients',
  'memberships.MembershipMembers.MembersList.copy_url': 'Copy URL',
  'memberships.MembershipMembers.MembersList.empty_state_header': 'No members, yet.',
  'memberships.MembershipMembers.MembersList.empty_state_message':
    `Not for long! Copy and share your ${nouns.membership}s page url.`,
  'memberships.MembershipMembers.Header.invite_client_field_hint_text':
    'Invite Client',
  'memberships.MembershipMembers.Header.btn_export': 'Export',
  'memberships.MembershipMembers.Header.btn_filter': 'Filters',
  'memberships.MembershipViewClient._MembershipViewClient.benefits': 'Benefits',
  'memberships.MembershipViewClient._MembershipViewClient.header_text_price': 'Membership Pricing',
  'memberships.MembershipViewClient._MembershipViewClient.header_text_commitments': 'Commitment',
  'memberships.MembershipViewClient._MembershipViewClient.header_text_summary': 'Membership Summary',
  'memberships.MembershipViewClient._MembershipViewClient.header_text_enrollment': 'Enrollment',
  'memberships.MembershipViewClient._PageHeaderBanner.purchase': 'Purchase',
  'memberships.MembershipViewClient._MembershipViewClient.free': 'Free',
  'memberships.MembershipViewClient._MembershipViewClient.monthly': 'Monthly',
  'memberships.MembershipViewClient._MembershipViewClient.annually': 'Annually',
  'memberships.MembershipViewClient._MembershipViewClient.lifetime': 'Lifetime',
  'memberships.MembershipViewClient._MembershipViewClient.join_fee': 'Join Fee',
  'memberships.MembershipViewClient._MembershipViewClient.header': 'Memberships',
  'memberships.MembershipViewClient._MembershipViewClient.interval_months': '{months} Months',
  'memberships.MembershipViewClient._MembershipViewClient.commitment_months': '{months} Months',
  'memberships.MembershipViewClient._MembershipViewClient.one_month_commitment': '{months} Month',
  'memberships.MembershipViewClient._MembershipViewClient.no_enrollments': 'No clients enrolled.',
  'memberships.MembershipViewClient._MembershipViewClient.no_commitment': 'No Commitment.',
  'memberships.MembershipViewClient._MembershipViewClient.no_join_fee': 'No Join Fee',
  'memberships.MembershipViewClient._MembershipViewClient.purchase': 'Purchase',

  'memberships.MembershipViewClient._PageHeaderBanner.commitment_months': '{months} Month Commitment',
  'memberships.MembershipViewClient._PageHeaderBanner.no_commitment': 'No Commitment.',
  'memberships.MembershipViewClient._PageHeaderBanner.memberships': 'Memberships',
  'memberships.MembershipViewClient._PageHeaderBanner.one_month_commitment': '{months} Month Commitment',
  'memberships.MembershipViewClient._PageHeaderBanner.no_join_fee': 'No Join Fee',

  'memberships.MembershipViewClient._MembershipTierCard.best_value': 'Best Value',
  'memberships.MembershipViewClient._MembershipTierCard.pricing_info': 'plus taxes & fees',
  'memberships.MembershipViewClient._MembershipTierCard.join_fee': 'Join Fee',
  'memberships.MembershipViewClient._MembershipTierCard.no_join_fee': 'No Join Fee',
  'memberships.MembershipViewClient._MembershipTierCard.no_commitment': 'No Commitment',
  'memberships.MembershipViewClient._MembershipTierCard.commitment': 'Commitment',
  'memberships.MembershipViewClient._MembershipTierCard.view_benefits': 'View Membership Benefits',
  'memberships.MembershipViewClient._MembershipTierCard.no_benefits': 'No discounts or credits assigned to this membership. See membership summary or contact business for more information.',

  'team_mgmt.index._Content.no_team': 'Create a team or adjust your filters to view more teams.',
  'team_mgmt.index._Header.manage_teams': 'Manage Teams',
  'team_mgmt.index._Header.create_new_team': 'Create Team',

  'team_mgmt.editing._Header.preview': 'Preview',
  'team_mgmt.editing._Header.post': 'Post',

  'team_mgmt.editing._TabTeamInformation.team_title': 'Team Name',
  'team_mgmt.editing._TabTeamInformation.team_placeholder': 'Enter Team Name',
  'team_mgmt.editing._TabTeamInformation.team_type': 'Team Type',
  'team_mgmt.editing._TabTeamInformation.team_type_placeholder': 'Select Team Type' ,
  'team_mgmt.editing._TabTeamInformation.private_team': 'Private Team',
  'team_mgmt.editing._TabTeamInformation.sport_type': 'Sport Type',
  'team_mgmt.editing._TabTeamInformation.sport_type_placeholder': 'Select Sport Type',
  'team_mgmt.editing._TabTeamInformation.seasons': 'Seasons',
  'team_mgmt.editing._TabTeamInformation.sport_type_tooltip': 'This information will be used for insurance and data purposes. If you do not see your sport listed please select Other',
  'team_mgmt.editing._TabTeamInformation.winter': 'Winter {year}',
  'team_mgmt.editing._TabTeamInformation.spring': 'Spring {year}',
  'team_mgmt.editing._TabTeamInformation.summer': 'Summer {year}',
  'team_mgmt.editing._TabTeamInformation.fall': 'Fall {year}',

  'team_mgmt.editing._TabMarketing.team_description':'Team Description',
  'team_mgmt.editing._TabMarketing.team_image': 'Team Image',
  'team_mgmt.editing._TabMarketing.team_title': 'Save team with a title to create url.',
  'team_mgmt.editing._TabMarketing.team_url': 'Team URL',

  'team_mgmt.editing._TabParticipants.age': 'AGE',
  'team_mgmt.editing._TabParticipants.min_age': 'Min',
  'team_mgmt.editing._TabParticipants.max_age': 'Max',
  'team_mgmt.editing._TabParticipants.coed': 'Coed',
  'team_mgmt.editing._TabParticipants.roster_size':'Roster Size' ,
  'team_mgmt.editing._TabParticipants.female': 'Female',
  'team_mgmt.editing._TabParticipants.gender': 'GENDER',
  'team_mgmt.editing._TabParticipants.male': 'Male',

  'team_mgmt.editing._TabPricing.add_automation': 'Add Automation',
  'team_mgmt.editing._TabPricing.add_warning': 'Save Team "{teamTitle}" to add automation.',
  'team_mgmt.editing._TabPricing.automation': 'Automation',
  'team_mgmt.editing._TabPricing.free_team': 'Free team',
  'team_mgmt.editing._TabPricing.list_price': 'List Price',
  'team_mgmt.editing._TabPricing.processing_fee': 'Processing Fee',
  'team_mgmt.editing._TabPricing.total_earnings': 'Total Earnings',

  'team_mgmt.editing._TabsContainer.team_info': 'Team Information',
  'team_mgmt.editing._TabsContainer.athletes': 'Athletes',
  'team_mgmt.editing._TabsContainer.pricing': 'Pricing',
  'team_mgmt.editing._TabsContainer.marketing': 'Marketing',
  'team_mgmt.editing._TabsContainer.registration': 'Registration',

  'team_mgmt.editing._TabPricing.bank': 'Bank',
  'team_mgmt.editing._TabPricing.card': 'Card',
  'team_mgmt.editing._TabPricing.pay_later': 'Pay Later',
  'team_mgmt.editing._TabPricing.payment_methods': 'Payment Methods',
  'team_mgmt.editing._TabPricing.info': 'Select which payment methods your clients can choose during checkout. This will override the default ones set in Business Settings. ',

  'team_mgmt.editing._TabRegistrationForm.team_form_directions': 'Check a field below to make it mandatory for team registration.',
  'team_mgmt.editing._TabRegistrationForm.add_fields_directions':
  "Choose what information you'd like to collect about athletes when they register. Select a category below and add a field from the dropdown.",
  'team_mgmt.editing._TabRegistrationForm.team_form_fields': 'Team Form Fields ',
  'team_mgmt.editing._TabRegistrationForm.team_form_fields_check':
    '(check required)',
  'team_mgmt.editing._TabRegistrationForm.field_categories':
    'Registration Field Categories:',
  'team_mgmt.editing._TabRegistrationForm.field_options':
    'Field Options (Separate by comma)',

    'team_mgmt.index._FilterButton.filter': 'Filter',
    'team_mgmt.index._ContentTable.status': 'status',
  'team_mgmt.index._ContentTable.name': 'name',
  'team_mgmt.index._ContentTable.roster_size': 'Registered/Max',
  'team_mgmt.index._ContentTable.price': 'price',
  'team_mgmt.index._ContentTable.private': 'private',

  'team_mgmt.index._ContentCards.status': 'status',
  'team_mgmt.index._ContentCards.roster_size': 'Registered/Max',

  'team_mgmt.index._ContentToolbar.action': 'Action',
  'team_mgmt.index._ContentToolbar.all': 'All',
  'team_mgmt.index._ContentToolbar.cancel': 'Cancel',
  'team_mgmt.index._ContentToolbar.complete': 'Complete',
  'team_mgmt.index._ContentToolbar.edit': 'Edit',
  'team_mgmt.index._ContentToolbar.clone': 'Clone',
  'team_mgmt.index._ContentToolbar.enrolled': 'Enrolled',
  'team_mgmt.index._ContentToolbar.search': 'Search',
  'team_mgmt.index._ContentToolbar.show': 'Show: ',
  'team_mgmt.index._ContentToolbar.view': 'View',
  'team_mgmt.index._ContentToolbar.delete': 'Delete',
  'team_mgmt.index._ContentToolbar.undelete': 'Undelete',
  'team_mgmt.index._ContentToolbar.delete_message': `If there are active registrants for this team, they will not be notified of this team deletion. Are you sure you want to delete this team?`,
  'team_mgmt.index._ContentToolbar.team_title': 'Delete {team}?',

  'team_mgmt.index._ContentCards.price': 'Price',
  'team_mgmt.index._ContentCards.private': 'Private',
  'team_mgmt.index._FilterDrawer.team_status': "Team Status",
  'team_mgmt.index._FilterDrawer.team_type': 'Team Type',

  'team_mgmt.shared.post_success': 'Team posted successfully.',
  'team_mgmt.shared.update_success': 'Team updated successfully.',
  'team_mgmt.shared.create_success': 'Team created successfully.',
  'team_mgmt.shared.clone_success': 'Team cloned successfully.',

  'shared.pricing._PricingFields.price_field_label': 'List Price',
  'shared.pricing._PricingFields.fee_field_label': 'Processing Fee',
  'shared.pricing._PricingFields.earnings_field_label': 'Total Earnings',
  'shared.pricing._PricingFields.fee_info': '({n}% + ${m} per transaction)', // eslint-disable-line
  'shared.pricing._PricingFields.interchange_price_label': 'Team Price',

  'team_mgmt.shared._TeamTitle.team_title': 'Team Name',
  'team_mgmt.shared._TeamTitle.private': 'Private',
  'team_mgmt.shared._TeamTitle.register_btn': 'Register',
  'team_mgmt.shared._TeamTitle.registered_btn': 'Registered',
  'team_mgmt.shared._TeamToolbar.teams': 'Teams',


  'TeamTypeEditor.Modal.cancel_button': 'Cancel',
  'TeamTypeEditor.Modal.success_button': 'Submit',
  'TeamTypeEditor.Modal.title_edit_mode': 'Edit Team Type',
  'TeamTypeEditor.Modal.title_create_mode': 'New Team Type',
  'TeamTypeEditor.TeamTypeEditor.edit_button': 'Edit',
  'TeamTypeEditor.TeamTypeEditor.placeholder': 'Select Team Type',
  'TeamTypeEditor.TeamTypeEditor.new_item': '+ New Team Type',

  'shared.AutomationListDrawer._AutomationListDrawer.automation': 'Automation',
  'shared.AutomationListDrawer._AutomationListDrawer.add': 'Add',

  'shared.TeamTypeForm.color_label': 'Team Color',
  'shared.TeamTypeForm.name_label': 'Team Type Name',

  'containers.teams.client.teams_tab.TeamCard.btn': 'Register',
  'containers.teams.client.teams_tab.TeamCard.registered_btn': 'Registered',
  'containers.teams.client.teams_tab.TeamCard.registration_closed': 'Registration Closed',
  'containers.teams.client.teams_tab.BrowseTeamsWrapped.no_results': 'No Results',
  'containers.teams.client.teams_tab.TeamsCompact.filters': 'Filters',

  'containers.events.admin.sessionSummaryDrawer.editDateTime.EditDateTime.location': 'Location',
  'containers.events.admin.clients.Filters.search_label-client': 'Search',
  'containers.events.admin.clients.Filters.search': 'Search Roster',
  'containers.events.admin.clients.TableHeader.name': 'Athlete Name',
  'containers.events.admin.clients.TableHeader.managed_by': 'Managed By',

  'containers.reports.paymentDetailsDrawer.PaymentDetailsDrawer.title': 'Balance',
  'containers.waiver.WaiverAgreementDrawer.agreement_header_label': 'Waiver Policy & Terms',
  'containers.waiver.WaiverAgreementDrawer.agreement_title': 'Athlete Waiver & Release Agreement',
  'containers.waiver.WaiverAgreementDrawer.client_name': 'Client Name: ',
  'containers.waiver.WaiverAgreementDrawer.acceptance_date': 'Acceptance Date: ',
  'containers.waiver.WaiverAgreementDrawer.download': 'Download',
  'containers.waiver.WaiverAgreementDrawer.policy_terms': 'Waiver Policy & Terms',
  'containers.waiver.WaiverAgreementDrawer.view_policy_and_terms': 'View Policy & Terms',
  'containers.waiver.WaiverAgreementDrawer.view_privacy_and_cookies':
    'View Privacy & Cookies Policy',
  'containers.waiver.WaiverAgreementDrawer.accept': 'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName} and Upper Hand Inc.',

  'containers.clientProfile.AdditionalEmails._AdditionalEmails.header':'Additional Emails',
  'containers.clientProfile.AdditionalEmails._AdditionalEmails.add_email':'Add Email',
  'containers.clientProfile.AdditionalEmails._AdditionalEmails.additional_emails_tooltip':'Add additional emails to receive notifications for multiple users',

  'user_management.shared.AdditionalEmailsDialog.title': 'Additional Emails',
  'user_management.shared.AdditionalEmailsDialog.update': 'Update',
  'user_management.shared.AdditionalEmailsDialog.create': 'Create',

  'user_management.shared.AdditionalEmailsEditor.first_name': 'First Name',
  'user_management.shared.AdditionalEmailsEditor.last_name': 'Last Name',
  'user_management.shared.AdditionalEmailsEditor.email_address': 'Email Address',
  'user_management.shared.AdditionalEmailsEditor.example_email': 'email@example.com',
  'user_management.shared.AdditionalEmailsEditor.is_required': 'This field is required',
  'user_management.shared.AdditionalEmailsEditor.invalid_email': 'Invalid email address',
  'user_management.shared.AdditionalEmailsEditor.add_success': 'Additional Email Added Successfully',
  'user_management.shared.AdditionalEmailsEditor.update_success': 'Additional Email Updated Successfully',
  'user_management.shared.AdditionalEmailsEditor.delete_success': 'Additional Email Deleted Successfully',
};

