import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { injectIntl } from 'react-intl';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import StateSelector from 'shared/components/StateSelector.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

// eslint-disable-next-line import/no-cycle
import {
  CardHolderField,
  CardNumberField,
  CvcField,
  ExpiryMonthYearField,
  ZipCodeField,
  FormTextField,
} from '../fields';
import { PaysafeService } from '../../services';

class PaysafeCardFormFieldsImpl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.cardNumberFieldId = `cardNumber-${uuidv4()}`;
    this.cvvFieldId = `cvv-${uuidv4()}`;
    this.expiryDateFieldId = `expiryDate-${uuidv4()}`;
  }

  componentDidMount() {
    const { intl } = this.props;

    window.paysafe.fields.setup(
      window.paysafe_token_api_key,
      {
        environment: window.paysafe_env,
        fields: {
          cardNumber: {
            selector: `#${this.cardNumberFieldId}`,
            placeholder: t('.card_number', intl, __filenamespace),
          },
          cvv: {
            selector: `#${this.cvvFieldId}`,
            placeholder: t('.zero', intl, __filenamespace),
            optional: false,
          },
          expiryDate: {
            selector: `#${this.expiryDateFieldId}`,
            placeholder: t(
              '.expiration_date_placeholder',
              intl,
              __filenamespace
            ),
          },
        },
        style: {
          input: {
            color: 'rgb(57, 60, 68)',
            'font-weight': 'lighter',
          },
        },
      },
      PaysafeService.setupCallback
    );
  }

  render() {
    const { intl, card, isEditing, style, onChange } = this.props;
    const { billingAddress } = card;

    return (
      <div style={style} className="new-card-form">
        {!isEditing && (
          <div className="new-card-form__card-info">
            <span className="new-card-form__card-info-label">Card Info</span>
            <CardNumberField
              fieldId={this.cardNumberFieldId}
              errorText={card.errors.getErrors('number')}
              hideHint
            >
              <div id={this.cardNumberFieldId} />
            </CardNumberField>
            <CardHolderField
              errorText={card.errors.getErrors('name')}
              onChangeCardHolder={onChange}
              value={card.name}
            />
            <FlexBoxJustify style={{ flexWrap: 'wrap', margin: '0 -5px' }}>
              <div style={{ flex: '1 0 5em', margin: '0 5px' }}>
                <ExpiryMonthYearField hideHint fieldId={this.expiryDateFieldId}>
                  <div id={this.expiryDateFieldId} />
                </ExpiryMonthYearField>
              </div>
              <div style={{ flex: '1 0 5em', margin: '0 5px' }}>
                <CvcField hideHint fieldId={this.cvvFieldId}>
                  <div id={this.cvvFieldId} />
                </CvcField>
              </div>
            </FlexBoxJustify>
          </div>
        )}
        <div className="new-card-form__billing-details">
          <span className="new-card-form__card-info-label">
            Billing Details
          </span>
          <FormTextField
            label={t('.street_address', intl, __filenamespace)}
            placeholder={t('.street_address', intl, __filenamespace)}
            name="street"
            value={billingAddress.street}
            errorText={card.errors.getErrors('street')}
            onChange={onChange}
          />
          <FormTextField
            label={t('.address_line_2', intl, __filenamespace)}
            placeholder={t('.address_line_2', intl, __filenamespace)}
            name="street2"
            value={billingAddress.street2}
            errorText={card.errors.getErrors('street2')}
            onChange={onChange}
          />
          <FormTextField
            label={t('.city', intl, __filenamespace)}
            placeholder={t('.city', intl, __filenamespace)}
            name="city"
            value={billingAddress.city}
            errorText={card.errors.getErrors('city')}
            onChange={onChange}
          />
          <ZipCodeField
            errorText={card.errors.getErrors('zip')}
            onChangeZipCode={onChange}
            value={billingAddress.zip}
          />
          <StateSelector
            useAbbreviations
            disableUnderline={false}
            outline={false}
            value={billingAddress.state}
            name="state"
            label={t('.state', intl, __filenamespace)}
            placeholder={t('.state', intl, __filenamespace)}
            errorText={card.errors.getErrors('state')}
            classes={{
              root: 'new-card-form__state-selector',
              select: 'new-card-form__state-selector-select',
              placeholder: 'new-card-form__state-selector-placeholder',
              inputLabelTypography: 'new-card-form__state-selector-input-label',
            }}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

const PaysafeCardForm = injectIntl(PaysafeCardFormFieldsImpl);

export default PaysafeCardForm;
